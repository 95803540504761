import { useState } from "react"
import styled from "styled-components";
import { cartMultiReducer, getOrderDays, RootState } from "@tiffin/app-common"
import { Day, IMenuItem } from "@tiffin/core";
import { MenuLegend } from "./MenuLegend";
import { MenuItem } from "./MenuItem";
import { connect, ConnectedProps } from "react-redux"
import { Tabs } from "antd";

const { TabPane } = Tabs

const MenuCategoryTabs = styled(BaseMenuCategoryTabs)`
  .ant-tabs {
    .ant-tabs-nav-wrap {
      background: #fff;
      color: var(--color-dark);
      font-weight: 600;

      .ant-tabs-nav-list {
        margin-left: 20px;

        .ant-tabs-tab {
          padding: 24px 20px;
        }

        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: var(--app-base-color);
          }
        }

        .ant-tabs-ink-bar {
          background: var(--app-base-color);
          height: 3px;
        }
      }
    }
  }

  .menu-legend {
    margin-left: 20px;
    padding-bottom: 20px;
  }
  
  .menu-group {
    display: grid;
    grid-row: auto;
    grid-auto-rows: min-content;

    .menu-group-title {
      padding: 20px 10px 10px;
      font-size: 24px;
      font-weight: 700;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--app-base-color);
    }

    .menu-items {
      margin: 0 20px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
      gap: 10px;

      .menu-item-card {
        border: 1px solid var(--ion-color-light);
        border-radius: 10px;
      }
    }
  }
`

//Steps for connected redux component
const mapState = (state: RootState) => ({
  partner: state.store
})
const connector = connect(mapState, null)
type PropsFromRedux = ConnectedProps<typeof connector>

interface IMenuCategoryTabs extends PropsFromRedux {
  className?: string,
  categories: Map<string, { order: number; items: IMenuItem[] }>,
  navigateItem: (id: string, key: string) => void,
  cart: cartMultiReducer.IStoreCart
}

function BaseMenuCategoryTabs({className, categories, navigateItem, cart, partner}: IMenuCategoryTabs) {

  const [activeMenuTabKey, setActiveMenuTabKey] = useState<string>()

  function renderGroups() {
    var items = []
    for (let [key, value] of categories) {
      items[value.order] = renderGroup(key, value)
    }
    return items
  }

  function renderGroup(key: string, value: { order: number; items: IMenuItem[] }) {
    let orderDays = Object.keys(getOrderDays(cart.orderType, partner.details.pickupDays, partner.details.deliveryDays))

    let items = value.items.filter((value: IMenuItem) => {
      return value.availability.some((day) => orderDays.includes(day.toString()))
    })

    if (items && items.length > 0) {
      return (
        <TabPane tab={key} key={value.order} closable={false}>
          <div className="menu-legend">
            <MenuLegend categories={categories} />
          </div>
          <div key={key} className="menu-group">
            <div className="menu-items">
              {items.map((item, i) => {
                return renderTiffinMenuItem(item, key, orderDays)
              })}
            </div>
          </div>
        </TabPane>
      )
    }
  }

  function renderTiffinMenuItem(item: IMenuItem, group: string, orderDays: string[]) {
    let availability = item.availability.filter((day: Day) => {
      if (orderDays.includes(day.toString())) {
        return true
      } else {
        return false
      }
    })
    return <MenuItem unavailable={partner?.details.status === 'inactive'} key={item.id} group={group} {...item} availability={availability} onClickCallback={navigateItem} />
  }

  return (
    <div className={className}>
      <Tabs activeKey={activeMenuTabKey} onChange={(key) => {
        setActiveMenuTabKey(key);
      }} tabPosition="top">
        {renderGroups()}
      </Tabs>
    </div>
  )
}

export default connector(MenuCategoryTabs);