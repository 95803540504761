import styled from "styled-components";
import { DateFormat, Day, DietaryAttribute, IMenuItem, OutOfStockType } from "@tiffin/core"
import { Moment } from "moment"
import { Tooltip } from "antd";

import VeganSVG from '../assets/vegan-icon.svg';
import VegetarianSVG from '../assets/vegetarian-icon.svg';
import GlutenFreeSVG from '../assets/gluten-free.svg';
import HalalSVG from '../assets/halal.svg';
import KosherSVG from '../assets/kosher.svg';
import NutFreeSVG from '../assets/nut-free.svg';
import LactoseFreeSVG from '../assets/lactose-free.svg';

export const MenuItem = styled(BaseMenuItem)`
  position: relative;
	display: grid;
  grid-template-columns: ${(props) => props.imageUrl ? '1fr 25%' : '100%'};
	gap: 5px;
	border-bottom: 1px solid #F0F0F0;
	background-color: #FFF;
	min-height: 4em;
  overflow: hidden;
  box-shadow: rgba(128, 128, 128, 0.08) -2px 2px 2px, rgba(128, 128, 128, 0.04) 0px 2px 2px;
  border-radius: 8px;

  @media (min-width:768px)  {
    grid-template-columns: ${(props) => props.imageUrl ? '1fr 150px' : '100%'};
  }

  .content {
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr auto;
    gap: 7px;
    background: #fff;
  }

  .item-unavailable {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--ion-color-light);
    opacity: 0.7;
    font-weight: 700;
  }

  .item-image {
    height: 100%;
    width: auto;
    max-width: 150px;
    background-position: center center;
    background-size: cover;
  }

  .item-title {
    gap: 5px;
    text-align: left;
    font-size: 16px;
    font-weight: 600;

    .dietary-attributes {
      display: inline-flex;
      vertical-align: middle;
      padding-left: 5px;
      gap: 5px;

      .dietary-icon {
        display: flex;

        svg {
          width: 20px;
          height: 20px;
        }

        &.vegetarian {
          width: 15px;
        }
      }
    }
  }

  .item-description {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    grid-row: 2;
    grid-column: 1/3;
    white-space: pre-wrap;
  }

  .item-price {
    font-weight: 600;
    color: var(--ion-color-dark);
    grid-column: 2;
  
    text-align: right;

    span {
      padding: 4px 10px;
      background: var(--ion-color-light);
      border-radius: 6px;
    }
  }
  
  .item-availability {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(7, min-content);
    padding: 5px 0px;
    grid-column: 1/3;
  }
  
  .availability-icon {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    border-radius: 2em;
    border: 1px solid var(--app-base-color);
    background-color: var(--app-base-color);
    width: 1.8em;
    height: 1.8em;
  }
  
  .unavailable {
    color: var(--app-base-color);
    background-color: #FFF;
    opacity: 0.5;
  }
  
  .disabled {
    color: #A0A0A0;
    border-color: #A0A0A0;
    background-color: #FFF;
  }
`

export interface IMenuItemProps extends IMenuItem {
  className?: string
  group: string
  unavailable?: boolean
  date?: Moment
  onClickCallback?: (id: string, group: string, date?: Moment) => void,
  displayAvailability?: boolean
}

export function BaseMenuItem({className, ...props}: IMenuItemProps) {

  function isItemOutOfStock() {
    if(props.outOfStockType !== OutOfStockType.None && props.date) {
      if(props.outOfStockDates?.includes(props.date.format(DateFormat))) {
        return true
      }
    }
    return false
  }

  function cardClickHandler() {
    if(outOfStock) {
      //Item is not clickable if it is out of stock
      return;
    }

    if (props.onClickCallback) {
      props.onClickCallback(props.id, props.group, props.date)
    }
  }

  const outOfStock = isItemOutOfStock()

  return (
    <div className={className} onClick={cardClickHandler}>
      {
        props.unavailable &&
        <div className="item-unavailable">
          Unavailable
        </div>
      }
      {
        !props.unavailable && outOfStock &&
        <div className="item-unavailable">
          Unavailable
        </div>
      }
      <div className="content">
        <div className="item-title">
          {props.name}
          <div className="dietary-attributes">
            {props.dietaryAttributes?.includes(DietaryAttribute.Vegan) && <Tooltip title="Vegan"><div className="dietary-icon"><VeganSVG/></div></Tooltip>}
            {props.dietaryAttributes?.includes(DietaryAttribute.Vegetarian) && <Tooltip title="Vegetarian"><div className="dietary-icon vegetarian"><VegetarianSVG/></div></Tooltip>}
            {props.dietaryAttributes?.includes(DietaryAttribute.GlutenFree) && <Tooltip title="Gluten Free"><div className="dietary-icon"><GlutenFreeSVG/></div></Tooltip>}
            {props.dietaryAttributes?.includes(DietaryAttribute.Halal) && <Tooltip title="Halal"><div className="dietary-icon"><HalalSVG/></div></Tooltip>}
            {props.dietaryAttributes?.includes(DietaryAttribute.Kosher) && <Tooltip title="Kosher"><div className="dietary-icon"><KosherSVG/></div></Tooltip>}
            {props.dietaryAttributes?.includes(DietaryAttribute.NutFree) && <Tooltip title="Nut Free"><div className="dietary-icon"><NutFreeSVG/></div></Tooltip>}
            {props.dietaryAttributes?.includes(DietaryAttribute.LactoseFree) && <Tooltip title="Lactose Free"><div className="dietary-icon"><LactoseFreeSVG/></div></Tooltip>}
          </div>
        </div>
        <div className="item-description">{props.description}</div>
        <div className="item-price">
          <span>
            ${props.price.toFixed(2)}
          </span>
        </div>
        {
          props.displayAvailability &&
          <div className="item-availability">
            <div className={`availability-icon ${!props.availability.includes(Day.Monday) ? "unavailable" : ""}`}>M</div>
            <div className={`availability-icon ${!props.availability.includes(Day.Tuesday) ? "unavailable" : ""}`}>T</div>
            <div className={`availability-icon ${!props.availability.includes(Day.Wednesday) ? "unavailable" : ""}`}>W</div>
            <div className={`availability-icon ${!props.availability.includes(Day.Thursday) ? "unavailable" : ""}`}>T</div>
            <div className={`availability-icon ${!props.availability.includes(Day.Friday) ? "unavailable" : ""}`}>F</div>
            <div className={`availability-icon ${!props.availability.includes(Day.Saturday) ? "unavailable" : ""}`}>S</div>
            <div className={`availability-icon ${!props.availability.includes(Day.Sunday) ? "unavailable" : ""}`}>S</div>
          </div>
        }
      </div>
      {
        props.imageUrl &&
        <div className="item-image" style={{backgroundImage: `url(${props.imageUrl})`}}></div>
      }
    </div>
  )
}
