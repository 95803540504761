var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//firebase
import { auth, db } from "../firebase";
import { updateEmail, updateProfile } from "firebase/auth";
import { collection, getDoc, setDoc, doc, updateDoc, arrayUnion, arrayRemove, query, where, limit, getDocs, onSnapshot } from "firebase/firestore";
import { OrderStatus } from "@tiffin/core";
export function signOut() {
    localStorage.removeItem("tifyn-shown-welcome");
    localStorage.removeItem("tifyn-category-tutorial");
    localStorage.removeItem("tifyn-menu-tutorial");
    if (auth.currentUser) {
        auth.signOut();
    }
}
export function createUserInfo(user) {
    return __awaiter(this, void 0, void 0, function () {
        var usersRef, newDoc;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    usersRef = collection(db, "users");
                    //Set display name
                    return [4 /*yield*/, updateProfile(auth.currentUser, {
                            displayName: "".concat(user.firstName, " ").concat(user.lastName),
                        })];
                case 1:
                    //Set display name
                    _a.sent();
                    return [4 /*yield*/, updateEmail(auth.currentUser, user.email)];
                case 2:
                    _a.sent();
                    newDoc = __assign({}, user);
                    delete newDoc.uid;
                    return [2 /*return*/, setDoc(doc(usersRef, user.uid), __assign({}, newDoc), {
                            merge: true
                        })];
            }
        });
    });
}
export function getUserInfo() {
    return new Promise(function (resolve, reject) {
        if (auth.currentUser) {
            var docRef = doc(db, "users", auth.currentUser.uid);
            getDoc(docRef).then(function (docSnap) {
                if (docSnap.exists()) {
                    resolve(docSnap.data());
                }
                else {
                    reject("User record not found. Please contact Tifyn customer support");
                }
            });
        }
    });
}
export function updateCustomerPolicyAck(version) {
    return __awaiter(this, void 0, void 0, function () {
        var docRef;
        return __generator(this, function (_a) {
            docRef = doc(db, "users", auth.currentUser.uid);
            updateDoc(docRef, {
                policyAckVer: version
            });
            return [2 /*return*/];
        });
    });
}
export function updatePartnerPolicyAck(version) {
    return __awaiter(this, void 0, void 0, function () {
        var docRef;
        return __generator(this, function (_a) {
            docRef = doc(db, "users", auth.currentUser.uid);
            updateDoc(docRef, {
                partnerPolicyAckVer: version
            });
            return [2 /*return*/];
        });
    });
}
export function updateUserInfo(values) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: 
                //Set display name
                return [4 /*yield*/, updateProfile(auth.currentUser, {
                        displayName: "".concat(values.firstName, " ").concat(values.lastName),
                    })];
                case 1:
                    //Set display name
                    _a.sent();
                    return [4 /*yield*/, updateEmail(auth.currentUser, values.email)];
                case 2:
                    _a.sent();
                    return [2 /*return*/, new Promise(function (resolve, reject) {
                            if (values.firstName && values.lastName && values.phone) {
                                if (auth.currentUser) {
                                    var docRef = doc(db, "users", auth.currentUser.uid);
                                    var update = {
                                        firstName: values.firstName,
                                        lastName: values.lastName,
                                        email: values.email,
                                    };
                                    if (values.policyAckVer) {
                                        update.policyAckVer = values.policyAckVer;
                                    }
                                    if (values.partnerPolicyAckVer) {
                                        update.partnerPolicyAckVer = values.partnerPolicyAckVer;
                                    }
                                    updateDoc(docRef, update).then(function (value) {
                                        //Update display name
                                        updateProfile(auth.currentUser, {
                                            displayName: "".concat(values.firstName, " ").concat(values.lastName),
                                        }).then(function (result) {
                                            resolve(true);
                                        }, function () {
                                            reject("An error occurred updating user information. Please contact customer support.");
                                        });
                                    }, function (error) {
                                        reject("An error occurred updating user information. Please contact customer support.");
                                    });
                                }
                            }
                            else {
                                reject("Invalid user information");
                            }
                        })];
            }
        });
    });
}
export function updateUserAddresses(addresses) {
    return new Promise(function (resolve, reject) {
        if (auth.currentUser) {
            var docRef = doc(db, "users", auth.currentUser.uid);
            updateDoc(docRef, {
                addresses: addresses,
            }).then(function (value) {
                //Update display name
                resolve(true);
            }, function (error) {
                reject("An error occurred adding delivery address. Please contact customer support.");
            });
        }
    });
}
export function updateUserPickupAddresses(addresses) {
    return new Promise(function (resolve, reject) {
        if (auth.currentUser) {
            var docRef = doc(db, "users", auth.currentUser.uid);
            updateDoc(docRef, {
                pickupAddresses: addresses,
            }).then(function (value) {
                //Update display name
                resolve(true);
            }, function (error) {
                reject("An error occurred adding delivery address. Please contact customer support.");
            });
        }
    });
}
export function updateUserFavourites(favourites) {
    return new Promise(function (resolve, reject) {
        if (auth.currentUser) {
            var docRef = doc(db, "users", auth.currentUser.uid);
            updateDoc(docRef, {
                favourites: favourites
            }).then(function (value) {
                //Update display name
                resolve(true);
            }, function (error) {
                reject("An error occurred updating favourites. Please contact customer support.");
            });
        }
    });
}
export function updatePartnerDeviceRegistrationToken(token, remove) {
    return new Promise(function (resolve, reject) {
        if (auth.currentUser) {
            var docRef = doc(db, "users", auth.currentUser.uid);
            updateDoc(docRef, {
                partnerNotificationTokens: remove ? arrayRemove(token) : arrayUnion(token),
            }).then(function (value) {
                resolve();
            }, function (error) {
                console.error(error);
                reject("An error occurred adding the registration token.");
            });
        }
    });
}
export function updateCustomerDeviceRegistrationToken(token, remove) {
    return new Promise(function (resolve, reject) {
        if (auth.currentUser) {
            var docRef = doc(db, "users", auth.currentUser.uid);
            updateDoc(docRef, {
                customerNotificationTokens: remove ? arrayRemove(token) : arrayUnion(token),
            }).then(function (value) {
                resolve();
            }, function (error) {
                console.error(error);
                reject("An error occurred adding the registration token.");
            });
        }
    });
}
export function setPendingDelete() {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                    var ordersRef, ordersQuery, querySnapshot, docRef;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                ordersRef = collection(db, "orders");
                                ordersQuery = query(ordersRef, where("customerId", "==", auth.currentUser.uid), where("status", ">=", OrderStatus.Unconfirmed), where("status", "<", OrderStatus.Completed), limit(1));
                                return [4 /*yield*/, getDocs(ordersQuery)];
                            case 1:
                                querySnapshot = _a.sent();
                                if (querySnapshot.size > 0) {
                                    reject("Cannot submit delete request as you have pending orders");
                                    return [2 /*return*/];
                                }
                                docRef = doc(db, "users", auth.currentUser.uid);
                                return [4 /*yield*/, updateDoc(docRef, {
                                        pendingDelete: true
                                    })];
                            case 2:
                                _a.sent();
                                resolve();
                                return [2 /*return*/];
                        }
                    });
                }); })];
        });
    });
}
export function updateUserAnalytics(update) {
    return __awaiter(this, void 0, void 0, function () {
        var docRef;
        return __generator(this, function (_a) {
            docRef = doc(db, "analytics", auth.currentUser.uid);
            setDoc(docRef, __assign(__assign({}, update), { lastLogin: new Date().toISOString() }), { merge: true });
            return [2 /*return*/];
        });
    });
}
export function getUserToken() {
    return __awaiter(this, void 0, void 0, function () {
        var adminConfigRef, adminConfigDoc, adminConfig;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    adminConfigRef = doc(db, "admin", "configuration");
                    return [4 /*yield*/, getDoc(adminConfigRef)];
                case 1:
                    adminConfigDoc = _a.sent();
                    adminConfig = adminConfigDoc.data();
                    if (adminConfig.token) {
                        return [2 /*return*/, adminConfig.token];
                    }
                    return [2 /*return*/];
            }
        });
    });
}
export function notifyWhenStripeCustomerIdAssigned(callback) {
    var unsubscribe = onSnapshot(doc(db, "users", auth.currentUser.uid), function (doc) {
        if (doc.data().customerId) {
            unsubscribe();
            callback();
        }
    });
}
