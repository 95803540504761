export var Constants = /** @class */ (function () {
    function Constants() {
    }
    Constants.SERVICE_FEE = 0.05; // This is the service fee we charge customer
    Constants.ERRORMESSAGE_CONFIRMPASSWORD = "Please confirm your password";
    Constants.ERRORMESSAGE_ENSUREDETAILSARECORRECT = "Please ensure all details are correct";
    Constants.ERRORMESSAGE_ENSUREEMAILANDPASSWORDCORRECT = "Please ensure both email and password are correct";
    Constants.ERRORMESSAGE_ENSUREPHONENUMBERCORRECT = "Please ensure you have entered a valid mobile number";
    Constants.ERRORMESSAGE_ENTEREMAIL = "Please enter your email address";
    Constants.ERRORMESSAGE_ENTERFIRSTNAME = "Please enter your first name";
    Constants.ERRORMESSAGE_ENTERLASTNAME = "Please enter your last name";
    Constants.ERRORMESSAGE_ENTERPASSWORD = "Please enter your password";
    Constants.ERRORMESSAGE_ENTERPHONENUMBER = "Please enter your phone number";
    Constants.ERRORMESSAGE_INVALIDEMAIL = "Invalid email address";
    Constants.ERRORMESSAGE_INVALIDPHONENUMBER = "Invalid phone number";
    Constants.ERRORMESSAGE_PASSWORDREQUIREMENT = "Passwords must be at least 8 characters long and include at least 1 upper case, lower case, numeric and special character";
    Constants.ERRORMESSAGE_PASSWORDSDONOTMATCH = "Passwords do not match";
    Constants.ERRORMESSAGE_PASSWORDWEAK = "Password is too weak";
    Constants.ERRORMESSAGE_CHECKOUTERROR = "An error occurred during the checkout process";
    Constants.ERRORMESSAGE_TIFFINNAMEEXISTS = "This Tifyn Service Name already exists";
    Constants.ERRORMESSAGE_URLNAMEEXISTS = "This url name is already in use";
    Constants.ERRORMESSAGE_POLICYACK = "You must accept to create an account";
    Constants.ERRORMESSAGE_POLICYACK_UPDATED = "Please agree to our updated terms and privacy policy";
    Constants.ERRORMESSAGE_UNEXPECTED_ERROR = "An unexpected error occurred";
    Constants.ERRORMESSAGE_STORE_CLOSED = "The store is closed and no longer accepting orders";
    Constants.ERRORMESSAGE_STORE_NOT_ACCEPTING_ORDERS = "The store is no longer accepting orders";
    Constants.UNCONFIRMED_COLOR = "#e89a3c";
    Constants.UNCONFIRMED_COLOR_RGB = "232, 154, 60";
    Constants.UNCONFIRMED_TEXT_COLOR = "#ffffff";
    Constants.CONFIRMED_COLOR = "#3c9ae8";
    Constants.CONFIRMED_COLOR_RGB = "60, 154, 232";
    Constants.CONFIRMED_TEXT_COLOR = "#ffffff";
    Constants.READY_COLOR = "#854eca";
    Constants.READY_COLOR_RGB = "133, 78, 202";
    Constants.READY_TEXT_COLOR = "#ffffff";
    Constants.COMPLETE_COLOR = "#6abe39";
    Constants.COMPLETE_COLOR_RGB = "106, 190, 57";
    Constants.COMPLETE_TEXT_COLOR = "#ffffff";
    Constants.CANCEL_COLOR = "#e84749";
    Constants.CANCEL_COLOR_RGB = "232, 71, 73";
    Constants.CANCEL_TEXT_COLOR = "#ffffff";
    Constants.DEFAULT_ADVANCE_ORDER_DAYS = 7;
    Constants.CUISINES = [
        "Indian",
        "Pakistani",
        "Sri Lankan",
        "Bangladeshi",
        "Nepali",
        "South Asian",
        "Chinese",
        "Thai",
        "Malaysian",
        "Japanese",
        "Asian",
        "Middle Eastern",
        "Lebanese",
        "Turkish",
        "Greek",
        "Italian",
        "Spanish",
        "French",
        "Salad",
        "Sandwiches",
        "Bakery",
        "Desserts",
        "Wraps",
        "Healthy",
        "Vegan",
        "Vegetarian"
    ];
    return Constants;
}());
