var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//firebase
import { auth, db, storage } from "../firebase";
import { ref, getDownloadURL, deleteObject, uploadBytes, } from "firebase/storage";
import { collection, getDoc, setDoc, doc, updateDoc, deleteField, query, where, getDocs, orderBy, limit, startAfter } from "firebase/firestore";
import { geohashForLocation } from "geofire-common";
import moment from "moment";
import { profanityFilter } from "../profanity";
import { Utils } from "../utils";
import isEmpty from "lodash.isempty";
import { AdvanceDurationType, DateFormat, AvailabilityType } from "@tiffin/core";
export function createPartner() {
    return new Promise(function (resolve) {
        var partnerRef = doc(db, "partners", auth.currentUser.uid);
        getDoc(partnerRef).then(function (docSnap) {
            if (!docSnap.exists()) {
                var initialDoc_1 = {
                    id: auth.currentUser.uid,
                    status: "onboarding",
                    preparationEnabled: false,
                    preparationDurationType: AdvanceDurationType.Days,
                    advanceOrderDays: 7,
                    offerPickup: false,
                    offerDelivery: false,
                    deliveryFee: 0,
                    minDeliveryOrder: 0,
                };
                var partnersColRef = collection(db, "partners");
                setDoc(doc(partnersColRef, auth.currentUser.uid), initialDoc_1).then(function (value) {
                    resolve(initialDoc_1);
                });
            }
            else {
                resolve(docSnap.data());
            }
        });
    });
}
export function updatePartner(values) {
    return new Promise(function (resolve, reject) {
        var update = __assign({}, values);
        var partnerRef = collection(db, "partners");
        if (values._geoloc) {
            //Create the geohash to allow for easy quering later
            update.geohash = geohashForLocation([values._geoloc.lat, values._geoloc.lng]);
        }
        if (values.preparationEnabled === true) {
            update.preparationInMins = deleteField();
        }
        if (values.offerPickup === false && values.offerDineIn === false) {
            //Clear pickup related fields
            update.pickupDays = deleteField();
        }
        if (values.offerDineIn === false) {
            update.numberOfTables = deleteField();
            update.absorbPaymentFee = deleteField();
            update.autoConfirmDineInOrders = deleteField();
        }
        if (values.offerDelivery === false) {
            //Clear all delivery related fields
            update.deliveryDays = deleteField();
            update.deliveryFee = deleteField();
            update.minDeliveryOrder = deleteField();
            update.deliveryAreas = deleteField();
            update.deliveryPlaceIds = deleteField();
            update.deliveryConfiguration = deleteField();
        }
        //Remove potential profanity
        if (update.name) {
            update.name = profanityFilter.clean(update.name);
        }
        if (update.description !== undefined) {
            if (isEmpty(update.description)) {
                update.description = deleteField();
            }
            else {
                update.description = profanityFilter.clean(update.description);
            }
        }
        //auth current can be considered to be non null
        updateDoc(doc(partnerRef, auth.currentUser.uid), __assign({}, update)).then(function () {
            resolve();
        }, function (error) {
            console.error(error);
            reject("An error occurred updating the partner details");
        });
    });
}
export function updatePartnerWebsiteDetails(values) {
    var update = __assign({}, values);
    if (!values.facebookUrl) {
        update.facebookUrl = deleteField();
    }
    if (!values.instagramUrl) {
        update.instagramUrl = deleteField();
    }
    if (!values.tiktokUrl) {
        update.tiktokUrl = deleteField();
    }
    return new Promise(function (resolve, reject) {
        var partnerRef = collection(db, "partners");
        //auth current can be considered to be non null
        updateDoc(doc(partnerRef, auth.currentUser.uid), __assign({}, update)).then(function () {
            resolve();
        }, function (error) {
            console.error(error);
            reject("An error occurred updating the partner details");
        });
    });
}
export function updatePartnerImage(file) {
    return new Promise(function (resolve, reject) {
        // Create the file metadata
        /** @type {any} */
        if (!file.originFileObj) {
            reject("Invalid image");
            return;
        }
        // Upload file and metadata
        var storageRef = ref(storage, "images/pending/" + auth.currentUser.uid);
        uploadBytes(storageRef, file.originFileObj).then(function (value) {
            getDownloadURL(value.ref).then(function (downloadURL) {
                var docRef = doc(db, "partners", auth.currentUser.uid);
                updateDoc(docRef, {
                    pendingImageUrl: downloadURL,
                    updateTimestamp: moment().toISOString()
                }).then(function (result) {
                    resolve(downloadURL);
                });
            });
        }, function (error) {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    reject("Unauthorized access");
                    break;
                case "storage/canceled":
                    // User canceled the upload
                    reject("Cancelled");
                    break;
                default:
                    console.error(error.code);
                    reject("Server error");
                    break;
            }
        });
    });
}
export function updatePartnerLogo(file) {
    return new Promise(function (resolve, reject) {
        // Create the file metadata
        /** @type {any} */
        if (!file.originFileObj) {
            reject("Invalid image");
            return;
        }
        // Upload file and metadata
        var storageRef = ref(storage, "images/logos/" + auth.currentUser.uid);
        uploadBytes(storageRef, file.originFileObj).then(function (value) {
            getDownloadURL(value.ref).then(function (downloadURL) {
                var docRef = doc(db, "partners", auth.currentUser.uid);
                updateDoc(docRef, {
                    logoImageUrl: downloadURL,
                }).then(function (result) {
                    resolve(downloadURL);
                });
            });
        }, function (error) {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    reject("Unauthorized access");
                    break;
                case "storage/canceled":
                    // User canceled the upload
                    reject("Cancelled");
                    break;
                default:
                    console.error(error.code);
                    reject("Server error");
                    break;
            }
        });
    });
}
export function removePartnerLogo() {
    // Create a reference to the file to delete
    var storageRef = ref(storage, "images/logos/" + auth.currentUser.uid);
    deleteObject(storageRef);
    // File deleted successfully
    var docRef = doc(db, "partners", auth.currentUser.uid);
    updateDoc(docRef, {
        logoImageUrl: deleteField()
    });
}
export function updatePartnerPauseDates(dates) {
    var partnerRef = collection(db, "partners");
    return new Promise(function (resolve, reject) {
        updateDoc(doc(partnerRef, auth.currentUser.uid), { pauseDates: dates.filter(function (date) { return moment(date, DateFormat) >= moment().startOf('day'); }) }).then(function () {
            resolve();
        }, function (error) {
            console.error(error);
            reject("An error occurred updating the partner details");
        });
    });
}
export function updatePartnerAdjustedDates(adjustedDates) {
    var partnerRef = collection(db, "partners");
    return new Promise(function (resolve, reject) {
        // Filter adjusted dates to only include those in the future
        for (var date in adjustedDates) {
            if (moment(date, DateFormat) < moment().startOf('day')) {
                delete adjustedDates[date];
            }
        }
        updateDoc(doc(partnerRef, auth.currentUser.uid), { adjustedDates: adjustedDates }).then(function () {
            resolve();
        }, function (error) {
            console.error(error);
            reject("An error occurred updating the partner details");
        });
    });
}
export function removePartnerImage() {
    // Create a reference to the file to delete
    var storageRef = ref(storage, "images/" + auth.currentUser.uid);
    var pendingStorageRef = ref(storage, "images/pending/" + auth.currentUser.uid);
    deleteObject(storageRef);
    deleteObject(pendingStorageRef);
    // File deleted successfully
    var docRef = doc(db, "partners", auth.currentUser.uid);
    updateDoc(docRef, {
        imageUrl: deleteField(),
        pendingImageUrl: deleteField()
    });
}
export function getPartnerInfo() {
    return new Promise(function (resolve, reject) {
        if (auth.currentUser) {
            var docRef = doc(db, "partners", auth.currentUser.uid);
            getDoc(docRef).then(function (docSnap) {
                if (docSnap.exists()) {
                    resolve(docSnap.data());
                }
                else {
                    reject("Partner record not found. Please contact Tifyn partner support.");
                }
            });
        }
    });
}
export function createTiffin(menu) {
    return new Promise(function (resolve, reject) {
        var tiffinsRef = doc(db, "tiffins", auth.currentUser.uid);
        getDoc(tiffinsRef).then(function (docSnap) {
            var _a;
            if (!docSnap.exists()) {
                var tiffinsColRef = collection(db, "tiffins");
                setDoc(doc(tiffinsColRef, auth.currentUser.uid), {
                    menu: menu,
                    modifiers: []
                }).then(function (value) {
                    resolve({
                        menu: menu,
                        modifiers: []
                    });
                });
            }
            else {
                var menuDetails = docSnap.data();
                if (!menuDetails.menu) {
                    //This is the old menu structure, so modify and return new structure
                    resolve({
                        menu: menuDetails,
                        modifiers: []
                    });
                }
                else {
                    //This is the new menu structure so return as is
                    resolve({
                        menu: menuDetails.menu,
                        modifiers: (_a = menuDetails.modifiers) !== null && _a !== void 0 ? _a : []
                    });
                }
            }
        });
    });
}
export function updateTiffin(menu, modifiers) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var _b, _c, _d, _i, category, _e, _f, item, res, blob, fileType, file, downloadUrl;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    _b = menu;
                    _c = [];
                    for (_d in _b)
                        _c.push(_d);
                    _i = 0;
                    _g.label = 1;
                case 1:
                    if (!(_i < _c.length)) return [3 /*break*/, 9];
                    _d = _c[_i];
                    if (!(_d in _b)) return [3 /*break*/, 8];
                    category = _d;
                    _e = 0, _f = menu[category].items;
                    _g.label = 2;
                case 2:
                    if (!(_e < _f.length)) return [3 /*break*/, 8];
                    item = _f[_e];
                    if (item.name) {
                        item.name = profanityFilter.clean(item.name);
                    }
                    if (item.description) {
                        item.description = profanityFilter.clean(item.description);
                    }
                    if (item.availabilityType === AvailabilityType.Everyday && !item.availability) {
                        delete item.availability;
                    }
                    if (!item.imageText) return [3 /*break*/, 6];
                    return [4 /*yield*/, fetch(item.imageText)];
                case 3:
                    res = _g.sent();
                    return [4 /*yield*/, res.blob()];
                case 4:
                    blob = _g.sent();
                    fileType = (_a = item.imageText.match(/^data:(.+);base64/)) === null || _a === void 0 ? void 0 : _a[1];
                    file = new File([blob], item.id, { type: fileType });
                    return [4 /*yield*/, updateMenuItemImage(file, item.id)];
                case 5:
                    downloadUrl = _g.sent();
                    item.imageUrl = downloadUrl;
                    delete item.imageText;
                    return [3 /*break*/, 7];
                case 6:
                    delete item.imageText;
                    _g.label = 7;
                case 7:
                    _e++;
                    return [3 /*break*/, 2];
                case 8:
                    _i++;
                    return [3 /*break*/, 1];
                case 9: return [2 /*return*/, new Promise(function (resolve) {
                        if (auth.currentUser) {
                            var docRef = doc(db, "tiffins", auth.currentUser.uid);
                            setDoc(docRef, {
                                menu: menu,
                                modifiers: modifiers
                            }).then(function (result) {
                                resolve(result);
                            });
                        }
                    })];
            }
        });
    });
}
export function storeNameIsUnique(name) {
    return new Promise(function (resolve) {
        var _a;
        var partnersRef = collection(db, "partners");
        var partnerQuery = query(partnersRef, where("nameLowercase", "==", Utils.convertToLowerName(name)), where("id", "!=", (_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid));
        getDocs(partnerQuery).then(function (querySnapshot) {
            if (querySnapshot.size)
                resolve(false);
            resolve(true);
        });
    });
}
export function urlNameIsUnique(name) {
    return new Promise(function (resolve) {
        var _a;
        var partnersRef = collection(db, "partners");
        var partnerQuery = query(partnersRef, where("urlName", "==", Utils.convertToLowerName(name)), where("id", "!=", (_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid));
        getDocs(partnerQuery).then(function (querySnapshot) {
            if (querySnapshot.size)
                resolve(false);
            resolve(true);
        });
    });
}
export function updateMenuItemImage(file, itemId) {
    return new Promise(function (resolve, reject) {
        // Create the file metadata
        /** @type {any} */
        if (!file) {
            reject("Invalid image");
            return;
        }
        // Upload file and metadata
        var storageRef = ref(storage, "images/menu/".concat(auth.currentUser.uid, "/").concat(itemId));
        uploadBytes(storageRef, file).then(function (value) {
            resolve("https://storage.googleapis.com/".concat(process.env.REACT_APP_FIREBASE_PROJECT_ID, ".appspot.com/images/menu/").concat(auth.currentUser.uid, "/thumbs/").concat(itemId, "_250x500"));
        }, function (error) {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    reject("Unauthorized access");
                    break;
                case "storage/canceled":
                    // User canceled the upload
                    reject("Cancelled");
                    break;
                default:
                    console.error(error.code);
                    reject("Server error");
                    break;
            }
        });
    });
}
//TODO: this is incorrect and needs to be fixed - should remove imageUrl from the menu item in the doc, not from the doc
export function removeMenuItemImage(itemId) {
    // Create a reference to the file to delete
    var storageRef = ref(storage, "images/menu/".concat(auth.currentUser.uid, "/").concat(itemId));
    deleteObject(storageRef);
    // File deleted successfully
    var docRef = doc(db, "tiffins", auth.currentUser.uid);
    updateDoc(docRef, {
        imageUrl: deleteField()
    });
}
/* Customer Reviews */
var REVIEW_LIMIT = 20;
export function fetchCustomerReviews() {
    return new Promise(function (resolve) {
        var partnerRef = doc(collection(db, "partners"), auth.currentUser.uid);
        var reviewsRef = collection(partnerRef, "reviews");
        var reviewsQuery = query(reviewsRef, orderBy("timestamp", "desc"), limit(REVIEW_LIMIT));
        getDocs(reviewsQuery).then(function (querySnapshot) {
            var records = [];
            querySnapshot.forEach(function (doc) {
                records.push(__assign({ id: doc.id, orderId: doc.id }, doc.data()));
            });
            resolve(records);
        });
    });
}
export function fetchNextCustomerReviews(lastDoc) {
    return new Promise(function (resolve) {
        var partnerRef = doc(collection(db, "partners"), auth.currentUser.uid);
        var reviewsRef = collection(partnerRef, "reviews");
        var reviewsQuery = query(reviewsRef, orderBy("timestamp", "desc"), startAfter(lastDoc), limit(REVIEW_LIMIT));
        getDocs(reviewsQuery).then(function (querySnapshot) {
            var records = [];
            querySnapshot.forEach(function (doc) {
                records.push(__assign({ id: doc.id, orderId: doc.id }, doc.data()));
            });
            resolve(records);
        });
    });
}
export function getReview(id) {
    return new Promise(function (resolve, reject) {
        var partnerRef = doc(collection(db, "partners"), auth.currentUser.uid);
        var reviewRef = doc(partnerRef, "reviews", id);
        getDoc(reviewRef).then(function (docSnap) {
            resolve(docSnap);
        }, function (error) {
            reject(error);
        });
    });
}
