import { IonContent, IonLoading, IonPage } from "@ionic/react";

export function Loading() {
  return (
    <IonPage>
      <IonContent>
          <IonLoading isOpen={true} spinner="crescent"/>
      </IonContent>
    </IonPage>
  )
}