import { useState } from "react"
import { partnerReducer, profanityFilter } from "@tiffin/app-common";
import { SpringModal, toast } from "@tiffin/components";
import { useAppDispatch } from "../../hooks"

import { useIonToast } from "@ionic/react"
import { Alert, Button, Input } from "antd"
import DeleteOutlined from "@ant-design/icons/DeleteOutlined"

import './MenuCategoryEditor.scss'

interface IMenuCategoryEditorProps {
  name?: string,
  newItem?: boolean,
  onSave?: (name: string) => void
}

export function MenuCategoryEditor(props: IMenuCategoryEditorProps) {
  
  const [name, setName] = useState(props.name)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const dispatch = useAppDispatch()
  const [present] = useIonToast();

  function saveNewCategory() {
    if(name) {
      if(profanityFilter.isProfane(name)) {
        toast(present, "Your category name cannot contain profane words", "danger", undefined, 5000)
        return;
      }

      dispatch(partnerReducer.addCategory({
        name: name
      }))
      
      if(props.onSave) props.onSave(name)
    } else {
      toast(present, "Please enter the category name")
    }
  }

  function saveCategory() {
    if(props.name && name) {

      if(profanityFilter.isProfane(name)) {
        toast(present, "Your category name cannot contain profane words", "danger", undefined, 5000)
        return;
      }

      dispatch(partnerReducer.updateCategory({
        oldName: props.name,
        newName: name
      }))

      if(props.onSave) props.onSave(name)
    } else {
      toast(present, "Please enter the category name")
    }
  }

  function deleteCategory() {
    if(props.name) {
      dispatch(partnerReducer.deleteCategory({
        name: props.name
      }))

      if(props.onSave) props.onSave(props.name!)

      setShowDeleteModal(false)
    }
  }

  return (
    <>
      <div className="category-details">
        <div className="category-content">
          <div>
            <Input defaultValue={name} onChange={(e) => {setName(e.target.value)}} placeholder="Category name" />
          </div>
        </div>
        <div className={`category-footer ${props.newItem ? 'new-btns' : 'update-btns'}`}>
          {
            props.newItem &&
            <Button type="primary" onClick={saveNewCategory}>Add</Button>
          }
          {
            !props.newItem &&
            <>
              <Button type="primary" danger onClick={() => setShowDeleteModal(true)} icon={<DeleteOutlined />}>Delete Category</Button>
              <Button type="primary" onClick={saveCategory}>Update</Button>
            </>
          }
        </div>
      </div>
      <SpringModal 
          title="Delete Category"
          isOpen={showDeleteModal}
          onOk={() => deleteCategory()}
          okText="Yes"
          onCancel={() => setShowDeleteModal(false)}
        >
          Are you sure you want to delete this category?
          <Alert showIcon message="All items within this category will also be deleted" type="error" />
      </SpringModal>
    </>
  )
}