import styled from "styled-components";
import { useRef, useState } from "react"
import { IOrderExt } from "@tiffin/core"
import { DocumentData } from "@firebase/firestore"

import { partnerOrdersHelper } from "@tiffin/app-common"
import { OrderCardListHistoric } from "../components/OrderCardListHistoric"

//3rd Party
import { IonContent, IonIcon, IonPage, useIonViewWillEnter } from "@ionic/react"
import { receiptOutline } from "ionicons/icons"

import { PageBreadcrumb } from "../components/PageBreadcrumb";

const ORDER_LIMIT_PER_REQUEST = 50

export const HistoricOrders = styled(BaseHistoricOrders)`
  ion-content {
    --background: var(--ion-color-light);
  }

  .main-content {
    padding: var(--page-padding);

    .partner-order-card-list {
      padding-bottom: calc(var(--ion-safe-area-bottom,0) + var(--page-padding));

      @media (orientation: portrait) {
        padding-bottom: calc(var(--ion-safe-area-bottom, 0) + var(--footer-height) + 10px);
      }
    }
  }
`

interface IHistoricalOrdersProps {
  className?: string
}

export function BaseHistoricOrders({className}: IHistoricalOrdersProps) {

  const [orders, setOrders] = useState<IOrderExt[]>([])
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [loading, setLoading] = useState(false)

  const start = useRef<DocumentData>()
  const end = useRef<DocumentData>()
  const updateInProgress = useRef(false)

  useIonViewWillEnter(() => {
    if(!start.current) {
      setLoading(true)
      partnerOrdersHelper.fetchHistoricOrders(ORDER_LIMIT_PER_REQUEST).then((value: partnerOrdersHelper.IHistoricalOrderResponse) => {
        setOrders(value.orders)
        start.current = value.start
        end.current = value.end
        setLoading(false)
      })
    } else {
      setLoading(true)
      partnerOrdersHelper.fetchPrevHistoricOrders(start.current).then((value: partnerOrdersHelper.IHistoricalOrderResponse) => {
        setLoading(false)
        if(value.orders.length > 0) {
          let newOrders = [...value.orders, ...orders]
          setOrders(newOrders)
          start.current = value.start
        }
      })
    }
  }, [])

  function getNextHistoricOrders() {
    if(hasMore && end.current && !updateInProgress.current) {
      //Get next
      updateInProgress.current = true
      partnerOrdersHelper.fetchNextHistoricOrders(ORDER_LIMIT_PER_REQUEST, end.current).then((value: partnerOrdersHelper.IHistoricalOrderResponse) => {
        if(value.orders.length > 0) {
          setOrders([...orders, ...value.orders])
          end.current = value.end
        } else {
          setHasMore(false)
        }
        updateInProgress.current = false
      })
    }
  }

  function updateOrder(id: string) {
    partnerOrdersHelper.fetchOrder(id).then((value: IOrderExt) => {
      let updatedOrders = [...orders]
      var foundIndex = updatedOrders.findIndex(x => x.id === id);
      updatedOrders[foundIndex] = value
      setOrders(updatedOrders)
    })
  }

  function renderOrderGroups() {
    return (
      <OrderCardListHistoric
        loading={loading}
        fetchMore={getNextHistoricOrders}
        hasMore={hasMore}
        orders={orders}
        orderChangeCallback={updateOrder}
      />
    )
  }

  return (
    <IonPage className={className}>
      <IonContent>
        <div className="page-container">
          <div className="page-breadcrumb-header">
            <PageBreadcrumb
              defaultHref="/main/orders"
              items={[
                {
                  title: <><IonIcon icon={receiptOutline} />Historic Orders</>,
                }
              ]}
              showBackButton={true}
            />
          </div>
          <div className="main-content">
            {renderOrderGroups()}
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}