import styled from "styled-components";
import { DietaryAttribute, IMenuItem } from "@tiffin/core";

import VeganSVG from '../assets/vegan-icon.svg';
import VegetarianSVG from '../assets/vegetarian-icon.svg';
import GlutenFreeSVG from '../assets/gluten-free.svg';
import HalalSVG from '../assets/halal.svg';
import KosherSVG from '../assets/kosher.svg';
import NutFreeSVG from '../assets/nut-free.svg';
import LactoseFreeSVG from '../assets/lactose-free.svg';

export const MenuLegend = styled(BaseMenuLegend)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;

  div {
    display: flex;
    align-items: center;
    gap: 2px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`

interface IMenuLegend {
  className?: string,
  categories: Map<string, { order: number; items: IMenuItem[] }>
}

function BaseMenuLegend({className, categories}: IMenuLegend) {

  let hasVegan = false;
  let hasVegetarian = false;
  let hasGF = false;
  let hasHalal = false;
  let hasKosher = false;
  let hasNutFree = false;
  let hasLactoseFree = false;

  if(categories) {
    for (let value of categories.values()) {
      for(let item of value.items) {
        if(item.dietaryAttributes?.includes(DietaryAttribute.Vegan)) {
          hasVegan = true;
        }
        if(item.dietaryAttributes?.includes(DietaryAttribute.Vegetarian)) {
          hasVegetarian = true;
        }
        if(item.dietaryAttributes?.includes(DietaryAttribute.GlutenFree)) {
          hasGF = true;
        }
        if(item.dietaryAttributes?.includes(DietaryAttribute.Halal)) {
          hasHalal = true;
        }
        if(item.dietaryAttributes?.includes(DietaryAttribute.Kosher)) {
          hasKosher = true;
        }
        if(item.dietaryAttributes?.includes(DietaryAttribute.NutFree)) {
          hasNutFree = true;
        }
        if(item.dietaryAttributes?.includes(DietaryAttribute.LactoseFree)) {
          hasLactoseFree = true;
        }
      }
    }
  }

  return (
    <div className={className} key="legend">
      {
        hasVegan &&
        <div>
          <VeganSVG/><span>= Vegan</span>
        </div>
      }
      {
        hasVegetarian &&
        <div>
          <VegetarianSVG/><span>= Vegetarian</span>
        </div>
      }
      {
        hasGF &&
        <div>
          <GlutenFreeSVG/><span>= Gluten Free</span>
        </div>
      }
      {
        hasHalal &&
        <div>
          <HalalSVG /><span>= Halal</span>
        </div>
      }
      {
        hasKosher &&
        <div>
          <KosherSVG /><span>= Kosher</span>
        </div>
      }
      {
        hasNutFree &&
        <div>
          <NutFreeSVG /><span>= Nut Free</span>
        </div>
      }
      {
        hasLactoseFree &&
        <div>
          <LactoseFreeSVG /><span>= Lactose Free</span>
        </div>
      }
    </div>
  )
}