import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userSlice";
import searchReducer from "./reducers/searchSlice";
import partnerReducer from "./reducers/partnerSlice";
import authenticateReducer from "./reducers/authenticateSlice";
import customerOrdersReducer from "./reducers/customerOrdersSlice";
import ordersReducer from "./reducers/ordersSlice";
import incomeReducer from "./reducers/incomeSlice";
import payoutsReducer from "./reducers/payoutsSlice";
import metricsReducer from "./reducers/metricsSlice";
import conversationsMetricsReducer from "./reducers/conversationsMetricsSlice";
import locationReducer from "./reducers/locationSlice";
import globalConfigReducer from "./reducers/globalConfigSlice";
import globalPartnerConfigReducer from "./reducers/globalPartnerConfigSlice";
import storeReducer from "./reducers/storeSlice";
import cartMultiReducer from "./reducers/cartMultiSlice";
export var store = configureStore({
    reducer: {
        authenticate: authenticateReducer,
        user: userReducer,
        search: searchReducer,
        partner: partnerReducer,
        customerOrders: customerOrdersReducer,
        orders: ordersReducer,
        income: incomeReducer,
        payouts: payoutsReducer,
        metrics: metricsReducer,
        conversationsMetrics: conversationsMetricsReducer,
        location: locationReducer,
        globalConfig: globalConfigReducer,
        globalPartnerConfig: globalPartnerConfigReducer,
        store: storeReducer,
        cartMulti: cartMultiReducer
    },
});
