import styled from "styled-components";
import { useEffect, useRef, useState } from "react";

import { convertToTime, DateFormat, IOrderExt } from "@tiffin/core";
import { partnerReducer } from "@tiffin/app-common";
import { calculateRevenue } from "../../common";
import { OrdersChart } from "../OrdersChart";
import { PauseOrders } from "../PauseOrders";
import { useAppDispatch, useAppSelector } from "../../hooks";

import { useMediaQuery } from "react-responsive";
import moment from "moment";
import EditOutlined from "@ant-design/icons/EditOutlined"
import { InputNumber } from "antd";

interface IDayMetrics {
  className?: string,
  date: string,
  orders: IOrderExt[],
  pickupTimings?: {
    start: number
    end: number
  },
  deliveryTimings?: {
    start: number
    end: number
  },
  orderPreparationTime?: number
}

export const DailyMetrics = styled(BaseDailyMetrics)`
  display: grid;
  grid-template-coluns: auto 1fr;
  grid-template-rows: auto 1fr;
  column-gap: 10px;

  @media (max-width:768px)  {
    display: flex;
    flex-wrap: wrap;
  }

  .store-status {
    display: flex;
    align-items: center;
    gap: 10px;

    grid-column: 1;
    font-size: x-large;
    font-weight: 700;
  }

  .pause-orders {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .pause-heading {
      font-size: medium;
    }
  }

  .store-prep-time {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .prep-time-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 5px 10px;
    }

    .prep-time-edit {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
      color: var(--ion-color-dark);
    }

    .prep-time-btn:hover {
      background: var(--ion-color-light-shade);
      cursor: pointer;
      border-radius: 8px;
    }

    .anticon {
      color: var(--ion-color-medium);
    }

    .metric {
      padding: 0;
    }
  }

  .metrics {
    padding: 15px 0;
    display: grid;
    grid-template-columns: ${(props) => props.orderPreparationTime !== undefined ? "repeat(8, auto)" : "repeat(6, auto)"};
    align-items: flex-start;
    justify-items: center;
    font-weight: 700;
    border-radius: 8px;
    gap: 5px;  
  }

  .timeline-chart {
    grid-column: 2;
    grid-row: 1/3;
  }

  .metric {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    gap: 5px;
    padding: 5px 10px;
  }

  .store-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    height: 100%;

    .store-timings {
      display: flex;
      flex-direction: column;
      font-size: var(--small-font-size);
      color: var(--ion-color-dark-tint);
      gap: 5px;

      div {
        display: flex;
        flex-wrap: wrap;
        gap: 2px;
        
        span {
          padding-right: 3px;
        }
      }
    }
  }

  .metric-title {
    color: var(--ion-color-dark-tint);
    text-align: center;
    font-size: var(--small-font-size);
  }

  .metric-value {
    font-size: large;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--ion-color-dark-tint);
  }

  .divider {
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    height: 80%;
    margin-top: 2px;
  }
`

export function BaseDailyMetrics({className, date, orders, pickupTimings, deliveryTimings, orderPreparationTime}: IDayMetrics) {

  const [editPrepTime, setEditPrepTime] = useState(false)
  const [storeStatus, setStoreStatus] = useState<"open" | "closed" |"paused">()
  const fullView = useMediaQuery({ query: '(min-width:850px)' })
  const pauseDates = useAppSelector((state) => state.partner.pauseDates)
  
  const timeoutRef = useRef<NodeJS.Timer>()
  const dispatch = useAppDispatch()

  let total = calculateRevenue(orders);

  useEffect(() => {
    const calculateStatus = () => {
      if(timeoutRef.current) {
        clearInterval(timeoutRef.current)
      }

      const active = !pauseDates.includes(date)
      if(!active) {
        setStoreStatus("paused")
      } else {
        if(moment(date, DateFormat).isBefore(moment(), 'day')) {
          setStoreStatus("closed")
        } else if(moment(date, DateFormat).isAfter(moment(), 'day')) {
          setStoreStatus("open")
        } else if(moment(date, DateFormat).isSame(moment(),'day')) {
          let open = false;
  
          let storeOpenTime, storeCloseTime;
          if(pickupTimings) {
            const start = convertToTime(pickupTimings.start)
            const end = convertToTime(pickupTimings.end)
            const storePickupOpenTime = moment().startOf('day').hours(start.hour).minutes(start.minute)
            const storePickupCloseTime = moment().startOf('day').hours(end.hour).minutes(end.minute)
            if(moment() >= storePickupOpenTime && moment() <= storePickupCloseTime) {
              open = true
            }
            if(!storeOpenTime) storeOpenTime = storePickupOpenTime
            if(!storeCloseTime) storeCloseTime = storePickupCloseTime
          }
  
          if(deliveryTimings) {
            const start = convertToTime(deliveryTimings.start)
            const end = convertToTime(deliveryTimings.end)
            const storeDeliveryOpenTime = moment().startOf('day').hours(start.hour).minutes(start.minute)
            const storeDeliveryCloseTime = moment().startOf('day').hours(end.hour).minutes(end.minute)
            if(moment() >= storeDeliveryOpenTime && moment() <= storeDeliveryCloseTime) {
              open = true
            }
            if(!storeOpenTime || storeDeliveryOpenTime < storeOpenTime) storeOpenTime = storeDeliveryOpenTime
            if(!storeCloseTime || storeDeliveryCloseTime > storeCloseTime) storeCloseTime = storeDeliveryCloseTime
          }

          if(storeOpenTime && moment() < storeOpenTime) {
            const diff = storeOpenTime.diff(moment(), 'milliseconds')
            timeoutRef.current = setInterval(calculateStatus, diff)
          } else if(storeCloseTime && moment() < storeCloseTime) {
            const diff = storeCloseTime.diff(moment(), 'milliseconds')
            timeoutRef.current = setInterval(calculateStatus, diff)
          }
  
          if(open) {
            setStoreStatus("open")
          } else {
            setStoreStatus("closed")
          }
        }
      }
    }

    calculateStatus()
  }, [pickupTimings, deliveryTimings, pauseDates, date])

  const renderStoreStatus = () => {
    if(storeStatus === "open") {
      return <span style={{color: "var(--ion-color-success)"}}>Open</span>
    } else if(storeStatus === "closed") {
      return <span style={{color: "var(--ion-color-warning)"}}>Closed</span>
    } else if(storeStatus === "paused") {
      return <span style={{color: "var(--ion-color-warning)"}}>Paused</span>
    }
  }

  const handlePrepTimeChange = (val: number) => {
    dispatch(partnerReducer.updatePrepTime(val))
    setEditPrepTime(false)
  }

  return (
    <div className={className}>
      <div className="store-status">{renderStoreStatus()}
        <PauseOrders date={date} />
      </div>
      <div>
        <div className="metrics">
          <div className="store-info">
            <div className="store-timings">
              {pickupTimings &&
                <div>
                  <span>Pickup:</span>
                  <span> {convertToTime(pickupTimings.start).formatted} - {convertToTime(pickupTimings.end).formatted}</span>
                </div>
              }
              {deliveryTimings &&
                <div>
                  <span>Delivery:</span>
                  <span> {convertToTime(deliveryTimings.start).formatted} - {convertToTime(deliveryTimings.end).formatted}</span>
                </div>
              }
            </div>
          </div>
          {
            orderPreparationTime !== undefined &&
            <>
              <div className="divider" />
              <div className="store-prep-time" onClick={() => setEditPrepTime(true)}>
                {
                  !editPrepTime ?
                  <>
                    <div className="prep-time-btn">
                      <EditOutlined />
                      <div className="metric">
                        <div className="metric-value">
                          {orderPreparationTime}
                        </div>
                        <div className="metric-title">Minutes</div>
                      </div>
                    </div>
                  </>
                  :
                  <div className="prep-time-edit">
                    <span>Preparation time</span>
                    <InputNumber autoFocus size="small" defaultValue={orderPreparationTime} onBlur={(e: any) => handlePrepTimeChange(e.target.value)} onPressEnter={(e: any) => handlePrepTimeChange(parseInt(e.target.value))}/>
                  </div>
                }
              </div> 
            </>
          }
          <div className="divider" />
          <div className="metric">
            <div className="metric-value">
              {orders.length}
            </div>
            <div className="metric-title">{orders.length === 1 ? 'Order' : 'Orders'}</div>
          </div>
          <div className="divider" />
          <div className="metric">
            <div className="metric-value">
              ${(total / 100).toFixed(2)}
            </div>
            <div className="metric-title">Revenue</div>
          </div>
          {
            fullView &&
            <div className="divider" />
          }
        </div>
      </div>
      {
        fullView &&
        <div className="timeline-chart">
          <OrdersChart
            date={date}
            orders={orders}
            pickupTimings={pickupTimings}
            deliveryTimings={deliveryTimings}
          />
        </div>
      }
    </div>
  )
}