"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DietaryAttribute = exports.OutOfStockType = exports.ModifierSelectType = exports.AvailabilityType = exports.ARCHVIED_CATEGORY = void 0;
exports.ARCHVIED_CATEGORY = "archived";
var AvailabilityType;
(function (AvailabilityType) {
    AvailabilityType[AvailabilityType["Custom"] = 1] = "Custom";
    AvailabilityType[AvailabilityType["Everyday"] = 2] = "Everyday";
})(AvailabilityType = exports.AvailabilityType || (exports.AvailabilityType = {}));
var ModifierSelectType;
(function (ModifierSelectType) {
    ModifierSelectType[ModifierSelectType["Single"] = 0] = "Single";
    ModifierSelectType[ModifierSelectType["Multiple"] = 1] = "Multiple";
})(ModifierSelectType = exports.ModifierSelectType || (exports.ModifierSelectType = {}));
var OutOfStockType;
(function (OutOfStockType) {
    OutOfStockType[OutOfStockType["None"] = 0] = "None";
    OutOfStockType[OutOfStockType["EndOfDay"] = 1] = "EndOfDay";
    OutOfStockType[OutOfStockType["SpecificDates"] = 2] = "SpecificDates";
})(OutOfStockType = exports.OutOfStockType || (exports.OutOfStockType = {}));
var DietaryAttribute;
(function (DietaryAttribute) {
    DietaryAttribute[DietaryAttribute["Vegan"] = 0] = "Vegan";
    DietaryAttribute[DietaryAttribute["Vegetarian"] = 1] = "Vegetarian";
    DietaryAttribute[DietaryAttribute["GlutenFree"] = 2] = "GlutenFree";
    DietaryAttribute[DietaryAttribute["Halal"] = 3] = "Halal";
    DietaryAttribute[DietaryAttribute["Kosher"] = 4] = "Kosher";
    DietaryAttribute[DietaryAttribute["NutFree"] = 5] = "NutFree";
    DietaryAttribute[DietaryAttribute["LactoseFree"] = 6] = "LactoseFree";
})(DietaryAttribute = exports.DietaryAttribute || (exports.DietaryAttribute = {}));
