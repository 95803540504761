import { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../hooks"

//Firebase
import { cloudFunction, partnerReducer } from "@tiffin/app-common"

//3rd party
import { Button, Form, Spin } from "antd"
import LoadingOutlined from "@ant-design/icons/LoadingOutlined"
import CheckCircleFilled from "@ant-design/icons/CheckCircleFilled"
import { IonLoading, IonRefresher, IonRefresherContent, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react"
import { App, AppState } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { ReactComponent as StripeLogoSVG } from '../assets/stripe.svg';
import { SubscriptionPlan } from "@tiffin/core"

interface IPaymentFormProps {
  independent?: boolean,
  onDone?: (action: "save" | "prev" | "next") => void,
}

export function PaymentForm({independent, onDone}: IPaymentFormProps) {
  const refreshRef = useRef<any>(null);

  //Internal states
  const subscriptionPlan = useAppSelector((state) => state.partner.subscriptionPlan)
  const partnerStatus = useAppSelector((state) => state.partner.partnerStatus)
  const paymentStatus = useAppSelector((state) => state.partner.paymentStatus)

  const [ isActive, setIsActive ] = useState(true)

  const [processing, setProcessing] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  useIonViewDidEnter(() => {
    App.addListener('appStateChange', (state: AppState) => {
      setIsActive(state.isActive)
    });
  }, [dispatch])

  useIonViewDidLeave(() => {
    App.removeAllListeners()
  })

  useEffect(() => {
    if(isActive) {
      dispatch(partnerReducer.getPaymentStatus())
    }
  }, [isActive, dispatch])

  useEffect(() => {
    if(refreshRef.current) {
      //Set refresh event to complete
      refreshRef.current.detail.complete()
      refreshRef.current = null;
    } else {
      if(paymentStatus === "unknown") {
        dispatch(partnerReducer.getPaymentStatus())
      }
    }
  }, [dispatch, paymentStatus])

  function setupPayment() {
    setProcessing(true)
    cloudFunction("initiatePaymentSetup").then(
      (result: any) => {
        if (result.data?.url) {
          setProcessing(false)
          window.location.href = result.data.url
        }
      },
      (error) => {
        setProcessing(false)
        console.error("Unable to generate payments onboarding link")
      }
    )
  }

  function updatePayment() {
    setProcessing(true)
    cloudFunction("updatePaymentLink").then(
      (result: any) => {
        if (result.data?.url) {
          setProcessing(false)
          Browser.open({ url: result.data.url });
        }
      },
      (error) => {
        console.error(error)
        setProcessing(false)
        console.error("Unable to generate payments update link")
      }
    )
  }

  function handlePrev() {
    if(onDone) onDone("prev")
  }

  function handleNext(e: any) {
    //Remove focus to prevent user clicking 'enter' button to progress forward
    if(e?.target) {
      e.target.blur()
    }

    if(onDone) onDone("next")
  }

  function doRefresh(event: any) {
    refreshRef.current = event
    dispatch(partnerReducer.getPaymentStatus())
  }

  return (
    <div className="partner-form payment-form">
      {
        paymentStatus !== "complete" &&
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            refreshingSpinner="crescent"
          >      
          </IonRefresherContent>
        </IonRefresher>
        }
      <div className="outer-form-container">
        <div className="payment-setup">
          <div className="stripe-partner-title">We partner with<StripeLogoSVG className="stripe-logo"/>for payments</div>
          <div className={`stripe-partner-description ${paymentStatus !== "complete" ? "fadeIn" : "fadeOut2"}`}>To start accepting online orders we require you to setup your payout details with Stripe.</div>
          <div className={`payment-onboarding-check ${(paymentStatus === "unknown" || paymentStatus === "loading") ? "fadeIn" : "fadeOut2"}`}>
            <Spin
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              indicator={<LoadingOutlined />}
            />
            <span>Checking Stripe status</span>
          </div>
          <div className={`${paymentStatus === "complete" ? "payment-onboarding-complete fadeIn" : "fadeOut2"}`}>
            <span>
              To update your Stripe details, including bank payout or contact details you can do so through the Stripe dashboard.
            </span>
            {
              partnerStatus === "onboarding" &&
              <div>
                <CheckCircleFilled style={{color: "var(--ion-color-success)"}} /> Onboarding complete
              </div>
            }
            <div>
              <Button size="large" type="primary" className="stripe-btn" onClick={updatePayment}>
                Access Stripe Dashboard
              </Button>
            </div>
          </div>
          <Button size="large" type="primary" className={`stripe-btn setup-btn ${paymentStatus === "incomplete" ? "fadeIn" : "fadeOut"}`} onClick={setupPayment}>
            Setup Payout
          </Button>
        </div>
      </div>
      <Form.Item key={1} className="form-buttons">
        {
          !independent &&
          <>
            <Button size="large" className="left-btn" onClick={handlePrev}>
              Back
            </Button>
            <Button size="large" type="primary" disabled={paymentStatus !== "complete"} className={`right-btn`} onClick={handleNext}>
              Next
            </Button>
            <Button size="large" className={`right-btn ${subscriptionPlan === SubscriptionPlan.BasicHome && paymentStatus !== "complete" ? "fadeIn" : "fadeOut"}`} onClick={handleNext}>
              Skip
            </Button>
          </>
        }
      </Form.Item>
      <IonLoading isOpen={processing} spinner="crescent" message="Please wait while we redirect you to Stripe" />
    </div>
  )
}
