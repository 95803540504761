import { useEffect, useState } from "react";
import { DateFormat } from "@tiffin/core";
import { metricsReducer } from "@tiffin/app-common";
import { useAppDispatch, useAppSelector } from "../hooks";

import { DatePicker } from "antd";
import { Dayjs } from "dayjs";
import { IonButton, IonIcon, useIonRouter } from "@ionic/react";
import { calendarClearOutline } from "ionicons/icons";
import moment from "moment";

import './DashboardPicker.scss'

interface IDashboardPickerProps {
  futureDates: string[]
}

export function DashboardPicker(props: IDashboardPickerProps) {

  const baseMetrics = useAppSelector((state) => state.metrics.base)
  const status = useAppSelector((state) => state.metrics.dailyStatus)
  const pickupDays = useAppSelector((state) => state.partner.pickup?.pickupDays ? Object.keys(state.partner.pickup?.pickupDays) : [])
  const deliveryDays = useAppSelector((state) => state.partner.delivery?.deliveryDays ? Object.keys(state.partner.delivery?.deliveryDays) : [])
  const advanceDays = useAppSelector((state) => state.partner.advance?.advanceOrderDays)

  const [open, setOpen] = useState(false)

  const dispatch = useAppDispatch()
  const router = useIonRouter()

  useEffect(() => {
    if(status === "unknown") {
      dispatch(metricsReducer.getLast30DaysMetrics())
    }
  }, [status, dispatch])

  function handleOnChange(selected: Dayjs | null) {
    if(selected) {
      let date = selected.format(DateFormat)

      if(selected >= moment().startOf('day')) {
        //Navigate to the active day dashboard
        router.push(`/main/dashboard/${encodeURIComponent(date)}`)
      } else {
        //Navigate to the historic day dashboard
        router.push(`/main/dashboard-historical/${encodeURIComponent(date)}`)
      }
      setOpen(false)
    }
  }

  function handleDisableDate(current: Dayjs) {
    let date = current.format(DateFormat)
    if(props.futureDates.includes(date) || baseMetrics.dates.includes(date)) {
      return false
    } else if(current >= moment().startOf('day') && current <= moment().add(advanceDays, 'days').endOf('day')) {
      if (pickupDays.includes(current.day().toString()) || deliveryDays.includes(current.day().toString())) {
        return false
      }
    }

    return true
  }

  return (
    <div className="dashboard-picker">
      <IonButton size="small" color="medium" onClick={() => setOpen(!open)}><IonIcon color="light" icon={calendarClearOutline} /></IonButton>
      <DatePicker open={open} onOpenChange={(open) => setOpen(open)} disabledDate={handleDisableDate} value={null} onChange={handleOnChange} />
    </div>
  )
}