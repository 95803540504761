import { partnerReducer } from "@tiffin/app-common";
import { useAppDispatch, useAppSelector } from "../hooks";
import { LoadingSpinner } from "@tiffin/components";

//3rd party
import RedoOutlined from "@ant-design/icons/RedoOutlined"
import { Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import { Card } from "antd";
import { IonButton } from "@ionic/react";

import './RatingChart.scss'

export function RatingChart() {

  const rating = useAppSelector((state) => state.partner.rating)
  const status = useAppSelector((state) => state.partner.status)
  const dispatch = useAppDispatch()

  function refreshData() {
    dispatch(partnerReducer.getPartner())
  }

  function renderCardHeader() {
    return (
      <>
        <span>
          Customer Rating
        </span>
        <div className="right-title-buttons">
          <IonButton color="light" className="chart-refresh-btn" size="small" onClick={refreshData}>          
            <RedoOutlined />
          </IonButton>
        </div>
      </>
    )
  }

  function getChartColor() {
    if(rating === undefined) {
      return
    }

    if(rating >= 4.5) {
      return "var(--ion-color-success-shade)"
    } else if(rating >= 4) {
      return "var(--ion-color-success)"
    } else if(rating >= 3) {
      return "var(--ion-color-warning)"
    } else {
      return "var(--ion-color-danger)"
    }
  }

  return (
    <Card className="dashboard-card rating-chart" title={renderCardHeader()}>
      {
        status !== "idle" ?
        <div className="loading-overlay">
          <LoadingSpinner />
        </div>
        :
          rating ? 
          <ResponsiveContainer debounce={300} width="100%" height="100%">
            <PieChart cx="50%" cy="50%" innerRadius="80%" outerRadius="100%">
              <Pie
                data={[{rating: rating}]}
                dataKey="rating"
                startAngle={450}
                endAngle={450 - rating / 5 * 360}
                innerRadius={70}
                outerRadius={80}
                fill={getChartColor()}
                stroke={getChartColor()}
              >
                <Label className="center-value" value={rating.toFixed(2)} position="center" />
              </Pie>
            </PieChart>
        </ResponsiveContainer>
        :
        <div className="no-ratings">No ratings</div>
      }
    </Card>
  )
}