import { IonButton, IonIcon, IonModal } from "@ionic/react"
import styled from "styled-components";

import React, { ReactNode, useEffect, useRef, useState } from "react"
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import { LoadingSpinner } from "./LoadingSpinner";
import { closeOutline } from "ionicons/icons";
import { Modal } from "antd";

interface ISpringModalProps {
  isOpen: boolean
  className?: string
  okLoading?: boolean
  okDisabled?: boolean
  title?: string
  showClose?: boolean
  children?: string | ReactNode
  onOk?: (e?: React.MouseEvent<HTMLElement>) => void
  okText?: string
  onCancel?: (e?: React.MouseEvent<HTMLElement>) => void
  cancelText?: string
  onClose?: (e?: React.MouseEvent<HTMLElement>) => void
  breakpoints?: number[]
  didPresent?: () => void
  disableAutoHeight?: boolean
  disableDrag?: boolean
  top?: number
}

export const SpringModal = styled(BaseSpringModal)`
  font-family: var(--ion-font-family);
  
  ${(props) => props.disableDrag ? `
  &::part(content) {
    max-height: 98%;
    overflow: scroll;
  }

    @media (max-width:768px)  {
      &::part(content) {
        position: absolute;
        bottom: 0px;
        max-height: 98%;
        overflow: scroll;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  ` : ''}

  &.spring-modal {
    #ion-react-wrapper {
      overflow: auto;
      max-height: calc(100vh - var(--ion-safe-area-top,0) - var(--ion-safe-area-bottom,0));
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      ion-icon.close-icon {
        cursor: pointer;
        padding: 10px;
        font-size: 1.4em;
      }
    }

    .title>h5 {
      font-weight: 600;
      padding: 0 5px;
    }

    .spring-modal-container {
      height: 100%;
      padding: 16px 16px 32px;
      display: flex;
      flex-direction: column;

      .inner-content {
        padding: ${(props) => props.onOk || props.onCancel ? "10px 5px 20px" : "10px 5px 0px"};
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    .spring-modal-controls {
      display: flex;
      flex-direction: column;
    }
  }

  &.auto-height {
    &.bottom {
      align-items: flex-end;
    }

    --height: auto;

    .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
        max-height: 80vh;
        overflow: auto;
        padding-bottom: 20px;
      }
    }
  }
  
  &.popup-modal {
    #ion-react-wrapper {
      padding: 16px;
      overflow: auto;
    }

    .title {
      font-weight: 600;
      color: var(--ion-color-dark-tint);
      font-size: 1.2em;
      padding-bottom: 20px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      ion-icon.close-icon {
        cursor: pointer;
        padding: 10px;
        font-size: 1.4em;
      }
    }

    .inner-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .popup-modal-controls {
      display: flex;
      gap: 10px;
      align-items: flex-end;
      justify-content: flex-end;
      padding-top: 20px;
    }
  }

  @media (min-width:961px) {
    &.spring-modal {
      .spring-modal-controls {
        gap: 5px;
        flex-direction: row-reverse;
  
        ion-button {
          width: 200px;
        }
      }
    }
  }

  .ant-input-affix-wrapper {
    border: none;
    padding: 10px 20px;
    background: var(--ion-color-light) !important;
    border-radius: 10px;
  }

  &.desktop-modal {
    padding: 0;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
`

function BaseSpringModal(props: ISpringModalProps) {

  const [okLoading, setOkLoading] = useState(props.okLoading ?? false)
  const okClickedRef = useRef(false)

  useEffect(() => {
    if (props.okLoading === undefined) {
      if (!props.isOpen) {
        if (okLoading) setOkLoading(false)
      }
    } else {
      setOkLoading(props.okLoading)
    }
  }, [props.isOpen, props.okLoading, okLoading])

  function handleOk(e?: React.MouseEvent<HTMLElement>) {
    okClickedRef.current = true
    if (props.okLoading === undefined) setOkLoading(true)
    props.onOk(e)
  }

  function handleDismiss() {
    if (!okClickedRef.current) {
      if (props.onCancel) props.onCancel()
      if (props.onClose) props.onClose()
    }
  }

  let breakpoints;
  if (props.breakpoints) {
    breakpoints = props.breakpoints
  } else if (!props.disableDrag) {
    breakpoints = [1]
  }

  return (
    <>
      {
        isMobile && !isTablet && !isDesktop &&
        <IonModal
          className={`spring-modal ${!props.disableAutoHeight ? 'auto-height' : ''} ${props.className ?? ''}`}
          isOpen={props.isOpen}
          onWillDismiss={handleDismiss}
          breakpoints={breakpoints}
          initialBreakpoint={1}
          onIonModalDidPresent={() => { if (props.didPresent) props.didPresent() }}
        >
          <div className="inner-content">
            <div className="spring-modal-container">
              <div className="title">
                <h5>{props.title}</h5>
                {
                  props.showClose &&
                  <IonIcon className="close-icon" icon={closeOutline} onClick={() => props.onClose()} />
                }
              </div>
              <div className="inner-content">
                {props.children && props.children}
              </div>
              <div className="spring-modal-controls">
                {
                  props.onOk &&
                  <IonButton onClick={handleOk} disabled={okLoading || props.okDisabled}>{okLoading && <LoadingSpinner className={`loading-btn ${okLoading ? 'fadeIn2' : 'fadeOut2'}`} />}{props.okText ? props.okText : "Ok"}</IonButton>
                }
                {
                  props.onCancel &&
                  <IonButton color="light" onClick={props.onCancel} disabled={okLoading}>
                    {props.cancelText ? props.cancelText : "Cancel"}
                  </IonButton>
                }
              </div>
            </div>
          </div>
        </IonModal>
      }
      {
        (isTablet || isDesktop) &&
        <Modal
          className={`desktop-modal ${props.className}`}
          style={props.top ? { top: `calc(var(--ion-safe-area-top) + ${props.top}px)` } : {}}
          title={props.title}
          open={props.isOpen}
          closable={props.showClose ?? false}
          cancelText={props.cancelText}
          okText={props.okText}
          onCancel={props.onCancel ?? props.onClose}
          onOk={props.onOk}
          okButtonProps={{
            loading: props.okLoading,
            disabled: props.okDisabled
          }}
          footer={!props.onOk || !props.onCancel ? [] : undefined}
        >
          {props.children}
        </Modal>
      }
    </>
  )
}
