var _a;
import { createSlice } from "@reduxjs/toolkit";
// Define the initial state using that type
var initialState = {
    status: "unknown",
};
export var authenticateSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setAuthenticated: function (state) {
            state.status = "authenticated";
        },
        setUnauthenticated: function (state) {
            state.status = "unauthenticated";
        },
        setIncomplete: function (state) {
            state.status = "incomplete";
        },
        setUnacknowledged: function (state) {
            state.status = "unacknowledged";
        },
        setGuest: function (state) {
            state.status = "guest";
        }
    },
});
export var setAuthenticated = (_a = authenticateSlice.actions, _a.setAuthenticated), setUnauthenticated = _a.setUnauthenticated, setIncomplete = _a.setIncomplete, setUnacknowledged = _a.setUnacknowledged, setGuest = _a.setGuest;
export default authenticateSlice.reducer;
