import styled from "styled-components";
import { IonBackButton } from "@ionic/react";
import { Breadcrumb, BreadcrumbProps } from "antd";
import { ReactElement } from "react";
import { isMobile } from "react-device-detect";

interface IDailyHeader extends BreadcrumbProps {
  className?: string,
  defaultHref?: string,
  extra?: ReactElement,
  showBackButton?: boolean
}

export const PageBreadcrumb = styled(BasePageBreadcrumb)`
  width: 100%;
  font-size: medium;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  .breadcrumb {
    display: flex;
    align-items: center;

    ion-back-button {
      padding-right: 5px;
    }
  }

  .ant-breadcrumb {
    @media (min-width:961px) {
      font-size: large;

      a {
        height: unset;
      }
    }
  }

  .ant-breadcrumb-link {
    display: flex;
    align-items: center;
    gap: 5px;

    .ant-skeleton-title {
      margin-top: 3px;
      margin-bottom: 3px;
      width: 75px;
    }
  }
`

function BasePageBreadcrumb({className, defaultHref, extra, showBackButton, ...props}: IDailyHeader) {
  return (
    <div className={className}>
      <div className="breadcrumb">
        {
          (isMobile || showBackButton) &&
          <IonBackButton defaultHref={defaultHref} mode="md" />
        }
        <Breadcrumb
          {...props}
        />
      </div>
      {extra && extra}
    </div>
  )
}