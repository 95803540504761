"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PushNotifType = exports.PushNotifTag = void 0;
var PushNotifTag;
(function (PushNotifTag) {
    PushNotifTag["ChatMessage"] = "chat-message";
    PushNotifTag["OrderTransition"] = "order-transitions";
    PushNotifTag["Promotion"] = "promotion";
})(PushNotifTag = exports.PushNotifTag || (exports.PushNotifTag = {}));
var PushNotifType;
(function (PushNotifType) {
    PushNotifType["Message"] = "message";
    PushNotifType["Order"] = "order";
    PushNotifType["Refund"] = "refund";
    PushNotifType["Promotion"] = "promotion";
})(PushNotifType = exports.PushNotifType || (exports.PushNotifType = {}));
