import styled from "styled-components";
import { IOrderExt, OrderItem, OrderType, calculateProcessingFee } from "@tiffin/core"

export const OrderItemsSummary = styled(BaseOrderItemsSummary)`
  display: grid;
  gap: 5px;
  font-size: 0.875em;
  padding: 0px 10px;

  @media (min-width:961px),
  (orientation: landscape) {
    padding: 0;
  }

  .item-details {
    display: grid;
    column-gap: 20px;
    grid-template-columns: auto 1fr 50px;
    align-items: center;
    font-weight: 700;
    color: var(--ion-color-dark);

    .item-total {
      text-align: end;
    }

    .item-modifiers {
      grid-column: 2/4;
  
      .item-modifier {
        display: flex;
        flex-direction: column;
        padding: 2px 0;
  
        .modifier-title {
          font-size: var(--font-size-regular);
          font-weight: 600;
        }
  
        .modifier-option {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          font-weight: 500;
          color: var(--ion-color-medium);
        }
      }
    }
  }

  .order-items-totals-summary {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    border-top: 1px solid var(--ion-color-light);
    color: var(--ion-color-medium);

    .order-items-totals {
      display: grid;
      gap: 2px;
    }

    .order-total-breakdown {
      display: grid;
      grid-template-columns: 1fr auto;
      font-weight: 700;
    }

    .promo-alert {
      margin-top: 5px;
      font-size: var(--small-font-size);
      padding: 4px 8px;
    }
  }
`

interface IOrderItemsList {
  className?: string,
  order: IOrderExt
}

export function BaseOrderItemsSummary({className, order}: IOrderItemsList) {
  
  function renderItemModifier(orderItem: OrderItem) {
    if(!orderItem.modifiers) return;

    let items: React.ReactElement[] = [];
    for(const modifier of orderItem.modifiers) {
      if(modifier.selectedOptions.length === 0) continue;

      items.push(
        <div className="item-modifier">
          <div className="modifier-title">{modifier.name}</div>
          {
            modifier.selectedOptions.map((selectedOption) => {
              const optionDetails = modifier.options.find((val) => val.label === selectedOption)
              if(optionDetails) {
                return (
                  <div className="modifier-option">
                    <div>{optionDetails.label}</div>
                    <div>{`+$${(optionDetails.price * orderItem.count).toFixed(2)}`}</div>
                  </div>
                )
              } else {
                // Return null as lint expects map to always return a value (we filter this out on return)
                return null
              }
            })
          }
        </div>
      )
    }

    // Remove null values
    return items.filter((val) => val !== null);
  }

  return (
    <div className={className}>
      {
        order.items.map((item) => {
          return (
            <div className="item-details" key={item.id}>
              <span className="item-count">
                {
                  item.count
                }
              </span>
              <span className="item-name">
                {
                  item.name
                }
              </span>
              <span className="item-total">
                {
                  `$${(item.count * item.price).toFixed(2)}`
                }
              </span>
              <div className="item-modifiers">
                {
                  renderItemModifier(item)
                }
              </div>
            </div>
          )
        })
      }
      <div className="order-items-totals-summary">
        <div className="order-items-totals">
          <div className="order-total-breakdown">
            <span>Subtotal</span>
            <span>${((order.subTotal - (order.promoAmount ?? 0)) / 100).toFixed(2)}</span>
          </div>
          {
            order.promoAmount !== undefined && order.promoAmount > 0 &&
            <>
              <div className="order-total-breakdown">
                <span>Promotion</span>
                <span>${(order.promoAmount / 100).toFixed(2)}</span>
              </div>
            </>
          }
          {order.orderType === OrderType.Delivery && order.deliveryFee !== undefined && (
            <div className="order-total-breakdown">
              <span>Delivery Fee</span>
              <span>${(order.deliveryFee / 100).toFixed(2)}</span>
            </div>
          )}
          {
            order.refundTotal > 0 &&
            <div className="order-total-breakdown">
              <span>Refunded</span>
              <span>-${(order.refundTotal / 100).toFixed(2)}</span>
            </div>
          }
          {
            order.cancellationFee !== undefined && order.cancellationFee > 0 &&
            <div className="order-total-breakdown">
              <span>Cancellation Fee</span>
              <span>${(order.cancellationFee / 100).toFixed(2)}</span>
            </div>
          }
          {
            order.isPartnerWebsite &&
            <div className="order-total-breakdown">
              <span>Processing fee (2%)</span>
              <span>-${(calculateProcessingFee(order).processingFee / 100).toFixed(2)}</span>
            </div>
          }
          {
            (!!order.paymentFee && order.paymentFeeAbsorbed) &&
            <div className="order-total-breakdown">
              <span>Payment fee (1.75% + $0.30)</span>
              <span>-${((order.paymentFee - order.paymentFeeRefund) / 100).toFixed(2)}</span>
            </div>
          }
        </div>
      </div>
    </div>
  )
}