import { useEffect, useRef, useState } from "react";
import { AdjustDateType, convertToTime, DateFormat, IAdjustedDate } from "@tiffin/core";
import { useAppDispatch, useAppSelector } from "../hooks";
import { partnerReducer } from "@tiffin/app-common";
import { TimeRangePicker } from "@tiffin/components";

//3rd party
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonPage, IonSegment, IonSegmentButton, IonTitle, IonToolbar } from "@ionic/react";
import UsergroupAddOutlined from "@ant-design/icons/UsergroupAddOutlined"
import { Calendar } from "antd";
import moment from "moment";
import { cloneDeep } from "lodash";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { CalendarMode } from "antd/lib/calendar/generateCalendar";
import { caretBackOutline, caretForwardOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

import './AvailableDates.scss'

const MAX_DATE_RANGE = 100

export function AvailableDates() {

  const status = useAppSelector((state) => state.partner.status)
  const pickup = useAppSelector((state) => state.partner.pickup)
  const delivery = useAppSelector((state) => state.partner.delivery)
  const adjustedDates = useAppSelector((state) => state.partner.adjustedDates)

  const dispatch = useAppDispatch()

  //Internal states
  const [ showAdjustTimeModal, setShowAdjustTimeModal ] = useState(false)
  const [ showUnavailableModal, setShowUnavailableModal ] = useState(false)
  const [ adjustDate, setAdjustDate ] = useState<Dayjs>()
  const [ adjustDateType, setAdjustDateType ] = useState<AdjustDateType>(AdjustDateType.Unchanged)
  const [ adjustPickup, setAdjustPickup ] = useState<{start: number, end: number}>()
  const [ adjustDelivery, setAdjustDelivery ] = useState<{start: number, end: number}>()

  const pageRef = useRef();

  useEffect(() => {
    if(status === "saved" && showAdjustTimeModal) {
      setShowAdjustTimeModal(false)
      dispatch(partnerReducer.resetStatus())
    }
  }, [status, dispatch, showAdjustTimeModal])

  function dateSelectHandler(date: Dayjs) {
    if(date < moment().startOf('day') || date > moment().add(100,'days')) {
      return;
    }

    // Only selectable if it is a pickup or delivery day
    if(pickup?.pickupDays?.[date.day()] || delivery?.deliveryDays?.[date.day()]) {
      setAdjustDate(date)

      //Check to see if this is an already adjusted date and if so setup the state information accordingly
      const dateIndex = date.format(DateFormat)
      if(adjustedDates?.[dateIndex]) {
        if(adjustedDates[dateIndex].pickupTimings) {
          setAdjustPickup(adjustedDates[dateIndex].pickupTimings)
        }
        if(adjustedDates[dateIndex].deliveryTimings) {
          setAdjustDelivery(adjustedDates[dateIndex].deliveryTimings)
        }
        setAdjustDateType(adjustedDates[dateIndex].type)
      }

      setShowAdjustTimeModal(true)
    } else {
      setAdjustDate(date)
      setShowUnavailableModal(true)
    }
  }

  function handleAvailabilityTypeSwitch(e: any) {
    //The switch has not changed, so do not process
    let type: AdjustDateType = AdjustDateType.Unchanged
    if (e.target.value === "adjusted") {
      type = AdjustDateType.Adjusted
    } else if (e.target.value === "closed") {
      type = AdjustDateType.Closed
    }

    setAdjustDateType(type)
  }

  function saveAdjust() {
    const dates = adjustedDates ? cloneDeep(adjustedDates) : {}
    const date = adjustDate!.format(DateFormat)

    if(!dates[date]) {
      // Not found, add to array if not adjusted or closed
      if(adjustDateType !== AdjustDateType.Unchanged) {
        let update: IAdjustedDate = {
          type: adjustDateType
        }

        if(adjustPickup) {
          update.pickupTimings = adjustPickup
        }
        if(adjustDelivery) {
          update.deliveryTimings = adjustDelivery
        }

        dates[date] = update
      }
    } else {
      if(adjustDateType === AdjustDateType.Unchanged) {
        delete dates[date]
      } else {
        dates[date].type = adjustDateType
        if(adjustPickup) {
          dates[date].pickupTimings = adjustPickup
        }
        if(adjustDelivery) {
          dates[date].deliveryTimings = adjustDelivery
        }
      }
    }

    dispatch(partnerReducer.updateAdjustedDates(dates))
  }

  function handleAdjustClose() {
    cleanUpAdjust()
    setShowAdjustTimeModal(false)
  }
  
  function handleUnavailableClose() {
    cleanUpAdjust()
    setShowUnavailableModal(false)
  }

  function cleanUpAdjust() {
    setAdjustDate(undefined)
    setAdjustDateType(AdjustDateType.Unchanged)
    setAdjustPickup(undefined)
    setAdjustDelivery(undefined)
  }

  function getDateTypeValue(type?: AdjustDateType) {
    if(type === undefined || type === AdjustDateType.Unchanged) {
      return "unchanged"
    } else if(type === AdjustDateType.Adjusted) {
      return "adjusted"
    } else if(type === AdjustDateType.Closed) {
      return "closed"
    }
  }

  function getCellIndicatorColour(date: Dayjs) {
    if(adjustedDates) {
      const index = date.format(DateFormat)
      if(adjustedDates[index]) {
        if(adjustedDates[index].type === AdjustDateType.Adjusted) {
          return "var(--ion-color-warning)"
        } else if(adjustedDates[index].type === AdjustDateType.Closed) {
          return "var(--ion-color-danger)"
        }
      }
    }

    return "var(--ion-color-success)"
  }

  function dateCellRender(date: Dayjs) {
    const dateIndex = date.format(DateFormat)

    if((date >= moment().startOf('day')) && (date < moment().add(100,'days')) && (pickup?.pickupDays?.[date.day()] || delivery?.deliveryDays?.[date.day()])) {
      const pickupTimings = pickup?.pickupDays?.[date.day()]
      let pickupStartTime, pickupEndTime;
      if(pickupTimings) {
        const adjustedDate = adjustedDates?.[dateIndex]
        if(adjustedDate?.pickupTimings) {
          pickupStartTime = convertToTime(adjustedDate.pickupTimings.start);
          pickupEndTime = convertToTime(adjustedDate.pickupTimings.end);
        } else {
          pickupStartTime = convertToTime(pickupTimings.start);
          pickupEndTime = convertToTime(pickupTimings.end);
        }
      }

      const deliveryTimings = delivery?.deliveryDays?.[date.day()];
      let deliveryStartTime, deliveryEndTime;
      if(deliveryTimings) {
        const adjustedDate = adjustedDates?.[dateIndex]
        if(adjustedDate?.deliveryTimings) {
          deliveryStartTime = convertToTime(adjustedDate.deliveryTimings.start);
          deliveryEndTime = convertToTime(adjustedDate.deliveryTimings.end);
        } else {
          deliveryStartTime = convertToTime(deliveryTimings.end);
          deliveryEndTime = convertToTime(deliveryTimings.end);
        }
      }

      return (
        <div className="calendar-cell" onClick={() => dateSelectHandler(date)}>
          <div className="calendar-content">
            {
              (isTablet || isDesktop) &&
              <>
                {
                  adjustedDates?.[dateIndex]?.type === AdjustDateType.Closed ?
                  <div>
                    <div className="cell-heading">
                      <div className="cell-indicator" style={{background: getCellIndicatorColour(date)}}/>
                      <span>Closed</span>
                    </div>
                  </div>
                  :
                  <>
                    {
                      pickupTimings &&
                      <div>
                        <div className="cell-heading">
                          <div className="cell-indicator" style={{background: getCellIndicatorColour(date)}}/>
                          <span>Pickup Hours</span>
                        </div>
                        <span className="cell-timings">{pickupStartTime?.formatted} - {pickupEndTime?.formatted}</span>
                      </div>
                    }
                    {
                      deliveryTimings &&
                      <div>
                        <div className="cell-heading">
                        <div className="cell-indicator" style={{background: getCellIndicatorColour(date)}}/>
                          <span>Delivery Hours</span>
                        </div>
                        <span className="cell-timings">{deliveryStartTime?.formatted} - {deliveryEndTime?.formatted}</span>
                      </div>
                    }
                  </>
                }
              </>
            }
            {
              (isMobile && !isTablet && !isDesktop) &&
              <div className="cell-heading">
                <div className="cell-indicator" style={{background: getCellIndicatorColour(date)}}/>
              </div>          
            }
          </div>
          <div className="calendar-date">
            {date.date()}
          </div>
        </div>
      )
    } else {
      return (
        <div className="calendar-cell" onClick={() => dateSelectHandler(date)}>
          <div className="calendar-date">
            {date.date()}
          </div>
        </div>
      )
    }
  }

  function renderCalendarHeader(config: {value: Dayjs, type: CalendarMode, onChange: (date: Dayjs) => void, onTypeChange: (type: CalendarMode) => void}) {
    return (
      <div className="calendar-header">
        <IonButton color="light" onClick={() => config.onChange(dayjs(config.value).subtract(1, 'month'))}>
          <IonIcon icon={caretBackOutline}/>
        </IonButton>
        <div>{config.value.format("MMMM YYYY")}</div>
        <IonButton color="light" onClick={() => config.onChange(dayjs(config.value).add(1, 'month'))}>
          <IonIcon icon={caretForwardOutline}/>
        </IonButton>
      </div>
    )
  }

  const renderModalHeader = (onClose: () => void) => {
    return (
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>{adjustDate?.format("DD MMMM YYYY")}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    )
  }

  const renderUnavailModalContainer = () => {
    return (
      <IonContent>
        <div className="unavail-modal-container">
          <span>You have not configured pickup or delivery for {adjustDate?.format('dddd')}</span>
          <span><Link to="/edit/store" onClick={handleUnavailableClose}>Click here</Link> to change pickup options</span>
          <span><Link to="/edit/delivery" onClick={handleUnavailableClose}>Click here</Link> to change delivery options</span>
        </div>
      </IonContent>
    )
  }

  const renderAvailModalContainer = () => {
    return (
      <IonContent>
        <div className="avail-modal-container" style={isMobile ? {height: 'calc(50% - 32px)'} : {}}>
          <IonSegment mode="ios" value={getDateTypeValue(adjustDateType)} onIonChange={handleAvailabilityTypeSwitch}>
            <IonSegmentButton value={"unchanged"}>
              <div className="availability-label">
                <div className="cell-indicator" style={{background: 'var(--ion-color-success)'}}/>
                <span>No change</span>
              </div>
            </IonSegmentButton>
            <IonSegmentButton value={"adjusted"}>
              <div className="availability-label">
                <div className="cell-indicator" style={{background: 'var(--ion-color-warning)'}}/>
                <span>Adjusted Hours</span>
              </div>
            </IonSegmentButton>
            <IonSegmentButton value={"closed"}>
              <div className="availability-label">
                <div className="cell-indicator" style={{background: 'var(--ion-color-danger)'}}/>
                <span>Closed</span>
              </div>
            </IonSegmentButton>
          </IonSegment>
          {
            adjustDateType === AdjustDateType.Unchanged &&
            <span>No changes to your normal opening hours</span>
          }
          {
            adjustDate && adjustDateType === AdjustDateType.Closed &&
            <div className="closed-message">
              <span>Your tifyn will appear as closed on: </span>
              <span><b>{adjustDate.format("LL")}</b></span>
            </div>
          }
          {
            adjustDate && adjustDateType === AdjustDateType.Adjusted &&
            <div>
              {
                pickup?.pickupDays?.[adjustDate.day()] &&
                <div className="timing-option">
                  <span className="label">Pickup Times</span>
                  <TimeRangePicker
                    className="available-time-picker"
                    initialValue={
                      {
                        start: dateIndex && adjustedDates?.[dateIndex]?.pickupTimings?.start ? adjustedDates[dateIndex].pickupTimings!.start : pickup.pickupDays[adjustDate.day()].start, 
                        end: dateIndex && adjustedDates?.[dateIndex]?.pickupTimings?.end ? adjustedDates[dateIndex].pickupTimings!.end : pickup.pickupDays[adjustDate.day()].end, 
                      }
                    } 
                    onChange={(start: number, end: number) => {
                      setAdjustPickup({start: start, end: end})
                    }}
                  />
                </div>
              }
              {
                delivery?.deliveryDays?.[adjustDate.day()] &&
                <div className="timing-option">
                  <span className="label">Delivery Times</span>
                  <TimeRangePicker 
                    initialValue={{start: delivery.deliveryDays[adjustDate.day()].start, end: delivery.deliveryDays[adjustDate.day()].end}}
                    onChange={(start: number, end: number) => {
                      setAdjustDelivery({start: start, end: end})
                    }}
                  />
                </div>
              }
            </div>
          }
          <div className="btn-group">
            <IonButton onClick={saveAdjust}>
              <div className="inner-btn">
                Save
              </div>
            </IonButton>
          </div>
        </div>
      </IonContent>
    )
  }

  const dateIndex = adjustDate?.format(DateFormat)

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main/settings" />
          </IonButtons>
          <div className="page-header">
            <>
              <UsergroupAddOutlined />
              <span className="page-header-text">Available Dates</span>
            </>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="available-dates">
          <Calendar
            headerRender={renderCalendarHeader}
            className="calendar"
            validRange={[dayjs().startOf('day'), dayjs().add(MAX_DATE_RANGE, 'days')]}
            fullCellRender={dateCellRender}
          />
        </div>
        <IonModal
          className="adjust-date-modal"
          isOpen={showAdjustTimeModal}
          onWillDismiss={handleAdjustClose}
          canDismiss={true}
          breakpoints={isMobile ? [0.5, 1] : undefined}
          initialBreakpoint={isMobile ? 0.5 : undefined}
        >
          {renderModalHeader(handleAdjustClose)}
          {renderAvailModalContainer()}
        </IonModal>
        <IonModal
          className="adjust-date-modal"
          isOpen={showUnavailableModal}
          onWillDismiss={handleUnavailableClose}
          canDismiss={true}
          breakpoints={isMobile ? [0.5, 1] : undefined}
          initialBreakpoint={isMobile ? 0.5 : undefined}
        >
          {renderModalHeader(handleUnavailableClose)}
          {renderUnavailModalContainer()}
        </IonModal>
      </IonContent>
    </IonPage>
  )
}