import { useState } from "react";

import { IonButton, IonIcon, useIonRouter } from "@ionic/react";
import { conversationsHelper, Utils } from "@tiffin/app-common";
import { IOrderExt, OrderStatus, OrderType } from "@tiffin/core";
import { useAppSelector } from "../hooks";
import { RefundButtons } from "./RefundButtons";
import { OrderButtons } from "./OrderButtons";
import { OrderItemsSummary } from "./OrderItemsSummary";

//3rd party
import { Button } from "antd";
import EnvironmentOutlined from "@ant-design/icons/EnvironmentOutlined"
import { chatbubbleEllipsesOutline } from "ionicons/icons";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { Browser } from "@capacitor/browser";
import moment from "moment";

import './OrderCard.scss';

interface IOrderCardProps {
  order: IOrderExt,
  orderChangeCallback?: (id: string) => void,
  orderBusyCallback?: (id: string) => void,
  compact?: boolean
}

export function OrderCard(props: IOrderCardProps) {
  
  const [buttonsAvailable, setButtonsAvailable] = useState(true);
  const fullView = useMediaQuery({ query: '(min-width:768px)' }) && !props.compact

  const partnerImageUrl = useAppSelector((state) => state.partner.imageUrl)
  const router = useIonRouter();
  const history = useHistory()

  function handleMessageCustomer() {
    //Check for existing chat, else create one
    conversationsHelper.getPartnerConversation(props.order, props.order.tiffinName, partnerImageUrl).then((conversation) => {
      history.push(`/messages/${conversation.id}`, {
        name: `${props.order.customerFirstName} ${props.order.customerLastName}`
      })
    })
  }

  function renderDeliveryAddress() {
    if(props.order.deliveryAddress) {
      return (
        <div>
          <div className="row">
            <span className="small-text"><EnvironmentOutlined /> {Utils.formatAddress(props.order.deliveryAddress)}</span>
          </div>
          <div className="small-text">
            <Button type="link" onClick={(e) => {e.stopPropagation(); Browser.open({ url: Utils.createGoogleMapsURL(Utils.formatAddress(props.order.deliveryAddress!)) })}}>Get directions</Button>
          </div>
        </div>
      )
    }
  }

  function renderCancellationReason() {
    return (
      <div>
        <div className="row">
            <span className="small-text">Cancelled due to "{props.order.cancellationReason}"</span>
        </div>
      </div>
    )
  }

  function renderOrderButtons() {
    return (
      <div className="row btn-group">
        {
          props.order.status !== OrderStatus.Cancelled &&
          <RefundButtons
            size="small"
            amount={props.order.total - (props.order.promoAmount ?? 0)}
            order={props.order}
            visible={!!(props.order.status >= OrderStatus.Confirmed && props.order.paymentIntent)}
            disabled={!buttonsAvailable}
            successCallback={(id: string) => {
              if(props.orderChangeCallback) props.orderChangeCallback(id)
            }}
          />
        }
        <OrderButtons
          size="small"
          {...props.order}
          submitCallback={(id: string) => {
            setButtonsAvailable(false)
            if(props.orderBusyCallback) props.orderBusyCallback(id)
          }}
          errorCallback={(id: string) => {
            setButtonsAvailable(true)
            if(props.orderChangeCallback) props.orderChangeCallback(id)
          }}
          successCallback={(id: string) => {
            setButtonsAvailable(true)
            if(props.orderChangeCallback) props.orderChangeCallback(id)
          }}
        />
      </div>
    )
  }

  function getOrderTime() {
    if(props.order.time === "now") {
      return 'ASAP'
    } else {
      return moment(props.order.time).format('LLL')
    }
  }

  function renderCompactView() {
    return (
      <>
        <div>
          <div className="row">
            <div className="flex-group">
              <div className="small-text order-time">{getOrderTime()}</div>
              {
                !props.order.isGuest &&
                <IonButton color="light" className="message-btn" size="small" onClick={() => handleMessageCustomer()}><IonIcon icon={chatbubbleEllipsesOutline} /></IonButton>
              }
            </div>
          </div>
          <div className="row">
            <div className="small-text">
              <Button type="link" onClick={() => {router.push(`/order/${props.order.id}`)}}>View details</Button>
            </div>
          </div>
        </div>
        {
          (props.order.orderType === OrderType.Delivery && props.order.deliveryAddress) &&
          renderDeliveryAddress()
        }
        {
          props.order.specialInstructions &&
          <div className="note-section">
            <div className="row">
              <div className="small-text">Special instructions from customer</div>
            </div>
            <div className="row">
              <div className="small-text order-note">{props.order.specialInstructions}</div>
            </div>
          </div>
        }
        {
          props.order.status === OrderStatus.Cancelled &&
          renderCancellationReason()
        }
        <OrderItemsSummary order={props.order} />
        {renderOrderButtons()}
      </>
    )
  }

  function renderFullView() {
    return (
      <>
        <div className="left-section">
          <div>
            <div className="row">
              <div className="flex-group">
                <div className="small-text order-time">{getOrderTime()}</div>
                {
                  !props.order.isGuest &&
                  <IonButton color="light" className="message-btn" size="small" onClick={() => handleMessageCustomer()}><IonIcon icon={chatbubbleEllipsesOutline} /></IonButton>
                }
              </div>
            </div>
            <div className="row">
              <div className="small-text">
                <Button type="link" onClick={() => {router.push(`/order/${props.order.id}`)}}>View details</Button>
              </div>
            </div>
          </div>
          {
            (props.order.orderType === OrderType.Delivery && props.order.deliveryAddress) &&
            renderDeliveryAddress()
          }
          {
            props.order.specialInstructions &&
            <div className="note-section">
              <div className="row">
                <div className="small-text">Special instructions from customer</div>
              </div>
              <div className="row">
                <div className="small-text order-note">{props.order.specialInstructions}</div>
              </div>
            </div>
          }
          {
            props.order.status === OrderStatus.Cancelled &&
            renderCancellationReason()
          }
         {renderOrderButtons()}
        </div>
        <div className="right-section">
          <OrderItemsSummary order={props.order} />
        </div>
      </>
    )
  }

  return (
    <div className={`order-content ${fullView ? 'full-view' : ''}`}>
      {fullView ? renderFullView() : renderCompactView()}
    </div>
  )
}