import { useIsPortrait } from "../hooks"

//3rd party components
import { IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar } from "@ionic/react"
import { pieChartOutline } from "ionicons/icons"
import { Plans } from "./PartnerPlans";

import './Plan.scss';

export function Plan() {

  const isPortrait = useIsPortrait()

  return (
    <IonPage className="plan">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main/settings" />
          </IonButtons>
          <div className="page-header">
            <IonIcon icon={pieChartOutline} />
            <span className="page-header-text">Plan Details</span>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="plan-container">
          <div className={`plan-details ${isPortrait ? 'portrait' : 'landscape'}`}>
            <Plans />
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}
