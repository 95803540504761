import { useEffect } from "react"

import { IPayoutTransaction } from "@tiffin/core"
import { payoutsReducer } from "@tiffin/app-common"
import { LoadingSpinner } from "@tiffin/components";

import { helpCircleOutline } from 'ionicons/icons';
import { IonIcon } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "../../hooks"
import Table, { ColumnsType } from "antd/lib/table"
import { Tag, Tooltip } from "antd"
import moment from "moment"

import './PayoutTable.scss'

const LAST_PAYOUT_LIMIT = 10

export function PayoutTable() {

  //Redux
  const payouts = useAppSelector((state) => state.payouts)
  const dispatch = useAppDispatch()

  const columns: ColumnsType<IPayoutTransaction> = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 60,
      render: (value) => {
        let color = '';
        if(value === 'paid') {
          color = 'var(--ion-color-success)'
        } else if(value === 'pending') {
          color = 'var(--ion-color-primary)'
        } else if(value === 'in_transit') {
          color = 'var(--ion-color-primary)'
        } else if(value === 'canceled') {
          color = 'var(--ion-color-warning)'
        } else if(value === 'failed') {
          color = 'var(--ion-color-danger)'
        }
        return <Tag color={color}>{value}</Tag>
      }
    },
    {
      title: 'Transaction Date',
      dataIndex: 'created',
      width: 100,
      key: 'created',
      render: (value) => <Tooltip title={moment(value * 1000).format('LLL')}>{moment(value * 1000).format('DD MMMM YYYY')}</Tooltip>
    },
    {
      title: 'Arrival Date',
      dataIndex: 'arrival_date',
      key: 'arrival_date',
      width: 100,
      render: (value) => <Tooltip title={moment(value * 1000).format('DD MMMM YYYY')}>{moment(value * 1000).format('DD MMMM YYYY')}</Tooltip>
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 100,
      render: (value) => {
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      title: 'Bank Account',
      width: 100,
      dataIndex: ['destination', 'bank_name'],
      key: 'bank_name'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 80,
      render: (value) => {
        if(value < 0) {
          return (
            <Tooltip placement="bottomRight" title={`$${(value * -1 / 100).toFixed(2)} was deducted. This is probably to cover cancellations fees accrued.`}>
              <div className="negative-amount">
                <span>{`$${(value / 100).toFixed(2)}`}</span>
                <IonIcon icon={helpCircleOutline} />
              </div>
            </Tooltip>
          )
        }
        return `$${(value / 100).toFixed(2)}`
      }
    }
  ]

  useEffect(() => {
    dispatch(payoutsReducer.getPayouts(LAST_PAYOUT_LIMIT))
  }, [dispatch])

  return (
    <Table
      className="payout-table"
      columns={columns}
      dataSource={payouts.transactions ? payouts.transactions : []}
      loading={
        payouts.status === "updating" ?
        {
          indicator: <LoadingSpinner />
        }
        :
        undefined
      }
      rowKey="id"
      size="small"
      pagination={false}
      footer={() => { return `Displaying last ${LAST_PAYOUT_LIMIT} payouts`}}
    />
  )
}