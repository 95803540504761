import { useCallback, useEffect, useState } from "react"
import { PhoneAuthProvider, signInWithCredential } from "firebase/auth";
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

//Redux
import { connect, ConnectedProps } from 'react-redux'

//Tiffin
import { auth, Constants, RootState } from "@tiffin/app-common"
import { toast } from "./Toast";
import { LoadingSpinner } from "./LoadingSpinner";
import { SpringModal } from "./SpringModal";
import { OTPInput } from "./OTPInput";

//Components
import { IonButton, useIonToast } from "@ionic/react"
import { Form, Input, Select } from "antd";

import "./LoginForm.scss"

const { Option } = Select

const prefixSelector = (
  <Form.Item name="phonePrefix" noStyle>
    <Select disabled style={{ width: 70 }}>
      <Option value="61">+61</Option>
    </Select>
  </Form.Item>
)

declare global {
  interface Window { recaptchaVerifier: any; }
}

//Steps for connected redux component
const mapState = (state: RootState) => ({
  authenticate: state.authenticate
})
const connector = connect(mapState, null)
type PropsFromRedux = ConnectedProps<typeof connector>

interface ILoginForm extends PropsFromRedux {
  logoSrc: string,
  logoAlt: string,
  successCallback?: () => void,
  lockPhone?: string,
  isVisible?: boolean
}

function LoginForm({logoSrc, logoAlt, successCallback, lockPhone, authenticate, isVisible = true}: ILoginForm) {
  const [loginLoading, setLoginLoading] = useState(false)
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false)
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const [tempVerificationCode, setTempVerificationCode] = useState<string>();
  const [submitVerificationCode, setSubmitVerificationCode] = useState<string | null>(null);
  const [otpFocused, setOtpFocused] = useState(false);

  useEffect(() => {
    //Reset states
    if(!isVisible) {
      setLoginLoading(false)
      setIsCodeModalOpen(false)
      setVerificationId(null)
      setTempVerificationCode(undefined)
      setSubmitVerificationCode(null)
      setOtpFocused(false)
    }
  }, [isVisible])

  useEffect(() => {
    FirebaseAuthentication.addListener('phoneCodeSent', async event => {
      setLoginLoading(false);
      if(event.verificationId) {
        setVerificationId(event.verificationId);
        setIsCodeModalOpen(true);
      } else {
        toast(present, "An error occurred signing in", "danger")
      }
    });
  }, [])

  const [present] = useIonToast();

  const onFinishMobile = useCallback((phone?: string) => {
    setLoginLoading(true);

    FirebaseAuthentication.signInWithPhoneNumber(
      {
        phoneNumber: phone,
      },
    ).then((value) => {
    }, (error) => {
      setLoginLoading(false);
      if(error.message.includes("Exceeded per phone number quota for sending verification codes.")) {
        toast(present, "You've exceeded the maximum number of verification codes, please try again in a little while", "danger", undefined, 5000)
      } else if(error.message.includes("Network error")) {
        toast(present, "Please check your internet connection and try again.", "danger", undefined, 5000)
      } else {
        toast(present, error.message, "danger", undefined, 3000)
      }
    });
  }, [present])

  useEffect(() => {
    if(lockPhone) {
      onFinishMobile(lockPhone)
    }
  }, [lockPhone, onFinishMobile])

  useEffect(() => {
    if (authenticate.status === "authenticated") {
      setSubmitVerificationCode(null);
    }
  }, [authenticate.status])

  useEffect(() => {
    if(verificationId && submitVerificationCode) {
      const credential = PhoneAuthProvider.credential(
        verificationId || '',
        submitVerificationCode,
      );
      signInWithCredential(auth, credential).then(() => {
        if(successCallback) {
          successCallback()
        }
        setVerificationId(null);
        setIsCodeModalOpen(false);
      }, (error) => {
        if(error.code === "auth/invalid-verification-code") {
          toast(present, "Invalid verification code", "danger");
        } else {
          alert(error.message)
        }
        setTempVerificationCode(undefined);
        setSubmitVerificationCode(null);
      })
    }
  }, [verificationId, submitVerificationCode, present, successCallback])

  function handleOtpSubmit() {
    if(tempVerificationCode?.length === 6) {
      setSubmitVerificationCode(tempVerificationCode)
    }
  }

  function onFinishFailedSignIn(errorInfo: any) {
    toast(present, Constants.ERRORMESSAGE_ENSUREPHONENUMBERCORRECT, "danger")
  }

  return (
    <>
      <div className="login">
        <div className="tiffin-logo">
          <img alt={logoAlt} src={logoSrc} />
        </div>
        {
          !lockPhone &&
          <>
            <span className="login-heading">Please enter your mobile number</span>
            <Form
              initialValues={{
                phonePrefix: "61",
              }}
              onFinish={(values) => onFinishMobile(`+${values.phonePrefix}${values.phone}`)}
              onFinishFailed={onFinishFailedSignIn}
              scrollToFirstError
            >
              <Form.Item
                className="phone-input" 
                name="phone"
                rules={[
                  {
                    required: true,
                    message: Constants.ERRORMESSAGE_ENTERPHONENUMBER,
                  },
                  () => ({
                    validator(_, value: string) {
                      if (value && !isNaN(Number(value))) {
                        if (value.length === 9 || (value.length === 10 && value.startsWith("0"))) {
                          return Promise.resolve()
                        }
                        return Promise.reject(new Error(Constants.ERRORMESSAGE_INVALIDPHONENUMBER))
                      }
                      if (typeof value === "undefined") {
                        //No need to send error if undefined as it is a required field
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error(Constants.ERRORMESSAGE_INVALIDPHONENUMBER))
                    },
                  }),
                ]}
              >
                <Input type="number" inputMode="numeric" addonBefore={prefixSelector} placeholder="Mobile Number" />
              </Form.Item>
              <Form.Item className="submit-btn">
                <IonButton type="submit">{loginLoading && <LoadingSpinner className={`loading-btn ${loginLoading ? 'fadeIn2' : 'fadeOut2'}`}/>}Continue</IonButton>
              </Form.Item>
            </Form>
          </>
        }
        {
          !!lockPhone &&
          <span className="login-heading">Verifying Identity</span>
        }
      </div>
      <SpringModal
        isOpen={isCodeModalOpen}
        okText="Submit"
        onOk={handleOtpSubmit}
        okDisabled={tempVerificationCode?.length !== 6}
        onCancel={() => setIsCodeModalOpen(false)}
        okLoading={!!submitVerificationCode}
        title="Please enter OTP"
        didPresent={() => setOtpFocused(true)}
        onClose={() => setOtpFocused(false)}
      >
        <div className="otp-modal">
          <span>Please enter the verification code that was sent to your mobile device.</span>
          <OTPInput
            disabled={!!submitVerificationCode}
            onChange={(val: string) => setTempVerificationCode(val)}
            focused={otpFocused}
          />
        </div>
      </SpringModal>
    </>
  )
}

export default connector(LoginForm);