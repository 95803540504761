var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
export function getEvents() {
    return new Promise(function (resolve, reject) {
        var eventsRef = collection(db, "events");
        var eventsQuery = query(eventsRef, where('registrationsOpen', '==', true));
        getDocs(eventsQuery).then(function (querySnapshot) {
            var records = [];
            querySnapshot.forEach(function (doc) {
                records.push(__assign({ id: doc.id }, doc.data()));
            });
            resolve(records);
        });
    });
}
export function getEventDetails(id) {
    return new Promise(function (resolve, reject) {
        var eventRef = doc(db, "events", id);
        getDoc(eventRef).then(function (docSnap) {
            resolve(__assign({ id: docSnap.id }, docSnap.data()));
        });
    });
}
