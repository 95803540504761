import { useState } from "react"
import { toast, SpringModal } from "@tiffin/components"

//Redux
import { cloudFunction, partnerReducer } from "@tiffin/app-common"
import { useAppDispatch, useAppSelector } from "../hooks"

//3rd party components
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLoading, IonPage, IonToolbar, useIonRouter, useIonToast } from "@ionic/react"
import { banOutline } from "ionicons/icons"
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined"
import CheckOutlined from "@ant-design/icons/CheckOutlined"
import { Alert, Result } from "antd"

import './Status.scss';

export function Status() {

  //Redux states
  const partnerStatus = useAppSelector((state) => state.partner.partnerStatus)

  //Internal states
  const [loading, setLoading] = useState(false)
  const [showOption1Modal, setShowOption1Modal] = useState<boolean>(false)
  const [showOption2Modal, setShowOption2Modal] = useState<boolean>(false)
  const [showActivateModal, setShowActivateModal] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const router = useIonRouter()
  const [present] = useIonToast();

  function handleDeactivate(cancelOrders: boolean) {
    setLoading(true)
    setShowOption1Modal(false)
    setShowOption2Modal(false)
    cloudFunction("deactivate", {cancelOrders: cancelOrders}).then(
      (data: any) => {
        setLoading(false)
        dispatch(partnerReducer.setPartnerStatus("inactive"))
        toast(present, "Your account in now deactivated")
        router.push("/main/settings")
      },
      (error) => {
        setLoading(false)
        console.error(error)
      }
    )
  }

  function handleActivate() {
    setLoading(true)
    setShowActivateModal(false)
    cloudFunction("activatePartner").then(
      (result: any) => {
        if (result.data?.status === "success") {
          setLoading(false)
          dispatch(partnerReducer.setPartnerStatus("active"))
          toast(present, "Your account in now active")
          router.push("/main/settings")
        }
      },
      (error) => {
        setLoading(false)
        console.error(error)
      }
    )
  }

  return (
    <IonPage className="status">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main/settings" />
          </IonButtons>
          <div className="page-header">
            <IonIcon icon={banOutline} />
            <span className="page-header-text">{partnerStatus === 'active' ? 'Deactivate' : 'Activate'}</span>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading isOpen={loading} spinner="crescent" message={`Please wait while we ${partnerStatus === 'active' ? 'deactivate' : 'activate'} your account`} />
        {
          partnerStatus === 'active' &&
          <Result
            icon={<CheckOutlined />}
            title="Your account is currently active"
            subTitle={
              <>
              <p>
                Deactivating your tifyn service will prevent customers from viewing and placing orders for your tifyn
              </p>
              <p>
              Please note, after deactivating you will still be able to access all historic orders and metrics
              </p>
              </>
            }
            extra={
              <>
                <h3>There are two available options when deactivating</h3>
                <div className="deactivate-options">
                  <div className="deactivate-option">
                    <div>
                      <p><b>Option 1:</b> Deactivate and cancel all outstanding orders</p>
                      <Alert showIcon message="Cancellation fees will apply for confirmed orders" type="warning" />
                    </div>
                    <IonButton onClick={() => setShowOption1Modal(true)}>Deactivate and cancel</IonButton>
                  </div>
                  <div className="deactivate-option">
                    <div>
                      <b>Option 2:</b> Deactivate and prevent any further orders
                    </div>
                    <IonButton onClick={() => setShowOption2Modal(true)}>Only Deactivate</IonButton>
                  </div>
                </div>
              </>
            }
          />
        }
        {
          partnerStatus === 'inactive' &&
          <Result
            icon={<CloseCircleOutlined />}
            title="Your account is currently deactivated"
            subTitle={
              <>
              <p>
                Your tifyn service is currently deactivated and customers are unable to view or place orders.
              </p>
              <p>
                When you are ready, you can click activate and your store will be publicly available allowing customers to start ordering your delicious food!
              </p>
              </>
            }
            extra={
              <IonButton onClick={() => setShowActivateModal(true)}>Activate</IonButton>
            }
          />
        }
        <SpringModal
          title="Are you sure you want to deactivate and cancel all outstanding orders?"
          isOpen={showOption1Modal}
          okText="Deactivate"
          onCancel={() => setShowOption1Modal(false)}
          onOk={() => handleDeactivate(true)}
        >
          You will incur cancellation fees for confirmed orders
        </SpringModal>
        <SpringModal
          title="Are you sure you want to deactivate?"
          isOpen={showOption2Modal}
          okText="Deactivate"
          onCancel={() => setShowOption2Modal(false)}
          onOk={() => handleDeactivate(false)}
        />
        <SpringModal
          title="Are you sure you want to activate?"
          isOpen={showActivateModal}
          okText="Activate"
          onCancel={() => setShowActivateModal(false)}
          onOk={() => handleActivate()}
        />
      </IonContent>
    </IonPage>
  )
}
