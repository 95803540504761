var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserInfo, updateUserInfo, signOut, updateUserAddresses, updateUserFavourites, createUserInfo, updateUserPickupAddresses, updatePartnerDeviceRegistrationToken, updateCustomerDeviceRegistrationToken, updateCustomerPolicyAck, updatePartnerPolicyAck } from "../firebase-helpers/user";
import { clone } from "lodash";
import { auth } from "../firebase";
// Define the initial state using that type
var initialState = {
    status: "unknown",
    error: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phonePrefix: "",
    uid: "",
    addresses: [],
    pickupAddresses: [],
    signedOut: false
};
export var fetchUser = createAsyncThunk("user/fetchUser", function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getUserInfo()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); });
export var createUser = createAsyncThunk("user/createUser", function (values) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, createUserInfo(values)];
            case 1:
                _a.sent();
                return [4 /*yield*/, getUserInfo()];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); });
export var updateUser = createAsyncThunk("user/updateUser", function (values) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, updateUserInfo(values)];
            case 1:
                _a.sent();
                return [4 /*yield*/, getUserInfo()];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); });
export var updatePolicyAck = createAsyncThunk("user/updatePolicyAck", function (values) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(values.type === "customer")) return [3 /*break*/, 2];
                return [4 /*yield*/, updateCustomerPolicyAck(values.version)];
            case 1:
                _a.sent();
                return [3 /*break*/, 4];
            case 2:
                if (!(values.type === "partner")) return [3 /*break*/, 4];
                return [4 /*yield*/, updatePartnerPolicyAck(values.version)];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [2 /*return*/, values];
        }
    });
}); });
export var updateAddress = createAsyncThunk("user/updateAddress", function (address, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, addresses, i, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    addresses = state.user.addresses ? clone(state.user.addresses) : [];
                    if (addresses.find(function (f) { return f.placeId === address.placeId; })) {
                        for (i = 0; i < addresses.length; i++) {
                            if (addresses[i].placeId === address.placeId) {
                                addresses[i] = address;
                            }
                        }
                    }
                    else {
                        if (addresses.length === 0) {
                            address.default = true;
                        }
                        addresses.unshift(address);
                    }
                    if (!(state.authenticate.status !== "guest" && !!auth.currentUser)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateUserAddresses(addresses)];
                case 1:
                    response = _b.sent();
                    if (response) {
                        return [2 /*return*/, addresses];
                    }
                    return [3 /*break*/, 3];
                case 2: return [2 /*return*/, addresses];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var updateAddresses = createAsyncThunk("user/updateAddresses", function (addresses) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, updateUserAddresses(addresses)];
            case 1:
                response = _a.sent();
                if (response) {
                    return [2 /*return*/, addresses];
                }
                return [2 /*return*/];
        }
    });
}); });
export var updatePickupAddresses = createAsyncThunk("user/updatePickupAddresses", function (addresses) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, updateUserPickupAddresses(addresses)];
            case 1:
                response = _a.sent();
                if (response) {
                    return [2 /*return*/, addresses];
                }
                return [2 /*return*/];
        }
    });
}); });
export var addPickupAddress = createAsyncThunk("user/addPickupAddress", function (address, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, addresses, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    //Cannot save addresses for guest
                    if (state.authenticate.status === "guest") {
                        return [2 /*return*/];
                    }
                    addresses = state.user.pickupAddresses ? clone(state.user.pickupAddresses) : [];
                    if (!!addresses.find(function (a) { return a.placeId === address.placeId; })) return [3 /*break*/, 2];
                    addresses.unshift(address);
                    return [4 /*yield*/, updateUserPickupAddresses(addresses)];
                case 1:
                    response = _b.sent();
                    if (response) {
                        return [2 /*return*/, addresses];
                    }
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
});
export var deletePickupAddress = createAsyncThunk("user/deletePickupAddress", function (id, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, newPickupAddresses, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    newPickupAddresses = state.user.pickupAddresses.filter(function (address) { return address.placeId !== id; });
                    return [4 /*yield*/, updateUserPickupAddresses(newPickupAddresses)];
                case 1:
                    response = _b.sent();
                    if (response) {
                        return [2 /*return*/, newPickupAddresses];
                    }
                    return [2 /*return*/];
            }
        });
    });
});
export var updateFavourites = createAsyncThunk('user/updateFavourites', function (favourites) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, updateUserFavourites(favourites)];
            case 1:
                response = _a.sent();
                if (response) {
                    return [2 /*return*/, favourites];
                }
                return [2 /*return*/];
        }
    });
}); });
export var signOutUser = createAsyncThunk('user/signout', function (type, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    if (!(state.user.token && state.authenticate.status !== "incomplete")) return [3 /*break*/, 4];
                    if (!(type === "customer")) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateCustomerDeviceRegistrationToken(state.user.token, true)];
                case 1:
                    _b.sent();
                    return [3 /*break*/, 4];
                case 2:
                    if (!(type === "partner")) return [3 /*break*/, 4];
                    return [4 /*yield*/, updatePartnerDeviceRegistrationToken(state.user.token, true)];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4:
                    signOut();
                    return [2 /*return*/];
            }
        });
    });
});
export var userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        updateDeviceRegistrationToken: function (state, action) {
            state.token = action.payload;
        },
        setPendingDelete: function (state) {
            state.pendingDelete = true;
        }
    },
    extraReducers: function (builder) {
        builder.addCase(fetchUser.pending, function (state) {
            state.status = "loading";
        });
        builder.addCase(fetchUser.fulfilled, function (state, action) {
            state.status = "succeeded";
            state.uid = action.payload.uid;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.email;
            state.phone = action.payload.phone;
            state.phonePrefix = action.payload.phonePrefix;
            state.addresses = action.payload.addresses;
            state.pickupAddresses = action.payload.pickupAddresses;
            state.favourites = action.payload.favourites;
            state.customerId = action.payload.customerId;
            state.policyAckVer = action.payload.policyAckVer;
            state.partnerPolicyAckVer = action.payload.partnerPolicyAckVer;
            state.availablePromos = action.payload.availablePromos;
            state.pendingDelete = action.payload.pendingDelete;
        });
        builder.addCase(fetchUser.rejected, function (state, action) {
            state.status = "idle";
            state.uid = "";
            state.firstName = "";
            state.lastName = "";
            state.email = "";
            state.phone = "";
            state.phonePrefix = "";
            state.error = action.error.message;
        });
        builder.addCase(createUser.pending, function (state) {
            state.status = "updating";
        });
        builder.addCase(createUser.fulfilled, function (state, action) {
            state.status = "succeeded";
            state.uid = action.payload.uid;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.email;
            state.phone = action.payload.phone;
            state.phonePrefix = action.payload.phonePrefix;
            state.policyAckVer = action.payload.policyAckVer;
            state.partnerPolicyAckVer = action.payload.partnerPolicyAckVer;
            state.availablePromos = action.payload.availablePromos;
            state.pendingDelete = action.payload.pendingDelete;
        });
        builder.addCase(createUser.rejected, function (state, action) {
            state.status = "failed";
            if (action.error.code === "permission-denied") {
                state.error = "You are not authorized to perform this action. If this error persists please contact Tifyn Support";
            }
            else {
                state.error = action.error.code;
            }
        });
        builder.addCase(updateUser.pending, function (state) {
            state.status = "updating";
        });
        builder.addCase(updateUser.fulfilled, function (state, action) {
            state.status = "succeeded";
            state.uid = action.payload.uid;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.email;
            state.phone = action.payload.phone;
            state.phonePrefix = action.payload.phonePrefix;
            if (action.payload.policyAckVer) {
                state.policyAckVer = action.payload.policyAckVer;
            }
            if (action.payload.partnerPolicyAckVer) {
                state.partnerPolicyAckVer = action.payload.partnerPolicyAckVer;
            }
            if (action.payload.availablePromos) {
                state.availablePromos = action.payload.availablePromos;
            }
            if (action.payload.pendingDelete) {
                state.pendingDelete = action.payload.pendingDelete;
            }
        });
        builder.addCase(updateUser.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.code;
        });
        builder.addCase(updatePolicyAck.pending, function (state) {
            state.status = "updating";
        });
        builder.addCase(updatePolicyAck.fulfilled, function (state, action) {
            state.status = "succeeded";
            if (action.payload.type === "customer") {
                state.policyAckVer = action.payload.version;
            }
            else if (action.payload.type === "partner") {
                state.partnerPolicyAckVer = action.payload.version;
            }
        });
        builder.addCase(updatePolicyAck.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.code;
        });
        builder.addCase(addPickupAddress.fulfilled, function (state, action) {
            if (action.payload) {
                state.pickupAddresses = action.payload;
            }
        });
        builder.addCase(addPickupAddress.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(deletePickupAddress.fulfilled, function (state, action) {
            state.pickupAddresses = action.payload;
        });
        builder.addCase(deletePickupAddress.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(updatePickupAddresses.fulfilled, function (state, action) {
            state.pickupAddresses = action.payload;
        });
        builder.addCase(updatePickupAddresses.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(updateAddress.fulfilled, function (state, action) {
            state.addresses = action.payload;
        });
        builder.addCase(updateAddress.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(updateAddresses.fulfilled, function (state, action) {
            state.addresses = action.payload;
        });
        builder.addCase(updateAddresses.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(updateFavourites.fulfilled, function (state, action) {
            state.favourites = action.payload;
        });
        builder.addCase(updateFavourites.rejected, function (state, action) {
            state.status = 'failed';
            state.error = action.error.message;
        });
        builder.addCase(signOutUser.fulfilled, function (state, action) {
            state.status = "idle";
            state.uid = "";
            state.firstName = "";
            state.lastName = "";
            state.email = "";
            state.phone = "";
            state.phonePrefix = "",
                state.signedOut = true;
        });
        builder.addCase(signOutUser.rejected, function (state, action) {
            state.status = 'failed';
            state.error = action.error.message;
        });
    },
});
export var updateDeviceRegistrationToken = (_a = userSlice.actions, _a.updateDeviceRegistrationToken), setPendingDelete = _a.setPendingDelete;
export default userSlice.reducer;
