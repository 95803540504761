import { useState } from "react";
import { IonButton, useIonToast } from "@ionic/react";
import CaretUpOutlined from "@ant-design/icons/CaretUpOutlined"
import { LoadingSpinner, SpringModal, toast } from "@tiffin/components";
import { Alert, InputNumber } from "antd";
import { cloudFunction } from "@tiffin/app-common";
import { IOrderExt } from "@tiffin/core";

import './RefundButtons.scss'

interface IRefundButtonsProps {
  order: IOrderExt,
  amount: number,
  size?: "small" | "large" | "default",
  visible?: boolean,
  disabled?: boolean,
  successCallback?: (id: string) => void
}

export function RefundButtons(props: IRefundButtonsProps) {

  const [refundModalVisible, setRefundModalVisible] = useState<boolean>(false)
  const [refundAmount, setRefundAmount] = useState<number>()
  const [loading, setLoading] = useState<boolean>(false)

  const [present] = useIonToast();

  const orderFullyRefunded = props.order.refundTotal >= (props.order.total - (props.order.promoAmount ?? 0))

  function handleRefund() {
    const remainingBalance = props.order.total - props.order.refundTotal - (props.order.promoAmount ?? 0);
    if(refundAmount && refundAmount * 100 > remainingBalance) {
      toast(present, `Cannot refund more than the remaining balance ($${(remainingBalance / 100).toFixed(2)})`, "danger")
      setLoading(false)
    } else if(!refundAmount || refundAmount <= 0) {
      toast(present, "Invalid refund amount", "danger")
      setLoading(false)
    } else if(refundAmount > 0) {
      setLoading(true)
      cloudFunction("refundOrder", {orderId: props.order.id, paymentIntentId: props.order.paymentIntent, amount: refundAmount * 100}).then(
        (result: any) => {
          if (result.data?.status === "success") {
            setRefundModalVisible(false)
          } else {
            if(result.data?.error?.raw?.message) {
              toast(present, result.data.error.raw.message, 'danger', 'top', 5000)
            } else {
              toast(present, 'An error occurred')
            }
          }
          setLoading(false)
          if(props.successCallback) props.successCallback(props.order.id)
        }
      ).catch((err) => {
        console.log(err);
      })
    }
  }

  function renderRefundModal() {
    const remainingAmount = (props.order.total - props.order.refundTotal - (props.order.promoAmount ?? 0)) - (props.order.serviceFee ?? 0) - (props.order.paymentFeeAbsorbed ? 0 : props.order.paymentFee)
    return (
      <SpringModal
        isOpen={refundModalVisible}
        onOk={handleRefund}
        onCancel={() => setRefundModalVisible(false)}
        title={"Refund order?"}
        okText="Refund"
        okLoading={loading}
        okDisabled={orderFullyRefunded}
      >
        {orderFullyRefunded && 
        <Alert message="Order has already been fully refunded" type="warning" showIcon />}
        <div className="refund-modal-content">
          <div className="item-price">
            $
            <InputNumber 
              placeholder="amount" 
              value={refundAmount}
              min={0}
              max={remainingAmount / 100}
              onChange={(value) => setRefundAmount(Number(value))}
              precision={2}
            />
          </div>
          <IonButton color="light" size="small" onClick={() => setRefundAmount(remainingAmount / 100)}>Remaining amount (${(remainingAmount / 100).toFixed(2)})</IonButton>
        </div>
      </SpringModal>
    )
  }

  return (
    <div className="refund-buttons" style={{visibility: props.visible ? 'inherit' : 'hidden'}}>
      {renderRefundModal()}
      <IonButton disabled={props.disabled || loading} size={props.size} color="light" onClick={() => setRefundModalVisible(true)}>
        <div className="refund-btn">
          {loading && <LoadingSpinner />}
          Refund <CaretUpOutlined />
        </div>
      </IonButton>
    </div>
  )
}