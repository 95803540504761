import { useState } from "react";
import styled from "styled-components";

import { cloudFunction, Constants, customerOrdersHelper, customerOrdersReducer, profanityFilter, Utils } from "@tiffin/app-common";
import { DateFormat, IOrderExt, OrderItem, OrderStatus, OrderType } from "@tiffin/core";
import { useAppDispatch } from "../../hooks";
import { OrderStatusBlob } from "./OrderStatusBlob";
import { toast } from "../Toast";
import { SpringModal } from "../SpringModal";
import { OrderTimeline } from "../OrderTimeline";
import { Loading } from "../Loading";

import { IonButton, IonIcon, IonToggle, useIonRouter, useIonToast } from "@ionic/react";
import { banOutline, chatboxOutline, documentTextOutline, starOutline} from "ionicons/icons";
import CarOutlined from "@ant-design/icons/CarOutlined"
import ShopOutlined from "@ant-design/icons/ShopOutlined"
import CaretRightOutlined from "@ant-design/icons/CaretRightOutlined";
import EnvironmentOutlined from "@ant-design/icons/EnvironmentOutlined";

import { Alert, Collapse, Divider, Rate } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { Browser } from "@capacitor/browser";

const { Panel } = Collapse;

interface ICustomerOrderModalProps {
  className?: string,
  order: IOrderExt,
  isOpen: boolean,
  onClose: () => void,
  hideViewStoreBtn?: boolean,
  messageCallback: () => void
}

export const CustomerOrderModal = styled(BaseCustomerOrderModal)`

  &.partner-website {

    @media (min-width:961px)  {
      height: 80%;
    }

    .ant-modal-content {
      display: grid;
      height: 100%;
      overflow: auto;
    }
  }

  .container {
    display: grid;
    gap: 20px;
    width: 100%;
  
    .main {
      display: grid;
      gap: 10px;
      grid-template-columns: 100%;
  
      .order {
        display: grid;
        font-size: 1em;

        .order-details {
          display: flex;
          flex-direction: column;
          gap: 5px;

          @media (max-width:768px)  {
            gap: 10px;
          }

          .info {
            display: flex;
            align-items: center;
            gap: 10px;

            &.grid {
              display: grid;
              grid-template-columns: 14px 1fr;
            }

            .direction-link {
              margin-left: 10px;
            }
          }
        }

        .store-btn {
          padding-top: 10px;
        }
  
        .status {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .order-header {
          font-size: 1.4em;
          font-weight: 600;
          padding-bottom: 5px;
        }
        
        .items {
          display: grid;
          padding: 10px 0px 0px;
          gap: 10px;
          
          .order-item {
            display: grid;
            grid-template-columns: auto 1fr auto;
            align-items: center;
            column-gap: 10px;
  
            .count {
              width: 30px;
              height: 30px;
              border: 2px solid var(--color-medium);
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 700;
            }

            .item-modifiers {
              font-size: var(--font-size-small);
              grid-column: 2/4;
          
              .item-modifier {
                display: flex;
                flex-direction: column;
          
                .modifier-title {
                  font-size: var(--font-size-regular);
                  font-weight: 600;
                }
          
                .modifier-option {
                  display: flex;
                  justify-content: space-between;
                  gap: 10px;
                }
              }
            }
          }
        }
      }

      .order-totals {
        display: grid;

        @media (max-width:768px)  {
          gap: 5px;
        }

        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .total {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 1.2em;
          font-weight: 600;
          padding-top: 10px;
        }

        .label {
          font-weight: 600;
        }

        .receipt-link {
          text-align: right;
        }
      }

      .ant-divider-horizontal {
        margin: 0;
      }

      .actions {
        display: flex;
        justify-content: space-between;
        
        .action-btn {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }
  
  &.cancel-order-modal {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;
  }

  &.customer-rating-modal {
    
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;

    .customer-order-rating {
      display: grid;
      gap: 20px;
    
      .ant-rate {
        font-size: 2em;
      }
    
      .customer-review-anon {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    
      textarea {
        padding: 10px 20px;
        background: var(--color-light);
        border: none;
        border-radius: 10px;
      }
    }
  }
  
  @media (min-width:961px)  {
    .customer-order-card {
      .main {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`

export function BaseCustomerOrderModal(props: ICustomerOrderModalProps) {

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)
  const [showRatingModal, setShowRatingModal] = useState(false)
  const [rating, setRating] = useState<number>()
  const [ratingDetails, setRatingDetails] = useState<string>()
  const [anonymous, setAnonymous] = useState<boolean>(false)
  const [cancelling, setCancelling] = useState<boolean>(false)

  //Redux
  const dispatch = useAppDispatch()

  const router = useIonRouter()
  const [present] = useIonToast();

  const cancelOrder = () => {
    setCancelling(true)
    customerOrdersHelper.cancelOrder(props.order.id).then(() => {
      setCancelling(false)
      toast(present, "Order successfully cancelled")
      setShowCancelConfirmation(false)
    }, (error) => {
      setCancelling(false)
      toast(present, error, "danger")
      setShowCancelConfirmation(false)
    })
  }

  const submitRating = () => {

    if(!rating) {
      toast(present, "Please select a rating", "danger")
      return
    }

    cloudFunction("customerReview", {
      id: props.order.id,
      rating: rating,
      details: ratingDetails ? profanityFilter.clean(ratingDetails) : ratingDetails,
      anonymous: anonymous
    }).then(
      (result: any) => {
        setShowRatingModal(false)
        dispatch(customerOrdersReducer.updateReviewedOrder(props.order.id))
      },
      (error) => {
        toast(present, error.message, "danger")
        setShowRatingModal(false)
      }
    )
  }

  const navigateToStore = () => {
    props.onClose()
    router.push(`/tiffin/${props.order.tiffinId}/`)
  }

  const numberOfItems = () => {
    let count = 0
    for(let item of props.order.items) {
      count += item.count
    }
    return count
  }

  function renderItemModifier(orderItem: OrderItem) {
    if(!orderItem.modifiers) return;

    let items: React.ReactElement[] = [];
    for(const modifier of orderItem.modifiers) {
      if(modifier.selectedOptions.length === 0) continue;
      items.push(
        <div className="item-modifier">
          <div className="modifier-title">{modifier.name}</div>
          {
            modifier.selectedOptions.map((selectedOption) => {
              const optionDetails = modifier.options.find((val) => val.label === selectedOption)
              if(optionDetails) {
                return (
                  <div className="modifier-option" key={optionDetails.label}>
                    <div>{optionDetails.label}</div>
                    <div>{optionDetails.price ? `+$${(optionDetails.price * orderItem.count).toFixed(2)}` : ''}</div>
                  </div>
                )
              }
            })
          }
        </div>
      )
    }

    return items;
  }

  const renderItemList = () => {
    let items: JSX.Element[] = []
    for(let item of props.order.items) {
      items.push(
        <div key={item.id} className="order-item">
          <span className="count">
            {item.count}
          </span>
          <span>
            {item.name}
          </span>
          <span>
            ${(item.price * item.count).toFixed(2)}
          </span>
          <div className="item-modifiers">
            {
              renderItemModifier(item)
            }
          </div>
        </div>
      )
    }
    return items
  }

  const numOfItems = numberOfItems();

  const dateText = () => {
    if(props.order.status === OrderStatus.Ready && props.order.orderType === OrderType.Pickup) {
      if(moment(props.order.date, DateFormat).isSame(moment(), 'day')) {
        return 'Today'
      } else {
        return `${moment(props.order.date).format("Do MMM YYYY")}`
      }
    }

    if(props.order.time === "now") {
      const readyBy = moment(props.order.orderTimestamp).add(props.order.preparationTimeInMins, 'minutes')
      if(props.order.orderType === OrderType.Delivery && props.order.deliveryInformation) {
        readyBy.add(props.order.deliveryInformation?.durationSeconds, 'seconds')
      }
      return `${props.order.orderType === OrderType.Pickup ? 'Today • ' : 'Today • ' } ${readyBy.format('h:mm a')}`
    } else {
      if(moment(props.order.date, DateFormat).isSame(moment(), 'day')) {
        return `Today at ${moment(props.order.time).format('h:mm a')}`
      } else {
        return `${moment(props.order.date).format("Do MMM YYYY")} at ${moment(props.order.time).format('h:mm a')}`
      }
    }
  }

  return (
    <SpringModal
      className={`${props.className} partner-website`}
      title={props.order.tiffinName}
      isOpen={props.isOpen}
      onClose={props.onClose}
      disableDrag={true}
      showClose={true}
    >
      <div className="container">
        <div className="main">
          <div className="order">
            <div className="order-details">
              <div className="status">
                <OrderStatusBlob status={props.order.status}/>{Utils.OrderCustomerStatusText(props.order.status, props.order.orderType, moment(props.order.date, DateFormat).isSame(moment(), 'day'))}
              </div>
              <div className="info">
                {
                  props.order.orderType === OrderType.Pickup &&
                  <>
                    <ShopOutlined /> Pickup order for {dateText()}
                  </>
                }
                {
                  props.order.orderType === OrderType.Delivery &&
                  <>
                    <CarOutlined /> Delivery order for {dateText()}
                  </>
                }
                {
                  props.order.orderType === OrderType.DineIn &&
                  <>
                    <ShopOutlined /> Dine in order
                  </>
                }
              </div>
              <div className="info">
                <EnvironmentOutlined /> 
                {(props.order.orderType === OrderType.Delivery && props.order.deliveryAddress) && Utils.formatAddress(props.order.deliveryAddress)}
                {(props.order.orderType === OrderType.Pickup && props.order.pickupAddress) && <div>
                  {props.order.pickupAddress}
                  <a className="direction-link" href={Utils.createGoogleMapsURL(props.order.pickupAddress)}>Get directions</a>  
                </div>}
                {(props.order.orderType === OrderType.DineIn) && <div>
                  Table {props.order.tableNumber}
                </div>}
              </div>
              {
                props.order.specialInstructions &&
                <div className="info grid">
                  <IonIcon icon={documentTextOutline} />
                  {props.order.specialInstructions}
                </div>
              }
            </div>
            {
              !props.hideViewStoreBtn &&
                <div className="store-btn">
                <IonButton size="small" color="light" onClick={navigateToStore}>View store</IonButton>
              </div>
            }
            <div className="items">
              <span className="order-header">Your order ({numOfItems} {numOfItems > 1 ? "Items" : "Item"})</span>
              {renderItemList()}
            </div>
          </div>
          <Divider />
          <div className="order-totals">
            <div className="info">
              <span className="label">Subtotal</span>
              <span>${(props.order.subTotal / 100).toFixed(2)}</span>
            </div>
            {
              props.order.deliveryFee !== undefined &&
              <div className="info">
                <span className="label">Delivery Fee</span>
                <span>{props.order.deliveryFee > 0 ? `$${(props.order.deliveryFee / 100).toFixed(2)}` : 'Free delivery'}</span>
              </div>
            }
            {
              !!props.order.serviceFee &&
              <div className="info">
                <span className="label">Service Fee</span>
                <span>${((props.order.serviceFee - (props.order.serviceFeeRefund ?? 0)) / 100).toFixed(2)}</span>
              </div>
            }
            {
              !!props.order.paymentFee &&
              <div className="info">
                <span className="label">Payment Fee</span>
                <span>${((props.order.paymentFee - (props.order.paymentFeeRefund ?? 0)) / 100).toFixed(2)}</span>
              </div>
            }
            {
              props.order.promoAmount !== undefined &&
              <div className="info">
                <span className="label">Promotion</span>
                <span>-${(props.order.promoAmount / 100).toFixed(2)}</span>
              </div>
            }
            {
              props.order.refundTotal > 0 &&
              <div className="info">
                <span className="label">Refund</span>
                <span>-${((props.order.refundTotal + (props.order.refundTotal * Constants.SERVICE_FEE)) / 100).toFixed(2)}</span>
              </div>
            }
            <div className="total">
              <span className="label">Total</span>
              <span>${((props.order.total - (props.order.promoAmount ?? 0) - (props.order.refundTotal ? (props.order.refundTotal + (props.order.refundTotal * Constants.SERVICE_FEE)) : 0)) / 100).toFixed(2)}</span>
            </div>
            <a className="receipt-link" href={props.order.receiptUrl} onClick={(e) => {e.stopPropagation(); e.preventDefault(); if(props.order.receiptUrl) Browser.open({ url: props.order.receiptUrl })}}>View receipt</a>
          </div>
          {
            props.order.status === OrderStatus.Cancelled &&
            <Alert message={`Cancellation Reason: ${props.order.cancellationReason}`} type="error" />
          }
          <Divider />
          {
            props.order.orderType !== OrderType.DineIn &&
            <div className="order-status">
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              >
                <Panel header="Timeline" key="1">
                  <OrderTimeline 
                    order={props.order}
                  />
                </Panel>
              </Collapse>
            </div>
          }
          <div className="actions">
            <div>
              {
                props.order.status === OrderStatus.Unconfirmed &&
                <IonButton
                  color="danger"
                  onClick={() => {
                    setShowCancelConfirmation(true)
                  }}
                >
                  <div className="action-btn">
                    <IonIcon icon={banOutline}/>Cancel order
                  </div>
                </IonButton>
              }
              {
                props.order.status === OrderStatus.Completed && !props.order.customerReview && 
                <IonButton color='light' onClick={() => setShowRatingModal(true)}>
                  <div className="action-btn">
                    <IonIcon icon={starOutline}/>Rate your order
                  </div>
                </IonButton>
              }
            </div>
            <IonButton color="light" onClick={() => {
              props.onClose()

              //Check for existing chat, else create one
              props.messageCallback()
            }}>
              <div className="action-btn">
                <IonIcon icon={chatboxOutline}/>Message store
              </div>
            </IonButton>
          </div>
        </div>
        <SpringModal
          className={`${props.className} customer-rating-modal`}
          title="Rate your order"
          isOpen={showRatingModal}
          onOk={() => submitRating()}
          okText="Submit"
          onCancel={() => setShowRatingModal(false)}
        >
          <div className={'customer-order-rating'}>
            <div className="customer-review-anon">
              <span>Anonymous</span>
              <IonToggle checked={anonymous} onIonChange={(e) => setAnonymous(e.detail.checked)} />
            </div>
            <Rate allowHalf defaultValue={0} onChange={(value) => setRating(value)} />
            <TextArea
              placeholder="Share details of your order experience"
              autoSize={{ minRows: 2, maxRows: 6 }}
              onChange={(event) => setRatingDetails(event.target.value)}
            />
          </div>
        </SpringModal>
        <SpringModal
          className={`${props.className} cancel-order-modal`}
          title="Cancel order"
          isOpen={showCancelConfirmation}
          onOk={cancelOrder}
          okText="Yes"
          onClose={() => setShowCancelConfirmation(false)}
          showClose={true}
        >
          <span>Are you sure you want to cancel the order?</span>
        </SpringModal>
        <Loading message={"Cancelling order"} isOpen={cancelling} />
      </div>
    </SpringModal>
  )
}