import { useEffect } from "react"
import { partnerReducer } from "@tiffin/app-common"

//Redux
import { useAppDispatch, useAppSelector } from "../hooks"

//3rd party components
import UsergroupAddOutlined from "@ant-design/icons/UsergroupAddOutlined"
import { IonBackButton, IonButtons, IonContent, IonHeader, IonLoading, IonPage, IonToolbar } from "@ionic/react"

import "./PartnerEdit.scss"
import "./PartnerForms.scss"

interface IPartnerEditProps {
  children: JSX.Element,
  heading: string,
  icon?: JSX.Element
}

export function PartnerEdit(props: IPartnerEditProps) {
  //Redux
  const status = useAppSelector((state) => state.partner.status)
  const dispatch = useAppDispatch()

  //Populate the partner store
  useEffect(() => {
    if(status === "unknown") {
      dispatch(partnerReducer.getPartner())
    }
  }, [status, dispatch])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main/settings" />
          </IonButtons>
          <div className="page-header">
            <>
              {props.icon ?? <UsergroupAddOutlined />}
              <span className="page-header-text">{props.heading}</span>
            </>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="partner-edit-tabs">
          {props.children}
        </div>
        <IonLoading isOpen={status === "loading"} spinner="crescent" />
      </IonContent>
    </IonPage>
  )
}
