import { useEffect, useState } from "react";
import { metricsReducer } from "@tiffin/app-common";
import { useAppSelector } from "../hooks";
import { LoadingSpinner, toast } from "@tiffin/components";

//Redux
import { useAppDispatch } from "../hooks"

//3rd party
import RedoOutlined from "@ant-design/icons/RedoOutlined"
import { IonButton, IonSegment, IonSegmentButton, useIonToast } from "@ionic/react";
import { Card, List } from "antd";

import './TopItemsCard.scss'

const NUM_TOP_ITEMS = 5

export function TopItemsCard() {
  const [sortType, setSortType] = useState<"quantity" | "revenue">("quantity")

  const items = useAppSelector((state) => state.metrics.items)
  const status = useAppSelector((state) => state.metrics.itemsStatus)
  const error = useAppSelector((state) => state.metrics.error)
  const dispatch = useAppDispatch()

  const [present] = useIonToast();

  useEffect(() => {
    if(status === "unknown") {
      dispatch(metricsReducer.getItemsMetrics({top: NUM_TOP_ITEMS, type: "quantity"}))
    }
  }, [status, dispatch])

  useEffect(() => {
    if(error) toast(present, error)
  }, [error, present])

  useEffect(() => {
    if(sortType === "revenue") {
      dispatch(metricsReducer.getItemsMetrics({top: NUM_TOP_ITEMS, type: "revenue"}))
    } else {
      dispatch(metricsReducer.getItemsMetrics({top: NUM_TOP_ITEMS, type: "quantity"}))
    }
  }, [sortType, dispatch])

  function refreshData() {
    if(sortType === "revenue") {
      dispatch(metricsReducer.getItemsMetrics({top: NUM_TOP_ITEMS, type: "revenue"}))
    } else if(sortType === "quantity") {
      dispatch(metricsReducer.getItemsMetrics({top: NUM_TOP_ITEMS, type: "quantity"}))
    }
  }

  function renderItems() {
    let elements: JSX.Element[] = []
    if(items) {
      if(items.length === 0) {
        elements.push(
          <List.Item key={"no-items"}>
            <div className="left-panel">
              <span className="item-heading">No Items</span>
              <span className="item-subheading">Once customers place orders your top selling items will be displayed here</span>
            </div>
          </List.Item>
        )
        return elements;
      }

      for(let item of items) {
        if(sortType === "quantity") {
          elements.push(
            <List.Item key={item.id}>
              <div className="left-panel">
                <span className="item-heading">{item.name}</span>
                <span className="item-subheading">Total Revenue ${(item.revenue / 100).toFixed(2)}</span>
              </div>
              <div className="right-panel">
                <span className="value">{item.count}</span>
                <span className="label">Quantity</span>
              </div>
            </List.Item>
          )
        } else {
          elements.push(
            <List.Item key={item.id}>
              <div className="left-panel">
                <span className="item-heading">{item.name}</span>
                <span className="item-subheading">Total Quantity ({item.count})</span>
              </div>
              <div className="right-panel">
                <span className="value">${(item.revenue / 100).toFixed(2)}</span>
                <span className="label">Revenue</span>
              </div>
            </List.Item>
          )
        }
      }
    }
    return elements
  }

  function renderCardHeader() {
    return (
      <>
        <span>
         Top {NUM_TOP_ITEMS} Items
        </span>
        <div className="right-title-buttons">
          <IonSegment color="dark" className="sort-switch" mode="ios" value={sortType} onIonChange={(e) => setSortType(e.detail.value === "quantity" ? "quantity" : "revenue")} >
            <IonSegmentButton value="quantity">
              Quantity
            </IonSegmentButton>
            <IonSegmentButton value="revenue">
              Revenue
            </IonSegmentButton>
          </IonSegment>
          <IonButton color="light" className="chart-refresh-btn" size="small" onClick={refreshData}>          
            <RedoOutlined />
          </IonButton>
        </div>
      </>
    )
  }

  return (
    <Card className="dashboard-card top-items-card" title={renderCardHeader()}>
      <List loading={(status === "unknown" || status === "updating") && {
        indicator: <LoadingSpinner/>
      }}>
        {renderItems()}
      </List>
    </Card>
  )
}