import { useEffect, useMemo, useRef, useState } from "react";
import { AvailabilityType, IMenuItem, PartnerMenu } from "@tiffin/core";
import { partnerReducer } from "@tiffin/app-common";
import { MenuItemEditor } from "./MenuItemEditor";
import { LoadingSpinner, SpringModal } from "@tiffin/components";

//Redux
import { useAppDispatch, useAppSelector } from "../../hooks";

//3rd party
import { IonButton, IonIcon, IonModal, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { addOutline, arrowBackOutline, chevronForwardOutline } from "ionicons/icons";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { Input, Menu } from "antd";

import moment from "moment";

import './MenuEditSelector.scss'

interface IMenuItemExt extends IMenuItem {
  category: string
}

type DayMenu = {
  [key: string]: {
    order: number
    items: IMenuItemExt[]
  }
}

interface IMenuEditSelectorProps {
  view: 'category' | 'day',
  hideArchive?: boolean,
  openTutorial?: boolean,
  closeTutorial?: () => void,
  addingNewItem?: (active: boolean) => void,
  discardItemRef?: React.MutableRefObject<(() => void) | undefined>
}

export function MenuEditSelector({view, hideArchive, openTutorial, closeTutorial, addingNewItem, discardItemRef}: IMenuEditSelectorProps) {

  //Redux
  const pickupDays = useAppSelector((state) => state.partner.pickup?.pickupDays)
  const deliveryDays = useAppSelector((state) => state.partner.delivery?.deliveryDays)
  const partnerMenu = useAppSelector((state) => state.partner.menu)
  const unsavedChanges = useAppSelector((state) => state.partner.unsavedMenuChanges)
  const status = useAppSelector((state) => state.partner.menuStatus)

  const dispatch = useAppDispatch()

  //Internal states
  const [selectedCategory, setSelectedCategory] = useState<string>()
  const [selectedItem, setSelectedItem] = useState<IMenuItem>()
  const [selectedItemId, setSelectedItemId] = useState<string>()
  const [addItemCategory, setAddItemCategory] = useState<string>()
  const [editingItem, setEditingItem] = useState(false)
  const [filteredMenu, setFilteredMenu] = useState<PartnerMenu>(partnerMenu)
  const [searchInput, setSearchInput] = useState<string>()
  const [discardNewItemPrompt, setDiscardNewItemPrompt] = useState(false)

  const categoryRef = useRef<string>()
  const selectedItemRef = useRef<string>()

  const showModalView = isMobile && !isTablet

  useIonViewWillLeave(() => {
    setSelectedItemId(undefined)
    setAddItemCategory(undefined)
    setEditingItem(false)
  }, [])

  useEffect(() => {
    if(discardItemRef) {
      discardItemRef.current = () => {
        if(addingNewItem) addingNewItem(false)
        setAddItemCategory(undefined)
      }
    }
  }, [discardItemRef, addingNewItem])

  useEffect(() => {
    const filteredMenu: PartnerMenu = {}
    if(!searchInput) {
      setFilteredMenu(partnerMenu)
    } else {
      for(let key in partnerMenu) {
        if(key.toLowerCase().includes(searchInput)) {
          filteredMenu[key] = partnerMenu[key]
        } else {
          const filteredItems = partnerMenu[key].items.filter((val: IMenuItem) => val.name.toLowerCase().includes(searchInput));
          if(filteredItems.length > 0) {
            filteredMenu[key] = {
              order: partnerMenu[key].order,
              items: filteredItems
            }
          }
        }
      }
      setFilteredMenu(filteredMenu)
    }
  }, [partnerMenu, searchInput])

  //Sets the selected item and category based on the menu select id
  useEffect(() => {
    if(editingItem) {
      if(selectedItemId) {
        for(let key in filteredMenu) {
          let matchingItem = filteredMenu[key].items.find((item) => item.id === selectedItemId)
          if(matchingItem) {
            setSelectedItem(matchingItem)
            setSelectedCategory(key)
            return
          }
        }

        //Didn't find matching id, unselected and hide edit modal
        setSelectedItemId(undefined)
        setEditingItem(false)
      } else {
        setSelectedItem(undefined)
        setEditingItem(false)
      }
    } else {
      setSelectedItemId(undefined)
    }
  }, [editingItem, selectedItemId, filteredMenu])

  //Populate the menu
  useIonViewWillEnter(() => {
    dispatch(partnerReducer.getMenu())
  }, [dispatch])

  useIonViewWillLeave(() => {
    if(unsavedChanges) {
      //This will reset the menu back and set the unsaved changes flag to false
      dispatch(partnerReducer.getMenu())
    }
  }, [dispatch, unsavedChanges])

  useEffect(() => {
    //Unselects the item, required otherwise the item edit form will persist old values
    if(!unsavedChanges) {
      setSelectedItemId(undefined)
      setSelectedItem(undefined)
    }
  }, [unsavedChanges])

  useEffect(() => {
    for(const key in filteredMenu) {
      if(filteredMenu[key].order === 0) {
        categoryRef.current = key;
      }
    }
  }, [filteredMenu])

  const dayMenu = useMemo(() => {
    if(view === 'day') {
      let menu: DayMenu = {}
      let weekdays = moment.weekdays()
      for(let day in pickupDays) {
        menu[weekdays[Number(day)]] = {
         order: Number(day),
         items: [] 
        }
      }
  
      for(let day in deliveryDays) {
        if(!menu[weekdays[Number(day)]]) {
          menu[weekdays[Number(day)]] = {
            order: Number(day),
            items: [] 
           }
        }
      }
  
      for(let category in filteredMenu) {
        for(let item of filteredMenu[category].items) {
          if(item.availabilityType === AvailabilityType.Everyday) {
            for(let day in menu) {
              menu[day].items.push({...item, category: category})
            }
          } else if(item.availabilityType === AvailabilityType.Custom && item.availability) {
            for(let availability of item.availability) {
              menu[weekdays[Number(availability)]].items.push({...item, category: category})
            }
          }
        }
      }
  
      return menu
    }
  }, [view, filteredMenu, deliveryDays, pickupDays]);

  function handleEditModalDismiss() {
    setEditingItem(false)
    setSelectedItemId(undefined)
  }

  function handleAddedNew(id: string) {
    if(addingNewItem) addingNewItem(false)

    setAddItemCategory(undefined);
    setSelectedItemId(id);

    if(isTablet || isDesktop) {
      setEditingItem(true);
    }
  }

  function handleCancelNew() {
    setDiscardNewItemPrompt(true)
  }

  function handleArchiveItem() {
    setSelectedItemId(undefined)
    setEditingItem(false)
  }
  
  function handleReorder(category: string, direction: 'up' | 'down') {
    let currentPos = 0
    let newPos = 0;
    if(category && filteredMenu && filteredMenu[category]) {
      for(let item of filteredMenu[category].items) {
        if(item.id === selectedItemId) {
          newPos = currentPos
          if(direction === 'up') {
            if(currentPos === 0) {
              //Can't go higher in the array
              return
            }
            newPos--
          } else if(direction === 'down') {
            if(currentPos === filteredMenu[category].items.length - 1) {
              return
            }
            newPos++
          }
          dispatch(partnerReducer.reorderItem({category: category, from: currentPos, to: newPos}))
          break
        }
        currentPos++
      }
    }
  }

  function sortedCategoryList() {
    const categories = [];
    for(const category in filteredMenu) {
      categories.push({name: category, order: filteredMenu[category].order})
    }
    categories.sort((a, b) => a.order - b.order)

    return categories.map((val) => val.name)
  }

  function renderNewItem() {
    return (
      <>
        <IonModal className="menu-item-modal" isOpen={!!addItemCategory && showModalView} onDidDismiss={() => {if(showModalView) setAddItemCategory(undefined)}}>
          <div className="page-header">
            <IonButton onClick={handleCancelNew}><IonIcon icon={arrowBackOutline}/></IonButton>
            <span className="page-header-text">New Item</span>
          </div>
          {
            addItemCategory &&
            <MenuItemEditor key={'new-item'} category={addItemCategory} newItem={true} onAddedNew={handleAddedNew} onCancelNew={handleCancelNew} />
          }
        </IonModal>
        {
          !showModalView && addItemCategory &&
          <MenuItemEditor key={'new-item'} category={addItemCategory} newItem={true} onAddedNew={handleAddedNew} onCancelNew={handleCancelNew} />
        }
      </>
    )
  }

  function renderEditItem() {
    if(selectedItem && selectedCategory) {
      return (
        <>
          <IonModal className="menu-item-modal" isOpen={editingItem && showModalView} onDidDismiss={() => {if(showModalView) handleEditModalDismiss()}}>
            <div className="page-header">
              <IonButton onClick={() => setEditingItem(false)}><IonIcon icon={arrowBackOutline}/></IonButton>
              <span className="page-header-text">Menu Item</span>
            </div>
            <MenuItemEditor key={selectedItem.id} item={selectedItem} category={selectedCategory} menuCategories={sortedCategoryList()} hideArchive={hideArchive} onArchivedItem={handleArchiveItem} />
          </IonModal>
          {
            (!showModalView && editingItem) && 
            <MenuItemEditor key={selectedItem.id} item={selectedItem} category={selectedCategory} reorderItem={handleReorder} menuCategories={sortedCategoryList()} hideArchive={hideArchive} onArchivedItem={handleArchiveItem}/>
          }
        </>
      )
    }
  }

  function getMenuCategories() {
    let items: any[] = []
    if(view === 'category') {
      for(let category in filteredMenu) {
        let childrenItems: any[] = []
        for(let item of filteredMenu[category].items) {
          childrenItems.push({
            label: (
              <div className="menu-item">
                <span>{item.name}</span>
                <IonIcon icon={chevronForwardOutline} />
              </div>
            ),
            key: item.id
          })
        }
        childrenItems.push({
          label: (
            <div className="new-item">
              <IonIcon icon={addOutline} />
              New Item
            </div>
          ),
          key: `add-menu-item-${category}`
        })

        //Multiply order by 2 to take into account the divider
        items[filteredMenu[category].order * 2] = ({
          type: 'group',
          label: category,
          children: childrenItems,
          key: category
        })

        items[filteredMenu[category].order * 2 + 1] = ({
          type: 'divider'
        })
      }
    } else if(view === 'day') {
      if(dayMenu) {
        for(let day in dayMenu) {
          let childrenItems: any[] = []

          for(let item of dayMenu[day].items) {
            childrenItems.push({
              label: (
                <div className="menu-item">
                  <span>{item.name}</span>
                  <IonIcon icon={chevronForwardOutline} />
                </div>
              ),
              key: item.id
            })
          }
  
          items.push({
            type: 'group',
            label: day,
            children: childrenItems,
            key: day
          })

          items.push({
            type: 'divider'
          })
        }
      }
    }

    return items;
  }

  return (
    <>
      {
        status !== 'unknown' && status !== 'loading' && 
        <div className={`menu-content ${showModalView ? 'compact-view' : 'full-view'}`}>
          {
            !showModalView &&
            <Input className="search-menu-input" placeholder="Search Menu" value={searchInput} onChange={(e) => setSearchInput(e.target.value.toLowerCase())} allowClear />
          }
          <Menu
            key="category"
            className={`menu-categories ${showModalView ? 'compact-menu' : 'full-menu'}`}
            style={{ width: showModalView ? 'auto' : 300 }}
            mode="inline"
            selectedKeys={selectedItemId ? [selectedItemId] : []}
            onSelect={(info) => {
              if(info.key.startsWith('add-menu-item')) {
                setEditingItem(false)
                setSelectedItemId(undefined)
                if(view === 'category') {
                  setAddItemCategory(info.key.substring(14))
                  if(addingNewItem) addingNewItem(true)
                }
              } else {                
                if(addItemCategory) {
                  selectedItemRef.current = info.key
                  setDiscardNewItemPrompt(true)
                } else {
                  setAddItemCategory(undefined)
                  setSelectedItemId(info.key)
                  setEditingItem(true)
                }

                if(addingNewItem) addingNewItem(false)
              }
            }}
            items={getMenuCategories()}
          />
          {
            renderEditItem()
          }
          {
            renderNewItem()
          }
        </div>
      }
      {
        (status === 'unknown' || status === 'loading') && 
        <div className="menu-loading">
          <LoadingSpinner />
        </div>
      }
      <SpringModal
        isOpen={discardNewItemPrompt}
        title="Discard new item"
        onOk={() => {
          setAddItemCategory(undefined)
          if(!showModalView) {
            //In non modal view, ensure new item is selected
            setSelectedItemId(selectedItemRef.current)
            setEditingItem(true)
          }
          if(addingNewItem) addingNewItem(false)
          setDiscardNewItemPrompt(false)
        }}
        onCancel={() => {
          selectedItemRef.current = undefined
          setDiscardNewItemPrompt(false)
        }}
        okText="Yes"
      >
        Are you sure you want to discard the new item?
      </SpringModal>
    </>
  )
}