import styled from "styled-components";
import { useState } from "react";
import pack from "../../package.json";

import { useAppDispatch, useAppSelector } from "../hooks"
import { userReducer } from "@tiffin/app-common";
import { SpringModal } from "@tiffin/components"
import { PageBreadcrumb } from "../components/PageBreadcrumb";

//Components
import { Browser } from "@capacitor/browser";
import { IonContent, IonIcon, IonPage, IonSpinner, useIonRouter } from "@ionic/react"
import { personOutline } from 'ionicons/icons';

const PARTNER_FAQ_LINK = "https://tifyn.com.au/partner-help"

export const AccountMenu = styled(BaseAccountMenu)`
  ion-content {
    --background: var(--ion-color-light);
  }

  .main-content {
    padding-top: 10px;
  }

  .account-menu-option {
    font-weight: 400;
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid var(--ion-color-light);
  }
  
  .account-menu-option:active {
    background-color: var(--ion-color-light-shade);
  }

  .version-tag {
    position: absolute;
    bottom: var(--footer-height);
    right: 0px;
    color: var(--ion-color-medium);
    padding-bottom: calc(var(--ion-safe-area-bottom) + 10px);
    padding-right: 20px;

    @media (orientation: landscape) {
      bottom: 0px;
    }
  }
`
interface IAccountMenuProps {
  className?: string
}

export function BaseAccountMenu({className}: IAccountMenuProps) {
  //Redux states
  const partnerStatus = useAppSelector((state) => state.partner.partnerStatus)
  const user = useAppSelector((state) => state.user)
  const [signingOut, setSigningOut] = useState(false) 

  const dispatch = useAppDispatch()
  const router = useIonRouter()

  const [signoutModalVisible, setSignoutModalVisible] = useState<boolean>(false)

  function renderSignoutConfirmation() {
    return (
      <SpringModal 
        isOpen={signoutModalVisible} 
        onOk={handleSignOut} 
        onCancel={() => {if(!signingOut) setSignoutModalVisible(false)}} 
        okText="Yes"
        title={"Sign out"}
      >
        Are you sure you want to sign out?
      </SpringModal>
    )
  }

  function handleSignOut() {
    setSigningOut(true)
    dispatch(userReducer.signOutUser("partner"))
  }

  return (
    <IonPage className={className}>
      <IonContent>
        <div className="page-container">
          <div className="page-breadcrumb-header">
            <PageBreadcrumb
              items={(user.firstName && user.lastName) ? [
                {
                  title: <><IonIcon icon={personOutline} /> {user.firstName} {user.lastName}</>,
                }
              ] : [
                {
                  title: <><IonIcon icon={personOutline} /> <IonSpinner color="primary"/></>,
                }
              ]}
            />
          </div>
          <div className="main-content">
            {
              partnerStatus !== "pending-delete" &&
              <div
                onClick={() => {
                  router.push("/account/info")
                }}
                className="account-menu-option"
              >
                Edit Account Info
              </div>
            }
            <div className="account-menu-option" onClick={() => {
                Browser.open({ url: PARTNER_FAQ_LINK });
              }}
            >
              Help
            </div>
            <div onClick={() => setSignoutModalVisible(true)} className="account-menu-option">
              Sign Out
            </div>
          </div>
        </div>
        <div className="version-tag">{pack.version}</div>
        {renderSignoutConfirmation()}
      </IonContent>
    </IonPage>
  )
}
