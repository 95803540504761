/* eslint-disable jsx-a11y/anchor-is-valid */

import { useRef, useState } from "react";
import { Unsubscribe } from "firebase/firestore";

import { DateFormat, IOrderExt, OrderStatus, OrderType, calculateProcessingFee } from "@tiffin/core"
import { Utils, partnerOrdersHelper, conversationsHelper } from "@tiffin/app-common";
import { GoogleMapRoute, OrderTimeline, OrderTypePill } from "@tiffin/components";
import { RefundStatusPill } from "../components/RefundStatusPill";
import { RefundButtons } from "../components/RefundButtons";
import { OrderButtons } from "../components/OrderButtons";
import { OrderItemsSummary } from "../components/OrderItemsSummary";
import { PageBreadcrumb } from "../components/PageBreadcrumb";
import { useAppSelector } from "../hooks";

//3rd party
import { IonButton, IonContent, IonIcon, IonPage, IonSkeletonText, useIonRouter, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { chatbubbleEllipsesOutline } from "ionicons/icons";
import { Alert, Divider, Skeleton } from "antd";
import moment from "moment";
import CarOutlined from "@ant-design/icons/CarOutlined"
import ShopOutlined from "@ant-design/icons/ShopOutlined"
import PhoneOutlined from "@ant-design/icons/PhoneOutlined"
import { useHistory } from "react-router";

import './OrderDetails.scss'

export function OrderDetails(props: any) {
  const [order, setOrder] = useState<IOrderExt>()
  const [loading, setLoading] = useState(true)
  const partnerImageUrl = useAppSelector((state) => state.partner.imageUrl)

  const orderId: string = props.match.params.orderId
  const unsubOrder = useRef<Unsubscribe | null>(null)
  const history = useHistory()
  const router = useIonRouter();

  useIonViewWillEnter(() => {
    unsubOrder.current = partnerOrdersHelper.subscribeToOrder(orderId, (order: IOrderExt) => {
      setLoading(false)
      setOrder(order)
    })
  })

  useIonViewWillLeave(() => {
    if(unsubOrder.current !== null) {
      unsubOrder.current()
    }
  })

  function handleMessageCustomer() {
    if(!order) return;

    //Check for existing chat, else create one
    conversationsHelper.getPartnerConversation(order, order.tiffinName, partnerImageUrl).then((conversation) => {
      history.push(`/messages/${conversation.id}`, {
        name: `${order.customerFirstName} ${order.customerLastName}`
      })
    })
  }

  return (
    <IonPage>
      <IonContent>
        <div className="order-details-breadcrumb">
          <PageBreadcrumb
            defaultHref="/main/orders"
            items={[
              {
                title: <a onClick={() => router.push('/main/dashboard')}>Dashboard</a>,
              },
              {
                title: order?.date ? <a onClick={() => router.push(`/main/dashboard/${order?.date}`)}>{moment(order?.date, DateFormat).format("dddd, D MMMM YYYY")}</a> : <Skeleton paragraph={false} />,
              },
              {
                title: order ? `Order for ${order?.customerFirstName} ${order?.customerLastName}` : <Skeleton paragraph={false}/>
              }
            ]}
          />
        </div>
        {
          loading && 
          <div className="order-details custom-skeleton">
            <IonSkeletonText animated style={{ width: '40%', height: '40px' }} />
            <Divider />
            <IonSkeletonText animated style={{ width: '40%', height: '40px'}} />
            <IonSkeletonText animated style={{ width: '30%', height: '40px' }} />
            <IonSkeletonText animated style={{ width: '40%', height: '40px' }} />
            <IonSkeletonText animated style={{ width: '100%', height: '40px' }} />
            <IonSkeletonText animated style={{ width: '100%', height: '40px' }} />
            <IonSkeletonText animated style={{ width: '100%', height: '40px' }} />
          </div>
        }
        {
          !loading && order &&
          <div className="order-details">
            <div className="order-header">
              <div className="customer-name-heading">
                <span className="customer-name">{order?.customerFirstName} {order?.customerLastName}</span>
                {
                  !order.isGuest &&
                  <IonButton color="light" className="message-btn" onClick={() => handleMessageCustomer()}><IonIcon icon={chatbubbleEllipsesOutline} /></IonButton>
                }
              </div>
              <span className="order-id">Order ID: {(props.match.params.orderId as string).substring(0,8)}</span>
              
            </div>
            <Divider />
            <div className="order-date">
              <span>{moment(order?.date, DateFormat).format("dddd, D MMMM YYYY")}</span>
              <div className="order-tags">
                <OrderTypePill orderStatus={order.status} />
                {
                  order.refundTotal > 0 &&
                  <RefundStatusPill fullRefund={order.refundTotal >= (order.total - (order.promoAmount ?? 0) - (order.serviceFee ?? 0) - (order.paymentFee ?? 0))} />
                }
              </div>
            </div>
            <div className={`cancel-reason ${order.status === OrderStatus.Cancelled ? 'fadeIn2' : 'fadeOut2'}`}>
              <Alert message={`Cancellation reason: ${order.cancellationReason}`} type="error"/>
            </div>
            <div className="customer-details">
              <div className="left-panel">
                <OrderTimeline 
                  order={order}
                />
              </div>
              <div className="middle-panel">
                <div className="info-section">
                  <div className="info-label">
                    Phone Number
                  </div>
                  <div className="info-value">
                    <>
                      <PhoneOutlined />
                      <a href={`tel:${order.customerPhonePrefix}${order.customerPhone}`}>
                        {order.customerPhonePrefix} {parseInt(order.customerPhone)}
                      </a>
                    </>
                  </div>
                </div>
                <div className="info-section">
                  <div className="info-label">
                    Order Type
                  </div>
                  <div className="info-value">
                    {
                      order.orderType === OrderType.Delivery &&
                      <>
                        <CarOutlined /> Delivery
                      </>
                    }
                    {
                      order.orderType === OrderType.Pickup &&
                      <>
                        <ShopOutlined /> Pickup
                      </>
                    }
                    {
                      order.orderType === OrderType.DineIn &&
                      <>
                        <ShopOutlined /> Dine In
                      </>
                    }
                  </div>
                </div>
                {
                  order.orderType === OrderType.DineIn &&
                  <div className="info-section">
                    <div className="info-label">
                      Table Number
                    </div>
                    <div className="info-value">
                      {order.tableNumber}
                    </div>
                  </div>
                }
                {
                  order?.deliveryAddress &&
                  <div className="info-section">
                    <div className="info-label">
                      Delivery Address
                    </div>
                    <div className="info-value">
                      {order.deliveryAddress && Utils.formatAddress(order.deliveryAddress)}
                    </div>
                  </div>
                }
                {
                  order.specialInstructions &&
                  <div className="info-section">
                    <div className="info-label">
                      Special instructions from customer
                    </div>
                    <div className="info-value">
                      {order.specialInstructions}
                    </div>
                  </div>
                }
              </div>
              {
                order?.deliveryAddress &&
                <div className="right-panel">
                  {
                    order?.deliveryInformation?.encodedPolyline &&
                    <GoogleMapRoute
                      encodedPolyline={order?.deliveryInformation?.encodedPolyline}
                    />
                  }
                </div>
              }
            </div>
            <Divider />
            <div className="order-summary">
              <OrderItemsSummary order={order} />
              <Divider />
              <div className="order-total">
              <span>Total</span>
                <span className="order-total-value">${((order.total - order.refundTotal - order.serviceFee - (order.cancellationFee ?? 0) - calculateProcessingFee(order).processingFee - (order.paymentFeeAbsorbed ? order.paymentFee - order.paymentFeeRefund : order.paymentFee)) / 100).toFixed(2)}</span>
              </div>
              <div className="order-footer">
                <RefundButtons 
                  size="default"
                  amount={order.total - (order.promoAmount ?? 0)} 
                  order={order}
                  visible={!!(order.status >= OrderStatus.Confirmed && order.status !== OrderStatus.Cancelled && order.paymentIntent)}
                />
                <OrderButtons
                  size="default"
                  {...order}
                />
              </div>
            </div>
          </div>
        }
      </IonContent>
    </IonPage>
  )
}