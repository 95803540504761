import { useState } from "react";
import { cloudFunction, partnerHelper, partnerReducer } from "@tiffin/app-common";
import { LoadingSpinner, toast } from "@tiffin/components";
import { useAppDispatch, useAppSelector } from "../hooks";

//3rd party
import { IonButton, IonContent, IonIcon, IonPage, IonRefresher, IonRefresherContent, useIonRouter, useIonToast } from "@ionic/react";
import { checkmarkDoneOutline, hourglassOutline, trashBinOutline } from "ionicons/icons";
import moment from "moment";

import './OnboardingStatus.scss'

export function OnboardingStatus() {
  const tiffinId = useAppSelector((state) => state.partner.id)
  const partnerStatus = useAppSelector((state) => state.partner.partnerStatus)
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const router = useIonRouter()
  const [present] = useIonToast();

  function activatePartner() {
    setLoading(true)
    cloudFunction("activatePartner", {timezone: moment.tz.guess()}).then(
      (result: any) => {
        if (result.data?.status === "success") {
          setLoading(false)
          dispatch(partnerReducer.setPartnerStatus("active"))
          toast(present, "Your account in now active")
          router.push("/main/dashboard")
        }
      },
      (error) => {
        setLoading(false)
        console.error(error)
      }
    )
  }

  function doRefresh(event: any) {
    partnerHelper.getPartnerInfo().then((data: any) => {
      if(data.status) {
        dispatch(partnerReducer.setPartnerStatus(data.status))
      }
      event.detail.complete()
    })
  }
 
  return (
    <IonPage>
      <IonContent>
        <div className="onboarding-status">
          {
            partnerStatus === "pending" &&
            <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
              <IonRefresherContent
                refreshingSpinner="crescent"
              >      
              </IonRefresherContent>
            </IonRefresher>
          }
          <div className="status-icon">
            {
              partnerStatus === "pending" &&
              <IonIcon icon={hourglassOutline} />
            }
            {
              partnerStatus === "approved" &&
              <IonIcon icon={checkmarkDoneOutline} style={{color: 'var(--ion-color-success)'}} />
            }
           {
              partnerStatus === "pending-delete" &&
              <IonIcon icon={trashBinOutline} style={{color: 'var(--ion-color-danger)'}} />
            }
          </div>
          <span className="status-heading">
            {
              partnerStatus === "pending" && 
              "Your application is pending approval"
            }
            {
              partnerStatus === "approved" && 
              "Congratulations! Your application has been approved"
            }
            {
              partnerStatus === "pending-delete" &&
              "Your account is pending deletion"
            }
          </span>
          {
            partnerStatus === "pending" &&
            "A representative will be in contact with you within the next 1-2 business days"
          }
          {
            partnerStatus === "approved" &&
            <>
              <span>Upon activation your store website will be publicly available!</span>
              <IonButton onClick={activatePartner} disabled={loading}>{loading && <LoadingSpinner className={`loading-btn ${loading ? 'fadeIn2' : 'fadeOut2'}`}/>}{loading ? 'Activating' : 'Activate'}</IonButton>
            </>
          }
          {
            partnerStatus === "pending-delete" &&
            <>
              <span>It can take up to <b>10 business days</b> to delete your account and all personal data.</span>
              <span>If this was not done by you or if you believe it is a mistake, please contact Tifyn support immediately at <a href = {`mailto: support@tifyn.com.au?subject=Cancel%20delete%20request%20-%20${encodeURIComponent(tiffinId)}`}>support@tifyn.com.au</a></span>
            </>
          }
        </div>
      </IonContent>
    </IonPage>
  )
}