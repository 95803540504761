import styled from "styled-components";
import { CartItems } from "@tiffin/app-common"
import { Button } from "antd";

export const CheckoutGroupButton = styled(BaseCheckoutGroupButton)`
`

interface ICheckoutGroupButtonProps {
  items: CartItems[],
  disabled?: boolean,
  checkoutCallback: () => void
}

export function BaseCheckoutGroupButton({items, disabled, checkoutCallback}: ICheckoutGroupButtonProps) {

  function cartTotalPrice() {
    let subTotal = 0
    for (let item of items) {
      subTotal += calculatePrice(item) * item.count
    }
    return subTotal
  }

  function calculatePrice(item: CartItems) {
    let total = item.details.price;
    if(item.modifiers) {
      for(const selectedModifier of item.modifiers) {
        for(const option of selectedModifier.selectedOptions) {
          const optionDetails = selectedModifier.options.find((val) => val.label === option)
          total += optionDetails?.price ?? 0
        }
      }
    }
    return total;
  }

  return (
    <Button
      type="primary"
      disabled={disabled}
      onClick={checkoutCallback}
    >
      Checkout • {`$${cartTotalPrice().toFixed(2)}`}
    </Button>
  )
}