"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromoCodeAction = exports.OrderType = exports.APP_ID = void 0;
exports.APP_ID = "APP";
var OrderType;
(function (OrderType) {
    OrderType[OrderType["Pickup"] = 0] = "Pickup";
    OrderType[OrderType["Delivery"] = 1] = "Delivery";
    OrderType[OrderType["DineIn"] = 2] = "DineIn";
})(OrderType = exports.OrderType || (exports.OrderType = {}));
var PromoCodeAction;
(function (PromoCodeAction) {
    PromoCodeAction[PromoCodeAction["Apply"] = 1] = "Apply";
    PromoCodeAction[PromoCodeAction["Remove"] = 2] = "Remove";
})(PromoCodeAction = exports.PromoCodeAction || (exports.PromoCodeAction = {}));
