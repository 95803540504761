import { useEffect, useRef, useState } from "react"
import { toast } from "@tiffin/components"
import { Constants, partnerReducer, partnerHelper } from "@tiffin/app-common"

//Redux
import { useAppDispatch, useAppSelector } from "../hooks"

//3rd party
import { Button, Form, FormInstance, Input } from "antd"
import { useIonToast } from "@ionic/react"
import { Prompt } from "react-router"
import { IPartnerWebsiteDetails } from "@tiffin/core"

export function WebsiteOptionsForm() {
  //Redux
  const status = useAppSelector((state) => state.partner.status)
  const error = useAppSelector((state) => state.partner.error)
  const partner = useAppSelector((state) => state.partner)

  const dispatch = useAppDispatch()

  //Internal states
  const [valueChanged, setValuesChanged] = useState(false)
  const [saving, setSaving] = useState(false)

  const nameValidateRef = useRef<any>(null)
  const formRef = useRef<FormInstance>(null)
  const isPopulatedRef = useRef(false)
  const [present] = useIonToast();

  //Populate form with user information if provided through props
  useEffect(() => {
    if (partner.urlName && !isPopulatedRef.current) {
      formRef.current?.setFieldsValue({ 
        urlName: partner.urlName,
        facebookUrl: partner.facebookUrl,
        instagramUrl: partner.instagramUrl,
        tiktokUrl: partner.tiktokUrl
       })
      isPopulatedRef.current = true;
    }
  }, [formRef, partner.urlName, partner.facebookUrl, partner.instagramUrl, partner.tiktokUrl])

  useEffect(() => {
    if (status === "saved") {
      setSaving(false)
      dispatch(partnerReducer.resetStatus())
      toast(present, "Saved changes", "success", "bottom")
      setValuesChanged(false)
    }
  }, [status, dispatch, present])

  useEffect(() => {
    if (error) {
      toast(present, error, "danger")
    }
  }, [error, present])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function handleSave(){
    setTimeout(() => {
      formRef.current?.validateFields().then(
        (fields: any) => {
          setSaving(true)

          let update: IPartnerWebsiteDetails = {}

          if(fields.urlName) {
            update.urlName = fields.urlName
          }

          if(fields.facebookUrl) {
            update.facebookUrl = fields.facebookUrl
          }

          if(fields.instagramUrl) {
            update.instagramUrl = fields.instagramUrl
          }

          if(fields.tiktokUrl) {
            update.tiktokUrl = fields.tiktokUrl
          }

          //Updates details in the redux store
          dispatch(partnerReducer.updateWebsiteDetails(update))
        },
        (err) => {
          if(err && err.errorFields?.[0]) {
            toast(present, err.errorFields[0].errors[0], "danger")
          }
        }
    )})
  }

  const isUniqueUrlName = async (_: any, name: string): Promise<boolean> => {
    const response = await partnerHelper.urlNameIsUnique(name)
    if(response) {
      return Promise.resolve(true)
    }
    return Promise.reject(Constants.ERRORMESSAGE_URLNAMEEXISTS)
  }

  return (
    <Form
      ref={formRef}
      className="partner-form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      scrollToFirstError
      onValuesChange={() => setValuesChanged(true)}
    >
      <div className="outer-form-container">
        <div className="form-container">
          <Form.Item
            name="urlName"
            label="Store URL"
            rules={[
              {
                required: true,
                message: "Please enter the name of your store",
              },
              () => ({
                async validator(_, value: string) {
                  // Empty value error message taken care of by first rule
                  if(!value) return Promise.reject()

                  return new Promise((resolve, reject) => {
                    if(nameValidateRef.current) {
                      clearTimeout(nameValidateRef.current)
                    }
                    
                    nameValidateRef.current = setTimeout(() => {
                      isUniqueUrlName(_, value).then((value) => {
                        resolve(value)
                      }, (err) => {
                        reject(err)
                      })
                    }, 500)
                  })

                },
              }),
            ]}
            hasFeedback
          >
            <Input placeholder="Please enter your store's url" />
          </Form.Item>
          <p style={{textAlign: "right", marginRight: "10px", marginTop: "0px"}}>Your store URL is <a href={`https://${process.env.REACT_APP_PARTNER_WEBSITE_URL_BASE}/${partner.urlName}`}>{`https://${process.env.REACT_APP_PARTNER_WEBSITE_URL_BASE}/${partner.urlName}`}</a></p>
          <Form.Item name="facebookUrl" label="Your Facebook profile link">
            <Input placeholder="A link to your Facebook profile" allowClear />
          </Form.Item>
          <Form.Item name="instagramUrl" label="Your Instagram profile link">
            <Input placeholder="A link to your Instagram profile" allowClear />
          </Form.Item>
          <Form.Item name="tiktokUrl" label="Your TikTok profile link">
            <Input placeholder="A link to your TikTok profile" allowClear />
          </Form.Item>
        </div>
      </div>
      <Form.Item key={1} className="form-buttons" wrapperCol={{ span: 24 }}>
        <Button type="primary" size="large" loading={saving} className="right-btn" htmlType="submit" onClick={handleSave}>
          Save
        </Button>
      </Form.Item>
      {
        valueChanged &&
        <Prompt
          message="Any unsaved changes will be discarded?"
        />
      }
    </Form>
  )
}
