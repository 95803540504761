import { AvailabilityType, Day, IMenuItem, PartnerStatus } from "@tiffin/core";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { MenuItem } from "./MenuItem";

export const MenuGroup = styled(BaseMenuGroup)`
  display: grid;
  grid-row: auto;
  grid-auto-rows: min-content;

  .menu-group-title {
    padding: 20px;
    font-size: var(--font-size-xlarge);
    font-weight: 600;
    color: var(--color-dark);
  }

  .menu-items {
    margin: 0 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    gap: 10px;

    @media (max-width:768px) {
      margin: 0;
      grid-template-columns: 100%;
      gap: 5px;
    }

    .menu-item-card {
      border: 1px solid var(--ion-color-light);
      border-radius: 10px;
    }
  }
`

interface IMenuGroup {
  className?: string,
  category: string,
  order: number,
  items: IMenuItem[],
  partnerStatus: PartnerStatus,
  orderDays: string[],
  clickCallback: (id: string, key: string) => void,
  inViewCallback: (key: string, order: number, inView: boolean) => void,
  displayAvailability?: boolean
}

function BaseMenuGroup({className, category, order, items, partnerStatus, orderDays, clickCallback, inViewCallback, displayAvailability}: IMenuGroup) {

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.5,
  });

  useEffect(() => {
    inViewCallback(category, order, inView)
  }, [inView, inViewCallback, category, order])
  
  function renderTiffinMenuItem(item: IMenuItem, group: string, orderDays: string[]) {
    let availability: Day[];
    if(item.availabilityType === AvailabilityType.Everyday) {
      availability = orderDays.map((val) => parseInt(val))
    } else {
      availability = item.availability.filter((day: Day) => {
        if (orderDays.includes(day.toString())) {
          return true
        } else {
          return false
        }
      })
    }
    return <MenuItem unavailable={partnerStatus === 'inactive'} key={item.id} group={group} {...item} availability={availability} onClickCallback={clickCallback} displayAvailability={displayAvailability} />
  }

  return (
    <div ref={ref} id={category} key={category} className={className}>
      <div className="menu-group-title">{category}</div>
      <div className="menu-items">
        {items.map((item, i) => {
          return renderTiffinMenuItem(item, category, orderDays)
        })}
      </div>
    </div>
  )
}