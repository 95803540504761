import { useEffect, useRef, useState } from "react"
import { Utils, partnerReducer } from "@tiffin/app-common"
import { AdvanceDurationType, convertToTime, Day, IDeliveryConfiguration, IPartnerDelivery } from "@tiffin/core"
import { toast } from "@tiffin/components"
import { TimeRangePicker } from "@tiffin/components";
import PlusOutlined from "@ant-design/icons/PlusOutlined"
import DeleteOutlined from "@ant-design/icons/DeleteOutlined"
import CheckOutlined from "@ant-design/icons/CheckOutlined"
import CloseOutlined from "@ant-design/icons/CloseOutlined"

//Redux
import { useAppDispatch, useAppSelector } from "../hooks"

//3rd party
import { useIonToast } from "@ionic/react"
import { Button, Checkbox, Form, FormInstance, InputNumber, Switch } from "antd"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import moment from "moment"
import cloneDeep from "lodash.clonedeep"
import { Prompt } from "react-router";

interface IDeliveryTier {
  distanceMeters?: number,
  pricePerKm?: number
}

interface IDeliveryFormProps {
  active?: boolean,
  independent?: boolean,
  onDone?: (action: "save" | "prev" | "next") => void,
  navigate?: (index: number) => void
}

export function DeliveryForm({active, onDone, ...props}: IDeliveryFormProps) {
  //Redux
  const status = useAppSelector((state) => state.partner.status)
  const error = useAppSelector((state) => state.partner.error)

  const partnerDelivery = useAppSelector((state) => state.partner.delivery)
  const partnerAdvance = useAppSelector((state) => state.partner.advance)
  const navigateFormRef = useRef<"prev" | "next" | null>(null)

  const dispatch = useAppDispatch()

  //Internal states
  const [deliveryAvailable, setDeliveryAvailable] = useState<boolean>(false)
  const [deliveryDays, setDeliveryDays] = useState<Day[]>()
  const [deliveryTimes, setDeliveryTimes] = useState<{ start: number; end: number }[]>([])
  const [deliveryPlaceIds, setDeliveryPlaceIds] = useState<Record<string, string>>({})
  const [valueChanged, setValuesChanged] = useState(false)
  const [baseDeliveryFee, setBaseDeliveryFee] = useState<number>()
  const [baseDeliveryDistance, setBaseDeliveryDistance] = useState<number>()
  const [deliveryTiers, setDeliveryTiers] = useState<IDeliveryTier[]>([])
  const [deliveryFeeError, setDeliveryFeeError] = useState<string>()

  const formRef = useRef<FormInstance>(null)
  const [present] = useIonToast();

  useEffect(() => {
    //Populate form
    if (partnerDelivery?.deliveryDays && partnerDelivery.offerDelivery) {
      let initDeliveryDays: number[] = []
      let initDeliveryTimes: { start: number; end: number }[] = []
      let deliveryTimesFields: any = {}

      for (let day in partnerDelivery.deliveryDays) {
        initDeliveryDays.push(Number(day))

        deliveryTimesFields[`deliveryTime-${day}`] = {...partnerDelivery.deliveryDays[day]}
        initDeliveryTimes[Number(day)] = {...partnerDelivery.deliveryDays[day]}
      }

      formRef.current?.setFieldsValue({
        offerDelivery: initDeliveryDays.length > 0 ? true : false,
        deliveryDays: initDeliveryDays,
        basePrice: partnerDelivery.deliveryConfiguration?.basePrice !== undefined ? partnerDelivery.deliveryConfiguration?.basePrice / 100 : undefined,
        baseDistanceMeters: partnerDelivery.deliveryConfiguration?.baseDistanceMeters ? partnerDelivery.deliveryConfiguration?.baseDistanceMeters / 1000 : undefined,
        minDeliveryOrder: partnerDelivery.minDeliveryOrder,
        ...deliveryTimesFields,
      })

      setDeliveryDays(initDeliveryDays)
      setDeliveryTimes(initDeliveryTimes)
      setDeliveryAvailable(partnerDelivery.offerDelivery)
      setDeliveryPlaceIds(partnerDelivery.deliveryPlaceIds ? partnerDelivery.deliveryPlaceIds : {})

      if(partnerDelivery.deliveryConfiguration) {
        setBaseDeliveryFee(partnerDelivery.deliveryConfiguration.basePrice / 100)
        setBaseDeliveryDistance(partnerDelivery.deliveryConfiguration.baseDistanceMeters / 1000)
    
        let tiers: IDeliveryTier[] = []
        let currentDistance = partnerDelivery.deliveryConfiguration.baseDistanceMeters / 1000

        if(partnerDelivery.deliveryConfiguration.tiers) {
          for(const tier of partnerDelivery.deliveryConfiguration.tiers) {
            tiers.push({
              pricePerKm: tier.pricePerKm / 100,
              distanceMeters: currentDistance + (tier.distanceMeters / 1000)
            })
            currentDistance += (tier.distanceMeters / 1000)
          }
        }
        setDeliveryTiers(tiers)
      }
      
    }
  }, [partnerDelivery, formRef])

  useEffect(() => {
    if(active) {
      if (status === "saved" && navigateFormRef.current === "next") {
        dispatch(partnerReducer.resetStatus())
        if(onDone) onDone("next")
        navigateFormRef.current = null
      } else if (status === "saved" && navigateFormRef.current === "prev") {
        dispatch(partnerReducer.resetStatus())
        if(onDone) onDone("prev")
        navigateFormRef.current = null
      } else if (status === "saved") {
        dispatch(partnerReducer.resetStatus())
        toast(present, "Saved changes", "success")
        if(onDone) onDone("save")
      }
    }
  }, [status, dispatch, onDone, active, present])

  useEffect(() => {
    if(props.independent) {
      if (status === "saved") {
        dispatch(partnerReducer.resetStatus())
        toast(present, "Saved changes", "success", "bottom")
        setValuesChanged(false)
      }
    }
  }, [props.independent, status, dispatch, present])

  useEffect(() => {
    if (error) {
      toast(present, error, "danger")
    }
  }, [error, present])

  function handlePrev() {
    handleSave()
    //When the save is successful, we will navigate to the prev form
    navigateFormRef.current = "prev"
  }

  function handleNext(e: any) {
    //Remove focus to prevent user clicking 'enter' button to progress forward
    if(e?.target) {
      e.target.blur()
    }
    
    handleSave()
    //When the save is successful, we will navigate to the next form
    navigateFormRef.current = "next"
  }

  function handleSave() {

    let deliveryConfiguration: IDeliveryConfiguration
    if(deliveryAvailable) {
      if(baseDeliveryFee === undefined) {
        setDeliveryFeeError("Please enter a delivery price")
        return
      }
  
      if(!baseDeliveryDistance) {
        setDeliveryFeeError("Please enter the distance in km")
        return
      }
  
      if(deliveryTiers.length > 0) {
        if(!deliveryTiers[deliveryTiers.length - 1].pricePerKm) {
          setDeliveryFeeError("Please enter the price per km")
          return
        }
    
        if(!deliveryTiers[deliveryTiers.length - 1].distanceMeters) {
          setDeliveryFeeError("Please enter the distance in km")
          return
        }
      }
  
      deliveryConfiguration = {
        basePrice: baseDeliveryFee * 100,
        baseDistanceMeters: baseDeliveryDistance * 1000,
        tiers: []
      }
  
      let currentDistance = baseDeliveryDistance
      if(deliveryConfiguration.tiers) {
        for(const tier of deliveryTiers) {
          if(tier.pricePerKm !== undefined &&  tier.distanceMeters !== undefined) {
            deliveryConfiguration.tiers.push({
              pricePerKm: tier.pricePerKm * 100,
              distanceMeters: (tier.distanceMeters - currentDistance) * 1000
            })
  
            currentDistance = tier.distanceMeters
          }
        }
      }
    }
    
    formRef.current?.validateFields().then(
      (fields: any) => {
        let details: IPartnerDelivery = {}
        details.offerDelivery = fields.offerDelivery

        if(details.offerDelivery) {
          details.deliveryDays = {}
          details.minDeliveryOrder = fields.minDeliveryOrder
          details.deliveryPlaceIds = deliveryPlaceIds
          details.deliveryConfiguration = deliveryConfiguration

          if (fields.deliveryDays) {
            for (let day of fields.deliveryDays) {
              details.deliveryDays[day] = {
                ...fields[`deliveryTime-${day}`]
              }
            }
          }
        }

        //Updates details in the redux store
        dispatch(partnerReducer.updateDelivery(details))
      },
      () => {
        navigateFormRef.current = null
      }
    )
  }

  function handleDeliverySwitch(checked: boolean) {
    setDeliveryAvailable(checked)
  }

  function handleDeliveryDays(checkedValue: Array<CheckboxValueType>) {
    setDeliveryDays(checkedValue as Day[])
  }

  function handleDeliveryTimes(day: Day, start: number, end: number) {
    let deliveryTimesFields: any = {}
    deliveryTimesFields[`deliveryTime-${day}`] = {start: start, end: end}

    formRef.current?.setFieldsValue({
      ...deliveryTimesFields
    })

    let newDeliveryTimes = cloneDeep(deliveryTimes)
    newDeliveryTimes[Number(day)] = {start: start, end: end}
    setDeliveryTimes(newDeliveryTimes)
  }

  function dayCutOffTimeMessage(day: Day) {
    if (partnerAdvance?.preparationEnabled && partnerAdvance?.preparationDurationType === AdvanceDurationType.Hours) {
      if (deliveryTimes[day]) {
        const time = convertToTime(deliveryTimes[day].end)
        let cutOffTime = moment(time.formatted, 'HH:mm').subtract(partnerAdvance?.preparationDuration, "hours")
        return `Cut off time for order placement is ${cutOffTime.format("h:mm A")}`
      }
    } else {
      if (deliveryTimes[day]) {
        const time = convertToTime(deliveryTimes[day].end)
        let cutOffTime = moment(time.formatted, 'HH:mm').subtract(partnerAdvance?.preparationInMins, "minutes")
        return `Cut off time for order placement is ${cutOffTime.format("h:mm A")}`
      }
    }
  }

  function addNewTier() {
    let currentIndex = deliveryTiers.length
    const updatedDeliveryTiers: IDeliveryTier[] = cloneDeep(deliveryTiers)

    if(currentIndex === 0) {
      updatedDeliveryTiers[0] = {}
      currentIndex = 0
    } else if(updatedDeliveryTiers[currentIndex - 1].pricePerKm !== undefined && updatedDeliveryTiers[currentIndex - 1].distanceMeters !== undefined) {
      //Add new tier
      updatedDeliveryTiers[currentIndex] = {}
    } else {
      //Cannot add new tier as last tier is incomplete
      console.error("Cannot add new tier as last tier is incomplete")
    }

    setDeliveryTiers(updatedDeliveryTiers)
  }

  function deleteTier(index: number) {
    const updatedDeliveryTiers: IDeliveryTier[] = cloneDeep(deliveryTiers)
    updatedDeliveryTiers.splice(index , 1)
    setDeliveryTiers(updatedDeliveryTiers)
  }

  function handleTierChange(value: number, type: "price" | "distance", index: number) {
    const updatedDeliveryTiers: IDeliveryTier[] = cloneDeep(deliveryTiers)
    if(type === "price" && value) {
      updatedDeliveryTiers[index].pricePerKm = value
    } else if(type === "distance" && value) {
      updatedDeliveryTiers[index].distanceMeters = value
    }
    setDeliveryTiers(updatedDeliveryTiers)
    setDeliveryFeeError(undefined)
  }

  function disableAddTier() {
    if(baseDeliveryDistance === undefined || baseDeliveryFee === undefined) {
      return true
    }

    if(deliveryTiers.length === 0) {
      return false
    }

    if(deliveryTiers[deliveryTiers.length - 1].pricePerKm === undefined || deliveryTiers[deliveryTiers.length - 1].distanceMeters === undefined) {
      return true
    }
  }

  return (
    <Form
      ref={formRef}
      className="partner-form"
      initialValues={{
        offerDelivery: false,
        deliveryFee: 0,
        minDeliveryOrder: 0,
      }}
      scrollToFirstError
      onValuesChange={() => props.independent && setValuesChanged(true)}
    >
      <div className="outer-form-container">
        <div className="form-container">
          <Form.Item
            name="offerDelivery"
            label="Do you offer delivery?"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            rules={[
              {
                required: true,
                message: "Please select an option",
              },
            ]}
          >
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} onChange={handleDeliverySwitch} checked={deliveryAvailable} />
          </Form.Item>
          {deliveryAvailable && (
            <>
              <Form.Item 
                className="delivery-fee"
                label={`What are your delivery fees?`}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter your delivery fees",
                  },
                ]}
              >
                <div className="monetary-input">
                  $
                  <Form.Item 
                    name="basePrice" 
                  >
                    <InputNumber type="number" onChange={(val) => {setDeliveryFeeError(undefined); setBaseDeliveryFee(val)}} parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")} precision={2} min={0} max={200}/>
                  </Form.Item>
                  up to
                  <Form.Item 
                    name="baseDistanceMeters" 
                  >
                    <InputNumber type="number" onChange={(val) => {setDeliveryFeeError(undefined); setBaseDeliveryDistance(val)}} parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")} precision={1} min={0}/>
                  </Form.Item>
                  km
                </div>
                {
                  deliveryTiers.map((tier, index) => (
                    <div className="monetary-input" key={index}>
                      $
                      <InputNumber value={tier.pricePerKm} onChange={(val) => handleTierChange(val, "price", index)} type="number" parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")} precision={2} min={0} max={20}/>
                      <b>/km</b> from {index === 0 ? baseDeliveryDistance : deliveryTiers[index - 1].distanceMeters} km to
                      <InputNumber value={tier.distanceMeters} onChange={(val) => handleTierChange(val, "distance", index)} type="number" parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")} precision={1} min={index === 0 ? baseDeliveryDistance : deliveryTiers[index - 1].distanceMeters}/>
                      km
                      <div className="delete-btn">
                        <Button danger icon={<DeleteOutlined />} onClick={() => deleteTier(index)}></Button>
                      </div>
                    </div>
                  ))
                }
                <div className="error-text" style={{display: deliveryFeeError ? 'inherit' : 'none'}}>{deliveryFeeError}</div>
                <Button disabled={disableAddTier()} className="add-tier-btn" icon={<PlusOutlined />} onClick={() => addNewTier()}>Add delivery tier</Button>
              </Form.Item>
              <Form.Item 
                label={`What is your minimum order value for delivery?`}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <div className="monetary-input">
                  $
                  <Form.Item
                    name="minDeliveryOrder" 
                  >
                    <InputNumber type="number" parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")} precision={2} min={0}/>
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item 
                label="Which days do you do deliveries?"
                labelCol={{ span: 24 }}
              >
                {partnerAdvance?.preparationEnabled &&
                  typeof partnerAdvance?.preparationDurationType !== "undefined" &&
                  typeof partnerAdvance?.preparationDuration !== "undefined" && (
                    <div className="field-description" style={{ paddingBottom: "10px" }}>
                      {Utils.getCutoffDescription(partnerAdvance, props.navigate)}
                    </div>
                  )}
                <Form.Item
                  name="deliveryDays"
                  rules={[
                    {
                      required: deliveryAvailable,
                      message: "Please select the delivery days",
                    },
                  ]}
                >
                  <Checkbox.Group
                    className="day-checkbox"
                    options={[
                      { label: "Mon", value: Day.Monday },
                      { label: "Tue", value: Day.Tuesday },
                      { label: "Wed", value: Day.Wednesday },
                      { label: "Thu", value: Day.Thursday },
                      { label: "Fri", value: Day.Friday },
                      { label: "Sat", value: Day.Saturday },
                      { label: "Sun", value: Day.Sunday },
                    ]}
                    onChange={handleDeliveryDays}
                  />
                </Form.Item>
                {[Day.Monday, Day.Tuesday, Day.Wednesday, Day.Thursday, Day.Friday, Day.Saturday, Day.Sunday].map((day, index) => {
                  return (
                    <div key={day}>
                      <Form.Item
                        className="day-timing"
                        label={Utils.getDayString(day)}
                        name={`deliveryTime-${day}`}
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 12 }}
                        rules={[
                          {
                            required: deliveryDays?.includes(day),
                            message: `Please select delivery times for ${Utils.getDayString(day)}`,
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if(!value) {
                                return Promise.resolve()
                              }
                              if(value.start === undefined) {
                                return Promise.reject(new Error("Open time cannot be empty"))
                              }
                              if(value.end === undefined) {
                                return Promise.reject(new Error("Close time cannot be empty"))
                              }
                              if(value.start >= value.end) {
                                return Promise.reject(new Error("Open time must be less than close time"))
                              }
                              return Promise.resolve()
                            },
                          })
                        ]}
                        hidden={!deliveryDays?.includes(day)}
                      >
                        <TimeRangePicker initialValue={{start: deliveryTimes[day]?.start, end: deliveryTimes[day]?.end}} onChange={(start: number, end: number) => {
                            handleDeliveryTimes(day, start, end)
                        }}/>
                      </Form.Item>
                      {
                        deliveryDays?.includes(day) &&
                        <div className="field-description">{dayCutOffTimeMessage(day)}</div>                    
                      }
                    </div>
                  )
                })}
              </Form.Item>
            </>
          )}
        </div>
      </div>
      <Form.Item className="form-buttons" wrapperCol={{ span: 24 }}>
        {
          !props.independent &&
          <>
            <Button size="large" className="left-btn" onClick={handlePrev}>
              Back
            </Button>
            <Button size="large" type="primary" className="right-btn" htmlType="submit" onClick={handleNext}>
              Next
            </Button>
          </>
        }
        <Button type={props.independent ? "primary" : "default"} size="large" className="right-btn" htmlType="submit" onClick={handleSave}>
          Save
        </Button>
      </Form.Item>
      {
        valueChanged &&
        <Prompt
          message="Any unsaved changes will be discarded?"
        />
      }
    </Form>
  )
}
