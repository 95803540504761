import { IOrderExt, OrderStatus, calculateProcessingFee } from "@tiffin/core";

export function calculateRevenue(orders: IOrderExt[]) {
  let total = 0;
  for(const order of orders) {
    if(order.status !== OrderStatus.Cancelled) {
      total += (order.total - order.refundTotal - (order.serviceFee ?? 0) - (order.paymentFee ?? 0) - calculateProcessingFee(order).processingFee)
    }
  }
  return total;
}