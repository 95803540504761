import styled from "styled-components";
import { useState } from "react"
import { IOrderExt, OrderStatus } from "@tiffin/core"
import { OrderCardList } from "../components/OrderCardList"
import { PageBreadcrumb } from "../components/PageBreadcrumb"

//Redux
import { useAppSelector } from "../hooks"

//3rd Party
import { useIonPopover, IonContent, IonIcon, IonItem, IonList, IonPage, useIonRouter, IonSelect, IonSelectOption, IonLabel } from "@ionic/react"
import { ellipsisVerticalOutline, receiptOutline } from "ionicons/icons"
import { Alert, Button } from "antd"
import { isDesktop, isMobile, isTablet } from "react-device-detect"

export const Orders = styled(BaseOrders)`
  ion-content {
    --background: var(--ion-color-light);

    .main-content {
      display: flex;
      flex-direction: column;

      .partner-order-card-list {
        padding-bottom: calc(var(--ion-safe-area-bottom,0) + var(--page-padding));
  
        @media (orientation: portrait) {
          padding-bottom: calc(var(--ion-safe-area-bottom, 0) + var(--footer-height) + 10px);
        }
      }
  
      .ant-alert {
        margin: var(--page-padding) var(--page-padding) 0;
      }
  
      .orders {
        padding: var(--page-padding);
        display: grid;
        grid-template-rows: auto 1fr;
        gap: 5px;
        height: 100%;
        background-color: var(--ion-color-light);
        overflow: auto;
  
        .filter {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0px 20px;
          background: var(--ion-background-color);
          font-size: 1em;
          color: var(--ion-color-dark);
          border-radius: 8px;
  
          .status-label {
            font-weight: 600;
            color:var(--ion-color-dark-tint);
          }
  
          .status-select {
            color: var(--ion-color-dark-tint);
          }
        }
      }
    }
  }
`

interface IOrderProps {
  className?: string
}

export function BaseOrders({className}: IOrderProps) {
  //Redux
  const orders = useAppSelector((state) => state.orders)

  //Internal states
  const [ statusFilter, setStatusFilter] = useState(-1)

  const router = useIonRouter()

  const PopoverList: React.FC<{
    onHide: () => void
  }> = () => (
    <IonList>
    <IonItem 
      onClick={() => {
        dismissPopover()
        //Navigate to historical orders
        router.push('./orders/historic')
      }}
      button
    >
        View historical
    </IonItem>
    </IonList>
  );
  
  const [presentPopover, dismissPopover] = useIonPopover(PopoverList, { onHide: () => dismissPopover() });

  function renderOrderGroups() {

    const unconfirmedOrders = orders.active.filter((order: IOrderExt) => order.status === OrderStatus.Unconfirmed).length

    return (
    <>
      {
        unconfirmedOrders > 0 &&
        <Alert type="warning" showIcon message={`You have ${unconfirmedOrders} ${unconfirmedOrders === 1 ? 'order' : 'orders'} that requires action`} />  
      }
      <div className="orders">
        <div className="filter">
          <IonLabel className="status-label">Status</IonLabel>
          <IonSelect interface={isMobile && !isTablet && !isDesktop ? "action-sheet" : "popover"} title="Select order status" className="status-select" value={statusFilter} onIonChange={e => setStatusFilter(e.detail.value)}>
            <IonSelectOption value={-1}>All</IonSelectOption>
            <IonSelectOption value={OrderStatus.Unconfirmed}>Requires Action</IonSelectOption>
            <IonSelectOption value={OrderStatus.Confirmed}>Preparing</IonSelectOption>
            <IonSelectOption value={OrderStatus.Ready}>Ready</IonSelectOption>
            <IonSelectOption value={OrderStatus.Completed}>Completed</IonSelectOption>
            <IonSelectOption value={OrderStatus.Cancelled}>Cancelled</IonSelectOption>
          </IonSelect>
        </div>
        <OrderCardList
          orders={statusFilter === -1 ? orders.active : orders.active.filter((order: IOrderExt) => order.status === statusFilter)}
        />
      </div>
    </>
    )
  }

  return (
    <IonPage className={className}>
      <IonContent>
        <div className="page-container">
          <div className="page-breadcrumb-header">
            <PageBreadcrumb
              items={[
                {
                  title: <><IonIcon icon={receiptOutline} /> Orders</>,
                }
              ]}
              extra={
                <Button 
                  size="small"
                  type="text"
                  onClick={(e) =>
                    presentPopover({
                      event: e.nativeEvent,
                    })
                  }
                >
                  <IonIcon icon={ellipsisVerticalOutline} />
                </Button>
              }
            />
          </div>
          <div className="main-content">
            {renderOrderGroups()}
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}