import { useEffect, useMemo, useState } from "react";
import { convertToTime, IOrderExt, OrderStatus } from "@tiffin/core";
import { Constants } from "@tiffin/app-common";

//3rd party
import { Bar, BarChart, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import moment, { Moment } from "moment";

import './OrdersChart.scss'

const MINUTE_MS = 60000;

interface IOrdersChart {
  date?: string,
  pickupTimings?: {
    start: number
    end: number
  },
  deliveryTimings?: {
    start: number
    end: number
  },
  orders: IOrderExt[]
}

export function OrdersChart({date, pickupTimings, deliveryTimings, orders}: IOrdersChart) {

  const [currentTime, setCurrentTime] = useState<Moment>(moment())
  const [currentRoundedTime, setCurrentRoundedTime] = useState<Moment>(() => {
    const now = moment();
    //Round to next 15 minute
    const minRemainder = 15 - (now.minute() % 15);
    if(minRemainder < 15) {
      now.add(minRemainder, 'minute')
    }
    return now
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      const now = moment();
      //Round to next 15 minute
      const minRemainder = 15 - (now.minute() % 15);
      if(minRemainder < 15) {
        now.add(minRemainder, 'minute')
      }
      setCurrentRoundedTime(now)
      setCurrentTime(moment())
    }, MINUTE_MS - moment().seconds() * 1000);

    return () => clearTimeout(timeout); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [currentTime])

  const data = useMemo(() => {
    let data = new Map<string, {
      date: string,
      unconfirmed: number,
      confirmed: number,
      ready: number,
      completed: number,
      cancelled: number
    }>()

    //Fill empty data
    let openTime;
    let closeTime;
    if(pickupTimings && deliveryTimings) {
      openTime = pickupTimings.start < deliveryTimings.start ? pickupTimings.start : deliveryTimings.start
      closeTime = pickupTimings.end > deliveryTimings.end ? pickupTimings.end : deliveryTimings.end
    } else if(pickupTimings) {
      openTime = pickupTimings.start
      closeTime = pickupTimings.end
    } else if(deliveryTimings) {
      openTime = deliveryTimings.start
      closeTime = deliveryTimings.end
    }

    if(openTime && closeTime) {
      let numberOfSegments = (closeTime - openTime) / 15 + 1;
      const open = convertToTime(openTime);

      const date = moment().set({
        hours: open.hour,
        minutes: open.minute
      })

      for(let i=0; i<numberOfSegments; i++) {
        data.set(date.format('h:mm a'), {
          date: date.format('h:mm a'),
          unconfirmed: 0,
          confirmed: 0,
          ready: 0,
          completed: 0,
          cancelled: 0
        })
        date.add(15, 'minute')
      }

      const timeGroupedOrders = new Map<string, IOrderExt[]>();

      for(const order of orders) {
        
        let dateKey: string | undefined;
        if(order.time === "now") {
          if(order.status === OrderStatus.Completed || order.status === OrderStatus.Cancelled) {
            //All completed 'now orders' should appear in the time group in which they were completed
            const timezone = moment.tz.guess();
            const orderTime = order.status === OrderStatus.Completed ? moment(order.completeTimestamp).tz(timezone) : moment(order.cancelledTimestamp).tz(timezone)
            orderTime.set({
              seconds: 0
            })

            //Round to next 15 minute
            const minRemainder = 15 - (orderTime.minute() % 15);
            if(minRemainder < 15) {
              orderTime.add(minRemainder, 'minute')
            }

            dateKey = moment(orderTime).format('h:mm a')
          } else {
            //Only pushed outstanding 'now' orders to 'now' group
            dateKey = moment(currentRoundedTime).format('h:mm a')
          }
        } else {
          const dateKey = moment(order.time).format('h:mm a')

          const group = timeGroupedOrders.get(dateKey);
          if(group) {
            group.push(order);
          } else {
            timeGroupedOrders.set(dateKey, [order]);
          }
        }

        if(dateKey) {
          //Add to time group
          const group = timeGroupedOrders.get(dateKey);
          if(group) {
            group.push(order);
          } else {
            timeGroupedOrders.set(dateKey, [order]);
          }
        }
      }

      if(orders) {
        for(let [key, value] of timeGroupedOrders) {
          if(data.has(key)) {
            data.set(key, {
              date: key,
              unconfirmed: value.filter((o) => o.status === OrderStatus.Unconfirmed).length,
              confirmed: value.filter((o) => o.status === OrderStatus.Confirmed).length,
              ready: value.filter((o) => o.status === OrderStatus.Ready).length,
              completed: value.filter((o) => o.status === OrderStatus.Completed).length,
              cancelled: value.filter((o) => o.status === OrderStatus.Cancelled).length
            })
          }
        }
      }
    }
    return data
  }, [orders, deliveryTimings, pickupTimings, currentRoundedTime]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      if(data.unconfirmed > 0 || data.confirmed > 0 || data.ready > 0 || data.completed > 0) {
        return (
          <div className="orders-tooltip">
            <span><b>{data.date}</b></span>
            {
              data.unconfirmed > 0 &&
              <span className="label">{`Requires action : ${data.unconfirmed}`}</span>
            }
            {
              data.confirmed > 0 &&
              <span className="label">{`Confirmed : ${data.confirmed}`}</span>
            }
            {
              data.ready > 0 &&
              <span className="label">{`Ready : ${data.ready}`}</span>
            }
            {
              data.completed > 0 &&
              <span className="label">{`Completed : ${data.completed}`}</span>
            }
            {
              data.cancelled > 0 &&
              <span className="label">{`Cancelled : ${data.cancelled}`}</span>
            }
          </div>
        );
      }
    }
  
    return null;
  };

  let isToday = moment(date).isSame(moment(), 'day') ? true : false;
  function renderNowLine() {
    const renderCustomLabel = ({ viewBox }: any) => {
      return (
        <g>
            <foreignObject x={viewBox.x - 40} y={0} height={25} width={80}>
            <div key="now-indicator" className="current-time-indicator"><div className="blob"></div><span>({currentTime.format('hh:mm a')})</span></div>
            </foreignObject>
        </g>
      )
    }
    return (
      <ReferenceLine x={currentRoundedTime.format('h:mm a')} stroke="var(--ion-color-success)" label={renderCustomLabel}  />
    )
  }

  return (
    <div className="orders-chart">
      {
        <div className="chart-container">
          <ResponsiveContainer debounce={300} width="100%" height="100%" >
            <BarChart barSize={20} data={[...data.values()]}
              margin={{
                top: 20,
                right: 20,
                left: 20,
                bottom: 20
              }}
            >
              <XAxis fontSize={'0.75em'} dataKey="date" axisLine={{ stroke: "var(--ion-color-light)" }} interval={"preserveStartEnd"} />
              <CartesianGrid vertical={false} horizontal={false} stroke="var(--ion-color-light)" />
              <Tooltip content={<CustomTooltip />} cursor={false} />
              <Bar dataKey="unconfirmed" stackId="orders" fill={Constants.UNCONFIRMED_COLOR} />
              <Bar dataKey="confirmed" stackId="orders" fill={Constants.CONFIRMED_COLOR} />
              <Bar dataKey="ready" stackId="orders" fill={Constants.READY_COLOR} />
              <Bar dataKey="completed" stackId="orders" fill={Constants.COMPLETE_COLOR} />
              <Bar dataKey="cancelled" stackId="orders" fill={Constants.CANCEL_COLOR} />
              { isToday && renderNowLine() }
            </BarChart>
          </ResponsiveContainer>
        </div>
      }
      {
        data.size === 0 &&
        <div className="no-data">
          No orders
        </div>
      }
    </div>
  )
}