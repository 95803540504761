import { ReactNode } from 'react'
import './Pill.scss'

export interface IPill {
  className?: string,
  text?: string,
  backgroundColor?: string,
  color?: string,
  borderColor?: string,
  children?: ReactNode
}

export function Pill({className, text, backgroundColor, color, borderColor, children}: IPill) {
  return (
    <div className={`tfn-pill ${className}`} style={{backgroundColor: backgroundColor, color: color, borderColor: borderColor}}>
      <span className="content">
        {text ?? children}
      </span>
    </div>
  )
}