import { useEffect, useState } from "react"
import { useAppSelector } from "../hooks"

//Tiffin
import { app, uiConfig } from "@tiffin/app-common"

//Components
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { IonContent, IonLoading, IonPage } from "@ionic/react"
import { Redirect } from "react-router-dom"

import "./LoginWeb.scss"

export function LoginWeb() {
  const authenticate = useAppSelector((state) => state.authenticate)

  const [loading, setLoading] = useState(authenticate.status === "unknown")

  useEffect(() => {
    if (authenticate.status === "authenticated") {
      setLoading(false)
    } else if (authenticate.status === "unauthenticated") {
      setLoading(false)
    }
  }, [authenticate.status])

  return (
    <>
    {
      authenticate.status === "authenticated" &&
      <Redirect to="/main" />
    }
    {
      authenticate.status === "incomplete" &&
      <Redirect to="/profile" />
    }
    {
      authenticate.status === "unacknowledged" &&
      <Redirect to="/unacknowledged" />
    }
    <IonPage>
      <IonContent className="ion-padding">
        <div className="login">
          <div className="tiffin-logo">
            <img alt="Tifyn Logo" src={`${process.env.PUBLIC_URL}/assets/images/tifyn-partner-icon.png`} />
          </div>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={app.auth()} />
        </div>
        <IonLoading isOpen={loading} spinner="crescent" onDidDismiss={() => setLoading(false)} />
      </IonContent>
    </IonPage>
    </>
  )
}
