import styled from "styled-components";

import { IonButton, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { ReactComponent as AcceptTermsSVG } from '../assets/accept-terms.svg';
import { useAppDispatch, useAppSelector } from "../hooks";

import { authenticateReducer, userReducer } from "@tiffin/app-common";
import { useEffect } from "react";
import { Redirect } from "react-router";
import { Button } from "antd";
import { Browser } from "@capacitor/browser";

export const Unacknowledged = styled(UnacknowledgedBase)`
  ion-content {
    --padding-top: var(--page-padding);
    --padding-end: var(--page-padding);
    --padding-start: var(--page-padding);
    --padding-bottom: var(--page-padding);
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    padding: 40px 10px;

    .inner-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex-grow: 1;
    }

    .title {
      text-align: center;
      font-size: var(--xl-font-size)
    }
    
    svg {
      width: 100px;
      height: fit-content;
    }

    .accept-btn {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }

    .internal-link {
      padding: 0;
    }
  }
`;

interface IUnacknowledged {
  className?: string
}

function UnacknowledgedBase({className}: IUnacknowledged) {

  const authenticate = useAppSelector((state) => state.authenticate)
  const userPolicyAckVersion = useAppSelector((state) => state.user.partnerPolicyAckVer)
  const tcPartnerVersion = useAppSelector((state) => state.globalPartnerConfig.tcPartnerVersion)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if(userPolicyAckVersion === tcPartnerVersion) {
      dispatch(authenticateReducer.setAuthenticated())
    }
  }, [userPolicyAckVersion, tcPartnerVersion, dispatch])

  function handleAccept() {
    if(tcPartnerVersion) {
      dispatch(userReducer.updatePolicyAck({
        type: "partner",
        version: tcPartnerVersion
      }))
    }
  }

  return (
    <>
      {
        (authenticate.status === "authenticated" || authenticate.status === "guest") &&
        <Redirect to="/main" />
      }
      <IonPage className={className}>
        <IonHeader>
          <IonToolbar>
            <div className="page-header">
              <span className="page-header-text">Terms and Conditions</span>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="content">
            <AcceptTermsSVG />
            <div className="inner-content">
              <div className="title">Update to Our Terms</div>
              <div>
                We've updated our Terms and Conditions. Please accept to continue to use Tifyn.
              </div>
              <div>
                By tapping Accept, you agree to the new <Button className="internal-link" type="link" onClick={() => Browser.open({ url: "https://tifyn.com.au/partner-terms-and-conditions.html" })}>Terms</Button> and <Button className="internal-link" type="link" onClick={() => Browser.open({ url: "https://tifyn.com.au/privacy-policy.html" })}>Privacy Policy</Button> of Tifyn.
              </div>
            </div>
            <div className="accept-btn">
              <IonButton onClick={handleAccept}>Accept</IonButton>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  )
}