import { useEffect } from "react";
import { partnerReducer } from "@tiffin/app-common"
import { CustomerReview } from "./CustomerReview";

//Redux
import { useAppDispatch, useAppSelector } from "../hooks"
import { Card, List } from "antd";
import { LoadingSpinner } from "@tiffin/components";
import { ICustomerReview } from "@tiffin/core";

//3rd party
import RedoOutlined from "@ant-design/icons/RedoOutlined"
import { IonButton, useIonRouter } from "@ionic/react";

import './CustomerReviewsCard.scss'

const NUMBER_OF_REVIEWS = 5;

export function CustomerReviewsCard() {

  const status = useAppSelector((state) => state.partner.status)
  const reviewStatus = useAppSelector((state) => state.partner.reviewsStatus)
  const customerReviews = useAppSelector((state) => state.partner.customerReviews)

  const router = useIonRouter()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if(status === "idle" && reviewStatus === "unknown") {
      //dispatch and get latest reviews
      dispatch(partnerReducer.getReviews())
    }
  })

  function refreshData() {
    dispatch(partnerReducer.getReviews())
  }

  function renderCardHeader() {
    return (
      <>
        <span>
          Customer Reviews
        </span>
        <div className="right-title-buttons">
          <IonButton size="small" color="light" onClick={() => router.push("/reviews")} >View all</IonButton>
          <IonButton color="light" className="chart-refresh-btn" size="small" onClick={refreshData}>          
            <RedoOutlined />
          </IonButton>
        </div>
      </>
    )
  }

  return (
    <Card className="dashboard-card customer-reviews-card" title={renderCardHeader()}>
      <List loading={(reviewStatus !== "idle") && {
        indicator: <LoadingSpinner/>
      }}>
        {customerReviews && customerReviews.slice(0, NUMBER_OF_REVIEWS).map((review: ICustomerReview) => (
          <List.Item key={review.orderId}>
            <CustomerReview key={review.orderId} {...review} />
          </List.Item>
        ))}
      </List>
    </Card>
  )
}