import {ICustomerReview} from "@tiffin/core"
import { Rate } from "antd"

import './CustomerReview.scss'

export function CustomerReview(props: ICustomerReview) {
  return (
    <div className="customer-review">
      <div className="heading">
        <span className="reviewer-name">{props.reviewerName}</span>
        <Rate disabled allowHalf value={props.rating}/>
      </div>
      <div className="details">
        {props.details}
      </div>
    </div>
  )
}