import { Timeline } from "antd";
import { IOrderExt, OrderType } from "@tiffin/core";
import moment from "moment";
import CheckCircleFilled from "@ant-design/icons/CheckCircleFilled"

import './OrderTimeline.scss'

interface IOrderTimelineProps {
  order: IOrderExt
}

export function OrderTimeline(props: IOrderTimelineProps) {

  function cancelledTimelineItem() {
    return (
      <Timeline.Item key={5} color={'red'} >
        Order was cancelled • {moment(props.order.orderTimestamp).format("LLL")}
      </Timeline.Item>
    )
  }

  function renderTimelineItems() {
    let items: JSX.Element[] = []
    
    let orderItem = <Timeline.Item key={0} color="green">Order placed • {moment(props.order.orderTimestamp).format("LLL")}</Timeline.Item>
    items.push(orderItem)

    if(!props.order.confirmedTimestamp && props.order.cancelledTimestamp) {
      items.push(cancelledTimelineItem())
      return items
    }

    let confirmedItem = 
    <Timeline.Item key={1} color={!props.order.confirmedTimestamp ? 'blue' : 'green'} >
      {props.order.confirmedTimestamp ? `Order confirmed • ${moment(props.order.confirmedTimestamp).format("LLL")}` : 'Order yet to be confirmed'}
    </Timeline.Item>
    items.push(confirmedItem)

    if(!props.order.readyTimestamp && props.order.cancelledTimestamp) {
      items.push(cancelledTimelineItem())
      return items
    }

    if(props.order.orderType === OrderType.Pickup || props.order.orderType === OrderType.Delivery) {
      let preparingItem = 
      <Timeline.Item key={2} color={props.order.readyTimestamp ? 'green' : props.order.confirmedTimestamp ? 'blue' : 'gray'}>
        {props.order.readyTimestamp ? `Order ready • ${moment(props.order.readyTimestamp).format("LLL")}`  : 'Order is being prepared'}
      </Timeline.Item>
      items.push(preparingItem)
    }

    if(!props.order.completeTimestamp && props.order.cancelledTimestamp) {
      items.push(cancelledTimelineItem())
      return items
    }

    let completeItem = 
    <Timeline.Item key={4} color={props.order.completeTimestamp ? 'green' : props.order.readyTimestamp ? 'blue' : 'gray'} dot={props.order.completeTimestamp && <CheckCircleFilled style={{ fontSize: '16px' }}/>}>
      {props.order.completeTimestamp ? `Order completed • ${moment(props.order.completeTimestamp).format("LLL")}` : props.order.orderType === OrderType.Pickup ? 'Ready for pickup' : 'Out for delivery'}
    </Timeline.Item>
    items.push(completeItem)
    
    return items
  }

  return (
    <Timeline>
      {renderTimelineItems()}
    </Timeline>
  )
}