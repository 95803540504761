import { useEffect, useState } from "react";
import styled from "styled-components";

//3rd party
import { SubscriptionPlan } from "@tiffin/core"
import { PlanCard } from "../components/PlanCard"
import { cloudFunction, eventHelper, partnerHelper, partnerReducer } from "@tiffin/app-common";
import { SpringModal, toast } from "@tiffin/components";
import { useIonToast, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { App, AppState } from '@capacitor/app';
import { Alert } from "antd";

export const Plans = styled(BasePlans)`
  &.plan-form {
    display: flex;
    gap: 10px;

    @media (max-width:768px)  {
      flex-direction: column;
    }
  }
`

interface IPlanForm {
  className?: string,
  onboardingForm?: boolean
}

export function BasePlans({className, onboardingForm}: IPlanForm) {

  const subscriptionPlan = useAppSelector((state) => state.partner.subscriptionPlan)
  const isHomeCook = useAppSelector((state) => !state.partner.isRestaurant)

  //Event ID only exists if the user has registered for an event
  const eventId = useAppSelector((state) => state.partner.details?.eventId)
  const [ isActive, setIsActive ] = useState(true)
  const [ switchPlan, setSwitchPlan ] = useState<SubscriptionPlan | null>(null);
  const [ loadingPlan, setLoadingPlan ] = useState<SubscriptionPlan>()
  const [ event, setEvent ] = useState<eventHelper.IEvent>()

  const [present] = useIonToast();
  const dispatch = useAppDispatch()

  useIonViewDidEnter(() => {
    App.addListener('appStateChange', (state: AppState) => {
      setIsActive(state.isActive)
    });
  }, [])

  useIonViewDidLeave(() => {
    App.removeAllListeners()
  })

  useEffect(() => {
    if(eventId) {
      eventHelper.getEventDetails(eventId).then((event) => {
        setEvent(event)
      })
    } else {
      setEvent(undefined)
    }
  }, [eventId])

  useEffect(() => {
    if(isActive) {
      partnerHelper.getPartnerInfo()
      .then((val: any) => {
        console.log(val)
        dispatch(partnerReducer.setSubscriptionPlan({
          plan: val.subscriptionPlan,
        }))
      })
    }
  }, [isActive, dispatch])
  
  const manageSubscription = (plan: SubscriptionPlan, action: "create" | "update" | "upgrade") => {
    setLoadingPlan(plan)
    cloudFunction("manageBasicSubscription", {action, onboarding: onboardingForm ?? false, plan: plan, eventId: eventId }).then((result: any) => {
      setLoadingPlan(undefined)
      if(result.data.status === "success") {
        dispatch(partnerReducer.setSubscriptionPlan({
          plan: plan,
        }))

        //Hande Free plans
        if(plan === SubscriptionPlan.Event || plan === SubscriptionPlan.BasicHome) {
          toast(present, "Successfully registered", "success")
          return
        }

        //Handle Paid Plans
        if(action === "upgrade") {
          toast(present, "Successfully switched plan", "success")
        } else {
          if(result.data.session.url) {
            window.location.href = result.data.session.url
          }
        }
      } else {
        toast(present, "An unexpected error occurred", "danger")
      }
    })
  }
  
  const switchSubscriptionPlan = () => {
    if(switchPlan !== null) {
      manageSubscription(switchPlan, "upgrade")
      setSwitchPlan(null)
    }
  }

  const handlePlanChange = (newPlan: SubscriptionPlan) => {
    if(subscriptionPlan === undefined) {
      manageSubscription(newPlan, "create")
    } else if(subscriptionPlan !== newPlan) {
      setSwitchPlan(newPlan);
    } else {
      if(subscriptionPlan === SubscriptionPlan.BasicHome || subscriptionPlan === SubscriptionPlan.Event) {
        return
      }
      manageSubscription(newPlan, "update")
    }
  }

  const planHeading = () => {
    if(switchPlan === SubscriptionPlan.StarterMonthly) {
      return 'Starter monthly'
    } else if(switchPlan === SubscriptionPlan.StarterAnnual) {
      return 'Starter annual'
    } else if(switchPlan === SubscriptionPlan.OnlineOrderingMonthly) {
      return 'Online Ordering Monthly'
    } else if(switchPlan === SubscriptionPlan.OnlineOrderingAnnual) {
      return 'Online rdering annual'
    }
  }

  return (
    <div className={`${className} plan-form`}>
      {
        isHomeCook &&
        <PlanCard 
          title="Basic Home-Cook"
          subTitle="Enhance your online presence on the Tifyn app"
          options={[
            {
              price: 0,
              frequency: "annually",
              plan: SubscriptionPlan.BasicHome
            }
          ]}
          features={[
            'Only on Tifyn App',
            'Digital menu',
            'Menu categories and groups',
            'Whatsapp Integration'
          ]}
          unavailableFeatures={[
            'Online Ordering'
          ]}
          onChange={handlePlanChange}
          loadingPlan={loadingPlan}
        />
      }
      {
        event &&
        <PlanCard 
          title={event.name}
          subTitle={`Enhance your online presence with a customised visually enticing website and interactive menus for ${event.name}`}
          logoImageUrl={event.logoImageUrl}
          options={[
            {
              price: 0,
              frequency: "annually",
              plan: SubscriptionPlan.Event
            }
          ]}
          features={[
            'Your very own store URL',
            'Customised webpage',
            'Digital menu',
            'Menu categories and groups'
          ]}
          unavailableFeatures={[
            'Online Ordering'
          ]}
          onChange={handlePlanChange}
          loadingPlan={loadingPlan}
        />
      }
      <PlanCard 
        title="Starter"
        subTitle="Enhance your online presence with a customised visually enticing website and interactive menus"
        options={[
          {
            price: 190,
            frequency: "monthly",
            plan: SubscriptionPlan.StarterMonthly
          },
          {
            price: 1900,
            frequency: "annually",
            plan: SubscriptionPlan.StarterAnnual
          }
        ]}
        features={[
          'Your very own store URL',
          'Customised website',
          'Digital menu',
          'Menu categories and groups'
        ]}
        unavailableFeatures={[
          'Online Ordering'
        ]}
        onChange={handlePlanChange}
        loadingPlan={loadingPlan}
      />
      <PlanCard 
        title="Online Ordering"
        subTitle="Increase your customer reach with flexible online ordering, an enticing website and easy to use owners portal to easily manage your store."
        options={[
          {
            price: 1900,
            frequency: "monthly",
            plan: SubscriptionPlan.OnlineOrderingMonthly
          },
          {
            price: 19900,
            frequency: "annually",
            plan: SubscriptionPlan.OnlineOrderingAnnual
          }
        ]}
        features={[
          'Your very own store URL',
          'Customised website',
          'No joining fee or setup costs',
          'Digital menu',
          'Menu categories and groups',
          'Item modifiers',
          'Pickup and delivery orders',
          'Dine in orders',
          'Scheduled pre-orders',
          'New order email alerts',
          'Apple Pay and Google Pay integrations',
          'Same day payouts',
          '2% per order processing fee'
        ]}
        onChange={handlePlanChange}
        loadingPlan={loadingPlan}
      />
      <SpringModal
        isOpen={switchPlan !== null}
        onCancel={() => {
          setSwitchPlan(null)
        }}
        okText="Confirm"
        onOk={switchSubscriptionPlan}
        title="Switch plans"
      >
        <p>Are you sure you want to switch to the <b>{planHeading()}</b> plan?</p>
        <Alert style={{fontSize: 'smaller'}} showIcon type="info" message="If you are switching to a lower plan, you will receive a pro-rata credit which will be used for future repayments" />
      </SpringModal>
    </div>
  )
}
