var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { addDoc, collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, runTransaction, serverTimestamp, startAfter, updateDoc, where } from "firebase/firestore";
import { auth, db } from "../firebase";
import { getTiffin } from "./browse";
var MESSAGE_LIMIT = 15;
export function getCustomerConversation(tiffinId, user) {
    var _this = this;
    return new Promise(function (resolve, reject) {
        var conversationsRef = collection(db, "conversations");
        var conversationsQuery = query(conversationsRef, where("customerId", "==", auth.currentUser.uid), where("tiffinId", "==", tiffinId), limit(1));
        getDocs(conversationsQuery).then(function (querySnapshot) { return __awaiter(_this, void 0, void 0, function () {
            var tiffin, newConvo, conversation;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(querySnapshot.size === 1)) return [3 /*break*/, 1];
                        //Conversations exists
                        resolve(__assign({ id: querySnapshot.docs[0].id }, querySnapshot.docs[0].data()));
                        return [3 /*break*/, 4];
                    case 1:
                        if (!(querySnapshot.size === 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, getTiffin(tiffinId)
                            //Create conversation
                        ];
                    case 2:
                        tiffin = _b.sent();
                        newConvo = {
                            tiffinId: tiffin.id,
                            tiffinName: tiffin.name,
                            tiffinPhotoURL: (_a = tiffin.imageUrl) !== null && _a !== void 0 ? _a : null,
                            tiffinUnreadMessages: 0,
                            customerId: user.uid,
                            customerName: "".concat(user.firstName, " ").concat(user.lastName),
                            customerUnreadMessages: 0
                        };
                        return [4 /*yield*/, addDoc(conversationsRef, newConvo)];
                    case 3:
                        conversation = _b.sent();
                        resolve(__assign({ id: conversation.id }, newConvo));
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    });
}
export function subscribeToSingleConversationInfo(tiffinId, user, callback) {
    var conversationsRef = collection(db, "conversations");
    var conversationsQuery = query(conversationsRef, where("customerId", "==", auth.currentUser.uid), where("tiffinId", "==", tiffinId), limit(1));
    var unsubscribe = onSnapshot(conversationsQuery, function (querySnapshot) {
        if (querySnapshot.size > 0) {
            callback(querySnapshot.docs[0].data());
        }
    });
    return unsubscribe;
}
export function subscribeToConversationMetrics(callback) {
    var unsubscribe = onSnapshot(doc(db, "conversationsMetrics", auth.currentUser.uid), function (doc) {
        callback(doc.data());
    });
    return unsubscribe;
}
export function getNewCustomerConversations(callback, date) {
    var conversationsQuery;
    if (date) {
        conversationsQuery = query(collection(db, "conversations"), where("customerId", "==", auth.currentUser.uid), where("lastMessage", ">", date), orderBy("lastMessage", "desc"));
    }
    else {
        conversationsQuery = query(collection(db, "conversations"), where("customerId", "==", auth.currentUser.uid), orderBy("lastMessage", "desc"));
    }
    var unsubscribe = onSnapshot(conversationsQuery, function (querySnapshot) {
        callback(querySnapshot);
    });
    return unsubscribe;
}
export function getCustomersConversations() {
    return new Promise(function (resolve, reject) {
        var conversationsRef = collection(db, "conversations");
        var converSationsQuery = query(conversationsRef, where("customerId", "==", auth.currentUser.uid), orderBy("lastMessage", "desc"), limit(10));
        getDocs(converSationsQuery).then(function (querySnapshot) {
            var records = [];
            querySnapshot.forEach(function (doc) {
                records.push(__assign({ id: doc.id }, doc.data()));
            });
            resolve(records);
        });
    });
}
export function resetCustomerUnreadMsgCount(conversationId) {
    return __awaiter(this, void 0, void 0, function () {
        var conversationsRef;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    conversationsRef = doc(collection(db, "conversations"), conversationId);
                    return [4 /*yield*/, updateDoc(conversationsRef, {
                            customerUnreadMessages: 0
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function getPartnerConversation(order, tiffinName, tiffinPhotoURL) {
    var _this = this;
    return new Promise(function (resolve, reject) {
        var conversationsRef = collection(db, "conversations");
        var conversationsQuery = query(conversationsRef, where("tiffinId", "==", auth.currentUser.uid), where("customerId", "==", order.customerId), limit(1));
        getDocs(conversationsQuery).then(function (querySnapshot) { return __awaiter(_this, void 0, void 0, function () {
            var newConvo, conversation;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(querySnapshot.size === 1)) return [3 /*break*/, 1];
                        //Conversations exists
                        resolve(__assign({ id: querySnapshot.docs[0].id }, querySnapshot.docs[0].data()));
                        return [3 /*break*/, 3];
                    case 1:
                        if (!(querySnapshot.size === 0)) return [3 /*break*/, 3];
                        newConvo = {
                            tiffinId: auth.currentUser.uid,
                            tiffinName: tiffinName,
                            tiffinPhotoURL: tiffinPhotoURL !== null && tiffinPhotoURL !== void 0 ? tiffinPhotoURL : null,
                            tiffinUnreadMessages: 0,
                            customerId: order.customerId,
                            customerName: "".concat(order.customerFirstName, " ").concat(order.customerLastName),
                            customerUnreadMessages: 0
                        };
                        return [4 /*yield*/, addDoc(conversationsRef, newConvo)];
                    case 2:
                        conversation = _a.sent();
                        resolve(__assign({ id: conversation.id }, newConvo));
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    });
}
export function getNewPartnersConversations(callback, date) {
    var conversationsQuery;
    if (date) {
        conversationsQuery = query(collection(db, "conversations"), where("tiffinId", "==", auth.currentUser.uid), where("lastMessage", ">", date), orderBy("lastMessage", "desc"));
    }
    else {
        conversationsQuery = query(collection(db, "conversations"), where("tiffinId", "==", auth.currentUser.uid), orderBy("lastMessage", "desc"));
    }
    var unsubscribe = onSnapshot(conversationsQuery, function (querySnapshot) {
        callback(querySnapshot);
    });
    return unsubscribe;
}
export function getPartnersConversations() {
    return new Promise(function (resolve, reject) {
        var conversationsRef = collection(db, "conversations");
        var converSationsQuery = query(conversationsRef, where("tiffinId", "==", auth.currentUser.uid), orderBy("lastMessage", "desc"), limit(10));
        getDocs(converSationsQuery).then(function (querySnapshot) {
            var records = [];
            querySnapshot.forEach(function (doc) {
                records.push(__assign({ id: doc.id }, doc.data()));
            });
            resolve(records);
        });
    });
}
export function sendMessage(conversationId, message) {
    return __awaiter(this, void 0, void 0, function () {
        var conversationRef, messagesRef;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    conversationRef = doc(collection(db, "conversations"), conversationId);
                    messagesRef = collection(conversationRef, "messages");
                    return [4 /*yield*/, addDoc(messagesRef, __assign(__assign({}, message), { createdAt: serverTimestamp() }))
                        //Update the conversation last message info and unread message counts
                    ];
                case 1:
                    _a.sent();
                    //Update the conversation last message info and unread message counts
                    return [4 /*yield*/, runTransaction(db, function (transaction) { return __awaiter(_this, void 0, void 0, function () {
                            var conversationDoc, conversation;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, transaction.get(conversationRef)];
                                    case 1:
                                        conversationDoc = _a.sent();
                                        if (!conversationDoc.exists()) {
                                            throw "Document does not exist!";
                                        }
                                        conversation = __assign({ id: conversationDoc.id }, conversationDoc.data());
                                        if (!(conversation.tiffinId === auth.currentUser.uid)) return [3 /*break*/, 3];
                                        return [4 /*yield*/, transaction.update(conversationRef, {
                                                lastMessage: serverTimestamp(),
                                                lastMessageText: message.text,
                                                customerUnreadMessages: !isNaN(conversation.customerUnreadMessages) ? conversation.customerUnreadMessages + 1 : 1
                                            })];
                                    case 2:
                                        _a.sent();
                                        return [3 /*break*/, 5];
                                    case 3:
                                        if (!(conversation.customerId === auth.currentUser.uid)) return [3 /*break*/, 5];
                                        return [4 /*yield*/, transaction.update(conversationRef, {
                                                lastMessage: serverTimestamp(),
                                                lastMessageText: message.text,
                                                tiffinUnreadMessages: !isNaN(conversation.tiffinUnreadMessages) ? conversation.tiffinUnreadMessages + 1 : 1
                                            })];
                                    case 4:
                                        _a.sent();
                                        _a.label = 5;
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); })];
                case 2:
                    //Update the conversation last message info and unread message counts
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function subscribeToMessages(conversationId, callback) {
    var conversationsRef = doc(collection(db, "conversations"), conversationId);
    var messagesQuery = query(collection(conversationsRef, "messages"), orderBy("createdAt", "desc"), limit(MESSAGE_LIMIT));
    var unsubscribe = onSnapshot(messagesQuery, function (querySnapshot) {
        callback(querySnapshot);
    });
    return unsubscribe;
}
export function getPrevMessages(conversationId, docSnap) {
    return new Promise(function (resolve, reject) {
        var conversationsRef = doc(collection(db, "conversations"), conversationId);
        getDoc(conversationsRef);
        var messagesQuery = query(collection(conversationsRef, "messages"), orderBy("createdAt", "desc"), limit(MESSAGE_LIMIT), startAfter(docSnap));
        getDocs(messagesQuery).then(function (querySnapshot) {
            resolve(querySnapshot);
        });
    });
}
export function resetPartnerUnreadMsgCount(conversationId) {
    return __awaiter(this, void 0, void 0, function () {
        var conversationsRef;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    conversationsRef = doc(collection(db, "conversations"), conversationId);
                    return [4 /*yield*/, updateDoc(conversationsRef, {
                            tiffinUnreadMessages: 0
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
