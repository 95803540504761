import { useMemo } from "react"
import styled from "styled-components";
import { cartMultiReducer, getOrderDays, StoreOpenStatus, Utils } from "@tiffin/app-common"
import { IPartner, OrderType } from "@tiffin/core";
import { PulseBlob } from "./PulseBlob";

import moment from "moment";

export const AcceptingOrdersMessage = styled(BaseAcceptingOrdersMessage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  background: rgba(0,0,0,0.7);
  border-radius: 7px;

  z-index: 1;
  padding: 10px 20px;
  margin: auto 0px;

  .message-container {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 5px;
  }

  .accepting-orders {
    color: rgb(var(--color-success-rgb));
    font-weight: 600;
  }

  .paused-orders {
    color: rgb(var(--color-warn-rgb));
    font-weight: 600;
  }

  .not-accepting-orders {
    color: rgb(var(--color-warn-rgb));
    font-weight: 600;
  }
`
interface IAcceptingOrders {
  className?: string,
  partner: IPartner,
  cart: cartMultiReducer.IStoreCart
}

function BaseAcceptingOrdersMessage({className, cart, partner}: IAcceptingOrders) {

  const storeState = useMemo(() => {
    if(cart.orderType !== undefined && partner) {
      const orderDays = getOrderDays(cart.orderType, partner?.pickupDays, partner?.deliveryDays)
      return Utils.getStoreStatus(
        cart.orderType,
        orderDays,
        partner.preparationEnabled,
        partner.preparationDurationType,
        partner.preparationDuration,
        partner.adjustedDates,
        partner.pauseDates,
        partner.preparationInMins
      )
    }
  }, [cart.orderType, partner])

  const renderMessage = () => {
    if(cart.orderType === OrderType.Pickup || cart.orderType === OrderType.Delivery) {
      if(storeState.status === StoreOpenStatus.PausedToday) {
        return (
          <>
            <PulseBlob colorrgb="var(--color-warn-rgb)" />
            <div className="paused-orders">Currently not accepting orders for today, please check back soon</div>
          </>
        )
      } else if(storeState.status === StoreOpenStatus.NotAcceptingOrders) {
        return (
          <>
            <PulseBlob colorrgb="var(--color-warn-rgb)" />
            <span className="not-accepting-orders">No longer accepting orders for today</span>
          </>
        )
      } else if(storeState.status === StoreOpenStatus.Open) {
        return (
          <>
            <PulseBlob colorrgb="var(--color-success-rgb)" />
            <span className="accepting-orders">Accepting orders for {Utils.getRelativeDayString(storeState.cutOffTime)} until {storeState.cutOffTime?.format("h:mm A")} {storeState.cutOffTime.isAfter(moment(), 'day') || (storeState.cutOffTime && storeState.cutOffTime.isAfter(moment(), 'day')) ? Utils.getRelativeDayString(storeState.cutOffTime) : ''}</span>
          </>
        )
      }
    } else if(cart.orderType === OrderType.DineIn) {
      if(storeState.status === StoreOpenStatus.NotAcceptingOrders) {
        return (
          <>
            <PulseBlob colorrgb="var(--color-warn-rgb)" />
            <span className="not-accepting-orders">No longer accepting orders</span>
          </>
        )
      } else if(storeState.status === StoreOpenStatus.Closed) {
        return (
          <>
            <PulseBlob colorrgb="var(--color-warn-rgb)" />
            <span className="not-accepting-orders">The store is currently closed</span>
          </>
        )
      } else if(storeState.status === StoreOpenStatus.Open) {
        return (
          <>
            <PulseBlob colorrgb="var(--color-success-rgb)" />
            <span className="accepting-orders">Accepting orders until {storeState.cutOffTime?.format("h:mm A")}</span>
          </>
        )
      }
    }
  }

  return (
    <div className={className}>
      <div className="message-container">
        {renderMessage()}
      </div>
    </div>
  )
}