import { v4 as uuidv4 } from "uuid"
import { CartItems } from "@tiffin/app-common"
import { OrderItem } from "../OrderItem"

import "./CheckoutDateGroup.scss"

export interface ICheckoutDateGroupProps {
  date: string
  items: CartItems[]
}

export function CheckoutDateGroup(props: ICheckoutDateGroupProps) {

  function generateUniqueKey(item: CartItems) {
    let key = item.details.id;
    for(const modifier of item.modifiers) {
      for(const option of modifier.selectedOptions) {
        key += option;
      }
    }
    return key
  }

  function renderCartItems() {
    let cartGroup: JSX.Element[] = []
    for (let item of props.items) {
      cartGroup.push(
        <OrderItem
          key={generateUniqueKey(item)}
          count={item.count}
          name={item.details.name}
          description={item.details.description}
          price={item.details.price}
          modifiers={item.modifiers}
        />
      )
    }
    return cartGroup
  }

  return (
    <div className="checkout-group">
      <div>{renderCartItems()}</div>
    </div>
  )
}
