import { ReactNode, useEffect, useState } from "react"
import styled from "styled-components";
import { getOrderDays } from "@tiffin/app-common"
import { AvailabilityType, IMenuItem, IPartner, OrderType } from "@tiffin/core";
import { MenuLegend } from "./MenuLegend";
import { MenuGroup } from "./MenuGroup";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const MenuView = styled(BaseMenuView)`
  .tabs {
    position: sticky;
    top: ${(props) => props.offsetTabsAnchor ? `${props.offsetTabsAnchor}px` : '0px'};
    z-index: 1;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.07); 
    overflow: auto;

    background: #fff;
    display: flex;
    width: 100%;
    padding-left: 20px;

    .tab {
      height: 64px;
      padding: 24px 20px;
      font-size: medium;
      color: var(--color-dark);
      font-weight: 600;
      cursor: pointer;
      min-width: fit-content; 

      &.active {
        color: var(--app-base-color);
        border-bottom: 3px solid;
      }
    }
  }

  .menu {
    padding: 20px;

    @media (max-width:768px) {
      padding: 10px;
    }

    .menu-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width:768px) {
        display: grid;
        grid-template-columns: 100%;
        gap: 10px;

        .search-menu {
          grid-row: 1;
          margin-top: 10px;
        }
      }

      .menu-legend {
        padding-bottom: 20px;
      }
  
      .search-menu {
        max-width: 200px;
        border-radius: 8px;

        @media (max-width:768px) {
          max-width: 100%;
        }
        
        &.ant-input {
          padding: 4px 11px;
          background: #fff;
          border: 1px solid var(--color-light);
        }

        .ant-input {
          background: #fff;
        }
      }
    }

    .search-heading {
      padding: 10px 20px 0;
      font-size: x-large;
      font-weight: 600;
      color: var(--color-dark);
    }

    .no-items {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-medium);
      font-size: medium;
    }
  }
`
interface IMenuCategoryTabs {
  className?: string,
  partner: IPartner,
  categories: Map<string, { order: number; items: IMenuItem[] }>,
  onItemClickCallback: (id: string, key: string) => void,
  orderType: OrderType,
  pageRef?: HTMLDivElement | HTMLElement | null,
  offsetTabsAnchor?: number,
  displayAvailability?: boolean
}

export function BaseMenuView({className, categories, onItemClickCallback, orderType, pageRef, partner, displayAvailability}: IMenuCategoryTabs) {

  const [filteredCategories, setFilteredCategories] = useState<Map<string, { order: number; items: IMenuItem[] }>>(categories)
  const [searchValue, setSearchValue] = useState<string>()
  const [inViewCategories, setInViewCategories] = useState<{category: string, order: number}[]>([])
  const [overrideCategory, setOverrideCategory] = useState<string>()

  useEffect(() => {
    setFilteredCategories(categories)
  }, [categories])

  useEffect(() => {
    for(const [key,value] of filteredCategories) {
      if(value.order === 0) {
        setInViewCategories([{category: key, order: value.order}])
      }
    }
  }, [filteredCategories])

  function renderGroups() {
    var items = []
    for (let [key, value] of filteredCategories) {
      items[value.order] = renderGroup(key, value)
    }

    if(items.every((val) => val === undefined)) {
      items.push(
        <div className="no-items">No matching items</div>
      )
    }
    return items
  }

  const inViewCallback = (category: string, order: number, inView: boolean) => {
    if(inView && !inViewCategories.find((val) => val.category === category)) {
      setInViewCategories([...inViewCategories, {category: category, order: order}])
    } else if(!inView && inViewCategories.length > 1 && inViewCategories.find((val) => val.category === category)) {
      //Reset override as it is now out of view
      if(category === overrideCategory) {
        setOverrideCategory(undefined)
      }
      setInViewCategories(inViewCategories.filter((val) => val.category !== category))
    }
  }

  function currentActiveCategory() {
    if(inViewCategories.length === 0) return;

    if(overrideCategory) return overrideCategory

    //Category not overriden then find top most category
    let active = inViewCategories[0]
    for (let [key, value] of filteredCategories) {      
      if(inViewCategories.find((val) => val.category === key)) {
        if(value.order < active.order) {
          active = {
            category: key,
            order: value.order
          }
        }
      }
    }
    return active.category
  }

  function filterMenu(searchText: string) {
    if(!searchText) {
      setFilteredCategories(categories)
      setSearchValue(undefined)
    } else {
      setSearchValue(searchText)
      let updatedCategories = new Map<string, { order: number; items: IMenuItem[] }>()
      for (let [key, value] of categories) {
        let items = value.items.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()) || item.description.toLowerCase().includes(searchText.toLowerCase()))
        updatedCategories.set(key, { order: value.order, items: items })
      }
      setFilteredCategories(updatedCategories)
    }
  }

  function renderGroup(key: string, value: { order: number; items: IMenuItem[] }) {
    let orderDays = Object.keys(getOrderDays(orderType, partner.pickupDays, partner.deliveryDays))

    let items = value.items.filter((value: IMenuItem) => {
      if(value.availabilityType === AvailabilityType.Everyday) {
        return true;
      } else {
        return value.availability?.some((day) => orderDays.includes(day.toString()))
      }
    })

    if (items && items.length > 0) {
      return (
        <MenuGroup key={value.order} category={key} order={value.order} items={items} partnerStatus={partner.status} orderDays={orderDays} clickCallback={onItemClickCallback} inViewCallback={inViewCallback} displayAvailability={displayAvailability} />
      )
    }
  }

  function scrollIntoView(key: string) {
    if(!pageRef) return;

    setOverrideCategory(key)
    const elem = document.getElementById(key);
    const y = pageRef.scrollTop + elem.getBoundingClientRect().top + window.pageYOffset - 64;
    pageRef.scrollTo({top: y, behavior: 'smooth'});
  }

  function renderTabs() {
    let tabs: ReactNode[] = []
    for (let [key, value] of filteredCategories) {
      if(key === "archived" || value.items.length === 0) continue;
      tabs[value.order] = (
        <div key={key} onClick={() => scrollIntoView(key)} className={`tab ${key === currentActiveCategory() ? 'active' : ''}`}>
          {key}
        </div>
      )
    }
    return (
      <div className="tabs">
        {tabs}
      </div>
    )
  }

  function renderMenu() {
    return (
      <div className="menu">
        <div className="menu-header">
          <MenuLegend categories={categories} />
          <Input className="search-menu" prefix={<SearchOutlined style={{color: "var(--color-medium)"}} />} placeholder="Search menu" allowClear onChange={(e) => filterMenu(e.target.value)} />
        </div>
        {
          searchValue &&
          <div className="search-heading">Results for '{searchValue}'</div>
        }
        {
          renderGroups()
        }
      </div>
    )
  }

  return (
    <div className={className}>
      {renderTabs()}
      {renderMenu()}
    </div>
  )
}