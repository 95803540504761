import { CheckoutDateGroup } from "./CheckoutDateGroup"
import { CartItems } from "@tiffin/app-common"
import { Collapse } from "antd"

import "./CheckoutItems.scss"

const { Panel } = Collapse

interface ICheckoutItemsProps {
  date: string,
  items: CartItems[]
}

export function CheckoutItems(props: ICheckoutItemsProps) {

  let totalItems = 0;
  for(let item of props.items) {
    totalItems += item.count
  }

  function renderHeader() {
    return (
      <>
        <div className="order-group-date">{totalItems} items</div>
      </>
    )
  }

  function renderCheckoutGroups() {
    return (
      <Panel key={props.date} header={renderHeader()}>
        <CheckoutDateGroup date={props.date} items={props.items} />
      </Panel>
    )
  }

  return (
    <Collapse defaultActiveKey={[props.date]} className="checkout-items-collapse" bordered={false}>
      {renderCheckoutGroups()}
    </Collapse>
  )
}