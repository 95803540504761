import styled from "styled-components";

//3rd party
import { Button, Segmented } from "antd"
import { IonIcon } from "@ionic/react";
import { checkmarkOutline, closeOutline } from "ionicons/icons";
import { NEW_CUSTOMER_DISCOUNT, SubscriptionPlan } from "@tiffin/core";
import { useAppSelector } from "../hooks";
import { useState } from "react";
import { SegmentedValue } from "antd/es/segmented";

export const PlanCard = styled(BasePlanCard)`
  border: 1px solid var(--ion-color-light);
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 350px;

  &.selected {
    border: 2px solid var(--ion-color-primary);
  }

  @media (max-width:768px)  {
    width: 100%;
  }

  .title-container {
    font-size: large;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .event-logo {
        height: 1.4em;
        width: auto;
      }
    }
  }

  .sub-title {
    font-size: small;
  }

  .discount-percentage {
    font-size: smaller;
    padding: 5px;
    background: var(--ion-color-primary);
    color: #fff;
    border-radius: 8px;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .price-details {
    font-weight: 700;
    display: flex;
    gap: 5px;
    align-items: flex-end;

    .price {
      font-size: xx-large;

      &.discounted {
        text-decoration: line-through;
      }
    }

    .frequency {
      font-size: smaller;
      padding-bottom: 4px;
      color: var(--ion-color-medium-shade);
    }
  }

  .discount-info {
    font-size: small;
  }

  ul {
    list-style: none;
    padding: 0;
    display: grid;
    gap: 10px;

    li {
      font-size: small;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .free-trial {
    font-weight: 700;
    color: var(--ion-color-dark-tint);
  }
`

interface IPlanOption {
  price: number,
  frequency:  "monthly" | "annually",
  plan: SubscriptionPlan
}

interface IPlanCardForm {
  className?: string,
  title: string,
  subTitle?: string,
  logoImageUrl?: string,
  options: IPlanOption[]
  features: string[],
  unavailableFeatures?: string[],
  onChange: (newPlan: SubscriptionPlan) => void,
  loadingPlan?: SubscriptionPlan
}

export function BasePlanCard({className, title, subTitle, logoImageUrl, options, features, unavailableFeatures, onChange, loadingPlan}: IPlanCardForm) {

  const subscriptionPlan = useAppSelector((state) => state.partner.subscriptionPlan)
  const subscriptionTrialClaimed = useAppSelector((state) => state.partner.subscriptionTrialClaimed)
  const [selectedPlan, setSelectedPlan] = useState<IPlanOption | undefined>(options[0])
  const isEventPlan = selectedPlan?.plan === SubscriptionPlan.Event
  const isBasicHomePlan = selectedPlan?.plan === SubscriptionPlan.BasicHome

  const handleSegmentChange = (val: SegmentedValue) => {
    const newPlan = options.find((option) => {
      if(val === 0 && option.frequency === "monthly") {
        return option
      } else if(val === 1 && option.frequency === "annually") {
        return option
      }
    })

    if(newPlan) {
      setSelectedPlan(newPlan)
    }
  }

  return (
    <div className={`${className} ${subscriptionPlan === selectedPlan ? 'selected' : ''}`}>
      {
        selectedPlan &&
        <>
          <div className="title-container">
            <div className="title">
              {
                logoImageUrl &&
                <img className="event-logo" alt="logo" src={logoImageUrl}/>
              }
              {title}
            </div>
            {
              (!isEventPlan && !isBasicHomePlan && !subscriptionTrialClaimed) &&
              <div className="discount-percentage">{NEW_CUSTOMER_DISCOUNT * 100}% off</div>
            }
          </div>
          <div className="sub-title">{subTitle}</div>
          <div className="price-details">
            {
              (subscriptionTrialClaimed || selectedPlan.price === 0) ?
              <span className="price">
                {selectedPlan.price > 0 ? `$${(selectedPlan.price / 100).toFixed(2)}` : 'Free'}
              </span>
              :
              <>
                <span className="price discounted">
                  {selectedPlan.price > 0 ? `$${(selectedPlan.price / 100).toFixed(2)}` : 'Free'}
                </span>
                <span className="price">
                  {selectedPlan.price > 0 ? `$${(selectedPlan.price * NEW_CUSTOMER_DISCOUNT / 100).toFixed(2)}` : 'Free'}
                </span>
              </>
            }
            {
              selectedPlan.price > 0 &&
              <span className="frequency">
                {selectedPlan.frequency === "monthly" ? "per month" : "per year"}
              </span>
            }
          </div>
          {
            (!isEventPlan && !isBasicHomePlan && !subscriptionTrialClaimed) &&
            <span className="discount-info">First 12 months, ${(selectedPlan.price / 100)} {selectedPlan.frequency === "monthly" ? "per month" : "per year"} thereafter</span>
          }
          {
            isEventPlan &&
            <span className="discount-info">Enjoy complimentary access to the <b>Starter</b> plan for 30 days after the event</span>
          }
          {
            (!isEventPlan && !isBasicHomePlan && !subscriptionTrialClaimed) &&
            <div className="free-trial">
              30 day free trial
            </div>
          }
          {
            options.length > 1 &&
            <Segmented
              block
              defaultValue={0}
              options={[
                {
                  label: 'Monthly', value: 0
                },
                {
                  label: 'Yearly', value: 1
                }
              ]}
              onChange={handleSegmentChange}
          />
          }
          {
            selectedPlan?.plan !== SubscriptionPlan.Event &&
            <Button disabled={!!loadingPlan} loading={loadingPlan === selectedPlan?.plan} onClick={() => onChange(selectedPlan?.plan)} type={subscriptionPlan === selectedPlan?.plan ? 'primary' : 'default'}>
              {
                subscriptionPlan === selectedPlan?.plan ? (isEventPlan || isBasicHomePlan) ? 'Selected' : 'Manage' : isEventPlan || isBasicHomePlan ? 'Select' : 'Buy plan'
              }
            </Button>
          }
          <ul>
            {
              features.map((feature) => {
                return (
                  <li>
                    <IonIcon icon={checkmarkOutline} style={{color: "var(--ion-color-primary)", fontSize: "1.2em"}} />
                    {feature}
                  </li>
                )
              })
            }
            {
              unavailableFeatures?.map((feature) => {
                return (
                  <li>
                    <IonIcon icon={closeOutline} style={{color: "var(--ion-color-danger)", fontSize: "1.2em"}} />
                    {feature}
                  </li>
                )
              })
            }
          </ul>
        </>
      }
    </div>
  )
}
