import { useEffect, useMemo, useState } from "react";

import { IOrderExt, OrderStatus, OrderType } from "@tiffin/core";
import { OrderCardHeader } from "./OrderCardHeader";
import { OrderCard } from "./OrderCard";
import { useAppSelector } from "../hooks";
import { Capacitor } from "@capacitor/core";

//3rd party
import moment, { Moment } from "moment";
import { Checkbox, Collapse } from "antd";
import { CaretRightOutlined } from '@ant-design/icons';
import { IonLabel, IonSelect, IonSelectOption, IonToggle } from "@ionic/react";
import { uniq } from "lodash";
import { ReactComponent as NoData } from '../assets/no-data.svg';
import { isDesktop, isMobile, isTablet } from "react-device-detect";

import './OrderCardList.scss';

const { Panel } = Collapse;

const MINUTE_MS = 60000;

type OrderFilter = 'all' | 'dine in' | 'pickup' | 'delivery'

interface IOrderCardList {
  date?: string,
  orders: IOrderExt[],
  grouped?: boolean,
  historic?: boolean
}

export function OrderCardList({orders, date, grouped, historic}: IOrderCardList) {

  const [expandedConfirmed, setExpandedConfirmed] = useState<boolean>(false)
  const [expandedUnconfirmed, setExpandedUnconfirmed] = useState<boolean>(false)
  const [expandedGroups, setExpandedGroups] = useState<string[]>([])
  const [activeConfirmedKeys, setActiveConfirmedKeys] = useState<string[]>([])
  const [activeUnconfirmedKeys, setActiveUnconfirmedKeys] = useState<string[]>([])
  const [orderTypeFilter, setOrderTypeFilter] = useState<OrderFilter>('all')
  const [currentTime, setCurrentTime] = useState<Moment>(moment())
  const [showInactiveOrders, setShowInactiveOrders] = useState<boolean>(historic ? true : false)

  const [busyOrders, setBusyOrders] = useState<string[]>([])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentTime(moment())
    }, MINUTE_MS - moment().seconds() * 1000);

    return () => clearTimeout(timeout); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [currentTime])

  const partner = useAppSelector((state) => state.partner)

  const unconfirmedOrders = orders.filter(order => order.status === OrderStatus.Unconfirmed);
  const confirmedOrders = orders.filter(order => {
    if(!showInactiveOrders && (order.status === OrderStatus.Completed || order.status === OrderStatus.Cancelled)) {
      return false;
    }
    if(order.status !== OrderStatus.Unconfirmed) {
      if(orderTypeFilter === "pickup") {
        return order.orderType === OrderType.Pickup;
      } else if(orderTypeFilter === "delivery") {
        return order.orderType === OrderType.Delivery;
      } else if(orderTypeFilter === "dine in") {
        return order.orderType === OrderType.DineIn;
      } else {
        return true;
      }
    }
    return false;
  });

  const groupedOrders = useMemo(() => {
    const nowGroup: IOrderExt[] = []
    if(grouped) {
      const timeGroupedOrders = new Map<string, IOrderExt[]>();

      //Group the orders based on time
      for(const order of confirmedOrders) {
        if(order.time === "now") {
          if(order.status === OrderStatus.Completed || order.status === OrderStatus.Cancelled) {
            //All completed 'now orders' should appear in the time group in which they were completed
            const timezone = moment.tz.guess();
            const orderTime = order.status === OrderStatus.Completed ? moment(order.completeTimestamp).tz(timezone) : moment(order.cancelledTimestamp).tz(timezone)
            orderTime.set({
              seconds: 0
            })

            //Round to next 15 minute
            const minRemainder = 15 - (orderTime.minute() % 15);
            if(minRemainder < 15) {
              orderTime.add(minRemainder, 'minute')
            }

            //Add to time group
            const group = timeGroupedOrders.get(orderTime.format());
            if(group) {
              group.push(order);
            } else {
              timeGroupedOrders.set(orderTime.format(), [order]);
            }
          } else {
            //Only pushed outstanding 'now' orders to 'now' group
            nowGroup.push(order)
          }
        } else {
          const group = timeGroupedOrders.get(order.time);
          if(group) {
            group.push(order);
          } else {
            timeGroupedOrders.set(order.time, [order]);
          }
        }
      }

      const sortedTimes = Array.from(timeGroupedOrders.keys()).sort((a,b) => moment(a).valueOf() - moment(b).valueOf())
      if(nowGroup.length > 0) {
        //Insert 'now' group just before the current time
        const insertIndex = sortedTimes.findIndex((time) => moment(time) > currentTime)

        if(insertIndex !== -1) {
          sortedTimes.splice(insertIndex, 0, "now")
        } else {
          sortedTimes.push("now")
        }
        
        //Add 'now' group to grouped orders
        timeGroupedOrders.set("now", nowGroup)
      }
    
      return {
        times: sortedTimes,
        groups: timeGroupedOrders
      }
    }
  },[confirmedOrders, grouped, currentTime])

  function getExpandedConfirmedRowKeys(time?: string) {
    let keys = []
    if(time) {
      const group = groupedOrders?.groups.get(time)
      if(group) {
        for(let order of group) {
          keys.push(order.key)
        }
      }
    } else {
      for(let order of confirmedOrders) {
        keys.push(order.key)
      }
    }
    return keys
  }

  function getExpandedUnconfirmedRowKeys() {
    let keys = []
    for(let order of unconfirmedOrders) {
      keys.push(order.key)
    }
    return keys
  }

  function handleConfirmedExpandToggle(e?: any, time?: string) {
    if(e && time) {
      if(e.detail.checked) {
        setActiveConfirmedKeys(uniq([
          ...activeConfirmedKeys,
          ...getExpandedConfirmedRowKeys(time)
        ]));
        if(!expandedGroups.includes(time)) {
          setExpandedGroups([
            ...expandedGroups,
            time
          ])
        }
      } else {
        const collapseOrders = getExpandedConfirmedRowKeys(time)
        setActiveConfirmedKeys(activeConfirmedKeys.filter((key) => !collapseOrders.includes(key)));
        if(expandedGroups.includes(time)) {
          setExpandedGroups(expandedGroups.filter((group) => group !== time));
        }
      }
    } else {
      if(expandedConfirmed) {
        setActiveConfirmedKeys([])
        setExpandedConfirmed(false)
      } else {
        setActiveConfirmedKeys(getExpandedConfirmedRowKeys(time))
        setExpandedConfirmed(true)
      }
    }
  }

  function handleUnconfirmedExpandToggle() {
    if(expandedUnconfirmed) {
      setActiveUnconfirmedKeys([])
      setExpandedUnconfirmed(false)
    } else {
      setActiveUnconfirmedKeys(getExpandedUnconfirmedRowKeys())
      setExpandedUnconfirmed(true)
    }
  }

  function getGroupedTitle(time: string) {
    if(time === "now") {
      return "ASAP"
    } else {
      return moment(time).format('h:mm a')
    }
  }

  function renderGroupedOrders(groupedOrders: {
    times: string[];
    groups: Map<string, IOrderExt[]>;
  }) {
    let children: React.ReactNode[] = [];

    //Insert only for card list showing today's orders
    let insertNow = moment(date).isSame(moment(), 'day') ? true : false;
    for(let time of groupedOrders.times) {
      if(insertNow && (time === "now" || moment(time) > currentTime)) {
        children.push(
        <div key="now-indicator" className="current-time-indicator">
          <div className="left-divider"></div>
          <div className="blob"></div>
          <span>Now ({currentTime.format('hh:mm a')})</span>
          <div className="right-divider"></div>
        </div>)
        insertNow = false; //Only need to insert now indicator once
      }

      children.push(
        <div className="group-heading" key={time}>
          {getGroupedTitle(time)}
          <div className="expand-toggle">
            {expandedGroups.includes(time) ? 'Collapse all' : 'Expand all'}
            <IonToggle mode={Capacitor.getPlatform() === 'android' ? 'md' : 'ios'} onIonChange={(e) => {handleConfirmedExpandToggle(e, time)}}/>
          </div>
        </div>
      )
      const orders = groupedOrders.groups.get(time)
      if(orders) {
        for(let order of orders) {
          children.push(
            <Panel className="partner-order-card" header={<OrderCardHeader order={order} busy={!!busyOrders.find((i) => i === order.id) && !activeConfirmedKeys.find((i) => i === order.id)} />} key={order.key}>
              <OrderCard 
                order={order} 
                orderBusyCallback={(id) => setBusyOrders((val) => [...val, id])}
                orderChangeCallback={(id) => setBusyOrders((val) => val.filter((i) => i !== id))}
              />
            </Panel>
          )
        }
      }
    }
    return children;
  }

  function renderUngroupedOrders() {
    let children: React.ReactNode[] = [];
    children.push(
      <div className="group-heading" key="heading">
        Confirmed Orders
        <div className="expand-toggle">
          {expandedConfirmed ? 'Collapse all' : 'Expand all'}
          <IonToggle mode={Capacitor.getPlatform() === 'android' ? 'md' : 'ios'} onClick={() => handleConfirmedExpandToggle()}/>
        </div>
      </div>
    )
    for(let order of confirmedOrders) {
      children.push(
        <Panel className="partner-order-card" header={<OrderCardHeader order={order} busy={!!busyOrders.find((i) => i === order.id) && !activeConfirmedKeys.find((i) => i === order.id)} />} key={order.id}>
          <OrderCard 
            order={order} 
            orderBusyCallback={(id) => setBusyOrders((val) => [...val, id])}
            orderChangeCallback={(id) => setBusyOrders((val) => val.filter((i) => i !== id))}
          />
        </Panel>
      )
    }
    return children;
  }

  return (
    <div
      className="partner-order-card-list"
    >
      {
        unconfirmedOrders.length > 0 &&
        <>
          <div className="group-heading">
            Requires Action
            <div className="expand-toggle">
              {expandedUnconfirmed ? 'Collapse all' : 'Expand all'}
              <IonToggle onClick={handleUnconfirmedExpandToggle} mode={Capacitor.getPlatform() === 'android' ? 'md' : 'ios'}/>
            </div>
          </div>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            expandIconPosition="right"
            activeKey={activeUnconfirmedKeys}
            onChange={(e) => setActiveUnconfirmedKeys(Array.isArray(e) ? e : [e])}
            >
            {
              unconfirmedOrders.map(order => {
                return (
                  <Panel className="partner-order-card" header={<OrderCardHeader order={order} busy={!!busyOrders.find((i) => i === order.id) && !activeUnconfirmedKeys.find((i) => i === order.id)} />} key={order.key}>
                    <OrderCard 
                      order={order} 
                      orderBusyCallback={(id) => setBusyOrders((val) => [...val, id])}
                      orderChangeCallback={(id) => setBusyOrders((val) => val.filter((i) => i !== id))}
                    />
                  </Panel>
                )
              })
            }
          </Collapse>
        </>
      }

      {
        date && (partner.delivery?.deliveryDays?.[moment(date).day()] || partner.pickup?.pickupDays?.[moment(date).day()]) && 
        <div className="order-type-filter">
          <div className="order-type-select">
            <IonLabel className="status-label">Order Type</IonLabel>
            <IonSelect interface={isMobile && !isTablet && !isDesktop ? "action-sheet" : "popover"} title="Select order status" className="status-select" value={orderTypeFilter} onIonChange={e => setOrderTypeFilter(e.detail.value)}>
              <IonSelectOption value="all">All</IonSelectOption>
              {
                partner.pickup?.offerDineIn &&
                <IonSelectOption value="dine in">Dine In</IonSelectOption>
              }
              {
                partner.pickup?.offerPickup &&
                <IonSelectOption value="pickup">Pickup</IonSelectOption>
              }
              {
                partner.delivery?.offerDelivery &&
                <IonSelectOption value="delivery">Delivery</IonSelectOption>
              }
            </IonSelect>
          </div>
          {
            !historic &&
            <Checkbox className="completed-checkbox" value={showInactiveOrders} onChange={() => setShowInactiveOrders((val) => !val)}>Show Inactive Orders</Checkbox>
          }
        </div>
      }

      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        expandIconPosition="right"
        activeKey={activeConfirmedKeys}
        onChange={(e) => setActiveConfirmedKeys(Array.isArray(e) ? e : [e])}
      >
        {
          grouped && !!groupedOrders && renderGroupedOrders(groupedOrders)
        }
        {
          !grouped && renderUngroupedOrders()
        }
      </Collapse>
      {
        confirmedOrders.length === 0 &&
        <div className="empty-message">
          <NoData />
          <span>There are currently no {!showInactiveOrders ? 'outstanding' : ''} {orderTypeFilter !== "all" ? orderTypeFilter : ""} orders</span>
        </div>
      }
    </div>
  )
}