import { useState } from "react";
import styled from "styled-components";
import { IModifier } from "@tiffin/core";
import { SpringModal } from "@tiffin/components";
import { ModifierForm } from "./ModifierForm";
import { useAppSelector } from "../../hooks";
import { Button, Select } from "antd";

export const ModifierSelect = styled(BaseModifierSelect)`
  display: flex;
  gap: 10px;
`;

interface IModifierList {
  className?: string,
  modifiers?: string[],
  onChange: (modifier: IModifier) => void
}

function BaseModifierSelect({className, modifiers, onChange}: IModifierList) {

  const globalModifiers = useAppSelector((state) => state.partner.modifiers)

  const [ showNewModifierModal, setShowNewModifierModal ] = useState(false)

  function handleModifierChange(modifier: IModifier) {
    onChange(modifier)
    setShowNewModifierModal(false)
  }

  let remainingFilters = globalModifiers.filter((globalModifier: IModifier) => !modifiers?.find((modifierId) => modifierId === globalModifier.id))

  return (
    <div className={className}>
      <Select
        value={null}
        showSearch
        autoClearSearchValue={true}
        style={{ width: 200 }}
        placeholder="Search modifiers"
        optionFilterProp="children"
        filterOption={(input: string, option?: {label: string, value: string}) => (option?.label ?? '').includes(input)}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
        options={remainingFilters?.map((val: IModifier) => ({
          label: val.name,
          value: val.id
        }))}
        onChange={(id) => {
          const modifier = globalModifiers.find((modifier: IModifier) => modifier.id === id)
          if(modifier) {
            onChange(modifier)
          }
        }}
      />
      <Button type="primary" onClick={() => setShowNewModifierModal(true)}>Add new modifier</Button>
      <SpringModal
        showClose={true}
        title="Add new modifier"
        okText="Save"
        isOpen={showNewModifierModal}
        onClose={() => setShowNewModifierModal(false)}
      >
        <div style={{paddingTop: "10px"}}>
          <ModifierForm isNew={true} onChange={handleModifierChange} />
        </div>
     </SpringModal>
    </div>
  )
}