import { IonIcon } from "@ionic/react";
import { IPayoutTransaction } from "@tiffin/core";
import { Tag, Tooltip } from "antd";
import moment from "moment";
import { helpCircleOutline } from 'ionicons/icons';

import './PayoutCard.scss'

export function PayoutCard(props: IPayoutTransaction) {

  function renderStatusTag() {
    let color = '';
    if(props.status === 'paid') {
      color = 'var(--ion-color-success)'
    } else if(props.status === 'pending') {
      color = 'var(--ion-color-primary)'
    }
    return <Tag className="status-tag" color={color}>{props.status}</Tag>
  }

  return (
    <div className="payout-card">
      <div className="card-header">
        <div className="payout-title">
          <span className="desc">Payout</span>
          <span className="type">({moment(props.created * 1000).format("D MMMM YYYY")})</span>
        </div>
        <div>
          {
            props.amount < 0 &&
            <Tooltip placement="bottomRight" title={`$${(props.amount * -1 / 100).toFixed(2)} was deducted. This is probably to cover cancellations fees accrued.`}>
              <div className="negative-amount">
                <span>{`$${(props.amount / 100).toFixed(2)}`}</span>
                <IonIcon icon={helpCircleOutline} />
              </div>
            </Tooltip>
          }
          {
            props.amount >= 0 &&
            <span className="amount">${(props.amount / 100).toFixed(2)}</span>
          }
        </div>
        <div>
          {renderStatusTag()}
        </div>
      </div>
      <div className="card-content">
        <div className="left-panel">
          <span className="subheading">Available Date</span>
          <span>{moment(props.arrival_date * 1000).format("D MMMM YYYY")}</span>
        </div>
        <div className="right-panel">
          <span className="subheading">Bank Account</span>
          <span>{props.destination.bank_name}</span>
        </div>
      </div>
    </div>
  )
}