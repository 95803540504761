import { CustomerReviewsList } from "../components/CustomerReviewsList"
import { useAppDispatch } from "../hooks"
import { partnerReducer } from "@tiffin/app-common"

//3rd Party
import { IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar, IonBackButton, IonButton } from "@ionic/react"
import { receiptOutline } from "ionicons/icons"
import RedoOutlined from "@ant-design/icons/RedoOutlined"

import './CustomerReviews.scss'

export function CustomerReviews() {

  //Redux
  const dispatch = useAppDispatch()

  function refreshPage() {
    dispatch(partnerReducer.getReviews())
  }

  return (
    <IonPage className="customer-reviews">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main/dashboard" />
          </IonButtons>
          <div className="page-header">
            <IonIcon icon={receiptOutline} />
            <span className="page-header-text">Customer Reviews</span>
          </div>
          <IonButtons slot="end">
            <IonButton 
              className="refresh-btn" 
              onClick={refreshPage}
            >
              <RedoOutlined style={{fontSize: "1.6em"}}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="main-content">
          <CustomerReviewsList />
        </div>
      </IonContent>
    </IonPage>
  )
}