import { useEffect } from 'react';

import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useAppDispatch } from '../hooks';
import { partnerReducer } from '@tiffin/app-common';

export function AppUrlListener() {

  const dispatch = useAppDispatch()

  // There is something wrong here, this listener does not fire for a deep link
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const route = event.url.split('.com.au').pop();
      if (route) {
        if(route === "/dynamiclinks/stripe-redirect") {
          dispatch(partnerReducer.getPaymentStatus())
        }
      }
    });

    return (() => {
      App.removeAllListeners()
    })
  }, [dispatch]);
  
  return null
}