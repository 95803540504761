import { useMemo } from "react";
import { DateFormat, IOrderExt, OrderStatus, SubscriptionPlan } from "@tiffin/core";
import { CustomerReviewsCard } from "../components/CustomerReviewsCard";
import { DashboardPicker } from "../components/DashboardPicker";
import { RevenueChart } from "../components/RevenueChart";
import { TopItemsCard } from "../components/TopItemsCard";
import { RatingChart } from "../components/RatingChart";
import { WeeklyRevenue } from "../components/WeeklyRevenue";
import { WeeklyOrders } from "../components/WeeklyOrders";
import { DaySummaryCard } from "../components/DaySummaryCard";
import { PageBreadcrumb } from "../components/PageBreadcrumb";

//Redux
import { useAppSelector } from "../hooks"

//3rd party
import { isMobile, isTablet } from "react-device-detect";
import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import moment from "moment";
import { Alert, Button } from "antd";

import './Dashboard.scss'

export function Dashboard() {
  //Redux
  const subscriptionPlan = useAppSelector((state) => state.partner.subscriptionPlan)
  const orders = useAppSelector((state) => state.orders)
  const pausedDates = useAppSelector((state) => state.partner.pauseDates)
  const chargesEnabled = useAppSelector((state) => state.partner.chargesEnabled)
  
  const router = useIonRouter()

  const mobileView = isMobile && !isTablet

  const groupedOrders = useMemo(() => {
    let groupedOrders: Map<string, IOrderExt[]> = new Map()

    for(let order of orders.active) {
      if(!groupedOrders.has(order.date)) {
        groupedOrders.set(order.date, []);
      }
      groupedOrders.get(order.date)?.push({...order})
    }

    return groupedOrders
  }, [orders.active]);

  const summaryCards = useMemo(() => {
    let cards: JSX.Element[] = []

    let dates = [...groupedOrders.keys()].filter((date) => {
      if(moment(date, DateFormat).endOf('day') > moment()) {
        return true
      }
      return false
    })

    dates.sort((a, b) => {
      if(moment(a, DateFormat) < moment(b, DateFormat)) {
        return -1
      }
      return 1
    })

    for(let date of dates) {
      //Should filter out cancelled and unpaid orders
      let orders = groupedOrders.get(date)
      if(orders) {
        let filteredOrders = orders.filter((order) => order.status !== OrderStatus.Cancelled && order.status !== OrderStatus.Unpaid)
        if(filteredOrders.length > 0) {
          cards.push(
            <DaySummaryCard key={date} date={date} orders={orders.filter((order) => order.status !== OrderStatus.Cancelled)} paused={pausedDates.includes(date)}/>
          )
        }
      }
    }
    return cards
  }, [groupedOrders, pausedDates])

  const navigateToPayout = () => router.push('/edit/payment')

  return (
    <IonPage>
      <IonContent>
        {
          (subscriptionPlan === SubscriptionPlan.StarterAnnual || subscriptionPlan === SubscriptionPlan.StarterMonthly || subscriptionPlan === SubscriptionPlan.Event) &&
          <Alert showIcon type="warning" message="Online ordering is not included on your current plan"/>
        }
        {
          (subscriptionPlan === SubscriptionPlan.BasicHome && !chargesEnabled) &&
          <Alert showIcon type="warning" message={<span>Online ordering is currently <b>disabled</b>, please <Button type="link" onClick={navigateToPayout}>setup payout details</Button> to enable</span>}/>
        }
        <div className="dashboard">
          <div className="subheading">
            <PageBreadcrumb
              items={[
                {
                  title: "Upcoming",
                }
              ]}
            />
            <span>
              <DashboardPicker futureDates={[...groupedOrders.keys()]} />
            </span>
          </div>
          <div className="first-row">
            <div className={`upcoming-orders ${mobileView ? 'stacked' : ''}`}>
            {
              summaryCards.length === 0 &&
              <span className="no-orders">
                No upcoming orders
              </span>
            }
            {
              summaryCards.length > 0 &&
              summaryCards
            }
            </div>
          </div>
          <div className="second-row">
            <div className="weekly">
              <WeeklyRevenue />
              <WeeklyOrders />
            </div>
            <RevenueChart className="dashboard-revenue-chart" disableViewToggle={isMobile && !isTablet}/>
          </div>
          <div className="third-row">
            <TopItemsCard />
            <RatingChart />
            <CustomerReviewsCard />
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}