/* eslint-disable jsx-a11y/anchor-is-valid */

import styled from "styled-components";
import { DateFormat } from "@tiffin/core";
import { PageBreadcrumb } from "../PageBreadcrumb";
import { useIonRouter } from "@ionic/react";
import moment from "moment";

interface IDailyHeader {
  className?: string,
  date: string
}

export const DailyHeader = styled(BaseDailyHeader)`  
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
  }
`

function BaseDailyHeader({className, date}: IDailyHeader) {

  const router = useIonRouter();

  return (
    <div className={className}>
      <div className="title">
        <PageBreadcrumb
          defaultHref="/main/dashboard" 
          items={[
            {
              title: <a onClick={() => router.push('/main/dashboard')}>Dashboard</a>,
            },
            {
              title: moment(date, DateFormat).format("dddd, D MMMM YYYY"),
            }
          ]}
        />
      </div>
    </div>
  )
}