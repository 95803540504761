import { useEffect, useRef, useState } from "react"
import { toast } from "@tiffin/components"
import { partnerReducer } from "@tiffin/app-common"

//Redux
import { useAppDispatch, useAppSelector } from "../hooks"

//3rd party
import { Alert, Button, Form, FormInstance, InputNumber, Radio } from "antd"
import { useIonRouter, useIonToast } from "@ionic/react"
import { Prompt } from "react-router"
import { IPartnerDineIn } from "@tiffin/core"

interface IDineInFormProps {
  active?: boolean,
  independent?: boolean,
  onDone?: (action: "save" | "prev" | "next") => void,
  navigate?: (index: number) => void
}

export function DineInForm({active, onDone, ...props}: IDineInFormProps) {
  //Redux
  const status = useAppSelector((state) => state.partner.status)
  const error = useAppSelector((state) => state.partner.error)

  const partnerDineIn = useAppSelector((state) => state.partner.dineIn)
  const dineInAvailable = useAppSelector((state) => state.partner.pickup?.offerDineIn)
  const partnerUrlName = useAppSelector((state) => state.partner.urlName)

  const navigateFormRef = useRef<"prev" | "next" | null>(null)

  const dispatch = useAppDispatch()

  //Internal states
  const [valueChanged, setValuesChanged] = useState(false)

  const formRef = useRef<FormInstance>(null)
  const isPopulatedRef = useRef(false)
  const [present] = useIonToast();
  const router = useIonRouter()

  //Populate form with user information if provided through props
  useEffect(() => {
    if (partnerUrlName && !isPopulatedRef.current && partnerDineIn) {
      formRef.current?.setFieldsValue({ 
        numberOfTables: partnerDineIn?.numberOfTables,
        absorbPaymentFee: partnerDineIn?.absorbPaymentFee,
        autoConfirmDineInOrders: partnerDineIn?.autoConfirmDineInOrders
      })
      isPopulatedRef.current = true;
    }
  }, [formRef, partnerDineIn, partnerUrlName])

  useEffect(() => {
    if(active) {
      if (status === "saved" && navigateFormRef.current === "next") {
        dispatch(partnerReducer.resetStatus())
        if(onDone) onDone("next")
        navigateFormRef.current = null
      } else if (status === "saved" && navigateFormRef.current === "prev") {
        dispatch(partnerReducer.resetStatus())
        if(onDone) onDone("prev")
        navigateFormRef.current = null
      } else if (status === "saved") {
        dispatch(partnerReducer.resetStatus())
        toast(present, "Saved changes", "success")
        if(onDone) onDone("save")
      }
    }
  }, [status, dispatch, onDone, active, present])

  useEffect(() => {
    if (props.independent && status === "saved") {
      dispatch(partnerReducer.resetStatus())
      toast(present, "Saved changes", "success", "bottom")
      setValuesChanged(false)
    }
  }, [props.independent, status, dispatch, present])

  useEffect(() => {
    if (error) {
      toast(present, error, "danger")
    }
  }, [error, present])

  function handlePrev() {
    handleSave()
    //When the save is successful, we will navigate to the prev form
    navigateFormRef.current = "prev"
  }

  function handleNext(e: any) {
    //Remove focus to prevent user clicking 'enter' button to progress forward
    if(e?.target) {
      e.target.blur()
    }
    
    handleSave()
    //When the save is successful, we will navigate to the next form
    navigateFormRef.current = "next"
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function handleSave(){
    setTimeout(() => {
      formRef.current?.validateFields().then(
        (fields: any) => {
          let update: IPartnerDineIn = {
            numberOfTables: fields.numberOfTables,
            absorbPaymentFee: fields.absorbPaymentFee,
            autoConfirmDineInOrders: fields.autoConfirmDineInOrders
          }

          //Updates details in the redux store
          dispatch(partnerReducer.updateDineIn(update))
        },
        () => {
          navigateFormRef.current = null
        }
    )})
  }

  return (
    <Form
      ref={formRef}
      className="partner-form"
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      scrollToFirstError
      onValuesChange={() => setValuesChanged(true)}
    >
      <div className="outer-form-container">
        <div className="form-container">
          {
            dineInAvailable &&
            <>
              <Form.Item 
                name="numberOfTables"
                label="Number of tables"
                rules={[
                  {
                    required: true,
                    message: "Please enter the number of tables",
                  },
                ]}
              >
                <InputNumber min={1} type="number" />
              </Form.Item>
              <Form.Item
                name="autoConfirmDineInOrders"
                label="Auto confirm dine in orders"
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={true}>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="absorbPaymentFee"
                label="Pass on payment fee to customers"
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={false}>Yes</Radio.Button>
                  <Radio.Button value={true}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <div className="field-description">
                <p>A payment fee of 1.75% + $0.30 is charged for all dine in orders to cover Stripe payment fees. When passed on, customers will be shown an additional payment fee at checkout.</p>
                <p>Payment fees do not exist for pickup or delivery orders as they are covered under the service fee charged by Tifyn.</p>
              </div>  
            </>
          }
          {
            (!dineInAvailable && props.independent) &&
            <Alert showIcon type="warning" message={<>Dine in orders are currently disabled. If you would like to enable dine in order you can do so <Button type="link" onClick={() => router.push("/edit/store")}>here</Button></>} />
          }
        </div>
      </div>
      <Form.Item className="form-buttons" wrapperCol={{ span: 24 }}>
        {
          !props.independent &&
          <>
            <Button size="large" className="left-btn" onClick={handlePrev}>
              Back
            </Button>
            <Button size="large" type="primary" className="right-btn" htmlType="submit" onClick={handleNext}>
              Next
            </Button>
          </>
        }
        <Button type={props.independent ? "primary" : "default"} size="large" className="right-btn" htmlType="submit" onClick={handleSave}>
          Save
        </Button>
      </Form.Item>
      {
        valueChanged &&
        <Prompt
          message="Any unsaved changes will be discarded?"
        />
      }
    </Form>
  )
}