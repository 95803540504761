import { useState } from "react";

import { IOrderExt, OrderStatus, OrderType } from "@tiffin/core";
import { cloudFunction } from "@tiffin/app-common"
import { SpringModal, toast } from "@tiffin/components";

//3rd party
import { IonButton, IonChip, IonIcon, IonLabel, IonSpinner, useIonToast } from "@ionic/react";
import { Alert, Input } from "antd";
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

import './OrderButtons.scss'

interface IOrderButtonProps extends IOrderExt {
  submitCallback?: (id: string) => void,
  successCallback?: (id: string) => void,
  errorCallback?: (id: string) => void,
  size?: "small" | "large" | "default",
  align?: "left" | "right"
}

const SUGGESTED_CANCEL_REASONS = [
  'Could not fulfill order',
  'No longer accepting orders',
  'Delivery unavailable'
]

export function OrderButtons(props: IOrderButtonProps) {

  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false)
  const [readyModalVisible, setReadyModalVisible] = useState<boolean>(false)
  const [completeModalVisible, setCompleteModalVisible] = useState<boolean>(false)
  const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false)

  const [cancellationReason, setCancellationReason] = useState<string>("")
  const [disabled, setDisabled] = useState<boolean>(false)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [readyLoading, setReadyLoading] = useState<boolean>(false)
  const [completeLoading, setCompleteLoading] = useState<boolean>(false)
  const [cancelLoading, setCancelLoading] = useState<boolean>(false)
  
  const [present] = useIonToast();

  function onError(err: string) {
    console.error(err)
    toast(present, err, "danger");
    setDisabled(false)
    setCancelLoading(false)
    if(props.errorCallback) props.errorCallback(props.id)
  }

  function handleCancelled() {
    if(!cancellationReason) {
      toast(present, "Please enter a cancellation reason", "danger")
      return
    }

    cloudFunction("transitionOrder", {
      id: props.id, 
      status: OrderStatus.Cancelled,
      cancellationReason: cancellationReason
    }).then((result: any) => {
      setDisabled(false)
      setCancelLoading(false)
      if(props.successCallback) props.successCallback(props.id)
    }, (error) => {
      onError(error)
    })
    setDisabled(true)
    setCancelLoading(true)
    if(props.submitCallback) props.submitCallback(props.id)
    setCancelModalVisible(false)
  }

  function handleReady() {
    cloudFunction("transitionOrder", {id: props.id, status: OrderStatus.Ready}).then((result: any) => {
      setDisabled(false)
      setReadyLoading(false)
      if(props.successCallback) props.successCallback(props.id)
    }, (error) => {
      onError(error)
    })
    setDisabled(true)
    setReadyLoading(true)
    if(props.submitCallback) props.submitCallback(props.id)
    setReadyModalVisible(false)
  }

  function handleCompleted() {
    cloudFunction("transitionOrder", {id: props.id, status: OrderStatus.Completed}).then((result: any) => {
      setDisabled(false)
      setCompleteLoading(false)
      if(props.successCallback) props.successCallback(props.id)
    }, (error) => {
      onError(error)
    })
    setDisabled(true)
    setCompleteLoading(true)
    if(props.submitCallback) props.submitCallback(props.id)
    setCompleteModalVisible(false)
  }
  
  function handleConfirm() {
    cloudFunction("transitionOrder", {id: props.id, status: OrderStatus.Confirmed}).then((result: any) => {
      setDisabled(false)
      setConfirmLoading(false)
      if(props.successCallback) props.successCallback(props.id)
    }, (error) => {
      onError(error)
    })
    setDisabled(true)
    setConfirmLoading(true)
    if(props.submitCallback) props.submitCallback(props.id)
    setConfirmModalVisible(false)
  }
  
  function suggestedCancelReasons() {
    let reasons: JSX.Element[] = []
    let key = 0;
    for(let reason of SUGGESTED_CANCEL_REASONS) {
      reasons.push(
        <IonChip key={key} onClick={() => setCancellationReason(reason)}>
          <IonLabel>{reason}</IonLabel>
        </IonChip>
      )
      key++
    } return reasons
  }

  function renderCancelledConfirmation() {
    return (
      <SpringModal
        isOpen={cancelModalVisible}
        onOk={handleCancelled} 
        okLoading={cancelLoading}
        onCancel={() => setCancelModalVisible(false)}
        title={"Cancel order?"}
        okText="Confirm"
      >
        {
          props.status === OrderStatus.Confirmed &&
          <Alert message="A fee will be applied if you proceed to cancel this order" type="warning" />
        }
        <Input name="cancellationReason" placeholder="Cancellation reason" allowClear value={cancellationReason} onChange={(e) => setCancellationReason(e.target.value)} />
        <span className="suggested-label">Suggested reasons</span>
        <div className="suggested-reasons">
          {suggestedCancelReasons()}
        </div>
      </SpringModal>
    )
  }

  function renderCompletedConfirmation() {
    return (
      <SpringModal
        isOpen={completeModalVisible}
        onOk={handleCompleted}
        onCancel={() => setCompleteModalVisible(false)}
        title={"Order Completed"}
        okText="Yes"
      >
        Are you sure want to mark the order as complete?
      </SpringModal>
    )
  }

  function renderReadyConfirmation() {
    return (
      <SpringModal
        isOpen={readyModalVisible}
        onOk={handleReady}
        onCancel={() => setReadyModalVisible(false)}
        title={`Order Ready`}
        okText="Yes"
      >
        {
          props.orderType === OrderType.Pickup &&
          <span>
            The customer will be notified the order is <b>ready for pickup</b>
          </span>
        }
        {
          props.orderType === OrderType.Delivery &&
          <span>
            The customer will be notified the order is <b>out for delivery</b>
          </span>
        }
      </SpringModal>
    )
  }

  function renderConfirmConfirmation() {
    return (
      <SpringModal
        isOpen={confirmModalVisible}
        onOk={handleConfirm}
        onCancel={() => setConfirmModalVisible(false)}
        title={"Confirm Order"}
        okText="Yes"
      >
        Are you sure want to confirm order?
      </SpringModal>
    )
  }

  function renderButtons() {
    if(props.status === OrderStatus.Unconfirmed) {
      return (
        <>
          <IonButton size={props.size} disabled={disabled} color="danger" onClick={() => setCancelModalVisible(true)}>
            <div className="btn-content">
              {cancelLoading ? <IonSpinner name="crescent" /> : <IonIcon icon={closeCircleOutline}/>}
              Cancel
            </div>
          </IonButton>
          <IonButton size={props.size} disabled={disabled} color="primary" onClick={() => setConfirmModalVisible(true)}>
            <div className="btn-content">
              {confirmLoading ? <IonSpinner name="crescent" /> : <IonIcon icon={checkmarkCircleOutline}/>}
              Confirm
            </div>
          </IonButton>
        </>
      )
    } else if(props.status === OrderStatus.Confirmed && props.orderType !== OrderType.DineIn) {
      return (
        <>
          <IonButton color="danger" size={props.size} disabled={disabled} onClick={() => setCancelModalVisible(true)}>
            <div className="btn-content">
              {cancelLoading ? <IonSpinner name="crescent" /> : <IonIcon icon={closeCircleOutline}/>}
              Cancel
            </div>
          </IonButton>
          <IonButton color="primary" size={props.size} disabled={disabled} onClick={() => setReadyModalVisible(true)}>
            <div className="btn-content">
              {readyLoading ? <IonSpinner name="crescent" /> : <IonIcon icon={checkmarkCircleOutline}/>}
              Ready
            </div>
          </IonButton>
        </>
      )
    } else if(props.status === OrderStatus.Confirmed && props.orderType === OrderType.DineIn) {
      return (
        <>
          <IonButton color="danger" size={props.size} disabled={disabled} onClick={() => setCancelModalVisible(true)}>
            <div className="btn-content">
              {cancelLoading ? <IonSpinner name="crescent" /> : <IonIcon icon={closeCircleOutline}/>}
              Cancel
            </div>
          </IonButton>
          <IonButton color="primary" size={props.size} disabled={disabled} onClick={() => setCompleteModalVisible(true)}>
            <div className="btn-content">
              {completeLoading ? <IonSpinner name="crescent" /> : <IonIcon icon={checkmarkCircleOutline}/>}
              Complete
            </div>
          </IonButton>
        </>
      )
    } else if(props.status === OrderStatus.Ready) {
      return (
        <>
          <IonButton color="primary" style={{"gridColumn": props.align === "left" ? 1 : 2}} size={props.size} disabled={disabled} onClick={() => setCompleteModalVisible(true)}>
            <div className="btn-content">
              {completeLoading ? <IonSpinner name="crescent" /> : <IonIcon icon={checkmarkCircleOutline}/>}
              Complete
            </div>
          </IonButton>
        </>
      )
    }
  }

  return (
    <div className="order-buttons">
      {renderButtons()}
      {renderConfirmConfirmation()}
      {renderReadyConfirmation()}
      {renderCompletedConfirmation()}
      {renderCancelledConfirmation()}
    </div>
  )
}