import { useState } from "react";
import styled from "styled-components";
import { IModifier, ModifierSelectType } from "@tiffin/core";
import { SpringModal } from "@tiffin/components";
import { ModifierForm } from "./ModifierForm";
import { useAppSelector } from "../../hooks";

import { Button, Table } from 'antd';
import { ColumnsType } from "antd/lib/table";
import { IonIcon } from "@ionic/react";
import { checkmarkCircle, closeCircle } from "ionicons/icons";

export const ModifierList = styled(BaseModifierList)`
  &.ant-table-wrapper {
    .ant-table  {
      font-size: small;
    }
  }

  .action-btns {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .modifier-options {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .modifier-option {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

interface IModifierList {
  className?: string,
  modifiers?: string[],
  onDelete: (id: string) => void
}

function BaseModifierList({className, modifiers, onDelete}: IModifierList) {

  const globalModifiers = useAppSelector((state) => state.partner.modifiers)

  const [ showEditModifierModal, setShowEditModifierModal ] = useState(false)
  const [ activeModifier, setActiveModifier ] = useState<IModifier>()

  const columns: ColumnsType<IModifier> = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Required', dataIndex: 'required', key: 'required', width: '100px', render: (val) => {
      if(val) {
        return (<IonIcon icon={checkmarkCircle} style={{color: "var(--ion-color-success)", fontSize: "1.2em"}} />)
      } else {
        return (<IonIcon icon={closeCircle} style={{"color": "var(--ion-color-danger)", fontSize: "1.2em"}} />)
      }
    } },
    { title: 'Type', dataIndex: 'type', key: 'type', width: '100px', render: (val) => {
      if(val === ModifierSelectType.Single) {
        return 'Single'
      } else if(val === ModifierSelectType.Multiple) {
        return 'Multiple'
      }
    }},
    {
      title: 'Action',
      dataIndex: '',
      key: 'edit',
      width: '175px',
      render: (val, record) => (
        <div className="action-btns">
          <Button type="primary" onClick={() => {
            setActiveModifier(record)
            setShowEditModifierModal(true)
          }}>Edit</Button>
          <Button danger onClick={() => {
            onDelete(record.id)
          }}>Delete</Button>
        </div>
      )
    }
  ];

  function handleModifierChange() {
    setShowEditModifierModal(false)
  }

  return (
    <>
      <Table
        className={className}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <div className="modifier-options">
              {
                record.options.map((val) => (
                  <div className="modifier-option">
                    <span>{val.label}</span>
                    <span>{val.price ? `+$${val.price.toFixed(2)}` : 'Free'}</span>
                  </div>
                ))
              }
            </div>
          )
        }}
        dataSource={globalModifiers.filter((globalModifier: IModifier) => modifiers?.find((modifierId) => modifierId === globalModifier.id)) ?? []}
        pagination={false}
        rowKey="id"
      />
      <SpringModal
        showClose={true}
        title="Edit Modifier"
        okText="Save"
        isOpen={showEditModifierModal}
        onClose={() => setShowEditModifierModal(false)}
      >
        <div style={{paddingTop: "10px"}}>
          <ModifierForm modifier={activeModifier} onChange={handleModifierChange} />
        </div>
     </SpringModal>
    </>
  )
}