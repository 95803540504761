import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";

import { IOrderExt, OrderStatus } from "@tiffin/core";
import { OrderCardHeader } from "./OrderCardHeader";
import { OrderCard } from "./OrderCard";
import { PulseBlob } from "@tiffin/components";
import { Utils } from "@tiffin/app-common";
import { ReactComponent as NoData } from '../assets/no-data.svg';

//3rd party
import moment, { Moment } from "moment";
import { Collapse, Switch } from "antd";
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const MINUTE_MS = 60000;

export const OrderList = styled(BaseOrderList)`

  .list-title {
    padding: 0px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .list-heading {
      display: flex;
      gap: 5px;
      align-items: center;
  
      span {
        font-size: medium;
        font-weight: 700;
      }
    }
  
  }

  .empty-message {
    flex-grow: 1;
    background: var(--ion-background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 8px;
    padding: 40px 0;

    svg {
      width: 75px;
      height: fit-content;
    }
  }
`

interface IOrderList {
  className?: string,
  date?: string,
  orders: IOrderExt[],
  orderStatus: OrderStatus
}

export function BaseOrderList({className, orders, date, orderStatus}: IOrderList) {

  const [activeConfirmedKeys, setActiveConfirmedKeys] = useState<string[]>([])
  const [currentTime, setCurrentTime] = useState<Moment>(moment())

  const [busyOrders, setBusyOrders] = useState<string[]>([])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentTime(moment())
    }, MINUTE_MS - moment().seconds() * 1000);

    return () => clearTimeout(timeout); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [currentTime])

  const groupedOrders = useMemo(() => {
    const nowGroup: IOrderExt[] = []
    const timeGroupedOrders = new Map<string, IOrderExt[]>();

    //Group the orders based on time
    for(const order of orders) {
      if(order.time === "now") {
        if(order.status === OrderStatus.Completed || order.status === OrderStatus.Cancelled) {
          //All completed 'now orders' should appear in the time group in which they were completed
          const timezone = moment.tz.guess();
          const orderTime = order.status === OrderStatus.Completed ? moment(order.completeTimestamp).tz(timezone) : moment(order.cancelledTimestamp).tz(timezone)
          orderTime.set({
            seconds: 0
          })

          //Round to next 15 minute
          const minRemainder = 15 - (orderTime.minute() % 15);
          if(minRemainder < 15) {
            orderTime.add(minRemainder, 'minute')
          }

          //Add to time group
          const group = timeGroupedOrders.get(orderTime.format());
          if(group) {
            group.push(order);
          } else {
            timeGroupedOrders.set(orderTime.format(), [order]);
          }
        } else {
          //Only pushed outstanding 'now' orders to 'now' group
          nowGroup.push(order)
        }
      } else {
        const group = timeGroupedOrders.get(order.time);
        if(group) {
          group.push(order);
        } else {
          timeGroupedOrders.set(order.time, [order]);
        }
      }
    }

    const sortedTimes = Array.from(timeGroupedOrders.keys()).sort((a,b) => moment(a).valueOf() - moment(b).valueOf())
    if(nowGroup.length > 0) {
      //Insert 'now' group just before the current time
      const insertIndex = sortedTimes.findIndex((time) => moment(time) > currentTime)

      if(insertIndex !== -1) {
        sortedTimes.splice(insertIndex, 0, "now")
      } else {
        sortedTimes.push("now")
      }
      
      //Add 'now' group to grouped orders
      timeGroupedOrders.set("now", nowGroup)
    }
  
    return {
      times: sortedTimes,
      groups: timeGroupedOrders
    }
  },[orders, currentTime])

  function getGroupedTitle(time: string) {
    if(time === "now") {
      return "ASAP"
    } else {
      return moment(time).format('h:mm a')
    }
  }

  function renderGroupedOrders(groupedOrders: {
    times: string[];
    groups: Map<string, IOrderExt[]>;
  }) {
    let children: React.ReactNode[] = [];

    //Insert only for card list showing today's orders
    let insertNow = moment(date).isSame(moment(), 'day') ? true : false;
    for(let time of groupedOrders.times) {
      if(insertNow && (time === "now" || moment(time) > currentTime)) {
        children.push(
        <div key="now-indicator" className="current-time-indicator">
          <div className="left-divider"></div>
          <div className="blob"></div>
          <span>Now ({currentTime.format('hh:mm a')})</span>
          <div className="right-divider"></div>
        </div>)
        insertNow = false; //Only need to insert now indicator once
      }

      children.push(
        <div className="group-heading" key={time}>
          {getGroupedTitle(time)}
        </div>
      )
      const orders = groupedOrders.groups.get(time)
      if(orders) {
        for(let order of orders) {
          children.push(
            <Panel className="partner-order-card" header={<OrderCardHeader order={order} busy={!!busyOrders.find((i) => i === order.id) && !activeConfirmedKeys.find((i) => i === order.id)} />} key={order.key}>
              <OrderCard 
                order={order} 
                orderBusyCallback={(id) => setBusyOrders((val) => [...val, id])}
                orderChangeCallback={(id) => setBusyOrders((val) => val.filter((i) => i !== id))}
                compact={true}
              />
            </Panel>
          )
        }
      }
    }
    return children;
  }

  function handleExpandToggle(expand: boolean) {
    if(expand) {
      setActiveConfirmedKeys((orders.map((order) => order.key)))
    } else {
      setActiveConfirmedKeys([])
    }
  }

  return (
    <div
      className={className}
    >
      <div className="list-title">
        <div className="list-heading">
          <PulseBlob colorrgb={Utils.OrderStatusColourRGB(orderStatus)} />
          <span>{Utils.OrderStatusText(orderStatus)}</span>
        </div>
        <Switch size="small" onChange={handleExpandToggle} disabled={orders.length === 0} checked={orders.length !== 0 && activeConfirmedKeys.length >= orders.length} />
      </div>
      <div className="partner-order-card-list">
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          expandIconPosition="right"
          activeKey={activeConfirmedKeys}
          onChange={(e) => setActiveConfirmedKeys(Array.isArray(e) ? e : [e])}
        >
          {
            !!groupedOrders && renderGroupedOrders(groupedOrders)
          }
        </Collapse>
        {
          orders.length === 0 &&
          <div className="empty-message">
            <NoData />
            <span>There are currently no orders</span>
          </div>
        }
      </div>
    </div>
  )
}