import { db } from "../firebase";
import { deleteField, doc, updateDoc } from "firebase/firestore";
export function addSpecialInstructionsNoteToOrder(id, note) {
    return new Promise(function (resolve) {
        var cartRef = doc(db, "orders", id);
        updateDoc(cartRef, {
            specialInstructions: note
        }).then(function () {
            resolve(null);
        });
    });
}
export function removeSpecialInstructionsFromOrder(id) {
    return new Promise(function (resolve) {
        var cartRef = doc(db, "orders", id);
        updateDoc(cartRef, {
            specialInstructions: deleteField()
        }).then(function () {
            resolve(null);
        });
    });
}
