import styled from "styled-components";

import { IonChip, IonIcon } from "@ionic/react"
import { useAppDispatch, useAppSelector } from "../hooks"
import { pause, play } from "ionicons/icons";
import { updatePauseDates } from "@tiffin/app-common/lib/reducers/partnerSlice";
import { cutoffDate, DateFormat } from "@tiffin/core";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Button } from "antd";

export const PauseOrders = styled(BasePauseOrders)`
  display: flex;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  gap: 5px;

  .pause-btn {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`

interface IPauseOrders {
  className?: string,
  date: string
}

export function BasePauseOrders({className, date}: IPauseOrders) {
  
  const pauseDates = useAppSelector((state) => state.partner.pauseDates)
  const partnerAdvance = useAppSelector((state) => state.partner.advance)
  const partnerPickup = useAppSelector((state) => state.partner.pickup)
  const partnerDelivery = useAppSelector((state) => state.partner.delivery)
  const adjustedDates = useAppSelector((state) => state.partner.adjustedDates)

  const [acceptingOrders, setAcceptingOrders] = useState(false)

  const timerRef = useRef<number>();

  useEffect(() => {
    const isPickupEnabled = () => {
      const pickupDate = moment(date, DateFormat)
      const orderDays = partnerPickup?.pickupDays
      const adjustedDate = adjustedDates?.[date]?.pickupTimings
  
      if (partnerPickup?.offerPickup && partnerAdvance && orderDays && orderDays[pickupDate.day()]) {
        const cutOff = cutoffDate(
          pickupDate,
          orderDays[pickupDate.day()],
          partnerAdvance.preparationEnabled,
          partnerAdvance.preparationDurationType,
          partnerAdvance.preparationDuration,
          adjustedDate,
          partnerAdvance.preparationInMins
        )
  
        if(moment() < cutOff) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    const isDeliveryEnabled = () => {
      const deliveryDate = moment(date, DateFormat)
      const orderDays = partnerDelivery?.deliveryDays
      const adjustedDate = adjustedDates?.[date]?.deliveryTimings
  
      if (partnerDelivery?.offerDelivery && partnerAdvance && orderDays && orderDays[deliveryDate.day()]) {
        const cutOff = cutoffDate(
          deliveryDate,
          orderDays[deliveryDate.day()],
          partnerAdvance.preparationEnabled,
          partnerAdvance.preparationDurationType,
          partnerAdvance.preparationDuration,
          adjustedDate,
          partnerAdvance.preparationInMins
        )
  
        if(moment() < cutOff) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    if(timerRef.current) {
      clearInterval(timerRef.current)
    }

    const acceptingOrders = isPickupEnabled() || isDeliveryEnabled()
    setAcceptingOrders(acceptingOrders)

    timerRef.current = window.setInterval(() => {
      const acceptingOrders = isPickupEnabled() || isDeliveryEnabled()
      setAcceptingOrders(acceptingOrders)
    }, 60000)
  }, [date, partnerAdvance, partnerPickup, partnerDelivery, adjustedDates])

  const dispatch = useAppDispatch()

  const active = !pauseDates.includes(date)

  const handlePause = () => {
    if(pauseDates.includes(date)) {
      dispatch(updatePauseDates(pauseDates.filter((val: string) => val !== date)))
    } else {
      const dates = [...pauseDates]
      dates.push(date)
      dispatch(updatePauseDates(dates))
    }
  }

  return (
    <div className={className}>
      {
        acceptingOrders &&
        <Button size="small" onClick={handlePause}>
          <div className="pause-btn">
            {
              active &&
              <>
                <IonIcon icon={pause} />
              </>
            }
            {
              !active &&
              <>
                <IonIcon icon={play} />
              </>
            }
          </div>
        </Button>
      }
      {
        !acceptingOrders &&
        <IonChip color="warning">
          <span>No longer accepting orders</span>
        </IonChip>
      }
    </div>
  )
}