import { useState } from "react";
import { IOrderExt, OrderStatus, PartnerStatus } from "@tiffin/core"
import { useAppSelector } from "../hooks";

import { IonIcon, useIonRouter } from "@ionic/react";
import { Badge, Menu } from "antd";
import { homeOutline, receiptOutline, personOutline, settingsOutline, cashOutline, peopleOutline, restaurantOutline, checkmarkDoneOutline, chatboxOutline } from 'ionicons/icons';
import DoubleLeftOutlined from "@ant-design/icons/DoubleLeftOutlined"
import DoubleRightOutlined from "@ant-design/icons/DoubleRightOutlined"

import './MenuBar.scss'

interface IMenuBarProps {
  defaultCollapse: boolean,
  onCollapseChange?: (collapsed: boolean) => void, 
  status?: PartnerStatus
}

export function MenuBar(props: IMenuBarProps) {

  const orders = useAppSelector((state) => state.orders)
  const conversationsMetrics = useAppSelector((state) => state.conversationsMetrics)

  const [collapsed, setCollapsed] = useState(props.defaultCollapse)
  const router = useIonRouter()

  function handleCollapse() {
    if(props.onCollapseChange) props.onCollapseChange(!collapsed)
    setCollapsed(!collapsed)
  }

  function renderOption(icon: string, label: string, path: string, badgeCount?: number) {
    return (
    <Menu.Item key={label} className={`menu-item ${collapsed && 'menu-item-collapsed'}`} icon={<Badge count={badgeCount}><IonIcon icon={icon}/></Badge>} onClick={() => {router.push(path)}}>
      {
        !collapsed &&
        label
      }
    </Menu.Item>
    )
  }

  function getSelectedKey() {
    if(router.routeInfo.pathname.startsWith("/main/dashboard")) {
      return 'Dashboard'
    } else if(router.routeInfo.pathname.startsWith("/main/orders")) {
      return 'Orders'
    } else if(router.routeInfo.pathname.startsWith("/main/menu")) {
      return 'Menu'
    } else if(router.routeInfo.pathname.startsWith("/main/income")) {
      return 'Income'
    } else if(router.routeInfo.pathname.startsWith("/main/settings")) {
      return 'Settings'
    } else if(router.routeInfo.pathname.startsWith("/main/setup")) {
      return 'Setup'
    } else if(router.routeInfo.pathname.startsWith("/main/account")) {
      return 'Account'
    } else if(router.routeInfo.pathname.startsWith("/main/status")) {
      return 'Status'
    } else if(router.routeInfo.pathname.startsWith("/main/messages")) {
      return 'Messages'
    }
    return ''
  }

  return (
    <div className={`menu-bar ${collapsed && 'menu-bar-collapsed'}`}>
      <Menu
        selectedKeys={[getSelectedKey()]}
        mode="inline"
      >
        {
          (props.status === "unregistered" || props.status === "onboarding" || !props.status) &&
          <>
            {renderOption(peopleOutline, "Setup", "/main/setup")}
            {renderOption(personOutline, "Account", "/main/account")}
          </>
        }
        {
          (props.status === "active" || props.status === "inactive") &&
          <>
            {renderOption(homeOutline, "Dashboard", "/main/dashboard")}
            {renderOption(receiptOutline, "Orders", "/main/orders", orders.active.filter((order: IOrderExt) => order.status === OrderStatus.Unconfirmed).length)}
            {renderOption(restaurantOutline, "Menu", "/main/menu")}
            {renderOption(cashOutline, "Income", "/main/income")}
            {renderOption(chatboxOutline, "Messages", "/main/messages", conversationsMetrics.metrics.tiffinUnread)}
            {renderOption(settingsOutline, "Settings", "/main/settings")}
          </>
        }
        {
          (props.status === "pending" || props.status === "pending-delete") &&
          <>
            {renderOption(checkmarkDoneOutline, "Status", "/main/status")}
            {renderOption(personOutline, "Account", "/main/account")}
          </>
        }
        {
          (props.status === "approved") &&
          <>
            {renderOption(checkmarkDoneOutline, "Status", "/main/status")}
            {renderOption(restaurantOutline, "Menu", "/main/menu")}
            {renderOption(settingsOutline, "Settings", "/main/settings")}
          </>
        }
        <Menu.Item key={'collapse'} className={`menu-item bottom-item ${collapsed && 'menu-item-collapsed'}`} icon={collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />} onClick={handleCollapse}>
          {
            !collapsed &&
            'Collapse'
          }
        </Menu.Item>
      </Menu>
    </div>
  )
}