import styled from "styled-components";
import { OrderStatus } from "@tiffin/core";
import { Constants } from "@tiffin/app-common";

interface IOrderStatusBlob {
  className?: string,
  status: OrderStatus
}

export const OrderStatusBlob = styled(BaseOrderStatusBlob)`
  --blob-color-rgb: ${(props) => {
    if(props.status === OrderStatus.Cancelled) {
      return Constants.CANCEL_COLOR_RGB
    } else if(props.status === OrderStatus.Unconfirmed) {
      return Constants.UNCONFIRMED_COLOR_RGB
    } else if(props.status === OrderStatus.Confirmed) {
      return Constants.CONFIRMED_COLOR_RGB
    } else if(props.status === OrderStatus.Ready) {
      return Constants.READY_COLOR_RGB
    } else if(props.status === OrderStatus.Completed) {
      return Constants.COMPLETE_COLOR_RGB
    }
  }};

  background: rgb(var(--blob-color-rgb));
  border-radius: 50%;
  margin: 0.2em;
  height: 0.6em;
  width: 0.6em;

  box-shadow: 0 0 0 0 rgba(var(--blob-color-rgb), 1);
  transform: scale(1);

  ${(props) => {
    if(props.status === OrderStatus.Unconfirmed || props.status === OrderStatus.Confirmed || props.status === OrderStatus.Ready) {
      return 'animation: pulse 2s infinite;'
    }
  }}


  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(var(--blob-color-rgb), 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 0.4em rgba(var(--blob-color-rgb), 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(var(--blob-color-rgb), 0);
    }
  }
`

export function BaseOrderStatusBlob(props: IOrderStatusBlob) {
  return (<div className={props.className}></div>)
}