import { useEffect } from "react";
import { payoutsReducer } from "@tiffin/app-common"
import { PayoutCard } from "./PayoutCard";

//Redux
import { useAppDispatch, useAppSelector } from "../../hooks";

import { IonItem, IonList, IonLoading } from "@ionic/react";
import { Alert } from "antd";

const LAST_PAYOUT_LIMIT = 10

export function PayoutCardList() {

  //Redux
  const payouts = useAppSelector((state) => state.payouts)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(payoutsReducer.getPayouts(LAST_PAYOUT_LIMIT))
  }, [dispatch])

  function renderTransactionsCards() {
    let cards: JSX.Element[] = []
    for(let transaction of payouts.transactions) {
      cards.push(
        <IonItem key={transaction.id}>
          <PayoutCard {...transaction} />
        </IonItem>
      )
    }
    return cards
  }

  return (
    <div className="income-card-list">
      <Alert message={`Displaying last ${LAST_PAYOUT_LIMIT} payouts`}/>
      <IonList>
        <IonLoading isOpen={payouts.status === "updating"} spinner="crescent" />
        {renderTransactionsCards()}
      </IonList>
    </div>
  )
}