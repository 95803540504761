import { useEffect, useState } from "react";
import { MAX_ADVANCE_DAYS } from "@tiffin/core";

//3rd Party
import { Capacitor } from "@capacitor/core"
import { IonButton, IonButtons, IonContent, IonDatetime, IonHeader, IonIcon, IonModal, IonSegment, IonSegmentButton, IonTitle, IonToolbar } from "@ionic/react"
import { closeCircleOutline, timeOutline } from "ionicons/icons";
import moment from "moment";
import { PickerView } from "antd-mobile";
import { PickerColumnItem, PickerValue } from "antd-mobile/es/components/picker";

import './OrderDatePickerModal.scss';

interface IOrderDatePickerModalProps {
  title: string;
  date: string;
  isOpen: boolean;
  presentingElement?: any;
  onClose: () => void;
  onUpdate: (selectedDate: string, clear?: boolean) => void;
}

const minColumns: PickerColumnItem[] = []
for(let i = 0; i < 60; i += 15) {
  minColumns.push({
    label: i < 10 ? `0${i}` : i.toString(),
    value: i.toString()
  })
}

const hourColumns: PickerColumnItem[] = []
for(let i = 0; i < 24; i ++) {
  hourColumns.push({
    label: i < 10 ? `0${i}` : i.toString(),
    value: i.toString()
  })
}

const SCHEDULE_HOUR_BUFFER = 1;

export function OrderDatePickerModal(props: IOrderDatePickerModalProps) {

  const [selectedDate, setSelectedDate] = useState<string>(props.date === "any" ? "now" : props.date);
  const [availableHours, setAvailableHours] = useState<PickerColumnItem[]>(hourColumns)
  const [availableMinutes, setAvailableMinutes] = useState<PickerColumnItem[]>(minColumns)
  const [showDatePicker, setShowDatePicker] = useState(false)

  useEffect(() => {
    const scheduleStart = moment().add(SCHEDULE_HOUR_BUFFER, 'hour');
    const date = moment(selectedDate);
    if(selectedDate === "now" || date.isSame(scheduleStart, 'day')) {
      setAvailableHours(hourColumns.filter((col) => (parseInt(col.value as string) > scheduleStart.hour() || parseInt(col.value as string) === scheduleStart.hour() && scheduleStart.minute() <= 45)));
      if(date.hour() === scheduleStart.hour()) {
        setAvailableMinutes(minColumns.filter((col) => parseInt(col.value as string) >= scheduleStart.minute()));
      } else {
        setAvailableMinutes(minColumns);
      }
    } else {
      setAvailableHours(hourColumns);
      setAvailableMinutes(minColumns);
    }
  }, [selectedDate])

  function handleUpdate(clear?: boolean) {
    if(selectedDate !== "now") {
      const updatedDate = moment(selectedDate);
      props.onUpdate(updatedDate.format(), clear);
    } else {
      props.onUpdate(selectedDate, clear);
    }
    props.onClose();
  }

  const handleOnConfirmTime = (value: PickerValue[]) => {
    let hour, minute;
    if(value[0] !== undefined) {
      hour = parseInt(value[0] as string);
    }
    if(value[1] !== undefined) {
      minute = parseInt(value[1] as string);
    }
    const date = moment(selectedDate);
    date.set({
      hour: hour,
      minute: minute
    })
    setSelectedDate(date.format())
  }

  const handleTypeChange = (e: any) => {
    if (e.target.value === "asap") {
      setSelectedDate("now");
    } else if(e.target.value === "schedule") {
      const now = moment().add(SCHEDULE_HOUR_BUFFER, 'hour');
      //Round to next 15 minute
      const minRemainder = 15 - (now.minute() % 15);
      if(minRemainder < 15) {
        now.add(minRemainder, 'minute')
      }
      setSelectedDate(now.format())
    }
  }

  const date = selectedDate === "now" ? moment() : moment(selectedDate)

  return (
    <IonModal
      className="order-date-picker-modal"
      isOpen={props.isOpen}
      canDismiss={true}
      presentingElement={props.presentingElement}
      onWillDismiss={props.onClose}
      onDidDismiss={() => setShowDatePicker(false)}
      onWillPresent={() => setShowDatePicker(true)}
      showBackdrop={false}
      mode={Capacitor.getPlatform() === 'android' ? 'md' : 'ios'}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>{props.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={props.onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="card-modal">
          {
            showDatePicker && date.isValid() &&
            <IonDatetime
              mode="md"
              minuteValues={[0,15,30,45]}
              presentation="date"
              size="cover"
              onIonChange={(e) => {
                if(moment(e.detail.value).startOf('minute').diff(moment().startOf('minute')) <= 0) {
                  setSelectedDate("now")
                } else {
                  const updatedDate = moment(e.detail.value)
                  updatedDate.set({
                    hour: date.hour(),
                    minute: date.minute()
                  })
                  setSelectedDate(updatedDate.format())
                }
              }}
              min={moment().format()}
              max={moment().startOf('day').add(MAX_ADVANCE_DAYS, 'days').format()}
            />
          }
          {
            <div>
              {
                (selectedDate === "now" || moment(selectedDate).isSame(moment(), 'day')) &&
                <IonSegment className="time-segment" mode="ios" value={selectedDate === "now" ? "asap" : "schedule"} onIonChange={(e) => handleTypeChange(e)}>
                  <IonSegmentButton value="asap">
                    <div>ASAP</div>
                  </IonSegmentButton>
                  <IonSegmentButton value="schedule">
                    <div>Schedule</div>
                  </IonSegmentButton>
                </IonSegment>
              }
              {
                selectedDate !== "now" &&
                <div className="date-time-picker">
                  <PickerView
                    className="tiffin-time-picker"
                    columns={
                      [
                        availableHours,
                        availableMinutes
                      ]
                    }
                    onChange={handleOnConfirmTime}
                    defaultValue={[moment(selectedDate).hour().toString(), moment(selectedDate).minute().toString()]}
                  />
                </div>
              }
            </div>
          }
          <div className="btn-group">
            <IonButton onClick={() => handleUpdate()} mode="ios">
              <div className="inner-btn">
                <IonIcon icon={timeOutline} />
                {selectedDate === "now" ? "Pickup • ASAP" : selectedDate === "any" ? "No time selected" : moment(selectedDate).format("LLL")}
              </div>
            </IonButton>
            <IonButton color="danger" onClick={() => handleUpdate(true)} mode="ios">
              <div className="inner-btn">
                <IonIcon icon={closeCircleOutline} />
                <span>Clear</span>
              </div>
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonModal>
  )
}