import { useEffect, useState } from "react"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete"

import "./AddressInput.scss"

interface IAddressInputProps {
  defaultAddress?: string
  setAddress: (address: string, coords: { lat: number; lng: number }, location?: google.maps.GeocoderResult) => void
  placeholder?: string
  types?: string[]
}

export function AddressInput(props: IAddressInputProps) {
  const [inputAddress, setInputAddress] = useState<string>(props.defaultAddress ? props.defaultAddress : "")

  useEffect(() => {
    if (props.defaultAddress) {
      setInputAddress(props.defaultAddress)
    }
  }, [props.defaultAddress])

  const handleChange = async (e: any) => {
    setInputAddress(e)
  }

  function handleSelect(e: any) {
    geocodeByAddress(e).then((geoLocations) => {
      getLatLng(geoLocations[0]).then((latLng) => {
        props.setAddress(e, { lat: latLng.lat, lng: latLng.lng }, geoLocations[0])
      })
    })
  }

  //Reset address when out of focus
  function resetAddress() {
    setInputAddress(props.defaultAddress ? props.defaultAddress : "")
  }

  return (
    <PlacesAutocomplete
      value={inputAddress}
      onChange={handleChange}
      onSelect={handleSelect}
      debounce={1000}
      searchOptions={{
        componentRestrictions: {
          country: "au",
        },
        types: props.types
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="address-input" onBlur={resetAddress}>
          <input
            {...getInputProps({
              placeholder: props.placeholder
            })}
            className="ant-input"
          />
          <div className="swiper-no-swiping">
            {loading ? <div>Loading...</div> : null}
            {suggestions.map((suggestion) => {
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className: "suggestion-item",
                  })}
                  key={suggestion.placeId}
                >
                  <span>{suggestion.description}</span>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}
