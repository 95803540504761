var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { APP_ID, ARCHVIED_CATEGORY, DateFormat, OrderType, } from "@tiffin/core";
import { auth, cloudFunction, db } from "../firebase";
import { getTiffinMenu } from "./browse";
import { collection, deleteField, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import isEmpty from "lodash.isempty";
import moment from "moment";
export function getStoreCart(partnerId, isApp, authenticateStatus, tableNumber) {
    var _this = this;
    return new Promise(function (resolve, reject) {
        if (!!tableNumber) {
            //Dine in orders will ignore firebase DB carts and differ from guest session storage carts
            var cartStringify = sessionStorage.getItem("".concat(partnerId, "-dinein-cart"));
            if (cartStringify) {
                var cart = JSON.parse(cartStringify);
                if (cart.tableNumber !== tableNumber) {
                    //Update table number if there is a mismatch
                    cart.tableNumber = tableNumber;
                    sessionStorage.setItem("".concat(partnerId, "-dinein-cart"), JSON.stringify(cart));
                }
                //Set current day time to asap as dine in orders cannot be scheduled
                if (!cart.times[moment().format(DateFormat)]) {
                    cart.times[moment().format(DateFormat)] = "now";
                }
                var id = isApp ? cart.partnerId : partnerId;
                resolveStoreCart(id, cart).then(function (val) {
                    resolve(val);
                });
            }
            else {
                resolve(null);
            }
        }
        else if (authenticateStatus === "authenticated") {
            var cartsRef = doc(collection(db, "carts"), auth.currentUser.uid);
            var docRef = doc(collection(cartsRef, "stores"), isApp ? APP_ID : partnerId);
            //If session cart exists, overwrite DB
            var sessionCart = void 0;
            var cartStringify = sessionStorage.getItem("".concat(partnerId, "-cart"));
            if (cartStringify) {
                sessionCart = JSON.parse(cartStringify);
                var id_1 = isApp ? sessionCart.partnerId : partnerId;
                resolveStoreCart(id_1, sessionCart).then(function (val) {
                    updateCart(val, id_1);
                    sessionStorage.removeItem("".concat(partnerId, "-cart"));
                    resolve(val);
                });
            }
            //If no session cart, get cart from DB
            if (!sessionCart) {
                getDoc(docRef).then(function (docSnap) { return __awaiter(_this, void 0, void 0, function () {
                    var id, cart;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!docSnap.exists()) return [3 /*break*/, 2];
                                id = isApp ? docSnap.data().partnerId : partnerId;
                                return [4 /*yield*/, resolveStoreCart(id, docSnap.data())];
                            case 1:
                                cart = _a.sent();
                                if (cart.orderType === OrderType.DineIn) {
                                    //Carts in DB cannot be of type dine in
                                    resolve(null);
                                }
                                else {
                                    resolve(cart);
                                }
                                return [3 /*break*/, 3];
                            case 2:
                                resolve(null);
                                _a.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                }); });
            }
        }
        else {
            //Guest carts are stored in session storage
            var cartStringify = sessionStorage.getItem("".concat(partnerId, "-cart"));
            if (cartStringify) {
                var cart = JSON.parse(cartStringify);
                var id = isApp ? cart.partnerId : partnerId;
                resolveStoreCart(id, cart).then(function (val) {
                    resolve(val);
                });
            }
            else {
                resolve(null);
            }
        }
    });
}
/**
 * Resolves the cart by obtaining the latest menu data
 */
function resolveStoreCart(partnerId, data) {
    return __awaiter(this, void 0, void 0, function () {
        var menuDetails, items, date, _i, _a, item, update;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    //Nothing in cart - return data as is
                    if (isEmpty(data.items)) {
                        return [2 /*return*/, data];
                    }
                    return [4 /*yield*/, getTiffinMenu(partnerId)];
                case 1:
                    menuDetails = _b.sent();
                    items = {};
                    for (date in data.items) {
                        items[date] = [];
                        for (_i = 0, _a = data.items[date]; _i < _a.length; _i++) {
                            item = _a[_i];
                            items[date].push({
                                details: getMenuItemById(item.id, menuDetails.menu),
                                count: item.count,
                                modifiers: item.modifiers
                            });
                        }
                    }
                    update = {
                        items: items,
                        orderType: data.orderType,
                        times: data.times,
                        partnerId: partnerId,
                        partnerName: data.partnerName,
                        orderId: data.orderId
                    };
                    if (data.deliveryAddress) {
                        update.deliveryAddress = data.deliveryAddress;
                    }
                    if (data.tableNumber) {
                        update.tableNumber = data.tableNumber;
                    }
                    return [2 /*return*/, update];
            }
        });
    });
}
function getMenuItemById(id, menu) {
    for (var group in menu) {
        if (group !== ARCHVIED_CATEGORY) {
            for (var _i = 0, _a = menu[group].items; _i < _a.length; _i++) {
                var item = _a[_i];
                if (item.id === id) {
                    return item;
                }
            }
        }
    }
}
export function updateCartDeliveryAddress(address, id) {
    var isAuthenticatedUser = !!auth.currentUser;
    return new Promise(function (resolve) {
        var updatedAddress = __assign({}, address);
        if (isAuthenticatedUser) {
            if (!address.subpremise) {
                updatedAddress.subpremise = deleteField();
            }
            var cartRef = void 0;
            if (id) {
                var cartsRef = doc(collection(db, "carts"), auth.currentUser.uid);
                cartRef = doc(collection(cartsRef, "stores"), id);
            }
            else {
                cartRef = doc(db, "carts", auth.currentUser.uid);
            }
            setDoc(cartRef, { deliveryAddress: updatedAddress }, { merge: true }).then(function () {
                resolve(null);
            });
        }
        else {
            var cartStringify = sessionStorage.getItem("".concat(id, "-cart"));
            var existingCart = cartStringify ? JSON.parse(cartStringify) : {};
            sessionStorage.setItem("".concat(id, "-cart"), JSON.stringify(__assign(__assign({}, existingCart), { deliveryAddress: updatedAddress })));
            resolve(null);
        }
    });
}
export function updateCartOrderType(data) {
    var isAuthenticatedUser = !!auth.currentUser;
    return new Promise(function (resolve, reject) {
        var update = {
            orderType: data.orderType
        };
        if (data.orderType === OrderType.Pickup) {
            //Remove delivery address if order type is pickup
            if (isAuthenticatedUser)
                update.deliveryAddress = deleteField();
        }
        else if (data.orderType === OrderType.Delivery && data.deliveryAddress) {
            //Delivery address must be specified
            update.deliveryAddress = __assign({}, data.deliveryAddress);
            //Can not have any undefined variables passed to setDoc
            if (update.deliveryAddress && !update.deliveryAddress.subpremise) {
                delete update.deliveryAddress.subpremise;
            }
        }
        if (isAuthenticatedUser) {
            var cartRef = void 0;
            var cartsRef = doc(collection(db, "carts"), auth.currentUser.uid);
            cartRef = doc(collection(cartsRef, "stores"), data.id);
            setDoc(cartRef, update, { merge: true }).then(function () {
                resolve(null);
            }, function (error) { return console.log(error); });
        }
        else {
            var cartStringify = sessionStorage.getItem("".concat(data.id, "-cart"));
            var existingCart = cartStringify ? JSON.parse(cartStringify) : {};
            sessionStorage.setItem("".concat(data.id, "-cart"), JSON.stringify(__assign(__assign({}, existingCart), update)));
            resolve(null);
        }
    });
}
export function updateOrderTime(times, date, time, id) {
    var _this = this;
    var isAuthenticatedUser = !!auth.currentUser;
    return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
        var update, cartRef, cartsRef, cartStringify, existingCart;
        return __generator(this, function (_a) {
            if (!times) {
                times = {};
            }
            times[date] = time;
            update = {
                times: times
            };
            if (isAuthenticatedUser) {
                cartRef = void 0;
                cartsRef = doc(collection(db, "carts"), auth.currentUser.uid);
                cartRef = doc(collection(cartsRef, "stores"), id);
                updateDoc(cartRef, update).then(function () {
                    resolve(null);
                });
            }
            else {
                cartStringify = sessionStorage.getItem("".concat(id, "-cart"));
                existingCart = cartStringify ? JSON.parse(cartStringify) : {};
                sessionStorage.setItem("".concat(id, "-cart"), JSON.stringify(__assign(__assign({}, existingCart), update)));
                resolve(null);
            }
            return [2 /*return*/];
        });
    }); });
}
export function updateCart(cart, id, isDineIn) {
    var _this = this;
    return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
        var isAuthenticatedUser, update, date, id_2, cartRef, cartsRef, cartData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    isAuthenticatedUser = !!auth.currentUser;
                    update = {
                        orderType: cart.orderType
                    };
                    if (cart.partnerId) {
                        update.partnerId = cart.partnerId;
                    }
                    if (cart.partnerName) {
                        update.partnerName = cart.partnerName;
                    }
                    if (cart.deliveryAddress) {
                        update.deliveryAddress = cart.deliveryAddress;
                    }
                    if (cart.tableNumber) {
                        update.tableNumber = cart.tableNumber;
                    }
                    if (cart.items) {
                        update.items = {};
                        //Only save vital cart information
                        for (date in cart.items) {
                            update.items[date] = [];
                            for (id_2 in cart.items[date]) {
                                update.items[date].push({
                                    id: cart.items[date][id_2].details.id,
                                    count: cart.items[date][id_2].count,
                                    modifiers: cart.items[date][id_2].modifiers
                                });
                            }
                        }
                    }
                    if (cart.times) {
                        update.times = cart.times;
                    }
                    if (cart.orderId) {
                        update.orderId = cart.orderId;
                    }
                    if (!isDineIn) return [3 /*break*/, 1];
                    //Dine in orders will ignore firebase DB carts and differ from guest session storage carts
                    sessionStorage.setItem("".concat(id, "-dinein-cart"), JSON.stringify(update));
                    resolve(null);
                    return [3 /*break*/, 4];
                case 1:
                    if (!isAuthenticatedUser) return [3 /*break*/, 3];
                    cartRef = void 0;
                    cartsRef = doc(collection(db, "carts"), auth.currentUser.uid);
                    cartRef = doc(collection(cartsRef, "stores"), id);
                    return [4 /*yield*/, getDoc(cartRef)];
                case 2:
                    cartData = (_a.sent()).data();
                    //Persist the orderId if it exists
                    if (!update.orderId && (cartData === null || cartData === void 0 ? void 0 : cartData.orderId)) {
                        update.orderId = cartData.orderId;
                    }
                    if (update.orderType === OrderType.DineIn) {
                        reject("Invalid order type");
                    }
                    setDoc(cartRef, update).then(function () {
                        resolve(null);
                    });
                    return [3 /*break*/, 4];
                case 3:
                    sessionStorage.setItem("".concat(id, "-cart"), JSON.stringify(update));
                    resolve(null);
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); });
}
export function cleanupCart(id, isDineIn) {
    var isAuthenticatedUser = !!auth.currentUser;
    return new Promise(function (resolve) {
        if (isDineIn) {
            sessionStorage.removeItem("".concat(id, "-dinein-cart"));
            resolve(null);
        }
        else if (isAuthenticatedUser) {
            cloudFunction("clearCart", { id: id }).then(function (result) {
                var _a;
                if (((_a = result.data) === null || _a === void 0 ? void 0 : _a.status) === "success") {
                    resolve(null);
                }
            });
        }
        else {
            sessionStorage.removeItem("".concat(id, "-cart"));
            resolve(null);
        }
    });
}
export function storeGuestOrderId(partnerId, orderId) {
    var cartStringify = sessionStorage.getItem("".concat(partnerId, "-cart"));
    var existingCart = cartStringify ? JSON.parse(cartStringify) : {};
    existingCart.orderId = orderId;
    sessionStorage.setItem("".concat(partnerId, "-cart"), JSON.stringify(existingCart));
}
export function removeGuestCart(partnerId, date, orderId) {
    var cartStringify = sessionStorage.getItem("".concat(partnerId, "-cart"));
    var existingCart = cartStringify ? JSON.parse(cartStringify) : {};
    delete existingCart.times[date];
    delete existingCart.items[date];
    delete existingCart.orderId;
    sessionStorage.setItem("".concat(partnerId, "-cart"), JSON.stringify(existingCart));
}
export function removeDineInCart(partnerId, date, orderId) {
    var cartStringify = sessionStorage.getItem("".concat(partnerId, "-dinein-cart"));
    var existingCart = cartStringify ? JSON.parse(cartStringify) : {};
    delete existingCart.times[date];
    delete existingCart.items[date];
    delete existingCart.orderId;
    sessionStorage.setItem("".concat(partnerId, "-dinein-cart"), JSON.stringify(existingCart));
}
