import { useCallback, useEffect, useState } from "react"
import { APP_ID, IAddressExtended, IPartner, OrderType } from "@tiffin/core"
import { auth, cartMultiReducer, CartStatus, RootState, searchReducer, Utils } from "@tiffin/app-common"
import DeliveryDetailsModal from "./DeliveryDetailsModal"
import { SpringModal } from "./SpringModal"

//Redux
import { useAppDispatch } from "../hooks"
import { connect, ConnectedProps } from "react-redux"

//3rd party components
import { IonSegment, IonSegmentButton } from "@ionic/react"

import "./OrderTypeSwitch.scss"

//Steps for connected redux component
const mapState = (state: RootState) => ({
  user: state.user
})
const connector = connect(mapState, null)
type PropsFromRedux = ConnectedProps<typeof connector>

interface IOrderTypeSwitch extends PropsFromRedux {
  className?: string,
  addAddressCallback?: () => void,
  pageRef?: any,
  isPartnerWebsite?: boolean,
  partner?: IPartner,
  cart: cartMultiReducer.IStoreCart,
  cartStatus: CartStatus
}

export function OrderTypeSwitch({className, cart, cartStatus, user, addAddressCallback, pageRef, isPartnerWebsite, partner}: IOrderTypeSwitch) {
  //Redux
  const dispatch = useAppDispatch()

  //Internal states
  const [cartOrderType, setCartOrderType] = useState(cart.orderType)
  const [clearCartModalVisible, setClearCartModalVisible] = useState<boolean>(false)
  const [noAddressModalVisible, setNoAddressModalVisible] = useState<boolean>(false)
  const [unsupportedAddressModalVisible, setUnsupportedAddressModalVisible] = useState<boolean>(false)
  const [showAddressModal, setShowAddressModal] = useState<boolean>(false)

  const dispatchOrderTypeUpdate = useCallback(async (orderType: OrderType, clearCart?: boolean) => {
    let deliveryAddress: IAddressExtended | undefined = undefined

    //If delivery address does not exist, get the user's default delivery address
    if (orderType === OrderType.Delivery && user.addresses) {
      const address = cart.deliveryAddress ?? user.addresses.find((val) => val.default)
      if(address && partner) {
        //If partner (cart of partner website) then check if delivery is available to this address
        let deliveryCheck = await Utils.checkDeliveryAvailable(partner._geoloc, address.coords, partner.deliveryConfiguration)
        if(deliveryCheck) {
          deliveryAddress = address;
        } else {
          for (let address of user.addresses) {
            let deliveryCheck = await Utils.checkDeliveryAvailable(partner._geoloc, address.coords, partner.deliveryConfiguration)
            if(deliveryCheck) {
              deliveryAddress = address
            }
          }
        }
      } else if(!partner) {
        //If no partner (Tifyn app) then set the new delivery address to the default
        deliveryAddress = address
      }

      if(!auth.currentUser) {
        //Show the address modal for guests
        setShowAddressModal(true)
        return;
      } else {
        if(!deliveryAddress) {
          setUnsupportedAddressModalVisible(true)
          return;
        }
      }
    }

    dispatch(
      cartMultiReducer.updateOrderType({
        orderType: orderType,
        deliveryAddress: deliveryAddress,
        clearCart: clearCart,
        id: isPartnerWebsite ? partner.id : APP_ID
      })
    )
    if(!isPartnerWebsite) {
      dispatch(searchReducer.orderTypeChange(true))
    }
  }, [cart.deliveryAddress, user.addresses, isPartnerWebsite, dispatch])
  
  useEffect(() => {
    //Updating the internal cart order type state
    setCartOrderType(cart.orderType)
  }, [cart.orderType])

  function handleOrderTypeSwitch(e: any) {

    //The switch has not changed, so do not process
    let orderType: OrderType = OrderType.Pickup
    if (e.target.value === "pickup") {
      orderType = OrderType.Pickup
    } else if (e.target.value === "delivery") {
      orderType = OrderType.Delivery
    }

    if(orderType === cart.orderType) {
      return;
    }

    if (!isPartnerWebsite && orderType === OrderType.Delivery && (!user.addresses || user.addresses.length === 0)) {
      //user has no delivery addresses, prompt user to add one before continuing
      setNoAddressModalVisible(true)
    } else {
      setCartOrderType(orderType)
      changeOrderType(orderType)
    }

    e.preventDefault()
  }

  function getDeliveryAddress() {
    let deliveryAddress: IAddressExtended | undefined = undefined
    if(cart.deliveryAddress) {
      deliveryAddress = cart.deliveryAddress
    } else {
      //If delivery address does not exist, get the user's default delivery address
      if (!cart.deliveryAddress && user.addresses) {
        for (let address of user.addresses) {
          if (address.default) {
            deliveryAddress = address
          }
        }
      }
    }
    return deliveryAddress;
  }

  function changeOrderType(orderType: OrderType) {
    if(cart.items && Object.keys(cart.items).length > 0) { 
      if(orderType === OrderType.Pickup && !partner?.offerPickup) {
        //Changing to pickup, but cart contains items from a store that does not offer pickup
        setClearCartModalVisible(true)
        return;
      }
    }

    //Check if items are available during the new order days
    if(cart.items && !Utils.cartItemsCheck(partner, cart.items, orderType)) {
      //Cannot switch as the cart contains dates that the store is not available
      setClearCartModalVisible(true)
      return
    }

    dispatchOrderTypeUpdate(orderType)
    if (clearCartModalVisible) setClearCartModalVisible(false)
  }

  function clearCart() {
    setClearCartModalVisible(false)
    let newOrderType = cart.orderType === OrderType.Pickup ? OrderType.Delivery : OrderType.Pickup
    dispatchOrderTypeUpdate(newOrderType, true)
  }

  function handleCancel() {
    setCartOrderType(cart.orderType)
    setClearCartModalVisible(false)
  }

  function renderClearCartConfirmation() {
    return (
      <SpringModal
        title="Start new order?"
        isOpen={clearCartModalVisible}
        onOk={() => clearCart()}
        onCancel={() => handleCancel()}
        okText="Continue"
      >
        <div className="clear-cart-text">
          You currently have items in your cart for <b>{cart.orderType === OrderType.Pickup ? "pickup" : "delivery"}</b>. The cart will be cleared if
          you switch to <b>{cart.orderType === OrderType.Pickup ? "delivery" : "pickup"}</b>?
        </div>
      </SpringModal>
    )
  }

  function handleAddAddress() {
    if(addAddressCallback) {
      addAddressCallback();
    } else {
      setShowAddressModal(true)
    }
    setNoAddressModalVisible(false)
  }

  function cancelAddAddress() {
    setCartOrderType(cart.orderType)
    setNoAddressModalVisible(false)
  }

  function renderAddNewAddressModal() {
    return (
      <SpringModal
        title="Add delivery address"
        isOpen={noAddressModalVisible}
        okText="Add address"
        onCancel={() => cancelAddAddress()}
        onOk={() => handleAddAddress()}
      >
        You do not have any saved delivery addresses, please add an address to continue
      </SpringModal>
    )
  }

  function handleChangeAddress() {
    setShowAddressModal(true)
    setUnsupportedAddressModalVisible(false)
  }

  function cancelChangeAddress() {
    setCartOrderType(cart.orderType)
    setUnsupportedAddressModalVisible(false)
  }

  function renderUnsupportedAddressModal() {
    const deliveryAddress = getDeliveryAddress()

    if(deliveryAddress && partner) {
      let maxDistanceMeters = partner.deliveryConfiguration?.baseDistanceMeters ?? 0
      if(partner.deliveryConfiguration?.tiers) {
        for(const tier of partner.deliveryConfiguration?.tiers) {
          maxDistanceMeters += tier.distanceMeters
        }
      }

      return (
        <SpringModal
          title="Change delivery address"
          isOpen={unsupportedAddressModalVisible}
          okText="Change address"
          onCancel={() => cancelChangeAddress()}
          onOk={() => handleChangeAddress()}
        >
          <span>Your current delivery address, <b>{Utils.formatAddress(deliveryAddress, false)}</b> is not within the delivery range for {partner.name}.</span>
          <span>Delivery is available up to <b>{maxDistanceMeters/1000} kilometres away</b> from store</span>
        </SpringModal>
      )
    }
  }

  function handleAddressChange() {
    setShowAddressModal(false);
  }

  return (
    <>
      <IonSegment className={`order-type-switch ${className ?? ""}`} mode="ios" value={cartOrderType === OrderType.Pickup ? "pickup" : "delivery"} onIonChange={(e) => handleOrderTypeSwitch(e)}>
        <IonSegmentButton className="order-type-btn" value="pickup">
          <div className="order-type-label">Pickup</div>
        </IonSegmentButton>
        <IonSegmentButton className="order-type-btn" value="delivery">
          <div className="order-type-label">Delivery</div>
        </IonSegmentButton>
      </IonSegment>
      {renderClearCartConfirmation()}
      {renderAddNewAddressModal()}
      {renderUnsupportedAddressModal()}
      <DeliveryDetailsModal
        isOpen={showAddressModal}
        onCancel={() => {
          setShowAddressModal(false)
          setCartOrderType(cart.orderType)
        }}
        presentingElement={pageRef}
        hideDatePicker={true}
        onAddressChange={handleAddressChange}
        cart={cart}
        cartStatus={cartStatus}
        partner={partner}
        isPartnerWebsite={isPartnerWebsite}
      />
    </>
  )
}

export default connector(OrderTypeSwitch);