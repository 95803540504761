import { useEffect } from "react";

//Redux
import { useAppDispatch, useAppSelector } from "../hooks"
import { partnerReducer } from "@tiffin/app-common";

//3rd party
import { IonButton, IonContent, IonPage, useIonRouter } from '@ionic/react';
import SwiperCore, { Pagination } from 'swiper';
import { Button } from "antd";
import { Browser } from "@capacitor/browser";

import './Setup.scss';

export function Setup() {
  SwiperCore.use([Pagination]);

  //Redux states
  const partner = useAppSelector((state) => state.partner)

  const dispatch = useAppDispatch()
  const router = useIonRouter()

  //Fetch the partner - only if not already fetched
  useEffect(() => {
    if (partner.status === "unknown") {
      dispatch(partnerReducer.getPartner())
    } else if (partner.status === "unregistered") {
      dispatch(partnerReducer.resetError()) //Clear error as user is not registered
    }
  }, [dispatch, partner.status])

  function renderGetStarted() {
    return (
      <div className="tiffin-application">
        <div className="tiffin-logo">
          <img alt="Tifyn Logo" src={`${process.env.PUBLIC_URL}/assets/images/tifyn-partner-icon.png`} />
        </div>  
        <h2>Welcome Partner!</h2>
        <span>Interested in seting up your own online store, fully equipped with customisable menus, flexibile options and online payments.</span>
        <span>Become a partner today and start your journey.</span>
        <IonButton size="large" onClick={() => router.push('/partner-onboarding')}>Get started</IonButton>
        <Button type="link" onClick={() => {Browser.open({ url: 'https://tifyn.com.au/partner.html' })}}>Learn more</Button>
      </div>
    )
  }

  function renderContinue() {
    return (
      <div className="tiffin-application">
        <div className="tiffin-logo">
          <img alt="Tifyn Logo" src={`${process.env.PUBLIC_URL}/assets/images/tifyn-partner-icon.png`} />
        </div>
        <h2>Welcome Partner!</h2>
        <span>Your application to become a Tifyn Partner is incomplete</span>
        <IonButton size="large" onClick={() => router.push('/partner-onboarding')}>Continue application</IonButton>
        <Button type="link" onClick={() => {Browser.open({ url: 'https://tifyn.com.au/partner.html' })}}>Learn more</Button>
      </div>
    )
  }

  return (
    <IonPage className="setup">
      <IonContent fullscreen>
        {partner.status === "unregistered" &&
          renderGetStarted()
        }
        {partner.partnerStatus === "onboarding" &&
          renderContinue()
        }
      </IonContent>
    </IonPage>
  )
}