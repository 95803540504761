import { useEffect, useRef, useState } from "react";
import { ARCHVIED_CATEGORY, AvailabilityType, DateFormat, Day, DietaryAttribute, IMenuItem, IModifier, OutOfStockType } from "@tiffin/core"
import { partnerReducer } from "@tiffin/app-common";
import { SpringModal, toast } from "@tiffin/components";
import { UploadChangeParam } from "antd/lib/upload";

//Redux
import { KeyboardState, useAppDispatch, useAppSelector, useKeyboardVisibility } from "../../hooks";

//3rd party
import UploadOutlined from "@ant-design/icons/UploadOutlined"
import { Capacitor } from "@capacitor/core";
import { v4 as uuidv4 } from "uuid"
import { IonButton, IonChip, IonIcon, IonLabel, IonSelect, IonSelectOption, IonToggle, useIonToast } from "@ionic/react";
import { Button, Collapse, DatePicker, Divider, Input, InputNumber, Select, Upload, UploadFile } from "antd";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined"
import TextArea from "antd/lib/input/TextArea";
import { arrowDown, arrowUp, closeCircle } from "ionicons/icons";
import moment from "moment";
import { cloneDeep } from "lodash";
import { Dayjs } from "dayjs";

import { ModifierList } from "./ModifierList";
import { ModifierSelect } from "./ModifierSelect";
import { ReactComponent as VeganSVG } from '../../assets/vegan-icon.svg';
import { ReactComponent as VegetarianSVG } from '../../assets/vegetarian-icon.svg';
import { ReactComponent as GlutenFreeSVG } from '../../assets/gluten-free.svg';
import { ReactComponent as HalalSVG } from '../../assets/halal.svg';
import { ReactComponent as KosherSVG } from '../../assets/kosher.svg';
import { ReactComponent as NutFreeSVG } from '../../assets/nut-free.svg';
import { ReactComponent as LactoseFree } from '../../assets/lactose-free.svg';

import './MenuItemEditor.scss'

const { Panel } = Collapse;

interface IMenuItemEditorProps {
  category: string,
  reorderItem?: (category: string, direction: 'up' | 'down') => void
  item?: IMenuItem,
  newItem?: boolean,
  onAddedNew?: (id: string) => void,
  onCancelNew?: () => void,
  onArchivedItem?: () => void,
  menuCategories?: string[],
  hideArchive?: boolean
}

let options = [
  { label: 'Sunday', value: 0, disabled: true },
  { label: 'Monday', value: 1, disabled: true },
  { label: 'Tuesday', value: 2, disabled: true },
  { label: 'Wednesday', value: 3, disabled: true },
  { label: 'Thursday', value: 4, disabled: true },
  { label: 'Friday', value: 5, disabled: true },
  { label: 'Saturday', value: 6, disabled: true }
];

let dietaryOptions = [
  { label: <div className="dietary-option-select"><VegetarianSVG className="dietary-icon" /> Vegetarian</div>, value: DietaryAttribute.Vegetarian},
  { label: <div className="dietary-option-select"><VeganSVG className="dietary-icon" /> Vegan</div>, value: DietaryAttribute.Vegan},
  { label: <div className="dietary-option-select"><GlutenFreeSVG className="dietary-icon" /> Gluten Free</div>, value: DietaryAttribute.GlutenFree},
  { label: <div className="dietary-option-select"><HalalSVG className="dietary-icon" /> Halal</div>, value: DietaryAttribute.Halal},
  { label: <div className="dietary-option-select"><KosherSVG className="dietary-icon" /> Kosher</div>, value: DietaryAttribute.Kosher},
  { label: <div className="dietary-option-select"><NutFreeSVG className="dietary-icon" /> Nut Free</div>, value: DietaryAttribute.NutFree},
  { label: <div className="dietary-option-select"><LactoseFree className="dietary-icon" /> Lactose Free</div>, value: DietaryAttribute.LactoseFree},
]

export function MenuItemEditor({category, reorderItem, item, newItem, onAddedNew, onCancelNew, onArchivedItem, menuCategories, hideArchive}: IMenuItemEditorProps) {

  //Redux
  const pickupDays = useAppSelector((state) => state.partner.pickup?.pickupDays)
  const deliveryDays = useAppSelector((state) => state.partner.delivery?.deliveryDays)
  const isRestaurant = useAppSelector((state) => state.partner.details?.isRestaurant)

  //Internal
  const [name, setName] = useState<string | undefined>(item?.name)
  const [image, setImage] = useState<UploadFile<any> | null>(null)
  const [imageText, setImageText] = useState<string>() //Only used for new items
  const [description, setDescription] = useState<string | undefined>(item?.description)
  const [price, setPrice] = useState(item?.price)
  const [availabilityType, setAvailablityType] = useState<AvailabilityType>(item?.availabilityType ?? AvailabilityType.Everyday)
  const [availability, setAvailability] = useState(item?.availability)
  const [outOfStockType, setOutOfStockType] = useState(item?.outOfStockType ? item.outOfStockType : OutOfStockType.None)
  const [outOfStockDates, setOutOfStockDates] = useState(item?.outOfStockType === OutOfStockType.SpecificDates && item.outOfStockDates ? item.outOfStockDates : [])
  const [dietaryAttributes, setDietaryAttributes] = useState<DietaryAttribute[]>(item?.dietaryAttributes ?? [])
  const [itemModifiers, setItemModifiers] = useState(item?.modifiers ?? [])

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showArchiveModal, setShowArchiveModal] = useState(false)

  const priceRef = useRef<HTMLInputElement>(null)
  const actionRef = useRef<'archive' | 'delete'>()
  const deleteImageRef = useRef<boolean>(false)

  const dispatch = useAppDispatch()
  const [present] = useIonToast()
  const keyboardVisibility = useKeyboardVisibility()

  for(let day in pickupDays) {
    options[Number(day)].disabled = false
  }

  for(let day in deliveryDays) {
    options[Number(day)].disabled = false
  }

  useEffect(() => {
    //1. Use image text to display latest pending image
    //2. If no pending image, use existing image
    //3. If no existing image, do not display any image
    if(imageText) {
      fetch(imageText).then((res) => {
        setImage({
          uid: "-1",
          name: "",
          status: "done",
          url: res.url,
          thumbUrl: res.url
        })
      })
    } else if(item?.imageText) {
      fetch(item?.imageText).then((res) => {
        setImage({
          uid: "-1",
          name: "",
          status: "done",
          url: res.url,
          thumbUrl: res.url
        })
      })
    } else if(item?.imageUrl) {
      setImage({
        uid: "-1",
        name: "",
        status: "done",
        url: item?.imageUrl,
        thumbUrl: item?.imageUrl,
      })
    }
  }, [imageText, item?.imageText, item?.imageUrl])

  useEffect(() => {
    if(!showArchiveModal && actionRef.current === 'archive') {
      if(item?.id && category !== ARCHVIED_CATEGORY) {
        dispatch(partnerReducer.archiveItem({
          category: category,
          itemId: item.id 
        }))
        if(onArchivedItem) onArchivedItem()
      }
      actionRef.current = undefined;
    }
  }, [showArchiveModal, item?.id, category, onArchivedItem, dispatch])

  useEffect(() => {
    if(!showDeleteModal && actionRef.current === 'delete') {
      if(item?.id) {
        if(category !== ARCHVIED_CATEGORY) {
          dispatch(partnerReducer.deleteItem({
            category: category,
            itemId: item.id
          }))
        } else if(category === ARCHVIED_CATEGORY) {
          dispatch(partnerReducer.deleteArchiveItem({
            itemId: item.id
          }))
        }
      }
      actionRef.current = undefined;
    }
  }, [showDeleteModal, item?.id, category, dispatch])

  function handleNameChange(name: string) {
    if(item) {
      dispatchUpdate(item.id, name, description, price, availabilityType, availability, outOfStockType, outOfStockDates, dietaryAttributes, itemModifiers)
    }
    setName(name)
  }

  function handleDescriptionChange(description: string) {
    if(item) {
      dispatchUpdate(item.id, name, description, price, availabilityType, availability, outOfStockType, outOfStockDates, dietaryAttributes, itemModifiers)
    }
    setDescription(description)
  }

  function handlePriceChange(price: number) {
    if(item) {
      dispatchUpdate(item.id, name, description, price, availabilityType, availability, outOfStockType, outOfStockDates, dietaryAttributes, itemModifiers)
    }
    setPrice(price)
  }

  function handleDietaryAttributeChange(attributes: DietaryAttribute[]) {
    if(item) {
      dispatchUpdate(item.id, name, description, price, availabilityType, availability, outOfStockType, outOfStockDates, attributes, itemModifiers)
    }
    setDietaryAttributes(attributes)
  }

  function handleAvailabilityTypeChange(availabilityType: AvailabilityType) {
    if(item) {
      dispatchUpdate(item.id, name, description, price, availabilityType, availabilityType === AvailabilityType.Custom ? availability : undefined, outOfStockType, outOfStockDates, dietaryAttributes, itemModifiers)
    }
    if(availabilityType === AvailabilityType.Everyday) {
      setAvailability(undefined)
    }
    setAvailablityType(availabilityType)
  }

  function handleAvailabilityChange(availability: Day[]) {
    if(item) {
      dispatchUpdate(item.id, name, description, price, availabilityType, availability, outOfStockType, outOfStockDates, dietaryAttributes, itemModifiers)
    }
    setAvailability(availability)
  }

  function handleOutOfStockChange(outOfStock: boolean) {
    if(item) {
      if(outOfStock) {
        let dates = [(moment().format(DateFormat))]
        dispatchUpdate(item.id, name, description, price, availabilityType, availability, OutOfStockType.EndOfDay, dates, dietaryAttributes, itemModifiers)
        setOutOfStockType(OutOfStockType.EndOfDay)
        setOutOfStockDates(dates)
      } else if(!outOfStock) {
        dispatchUpdate(item.id, name, description, price, availabilityType, availability, OutOfStockType.None, [], dietaryAttributes, itemModifiers)
        setOutOfStockType(OutOfStockType.None)
        setOutOfStockDates([])
      }
    }
  }
  
  function handleOutOfStockTypeChange(outOfStockType: OutOfStockType) {
    if(item) {
      if(outOfStockType === OutOfStockType.EndOfDay) {
        let dates = [(moment().format(DateFormat))]
        dispatchUpdate(item.id, name, description, price, availabilityType, availability, outOfStockType, dates, dietaryAttributes, itemModifiers)
        setOutOfStockDates(dates)
      } else if(outOfStockType === OutOfStockType.SpecificDates) {
        dispatchUpdate(item.id, name, description, price, availabilityType, availability, outOfStockType, [], dietaryAttributes, itemModifiers)
        setOutOfStockDates([])
      } else if(outOfStockType === OutOfStockType.None) {
        dispatchUpdate(item.id, name, description, price, availabilityType, availability, outOfStockType, [], dietaryAttributes, itemModifiers)
        setOutOfStockDates([])
      }
      setOutOfStockType(outOfStockType)
    }
  }

  function handleOutOfStockDates(value: Dayjs | null) {
    if(value === null) {
      return
    }

    let dates = cloneDeep(outOfStockDates)
    let newDate = value.format(DateFormat)
    
    if(dates.includes(newDate)) {
      //Do not proceed if the date is already included
      return
    }

    dates.push(newDate)
    if(item) {
      dispatchUpdate(item.id, name, description, price, availabilityType, availability, outOfStockType, dates, dietaryAttributes, itemModifiers)
    }
    setOutOfStockDates(dates)
  }

  function removeOutOfStockDate(date: string) {
    if(item) {
      let dates = cloneDeep(outOfStockDates)
      const index = dates.indexOf(date);
      if (index > -1) {
        dates.splice(index, 1);
        dispatchUpdate(item.id, name, description, price, availabilityType, availability, outOfStockType, dates, dietaryAttributes, itemModifiers)
        setOutOfStockDates(dates)
      }
    }
  }

  function handleAddModifier(modifier: IModifier) {
    const updatedModifiers = [...itemModifiers, modifier.id];
    if(item) {
      dispatchUpdate(item.id, name, description, price, availabilityType, availability, outOfStockType, outOfStockDates, dietaryAttributes, updatedModifiers)
    }
    setItemModifiers(updatedModifiers)
  }

  function handleDeleteModifier(id: string) {
    const updatedModifiers = itemModifiers.filter((val) => val !== id)
    if(item) {
      dispatchUpdate(item.id, name, description, price, availabilityType, availability, outOfStockType, outOfStockDates, dietaryAttributes, updatedModifiers)
    }
    setItemModifiers(updatedModifiers)
  }

  function dispatchUpdate(
    id: string, 
    name?: string, 
    description?: string, 
    price?: number,
    availabilityType?: AvailabilityType,
    availability?: Day[], 
    outOfStockType?: OutOfStockType,
    outOfStockDates?: string[],
    dietaryAttributes?: DietaryAttribute[],
    modifiers?: string[],
    imageText?: string
  ) {
      if(name && price && availabilityType) {
        if(category !== ARCHVIED_CATEGORY) {
          dispatch(partnerReducer.updateItem({
            category: category,
            item: {
              id: id,
              name: name,
              description: description,
              price: price,
              availabilityType: availabilityType,
              availability: availability,
              outOfStockType: outOfStockType ?? OutOfStockType.None,
              outOfStockDates: outOfStockDates,
              dietaryAttributes: dietaryAttributes,
              imageText: imageText,
              modifiers
            },
            deleteImage: deleteImageRef.current
          }))    
        } else if(category === ARCHVIED_CATEGORY) {
          dispatch(partnerReducer.updateArchiveItem({
            item: {
              id: id,
              name: name,
              description: description,
              price: price,
              availabilityType: availabilityType,
              availability: availability,
              outOfStockType: outOfStockType ?? OutOfStockType.None,
              outOfStockDates: outOfStockDates,
              dietaryAttributes: dietaryAttributes,
              imageText: imageText,
              modifiers
            },
            deleteImage: deleteImageRef.current
          }))  
        }
    }
  }

  function saveNewItem() {
    if(name && price && price > 0 && availabilityType && category !== ARCHVIED_CATEGORY) {
      const id = uuidv4()
      dispatch(partnerReducer.addItem({
        category: category,
        item: {
          id: id,
          name: name,
          description: description ?? "",
          price: price,
          availabilityType: availabilityType,
          availability: availability,
          outOfStockType: outOfStockType,
          outOfStockDates: outOfStockDates,
          dietaryAttributes: dietaryAttributes,
          imageText: imageText,
          modifiers: itemModifiers
        }
      }))
      
      if(onAddedNew) {
        onAddedNew(id)
      }
    } else if(!name) {
      toast(present, "Please enter the item's name", "danger")
    } else if(!price) {
      toast(present, "Please enter the item's price", "danger")
    } else if (price <= 0) {
      toast(present, "Please enter a valid price", "danger")
    } else if(!availability) {
      toast(present, "Please enter the item's availability", "danger")
    }
  }

  function deleteItem() {
    actionRef.current = 'delete'
    setShowDeleteModal(false)
  }

  function archiveItem() {
    actionRef.current = 'archive'
    setShowArchiveModal(false)
  }

  function unarchiveItem(e: any) {
    if(item?.id) {
      dispatch(partnerReducer.unarchiveItem({
        category: e.detail.value,
        itemId: item.id
      }))
    }
  }

  function moveItem(e: any) {
    if(item?.id && category !== ARCHVIED_CATEGORY) {
      dispatch(partnerReducer.moveItem({
        oldCategory: category,
        newCategory: e.detail.value,
        itemId: item.id
      }))
    }
  }

  function changeCoverImage(info: UploadChangeParam) {
    if (info.fileList.length === 0) {
      deleteImageRef.current = true
      setImage(null)
      if(item) {
        dispatchUpdate(item.id, name, description, price, availabilityType, availability, outOfStockType, outOfStockDates, dietaryAttributes, itemModifiers)
      }
      setImageText(undefined)
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(info.fileList[0].originFileObj as Blob);
      reader.onload = () => {
        if(reader.result && typeof(reader.result) === "string") {
          deleteImageRef.current = false
          if(item) {
            dispatchUpdate(item.id, name, description, price, availabilityType, availability, outOfStockType, outOfStockDates, dietaryAttributes, itemModifiers, reader.result)
          }
          setImageText(reader.result)
        } else {
          //TODO: better error handling here
          alert('Unexpted error')
        }
      }
      //TODO: better error handling here
      reader.onerror = error => alert(error);
    }
  }
  
  function renderCategoryOptions() {
    let options: JSX.Element[] = []
    if(menuCategories) {
      for(let unarchiveCategory of menuCategories) {
        options.push(<IonSelectOption key={unarchiveCategory} value={unarchiveCategory}>{unarchiveCategory}</IonSelectOption>)
      }
    }
    return options
  }

  function renderAvailabilityOption() {
    return (
      <div>
        <div className="subheading">Availability</div>
        <div className="item-availability">
          <Select
            value={availabilityType}
            style={{ width: 120 }}
            onChange={handleAvailabilityTypeChange}
            options={[
              { value: AvailabilityType.Everyday, label: 'Everyday' },
              { value: AvailabilityType.Custom, label: 'Custom' }
            ]}
          />
          {
            availabilityType === AvailabilityType.Custom &&
            <Select
              mode="multiple"
              placeholder="Please select days"
              value={availability}
              style={{ width: '100%' }}
              onChange={(value: Day[]) => {handleAvailabilityChange(value as Day[])}}
              options={options}
            />
          }
        </div>
        <div className="note">Available days are restricted to the pickup and/or delivery days you have set</div>
      </div>
    )
  }

  return (
    <div className="menu-item-editor-container">
      <div className="menu-item-editor">
        <div className="item-header content-padding">
          <div className="item-name">
            <Input defaultValue={name} onChange={(e) => {handleNameChange(e.target.value)}} placeholder="Item name" />
          </div>
          {
            reorderItem &&
            <div>
              <IonButton size="small" color="light" onClick={() => {if(category !== ARCHVIED_CATEGORY) reorderItem?.(category, 'up')}}>
                <IonIcon icon={arrowUp} />
              </IonButton>
              <IonButton size="small" color="light" onClick={() => {if(category !== ARCHVIED_CATEGORY) reorderItem?.(category, 'down')}}>
                <IonIcon icon={arrowDown} />
              </IonButton>
            </div>          
          }
        </div>
        <Divider className="item-divider" />
        <div className="item-content">
          <div className="content-container content-padding">
            <div className="row1">
                <Upload
                  className="menu-image-upload"
                  accept={"image/*"}
                  listType="picture-card"
                  maxCount={1}
                  fileList={image ? [image] : []}
                  onChange={changeCoverImage}
                >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
              <div>
                <div className="subheading">Description</div>
                <TextArea
                  className="item-description"
                  placeholder="Item description"
                  defaultValue={description}
                  rows={2}
                  onChange={(e) => {handleDescriptionChange(e.target.value)}}
                />
                <div className="info">A short description about the item and we suggest including the quantity.</div>
              </div>
            </div>
            <div>
              <div className="subheading">Price</div>
              <div className="item-price">
                $
                <InputNumber
                  ref={priceRef}
                  onFocus={() => priceRef.current?.scrollIntoView({ behavior: 'smooth' })}
                  placeholder="Price"
                  defaultValue={item?.price.toFixed(2)}
                  onChange={(value) => handlePriceChange(Number(value))}
                  precision={2}
                />
              </div>
            </div>
            <div>
              <div className="subheading">Dietary Attributes</div>
              <div className="dietary-attributes">
                <Select
                  mode="multiple"
                  placeholder="Please select attributes"
                  value={dietaryAttributes}
                  style={{ width: '100%' }}
                  onChange={handleDietaryAttributeChange}
                  options={dietaryOptions}
                  dropdownRender={(val) => (
                    <div className="select-dietary-dropdown">
                      {val}
                    </div>
                  )}
                  showSearch={false}
                />
              </div>
            </div>
            {
              !isRestaurant &&
              renderAvailabilityOption()
            }
            <Collapse ghost>
              <Panel className="advanced-options-panel" header="Advanced options" key="1">
                {
                  isRestaurant &&
                  renderAvailabilityOption()
                }
                <div>
                  <div className="subheading">Out of stock</div>
                  <div className="item-oos">
                    <IonToggle mode={Capacitor.getPlatform() === 'android' ? 'md' : 'ios'} checked={outOfStockType !== OutOfStockType.None} onIonChange={(e) => handleOutOfStockChange(e.detail.checked)} />
                    <IonSelect value={outOfStockType} okText="Okay" cancelText="Dismiss" onIonChange={(e) => handleOutOfStockTypeChange(e.detail.value)}>
                      <IonSelectOption value={OutOfStockType.EndOfDay}>Just today</IonSelectOption>
                      <IonSelectOption value={OutOfStockType.SpecificDates}>For specific dates</IonSelectOption>
                    </IonSelect>
                    {
                      outOfStockType === OutOfStockType.SpecificDates &&
                      <DatePicker format={() => 'Select date'} onChange={handleOutOfStockDates} />
                    }
                  </div>
                  {
                    item?.outOfStockType === OutOfStockType.SpecificDates && item.outOfStockDates && item.outOfStockDates?.length > 0 &&
                    <div className="item-oos-dates">
                      {
                        item.outOfStockDates.map((date, i) => {
                          return (
                            <IonChip key={date}>
                              <IonLabel>{date}</IonLabel>
                              <IonIcon icon={closeCircle} onClick={() => removeOutOfStockDate(date)} />
                            </IonChip>
                          )
                        })
                      }
                    </div>
                  }
                </div>
                <div>
                  <div className="subheading">Modifiers</div>
                  <div className="modifiers-content">
                    <ModifierSelect modifiers={itemModifiers} onChange={handleAddModifier} />
                    <ModifierList modifiers={itemModifiers} onDelete={handleDeleteModifier} />
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
          {
            !newItem &&
            <div className={`item-footer ${keyboardVisibility === KeyboardState.HIDDEN || keyboardVisibility === KeyboardState.WILL_HIDE ? 'fadeIn' : 'fadeOut2'}`}>
              <Button danger type="primary" onClick={() => setShowDeleteModal(true)} icon={<DeleteOutlined />}></Button>
              {
                category !== ARCHVIED_CATEGORY &&
                <div className="right-footer-buttons">
                  <IonSelect className="move-selector" interfaceOptions={{header: "Select a menu category"}} onIonChange={moveItem} okText="Move" cancelText="Cancel" placeholder="Move item">
                    {renderCategoryOptions()}
                  </IonSelect>
                  {
                    !hideArchive &&
                    <Button onClick={() => setShowArchiveModal(true)}>Archive Item</Button>
                  }
                </div>
              }
              {
                category === ARCHVIED_CATEGORY &&
                <IonSelect className="archive-category-selector" interfaceOptions={{header: "Select a menu category"}} onIonChange={unarchiveItem} okText="Unarchive" cancelText="Cancel" placeholder="Select unarchive category">
                  {renderCategoryOptions()}
                </IonSelect>
              }
            </div>
          }
          {
            newItem &&
            <div className={`item-footer ${keyboardVisibility === KeyboardState.HIDDEN || keyboardVisibility === KeyboardState.WILL_HIDE ? 'fadeIn' : 'fadeOut'}`} style={{position: "sticky", bottom: "0px"}}>
              <Button onClick={() => onCancelNew && onCancelNew()}>Cancel</Button>
              <Button type="primary" onClick={saveNewItem}>Add item</Button>
            </div>
          }
        </div>
        <SpringModal 
          title="Delete Item"
          isOpen={showDeleteModal}
          onOk={() => deleteItem()}
          okText="Yes"
          onCancel={() => setShowDeleteModal(false)}
        >
          Are you sure you want to delete this item?
        </SpringModal>
        <SpringModal 
          title="Archive Item?"
          isOpen={showArchiveModal}
          onOk={() => archiveItem()}
          okText="Yes"
          onCancel={() => setShowArchiveModal(false)}
        >
          Are you sure you want to archive this item
        </SpringModal>
      </div>
    </div>
  )
}