import styled from "styled-components";
import { useAppSelector } from "../../hooks";
import { RightOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import PlusOutlined from "@ant-design/icons/PlusOutlined"
import { SpringModal } from "@tiffin/components";
import { useState } from "react";
import { ModifierForm } from "./ModifierForm";
import { IModifier } from "@tiffin/core";
import { IonIcon } from "@ionic/react";
import { createOutline } from "ionicons/icons";

export const MenuEditModifiers = styled(BaseMenuEditModifiers)`
  display: grid;
  grid-template-rows: 0 100%;
  background: var(--ion-color-light);
  padding: 10px;
  min-height: 100%;
  height: 100%;
  overflow: auto;

  .modifier-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .modifier {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 20px;
    border-radius: 8px;

    .name {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      ion-icon {
        font-size: 1.6em;
      }
    }
  }
`

interface IMenuEditModifiers {
  className?: string,
  onboardingForm?: boolean
}

function BaseMenuEditModifiers({className}: IMenuEditModifiers) {

  const modifiers = useAppSelector((state) => state.partner.modifiers)

  const [ showNewModifierModal, setShowNewModifierModal ] = useState(false)
  const [ showEditModifierModal, setShowEditModifierModal ] = useState(false)
  const [ selectedModifier, setSelectedModifier ] = useState<IModifier>()

  function openEditModifierModal(modifier: IModifier) {
    setSelectedModifier(modifier)
    setShowEditModifierModal(true)
  }

  return (
    <div className={className}>
      <FloatButton type="primary" style={{position: "sticky", left: "calc(100% - 50px)", top: "calc(100% - 50px)"}} icon={<PlusOutlined />} onClick={() => setShowNewModifierModal(true)}></FloatButton>
      <div className="modifier-list">
        {
          modifiers.map((modifier) => (
            <div className="modifier" onClick={() => openEditModifierModal(modifier)}>
              <div className="name">
                <IonIcon icon={createOutline}/>
                {modifier.name}
              </div>
              <RightOutlined />
            </div>
          ))
        }
      </div>
      <SpringModal
        top={20}
        showClose={true}
        title="Edit modifier"
        isOpen={showEditModifierModal}
        onClose={() => setShowEditModifierModal(false)}
      >
        <div style={{paddingTop: "10px"}}>
          <ModifierForm modifier={selectedModifier} onChange={() => setShowEditModifierModal(false)} onDelete={() => setShowEditModifierModal(false)} />
        </div>
     </SpringModal>
      <SpringModal
        top={20}
        showClose={true}
        title="Add new modifier"
        okText="Save"
        isOpen={showNewModifierModal}
        onClose={() => setShowNewModifierModal(false)}
      >
        <div style={{paddingTop: "10px"}}>
          <ModifierForm isNew={true} onChange={() => setShowNewModifierModal(false)} />
        </div>
     </SpringModal>
    </div>
  )
}