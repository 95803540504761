import { useEffect } from "react";
import { metricsReducer } from "@tiffin/app-common";
import { LoadingSpinner, toast } from "@tiffin/components";

//Redux
import { useAppDispatch, useAppSelector } from "../hooks"

//3rd party
import RedoOutlined from "@ant-design/icons/RedoOutlined"
import CaretUpOutlined from "@ant-design/icons/CaretUpOutlined"
import CaretDownOutlined from "@ant-design/icons/CaretDownOutlined"
import { IonButton, useIonToast } from "@ionic/react";
import { Card } from "antd";

import './WeeklyMetric.scss'

export function WeeklyOrders() {

  const currentWeek = useAppSelector((state) => state.metrics.currentWeek)
  const lastWeek = useAppSelector((state) => state.metrics.lastWeek)
  const status = useAppSelector((state) => state.metrics.weeklyStatus)
  const error = useAppSelector((state) => state.metrics.error)
  const dispatch = useAppDispatch()
  const [present] = useIonToast();

  useEffect(() => {
    if(status === "unknown") {
      dispatch(metricsReducer.getWeeklyMetrics())
    }
  }, [status, dispatch])

  useEffect(() => {
    if(error) toast(present, error)
  }, [error, present])

  function refreshData() {
    dispatch(metricsReducer.getWeeklyMetrics())
  }

  function renderCardHeader() {
    return (
      <>
        <span>
         Orders last 7 days
        </span>
        <div className="right-title-buttons">
          <IonButton color="light" className="refresh-btn" size="small" onClick={refreshData}>          
            <RedoOutlined />
          </IonButton>
        </div>
      </>
    )
  }

  let color = 'inherit'
  if(currentWeek && lastWeek) {
    if(currentWeek.orderCount > lastWeek.orderCount) {
      color = "var(--ion-color-success)"
    } else if(currentWeek.orderCount < lastWeek.orderCount) {
      color = "var(--ion-color-danger)"
    }
  }

  return (
    <div className="weekly-card">
      <div className={`loading-overlay ${(status === "unknown" || status === "updating") ? 'fadeIn' : 'fadeOut2'}`}>
        <LoadingSpinner />
      </div>
      <Card className="dashboard-card weekly-card" title={renderCardHeader()}>
        {
          currentWeek && lastWeek &&
          <div className="content">
            <span className="main-stat">
              {currentWeek.orderCount} Orders
            </span>
            <span className="secondary-stat">
              {
                currentWeek.orderCount > lastWeek.orderCount &&
                <CaretUpOutlined style={{color: color}} />
              }
              {
                currentWeek.orderCount < lastWeek.orderCount &&
                <CaretDownOutlined style={{color: color}} />
              }
              {
                lastWeek.orderCount > 0 &&
                <span style={{color: color}}>
                  {((currentWeek.orderCount - lastWeek.orderCount) / lastWeek.orderCount * 100).toFixed(1)}%
                </span>
              }
              Since previous 7 days
            </span>
          </div>
        }
      </Card>
    </div>
  )
}