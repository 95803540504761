import styled from "styled-components";
import { CartItemCard } from "./CartItemCard"
import { CheckoutGroupButton } from "./CheckoutGroupButton"
import { DateFormat, IPartner, OrderType } from "@tiffin/core" 
import { CartItems, cartMultiReducer } from "@tiffin/app-common";

//3rd party
import { IonIcon } from "@ionic/react"
import { addOutline } from "ionicons/icons"
import { Alert, Button, Divider } from "antd"
import moment from "moment"

export const CartDateGroup = styled(BaseCartDateGroup)`
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(128, 128, 128, 0.08) -2px 2px 2px, rgba(128, 128, 128, 0.04) 0px 2px 2px;

  .warning {
    padding: 10px;
  }

  .main-content {
    padding: 0px 20px;
    display: grid;
    grid-template-columns: 1fr auto;

    @media (min-width:768px)  {
      padding: 0px 20px;
    }

    .warning {
      grid-column: 1/3;
    }

    .cart-items {
      grid-column: 1/3;
    }

    .ant-divider-inner-text {
      padding: 0px;
    }

    .cart-group-btns {
      display: flex;
      padding-bottom: 20px;
      justify-content: space-between;

      .add-items {
        display: flex;
        align-items: center;
        gap: 5px;

        ion-icon {
          font-size: 1.2em;
        }
      }

      @media (min-width:768px)  {
        gap: 10px;
        justify-content: flex-end;
      }
    }
  }
`

export interface ICartDateGroupProps {
  className?: string,
  date: string,
  partner: IPartner,
  cart: cartMultiReducer.IStoreCart,
  addMoreItemsCallback: () => void,
  checkoutCallback: () => void,
  isPartnerWebsite: boolean,
  tableNumber?: number
}

function BaseCartDateGroup({className, date, partner, cart, addMoreItemsCallback, checkoutCallback, isPartnerWebsite, tableNumber}: ICartDateGroupProps) {

  function handleAddMoreItems() {
    addMoreItemsCallback()
  }

  function calculatePrice(item: CartItems) {
    let total = item.details.price;
    if(item.modifiers) {
      for(const selectedModifier of item.modifiers) {
        for(const option of selectedModifier.selectedOptions) {
          const optionDetails = selectedModifier.options.find((val) => val.label === option)
          total += optionDetails?.price ?? 0
        }
      }
    }
    return total;
  }

  function cartSubTotal() {
    let subTotal = 0
    if(cart.items) {
      for (let item of cart.items[date]) {
        subTotal += calculatePrice(item) * item.count
      }
    }
    return subTotal
  }

  function showMinOrderWarning(subTotal: number) {
    if(cart.orderType === OrderType.Delivery) {
      if(partner.minDeliveryOrder !== undefined && partner.minDeliveryOrder > 0) {
        if(subTotal < partner.minDeliveryOrder) {
          return true
        }
      }
    }
    return false
  }

  function renderCartItems() {
    let cartGroup: JSX.Element[] = []
    if(cart.items?.[date] && partner) {
      let index = 0;
      for (let item of cart.items[date]) {
        cartGroup.push(
          <CartItemCard key={index} index={index} partnerId={partner.id} count={item.count} {...item.details} modifiers={item.modifiers} date={date} isPartnerWebsite={isPartnerWebsite} />
        )
        index++;
      }
    }
    return cartGroup
  }

  const subTotal = cartSubTotal()
  const displayMinOrderWarning = showMinOrderWarning(subTotal)
  const isValidDeliveryAddress = cart.orderType === OrderType.Delivery ? !!cart.deliveryAddress : true
  const paused = partner.pauseDates?.includes(date)

  return (
    <div className={className}>
      {
        (paused && cart.orderType !== OrderType.DineIn) &&
        <div className="warning">
          <Alert
            type="warning"
            showIcon={true}
            message={`Sorry, we are currently not accepting new orders for this day, please check back soon.`}
          />
        </div>
      }
      <div className="main-content">
        {
          tableNumber ?
          <div>
            <Divider orientation="left">Table {tableNumber}</Divider>
          </div>
          :
          <div>
            <Divider orientation="left">{moment(date, DateFormat).format("dddd, D MMMM")}</Divider>
          </div>
        }
        <div className="cart-items">{renderCartItems()}</div>
        {
          displayMinOrderWarning && partner.minDeliveryOrder !== undefined &&
          <div className="warning">
            <Alert type="warning" message={`You do not meet the minimum order total of $${partner.minDeliveryOrder.toFixed(2)}`} />
          </div>
        }
        <div className="cart-group-btns">
          <Button onClick={() => handleAddMoreItems()}>
            <div className="add-items">
              <IonIcon icon={addOutline} /><span>Add more items</span>
            </div>
          </Button>
          {
            cart.items?.[date] &&
            <CheckoutGroupButton disabled={displayMinOrderWarning || !isValidDeliveryAddress || (paused && cart.orderType !== OrderType.DineIn)} items={cart.items[date]} checkoutCallback={checkoutCallback} />
          }
        </div>

      </div>
    </div>
  )
}