import { useMemo, useState } from "react";

import { DateFormat, IOrderExt } from "@tiffin/core";
import { OrderCardHeader } from "./OrderCardHeader";
import { OrderCard } from "./OrderCard";
import { InfiniteList } from "@tiffin/components";

//3rd party
import { Capacitor } from "@capacitor/core";
import { Collapse } from "antd";
import { CaretRightOutlined } from '@ant-design/icons';
import { IonToggle } from "@ionic/react";
import { uniq } from "lodash";
import moment from "moment";

import './OrderCardList.scss';

const { Panel } = Collapse;

interface IOrderCardList {
  orders: IOrderExt[],
  loading: boolean,
  fetchMore: () => void,
  hasMore: boolean,
  orderChangeCallback?: (id: string) => void
}

export function OrderCardListHistoric({orders, loading, fetchMore, hasMore, orderChangeCallback }: IOrderCardList) {

  const [activeKeys, setActiveKeys] = useState<string[]>([])
  const [expandedGroups, setExpandedGroups] = useState<string[]>([])

  function getExpandedRowKeys(date?: string) {
    let keys = []
    if(date) {
      const group = groupedOrders?.groups.get(date)
      if(group) {
        for(let order of group) {
          keys.push(order.key)
        }
      }
    } else {
      for(let order of orders) {
        keys.push(order.key)
      }
    }
    return keys
  }

  function handleConfirmedExpandToggle(e: any, time: string) {
    if(e.detail.checked) {
      setActiveKeys(uniq([
        ...activeKeys,
        ...getExpandedRowKeys(time)
      ]));
      if(!expandedGroups.includes(time)) {
        setExpandedGroups([
          ...expandedGroups,
          time
        ])
      }
    } else {
      const collapseOrders = getExpandedRowKeys(time)
      setActiveKeys(activeKeys.filter((key) => !collapseOrders.includes(key)));
      if(expandedGroups.includes(time)) {
        setExpandedGroups(expandedGroups.filter((group) => group !== time));
      }
    }
  }

  const groupedOrders = useMemo(() => {
    const timeGroupedOrders = new Map<string, IOrderExt[]>();

    for(const order of orders) {
      const group = timeGroupedOrders.get(order.date);
      if(group) {
        group.push(order);
      } else {
        timeGroupedOrders.set(order.date, [order]);
      }
    }

    for(const value of timeGroupedOrders.values()) {
      value.sort((a,b) => moment(a.time).valueOf() - moment(b.time).valueOf())
    }
  
    return {
      dates: Array.from(timeGroupedOrders.keys()).sort((a,b) => moment(b, DateFormat).valueOf() - moment(a, DateFormat).valueOf()),
      groups: timeGroupedOrders
    }
  },[orders])
  
  function renderGroupedOrders(groupedOrders: {
    dates: string[];
    groups: Map<string, IOrderExt[]>;
  }) {
    let children: React.ReactNode[] = [];
    for(let date of groupedOrders.dates) {
      children.push(
        <div className="group-heading" key={date}>
          {moment(date, DateFormat).format('LL')}
          <div className="expand-toggle">
            {expandedGroups.includes(date) ? 'Collapse all' : 'Expand all'}
            <IonToggle mode={Capacitor.getPlatform() === 'android' ? 'md' : 'ios'} onIonChange={(e) => {handleConfirmedExpandToggle(e, date)}}/>
          </div>
        </div>
      )
      const orders = groupedOrders.groups.get(date)
      if(orders) {
        for(let order of orders) {
          children.push(
            <Panel className="partner-order-card" header={<OrderCardHeader order={order} />} key={order.key}>
              <OrderCard order={order} orderChangeCallback={orderChangeCallback}/>
            </Panel>
          )
        }
      }
    }
    return children;
  }

  return (
    <div
      className="partner-order-card-list"
    >
      {
        orders.length > 0 &&
        <>
          <InfiniteList
            loading={loading}
            hasMore={hasMore}
            fetchMore={fetchMore}
          >
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              expandIconPosition="right"
              activeKey={activeKeys}
              onChange={(e) => setActiveKeys(Array.isArray(e) ? e : [e])}
              >
                {renderGroupedOrders(groupedOrders)}
            </Collapse>
          </InfiniteList>
        </>
      }
    </div>
  )
}