
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../hooks";

import { AdjustDateType, AvailabilityType, DateFormat, IMenuItem, IModifier, IPartner, ISelectedModifier, ModifierSelectType, OrderType, OutOfStockType, SubscriptionPlan, calculatePrice } from "@tiffin/core";
import { cartMultiReducer, Constants, Utils } from "@tiffin/app-common";
import { DatePickerModal } from "./DatePickerModal";
import { SpringModal } from "./SpringModal";

import { Alert, Button, Checkbox, Divider, Modal } from "antd";
import MinusOutlined from "@ant-design/icons/MinusOutlined"
import PlusOutlined from "@ant-design/icons/PlusOutlined"
import ClockCircleOutlined from "@ant-design/icons/ClockCircleOutlined"
import moment, { Moment } from "moment";


export const MenuItemModal = styled(BaseMenuItemModal)`
  height: calc(100% - 40px);
  padding: 0;

  &.static {
    .ant-modal-footer {
      display: none;
    }
  }

  .shake {
    animation: shake 0.2s;
    animation-iteration-count: 5;
  }

  @keyframes shake {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(3deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-3deg); }
    100% { transform: rotate(0deg); }
  }

  .ant-modal-content {
    display: grid;
    grid-template-rows: 1fr auto;
    max-height: 100%;
    overflow: auto;
    padding: 0;
  }

  .ant-modal-body {
    padding: 20px 24px 0 24px;
    overflow: auto;
  }

  .ant-modal-footer {
    padding: 20px;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.02);
    background: #fff;
  }

  .item-details {
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0;

    .item-header {
      display: grid;
      gap: 20px;

      .item-image{
        img {
          height: 100px;
          width: 100px;
          object-fit: cover;
          border-radius: 20px;
        }
      }

      .item-info {
        display: flex;
        flex-direction: column;
      }

      .item-title {
        font-size: var(--font-size-large);
        font-weight: 600;
      }

      .item-description {
        font-size: var(--font-size-regular);
      }
    }

    .ant-picker {
      visibility: hidden;
      width: 0px;
      padding: 0px;
      border: 0px;
    }

    .date-btn {
      height: 100%;

      .date-selector {
        display: flex;
        gap: 10px;
        font-size: 14px;
        padding: 5px;
  
        .button-inner {
          gap: 10px;
        }
      }
    }
  }

  .item-price {
    font-size: var(--font-size-regular);
  }
  
  .item-cart-buttons {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    margin: auto;
  }

  .update-order-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;

    .item-order-time {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }

  .ant-divider {
    margin: 0 0 10px 0;
  }

  .modifier-group {
    &:not(:first-child) {
      padding-top: 10px;
    }

    .modifier-title {
      display: flex;
      align-items: center;
      gap: 5px;

      .text {
        font-size: var(--font-size-medium);
        font-weight: 600;
      }

      .required {
        font-size: var(--font-size-small);
      }
    }

    .modifier-type {
      color: var(--color-medium);
      font-size: var(--font-size-small);
    }

    .modifier-error {
      color: var(--color-danger);
      font-size: var(--font-size-small);
      font-weight: 600;
    }

    .modifier-options {
      padding: 10px 0;
      flex-direction: column;

      .ant-checkbox-wrapper {
        margin: 5px 0;

        > span:not(.ant-checkbox) {
          flex-grow: 1;
        }
      }

      .modifier-option {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`

interface IMenuItemPage {
  className?: string
  open: boolean,
  onClose: () => void,
  orderType: OrderType,
  partner: IPartner,
  menuItem: IMenuItem,
  modifiers: IModifier[],
  isPartnerWebsite: boolean,
  currCartPartnerName?: string,
  disableAdd?: boolean,
  errorMessage?: React.ReactNode | string,
  staticPlan?: boolean
}

function BaseMenuItemModal({className, open, onClose, orderType, partner, menuItem, modifiers, isPartnerWebsite, currCartPartnerName, disableAdd, errorMessage, staticPlan}: IMenuItemPage) {

  const dispatch = useAppDispatch()

  const [selectedDate, setSelectedDate] = useState<string | undefined>()
  const [numberOfItems, setNumberOfItems] = useState<number>(1)
  const [dateModalVisible, setDateModalVisible] = useState(false)
  const [selectedModifiers, setSelectedModifiers] = useState<Record<string, string[]>>({})
  const [modifierErrors, setModifierErrors] = useState<string[]>([])

  //States used for app
  const [clearCartModalVisible, setClearCartModalVisible] = useState<boolean>(false)

  const onlineOrderingEnabled = partner.subscriptionPlan === SubscriptionPlan.OnlineOrderingMonthly || partner.subscriptionPlan === SubscriptionPlan.OnlineOrderingAnnual

  const modifiersRef = useRef([]);

  let mods: ISelectedModifier[] = []
  if(selectedModifiers) {
    for(const id in selectedModifiers) {
      const modifier = modifiers.find((val) => val.id === id);
      if(!modifier) {
        throw("An unexpected error occurred")
      }

      mods.push({
        ...modifier,
        selectedOptions: selectedModifiers[id]
      })
    }
  }

  useEffect(() => {
    if(!menuItem) return;

    //Dine in orders are always for today
    if(orderType === OrderType.DineIn) {
      setSelectedDate(moment().startOf("day").toISOString());
      return;
    }

    let orderDays = orderType === OrderType.Pickup ? partner.pickupDays : partner.deliveryDays

    //There are no days configured for the order type
    if(!orderDays) return;

    const day = Utils.getNextOrderDay(
      orderType,
      orderDays,
      partner.preparationEnabled,
      partner.preparationDurationType,
      partner.preparationDuration,
      partner.adjustedDates,
      undefined,
      partner.preparationInMins
    )

    //Find first day this item is available
    if(menuItem?.availabilityType === AvailabilityType.Custom) {
      if(!menuItem?.availability) {
        return;
      } else {
        while(!menuItem?.availability.includes(day.day())) {
          day.add(1, 'day')
        }
      }
    }

    setSelectedDate(day.toISOString());
  }, [partner, menuItem])

  function incrementNumOfItems() {
    setNumberOfItems(numberOfItems + 1)
  }

  function decrementNumOfItems() {
    if (numberOfItems > 1) {
      setNumberOfItems(numberOfItems - 1)
    }
  }

  function isDateAvailable(date: Moment) {
    let orderDays = orderType === OrderType.Pickup ? partner.pickupDays : partner.deliveryDays

    const lastDate = moment().add(partner.advanceOrderDays ?? Constants.DEFAULT_ADVANCE_ORDER_DAYS, "days")

    //There are no days configured for the order type
    if(!orderDays) {
      return false;
    }

    const nextOrderDay = Utils.getNextOrderDay(
      orderType,
      orderDays,
      partner.preparationEnabled,
      partner.preparationDurationType,
      partner.preparationDuration,
      partner.adjustedDates,
      undefined,
      partner.preparationInMins
    )

    //Must pass 6 conditions
    //1. Date must be >= the next possible order day
    //2. The day of the date must be a configured pickup/delivery date depending on the order type
    //3. The day must be one of the adjust closed days
    //4. If custom availability, the day of the date must be one of the days in the items availability
    //5. Cannot be later than the configured days in advance
    //6. Item is not out of stock on the date
    if(
      date.isSameOrAfter(nextOrderDay) && 
      orderDays[date.day()] && 
      !isDateAdjustedClosed(date) &&
      (menuItem?.availabilityType === AvailabilityType.Everyday || menuItem?.availability?.includes(date.day())) &&
      date < lastDate && 
      !isItemOutOfStock(date)
    ) {
      return true;
    }

    return false;
  }

  function isDateAdjustedClosed(date: Moment) {
    if(!partner?.adjustedDates || !partner?.adjustedDates[date.format(DateFormat)]) {
      return false;
    }

    return partner.adjustedDates[date.format(DateFormat)].type === AdjustDateType.Closed
  }

  function isItemOutOfStock(date: Moment) {
    if(menuItem?.outOfStockType !== OutOfStockType.None) {
      if(menuItem?.outOfStockDates?.includes(date.format(DateFormat))) {
        return true
      }
    }
    return false
  }

  function updateCart(clearCart: boolean = false) {
    if(!partner || !menuItem) return;

    if (!clearCart && currCartPartnerName && currCartPartnerName !== partner.name) {
      setClearCartModalVisible(true)
      return;
    }

    dispatch(
      cartMultiReducer.addItemToCart({
        partnerId: partner.id,
        partnerName: partner.name,
        item: {
          date: moment(selectedDate).format(DateFormat),
          id: menuItem.id,
          details: {
            id: menuItem.id,
            name: menuItem.name,
            description: menuItem.description,
            price: menuItem.price,
            availabilityType: menuItem.availabilityType,
            availability: menuItem.availability,
            outOfStockType: menuItem.outOfStockType,
            outOfStockDates: menuItem.outOfStockDates
          },
          count: numberOfItems,
          modifiers: mods,
        },
        isApp: !isPartnerWebsite
      })
    )
    setClearCartModalVisible(false)
    onClose()
  }

  function handleAddToCart() {
    const requiredModifiers = modifiers.filter((val) => menuItem?.modifiers?.includes(val.id) && val.required)
    let modifiersFirstErrorId: string = null;
    if(requiredModifiers.length > 0) {
      const errors: string[] = []
      //Check that required modifiers have been selected
      for(const requiredModifier of requiredModifiers) {
        const selectedOptions = selectedModifiers[requiredModifier.id]
        if(!selectedOptions || selectedOptions.length === 0) {
          //Display error as not options have been selected
          errors.push(requiredModifier.id)
          if(modifiersFirstErrorId === null) modifiersFirstErrorId = requiredModifier.id
          modifiersRef.current[requiredModifier.id].getElementsByClassName("modifier-title")[0].getElementsByClassName("text")[0].classList.add(`shake`);
        } else {
          //Ensure selected options are valid
          for(const selectedOption of selectedOptions) {
            if(!requiredModifier.options.find((val) => val.label === selectedOption)) {
              errors.push(requiredModifier.id)
              if(modifiersFirstErrorId === null) modifiersFirstErrorId = requiredModifier.id
              modifiersRef.current[requiredModifier.id].getElementsByClassName("modifier-title")[0].getElementsByClassName("text")[0].classList.add(`shake`);
            }
          }
        }
      }

      setModifierErrors(errors);

      if(errors.length > 0) {
        //Scroll to first error
        modifiersRef.current[modifiersFirstErrorId].scrollIntoView({ behavior: 'smooth' })
        //Do not contiue as there are errors with the modifiers
        return;
      }
    }

    updateCart()
    onClose()
    setSelectedModifiers({})

    //Reset number of items
    setNumberOfItems(1)
  
  }

  function renderDateChangerWeb () {
    if(!partner) return;

    //If next order day and order days are provided, render a list of options available
    let orderDays = orderType === OrderType.Pickup ? partner.pickupDays : partner.deliveryDays
    if (orderDays) {
      return (
        <div className="update-order-date">
          <div className="item-order-time">
            <ClockCircleOutlined />
            <span>{Utils.getRelativeDateString(moment(selectedDate), "dddd, D MMMM YYYY")}</span>
          </div>
          <Button onClick={() => setDateModalVisible(true)}>Change date</Button>
        </div>
      )
    }
  }

  function renderModifiers() {
    if(menuItem?.modifiers) {
      return (
        <div className="modifiers">
          {
            menuItem.modifiers.map((modifierId) => {
              const modifier = modifiers.find((val) => val.id === modifierId);
              if(modifier) {
                return (
                  <div className="modifier-group" key={modifier.id} ref={(e) => modifiersRef.current[modifier.id] = e}>
                    <div className="modifier-title"><span className="text">{modifier.name}</span> <span className="required">{modifier.required ? '(Required)' : ''}</span></div>
                    {
                      modifierErrors.includes(modifier.id) ?
                      <div className="modifier-error">
                        {
                          modifier.type === ModifierSelectType.Single ?
                          'Select a single option'
                          :
                          'Select at least one option'
                        }
                      </div>
                      :
                      <div className="modifier-type">{modifier.type === ModifierSelectType.Single ? 'Select a single option' : 'Select all that apply'}</div>
                    }
                    <Checkbox.Group 
                      className="modifier-options" 
                      style={{ width: '100%' }} 
                      value={selectedModifiers[modifierId]}
                      options={
                        modifier.options.map((option) => ({
                          label: (
                            <div className="modifier-option" key={option.label}>
                              <div>{option.label}</div>
                              <div>{option.price ? `+$${option.price.toFixed(2)}` : ''}</div>
                            </div>
                          ),
                          value: option.label
                        }))
                      }
                      onChange={(val) => {
                        const selected = val as string[]
                        const updatedSelectedModifiers = {...selectedModifiers}
                        if(modifier.type === ModifierSelectType.Single) {
                          updatedSelectedModifiers[modifier.id] = selectedModifiers[modifier.id] ? [...selected.filter((val) => !selectedModifiers[modifier.id].includes(val))] : selected
                        } else {
                          updatedSelectedModifiers[modifier.id] = selected
                        }
                        setSelectedModifiers(updatedSelectedModifiers)

                        if(modifierErrors.includes(modifier.id)) {
                          setModifierErrors((errors) => errors.filter((val) => val !== modifier.id))
                        }
                      }}
                    />
                  </div>
                )
              }
            })
          }
        </div>
      )
    }
  }

  function renderClearCartConfirmation() {
    return (
      <SpringModal
        title="Start new order?"
        isOpen={clearCartModalVisible}
        onOk={() => updateCart(true)}
        onCancel={() => setClearCartModalVisible(false)}
        okText="Yes"
      >
        <span>  
          You currently have items from <b>{currCartPartnerName}</b> in your Cart.
        </span>
        <span>
          Would you like to clear the cart and add items from{" "}
          <b>{partner?.name}</b> instead?
        </span>
      </SpringModal>
    )
  }


  return (
    <>
      <Modal
        className={`${className} ${staticPlan ? 'static' : ''}`}
        style={{ top: "calc(var(--ion-safe-area-top) + 20px)" }}
        open={open}
        onCancel={() => {
          setSelectedModifiers({})
          onClose()
          setDateModalVisible(false)
          setModifierErrors([])
          setNumberOfItems(1)
        }}
        okText={`Add ${numberOfItems} • $${(menuItem.price * numberOfItems).toFixed(2)}`}
        okButtonProps={{disabled: disableAdd}}
        onOk={handleAddToCart}
        destroyOnClose={true}
        footer={!staticPlan ? [
          <Alert key="alert" showIcon message={errorMessage} type="warning" style={{marginBottom: '10px', display: disableAdd ? 'flex' : 'none'}} />,
          <Button key="cancel" onClick={() => {
            setSelectedModifiers({})
            onClose()
            setDateModalVisible(false)      
            setModifierErrors([])
            setNumberOfItems(1)
          }}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleAddToCart} disabled={disableAdd}>
            {`Add ${numberOfItems} • $${calculatePrice(menuItem.price, numberOfItems, mods).toFixed(2)}`}
          </Button>
        ] : []}
      >
        <div className="item-details">
          <div className="item-header" style={{gridTemplateColumns: menuItem.imageUrl ? '100px 1fr' : '100%'}}>
            {
              menuItem.imageUrl &&
              <div className="item-image">
                <img className="fadeOut" src={menuItem.imageUrl} onLoad={(e) => e.currentTarget.classList.add("fadeIn")}/>
              </div>
            }
            <div className="item-info">
              <div className="item-title">{menuItem.name}</div>
              <div className="item-description">{menuItem.description}</div>
            </div>
          </div>
          {onlineOrderingEnabled && orderType !== OrderType.DineIn && renderDateChangerWeb()}
          {
            onlineOrderingEnabled &&
            <div className="item-cart-buttons">
              <Button size="large" onClick={decrementNumOfItems}><MinusOutlined /></Button>
              {numberOfItems}
              <Button size="large" onClick={incrementNumOfItems}><PlusOutlined /></Button>
            </div>
          }
          <Divider />
          {renderModifiers()}
        </div>
      </Modal>
      <DatePickerModal
        title="Select a date"
        isOpen={dateModalVisible}
        date={moment(selectedDate)}
        min={moment().toISOString()}
        onClose={() => setDateModalVisible(false)}
        onUpdate={(date) => setSelectedDate(date.toISOString())}
        isDateEnabled={isDateAvailable}
      />
      {renderClearCartConfirmation()}
    </>
  )
}