import { useState } from "react"
import { cloudFunction, partnerReducer } from "@tiffin/app-common"

import { useAppDispatch } from "../hooks"

//3rd party
import { IonLoading, useIonToast } from "@ionic/react"
import { toast } from "@tiffin/components"
import { Button } from "antd"

interface ISubmitFormProps {
  onDone?: (action: "save" | "prev" | "next") => void,
}

export function SubmitForm({onDone}: ISubmitFormProps) {
  //Redux
  const dispatch = useAppDispatch()

  //Internal states
  const [loading, setLoading] = useState<boolean>(false)

  const [present] = useIonToast();

  function submitApplication() {
    setLoading(true)
    cloudFunction("submitPartnerApplication").then(
      (result: any) => {
        if (result.data?.status === "success") {
          setLoading(false)
          dispatch(partnerReducer.setPartnerStatus("pending"))
        }
      },
      (error) => {
        if(error.details?.error) {
          toast(present, error.details.error, "danger")
        } else {
          toast(present, error.message, "danger")
        }
        setLoading(false)
        console.error(error)
      }
    )
  }

  function handlePrev() {
    if(onDone) onDone("prev")
  }

  return (
    <div className="partner-form activation-page">
      <div className="activation-info">
        <span className="title">You are nearly there!</span>
        <span>Once you submit your applications one of our representatives will contact you within 1-2 business days.</span>
        <span>Upon approval you will be able to activate your store and go live!</span>
        <Button type="primary" size="large" className="submit-btn" onClick={submitApplication}>
          Submit Application
        </Button>
      </div>
      <div className="form-buttons">
        <Button size="large" className="left-btn" onClick={handlePrev}>
          Back
        </Button>
      </div>
      <IonLoading isOpen={loading} message="Submitting Application" spinner="crescent" />
    </div>
  )
}
