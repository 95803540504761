import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import { IonIcon, IonItem, IonLabel, IonReorder, IonReorderGroup } from "@ionic/react";
import { partnerReducer } from "@tiffin/app-common";
import { MenuCategoryEditor } from "./MenuCategoryEditor";
import { SpringModal } from "@tiffin/components";
import PlusOutlined from "@ant-design/icons/PlusOutlined"
import { FloatButton } from "antd";

//Redux
import { useAppDispatch, useAppSelector } from "../../hooks";

//3rd party 
import { createOutline } from "ionicons/icons";
import { StepType, useTour } from '@reactour/tour'

export const MenuCategoryReorder = styled(BaseMenuCategoryReorder)`
  display: grid;
  grid-template-rows: 0 100%;
  height: 100%;
  background: var(--ion-color-light);
  padding: 10px;
  overflow: auto;

  .menu-category {
    margin-bottom: 5px;
    border-radius: 8px;

    &::part(native) {
      --border-style: none;
    }
  }

  .category-label {
    padding: 10px 10px;
  }

  .new-category {
    justify-self: flex-end;
  }

  .edit-category {
    height: 40px;
    width: 40px;
  }

  .update-menu-category,
  .new-menu-category {
    &.hide {
      visibility: hidden;
    }
  }
`

interface IMenuCategoryReorder {
  className?: string,
  openTutorial?: boolean,
  closeTutorial?: () => void,
  onboardingForm?: boolean
}

export function BaseMenuCategoryReorder({className, openTutorial, closeTutorial}: IMenuCategoryReorder) {

  //Redux
  const partnerMenu = useAppSelector((state) => state.partner.menu)

  //Internal
  const [showNewCategoryModal, setShowNewCategoryModal] = useState(false)
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false)
  const [editingCategory, setEditingCategory] = useState<string>()

  //Tutorial states
  const { isOpen, setIsOpen, setSteps, setCurrentStep } = useTour()
  const [hideModal, setHideModal] = useState(false)

  const dispatch = useAppDispatch()

  const steps: StepType[] = useMemo(() => {
    return ([
      {
        content: <p>The categories tab allows you to <b>add</b>, <b>edit</b> or <b>delete</b> categories from your menu</p>,
        position: 'center',
        selector: '#menu .ant-tabs-tab-active',
      },
      {
        selector: '.new-category',
        content: <p>Click this button to <b>add</b> a new category</p>,
        action: () => {
          setHideModal(true)
          setShowNewCategoryModal(true)
        },
        actionAfter: () => {
          setHideModal(false)
        }
      },
      {
        selector: '.new-menu-category > #ion-react-wrapper',
        position: 'top',
        content: <p>Here you can enter a category name, once completed click the <b>Save</b> button</p>,
        actionAfter: () => {
          dispatch(partnerReducer.addCategory({
            name: "Drinks"
          }))
          setShowNewCategoryModal(false)
        }
      },
      {
        selector: '.menu-category-reorder',
        content: <p>Your new category is available here</p>,
      },
      {
        selector: '.menu-category-reorder ion-item:last-child ion-button',
        content: <p>To <b>edit</b> a category click this button</p>,
        action: () => {
          setHideModal(true)
          setEditingCategory('Drinks')
          setShowEditCategoryModal(true)
        },
        actionAfter: () => {
          setHideModal(false)
        }
      },
      {
        selector: '.update-menu-category > #ion-react-wrapper',
        position: 'top',
        content: <p>Here you can <b>update the name</b> or <b>delete</b> the category</p>,
      },
      {
        selector: '.update-menu-category > #ion-react-wrapper .update-btns > ion-button:first-child',
        position: 'top',
        content: <p>Click this button to <b>delete</b> the category</p>,
        actionAfter: () => {
          dispatch(partnerReducer.deleteCategory({
            name: "Drinks"
          }))
          setShowEditCategoryModal(false)
        }
      },
      {
        selector: '#save.right-btn',
        content: <p>Once you have made all your changes click <b>Save</b></p>,
        actionAfter: () => {
          dispatch(partnerReducer.getMenu())
          if(closeTutorial) {
            closeTutorial()
          }
        }
      }
    ])
  }, [closeTutorial, dispatch])

  useEffect(() => {
    if(openTutorial) {
      if(setSteps) setSteps(steps)
      setCurrentStep(0)
      setIsOpen(true)
    }
  }, [setSteps, steps, setIsOpen, setCurrentStep, openTutorial])

  function onItemReorder(result: any) {
    dispatch(partnerReducer.reorderCategory({from: result.detail.from, to: result.detail.to}))
    result.detail.complete(true)
  }
  
  function handleEditCategory(category: string) {
    setEditingCategory(category)
    setShowEditCategoryModal(true)
  }

  function renderMenuCategories() {
    let elements: JSX.Element[] = []
    if(partnerMenu) {
      for(let category in partnerMenu) {
        elements[partnerMenu[category].order] = (
          <IonItem key={category} className="menu-category" onClick={() => handleEditCategory(category)}>
            <IonIcon icon={createOutline} />
            <IonLabel className="category-label">{category}</IonLabel>
            <IonReorder slot="end" />
          </IonItem>
        )
      }
    }
    return elements
  }

  function renderCategoryModal() {
    return (
      <SpringModal 
        className={`${hideModal ? 'hide' : ''} update-menu-category auto-height`}
        isOpen={showEditCategoryModal}
        title="Update menu category"
        onClose={() => setShowEditCategoryModal(false)}
        showClose={true}
      >
        <MenuCategoryEditor name={editingCategory} onSave={() => setShowEditCategoryModal(false)}/>
      </SpringModal>
    )
  }

  return (
    <div className={className}>
      <FloatButton type="primary" style={{position: "sticky", left: "calc(100% - 50px)", top: "calc(100% - 50px)"}} icon={<PlusOutlined />} onClick={() => setShowNewCategoryModal(true)}></FloatButton>
      <IonReorderGroup disabled={false} onIonItemReorder={onItemReorder}>
        {renderMenuCategories()}
      </IonReorderGroup>
      <SpringModal
        className={`${hideModal ? 'hide' : ''} new-menu-category auto-height`}
        isOpen={showNewCategoryModal}
        title="Add new menu category"
        onClose={() => setShowNewCategoryModal(false)}
        showClose={true}
      >
        <MenuCategoryEditor newItem={true} onSave={() => setShowNewCategoryModal(false)} name={isOpen ? 'Drinks' : undefined} />
      </SpringModal>
      {renderCategoryModal()}
    </div>
  )
}