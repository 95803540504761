import { createSlice } from "@reduxjs/toolkit";
// Define the initial state
var initialState = {
    status: "unknown",
    metrics: {
        tiffinUnread: 0,
        customerUnread: 0
    }
};
export var conversationsMetricsSlice = createSlice({
    name: "conversationsMetrics",
    initialState: initialState,
    reducers: {
        updateMetrics: function (state, action) {
            state.status = "idle";
            state.metrics = action.payload;
        }
    }
});
export var updateMetrics = conversationsMetricsSlice.actions.updateMetrics;
export default conversationsMetricsSlice.reducer;
