import { useEffect, useRef, useState } from "react"
import { IOrderExt, IUser, OrderStatus } from "@tiffin/core"
import { toast, SpringModal, LoginForm } from "@tiffin/components"

//Redux
import { app, auth, cloudFunction, Constants, partnerReducer, uiConfig, userReducer } from "@tiffin/app-common"
import { useAppDispatch, useAppSelector, useIsPortrait } from "../hooks"

//3rd party components
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLoading, IonPage, IonToolbar, useIonRouter, useIonToast } from "@ionic/react"
import { Alert, Button, Checkbox, Form, FormInstance, Input, Select } from "antd"
import { personOutline } from "ionicons/icons"
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Capacitor } from "@capacitor/core"
import { Browser } from "@capacitor/browser"

import "./AccountInfo.scss"

const { Option } = Select

export function AccountInfo() {
  const formRef = useRef<FormInstance>(null)
  const formValues = useRef<{
    email?: string
    firstName?: string
    lastName?: string
    phone?: string
    phonePrefix?: string
    policyAck?: boolean
  }>({})

  //Redux states
  const partnerStatus = useAppSelector((state) => state.partner.partnerStatus)
  const orders = useAppSelector((state) => state.orders.active)

  const user = useAppSelector((state) => state.user)
  const authenticate = useAppSelector((state) => state.authenticate)
  const tcPartnerVersion = useAppSelector((state) => state.globalPartnerConfig.tcPartnerVersion)

  const dispatch = useAppDispatch()

  //Internal states
  const [loginModalVisible, setLoginModalVisible] = useState<boolean>(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const [loading, setLoading] = useState<boolean>(false)

  const router = useIonRouter()
  const [present] = useIonToast();

  const isPortrait = useIsPortrait()

  useEffect(() => {
    if(user.status === "failed") {
      if(user.error === 'auth/requires-recent-login') {
        setLoginModalVisible(true)
        setLoading(false);
      } else {
        if(user.error === 'auth/email-already-in-use') {
          toast(present, "Email already in use", "danger");
        } else {
          if(user.error) toast(present, user.error, "danger", undefined, 5000);
        }
        setLoginModalVisible(false);
        setLoading(false);
      }
    }
  }, [user.status, user.error, present])

  const goBack = router.goBack

  useEffect(() => {
    if (user.status === "updating") {
      setLoading(true)
    } else if (loading === true && user.status === "succeeded") {
      setLoading(false)
      if(authenticate.status === "authenticated") {
        toast(present, "Updated user information", undefined, "bottom")
        goBack()
      }
    } else if (user.status === "succeeded") {
      formRef.current?.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        phonePrefix: user.phonePrefix,
        email: user.email
      })
    } else if (user.status === "idle") {
      formRef.current?.setFieldsValue({
        phone: auth.currentUser?.phoneNumber?.slice(3),
        phonePrefix: auth.currentUser?.phoneNumber?.slice(0,3),
      })
    }
  }, [user, loading, authenticate.status, present, goBack])

  function onFinishEdit() {
    let values = formValues.current
    if (values.firstName && values.lastName && values.phonePrefix && values.phone && values.email) {
      if (isNaN(Number(user.phone))) {
        toast(present, "Phone number contains non numeric characters", "danger")
        hideSaveConfirmation()
        return
      }

      if (values.phone.length < 9 || (values.phone.length === 9 && values.phone[0] !== "4")) {
        toast(present, "Phone number incomplete", "danger")
        hideSaveConfirmation()
        return
      } else if (values.phone.length > 10) {
        toast(present, "Phone number exceeds 10 digits", "danger")
        hideSaveConfirmation()
        return
      }

      let adjustedPhoneNumber = values.phone
      if (adjustedPhoneNumber.length === 9) {
        adjustedPhoneNumber = `0${values.phone}`
      }

      const policyAckVersion = tcPartnerVersion;
      let update: IUser = {
        uid: auth.currentUser!.uid,
        email: values.email.trim(),
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        phonePrefix: user.phonePrefix ? user.phonePrefix : values.phonePrefix,
        phone: user.phone ? user.phone : values.phone,
        partnerPolicyAckVer: values.policyAck ? policyAckVersion : undefined
      }

      if(authenticate.status === "incomplete") {
        if(user.token) {
          update.partnerNotificationTokens = [ user.token ]
        }
        dispatch(userReducer.createUser(update))
      } else {
        dispatch(userReducer.updateUser(update))
      }
    }
  }

  function onFinishFailedEdit(errorInfo: any) {
    console.error(errorInfo)
  }

  function showSaveConfirmation(values: any) {
    if(!values.firstName) {
      toast(present, Constants.ERRORMESSAGE_ENTERFIRSTNAME, "danger")
      return;
    }
    if(!values.lastName) {
      toast(present, Constants.ERRORMESSAGE_ENTERLASTNAME, "danger")
      return;
    }
    if(!values.email) {
      toast(present, Constants.ERRORMESSAGE_ENTEREMAIL, "danger")
    }
    if(!values.policyAck && router.routeInfo.pathname === "/profile") {
      toast(present, Constants.ERRORMESSAGE_POLICYACK, "danger")
    }
    formValues.current = values
    if(router.routeInfo.pathname === "/profile") {
      handleSave()
    } else {
      setConfirmModalVisible(true)
    }
  }

  function handleSave() {
    onFinishEdit()
    setConfirmModalVisible(false)
  }

  function hideSaveConfirmation() {
    setConfirmModalVisible(false)
  }

  function renderSaveConfirmation() {
    return (
      <SpringModal
        isOpen={confirmModalVisible}
        onOk={handleSave}
        onCancel={() => setConfirmModalVisible(false)}
        title={"Apply changes"}
        okText="Yes"
      >
        Are you sure you want to apply these changes?
      </SpringModal>
    )
  }

  function handleDelete() {
    cloudFunction("deletePartnerAccount").then(
      (result: any) => {
        setDeleteModalVisible(false)
        dispatch(partnerReducer.setPartnerStatus("pending-delete"))
        toast(present, "Your account has been scheduled for deletion")
        router.push("/main/status")
      },
      (error) => {
        console.error(error.message)
        setError('Your account cannot be deleted, please ensure your store has been deactivated and have no outstanding orders. If this error persists contact Tifyn Support at support@tifyn.com.au')
        setDeleteModalVisible(false)
      }
    )
  }

  const disableDelete = () => {
    if(partnerStatus !== "inactive" && partnerStatus !== "approved" && partnerStatus !== "onboarding" && partnerStatus !== "pending") {
      return true;
    }

    if(orders.filter((order: IOrderExt) => order.status !== OrderStatus.Completed && order.status !== OrderStatus.Cancelled).length !== 0) {
      return true;
    }

    return false;
  }

  function renderDeleteConfirmation() {
    return (
      <SpringModal
        isOpen={deleteModalVisible}
        onOk={handleDelete}
        onCancel={() => setDeleteModalVisible(false)}
        title={"Delete Account"}
        okText="Delete"
        okDisabled={disableDelete()}
      >
        <p>Are you sure you want to submit a request to delete your account? Once submitted your account will be deleted within <b>10 business days</b>.</p>
        <Alert showIcon type="error" message={<p className="alert-content">Once deleted, your account <b>can't be recovered</b></p>} />
        {
          disableDelete() &&
          <Alert showIcon type="warning" message="To delete your account, your store must be deactivated and have no outstanding orders." />
        }
      </SpringModal>
    )
  }

  function renderAccountInfoForm() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    const prefixSelector = (
      <Form.Item name="phonePrefix" noStyle>
        <Select disabled style={{ width: 70 }}>
          <Option value={"61"}>+61</Option>
        </Select>
      </Form.Item>
    )

    return (
      <Form
        ref={formRef}
        {...formItemLayout}
        name="edit-account"
        className={isPortrait ? 'portrait' : 'landscape'}
        initialValues={{
          prefix: "61",
        }}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={(values: any) => showSaveConfirmation(values)}
        onFinishFailed={onFinishFailedEdit}
      >
        <Form.Item className="phone-input" label="Phone">
          <Form.Item 
            name="phone"
            rules={[
              {
                required: true,
                message: Constants.ERRORMESSAGE_ENTERPHONENUMBER,
              },
              () => ({
                validator(_, value: string) {
                  if (value && !isNaN(Number(value))) {
                    if (value.length === 9 || (value.length === 10 && value.startsWith("0"))) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error(Constants.ERRORMESSAGE_INVALIDPHONENUMBER))
                  }
                  if (typeof value === "undefined") {
                    //No need to send error if undefined as it is a required field
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(Constants.ERRORMESSAGE_INVALIDPHONENUMBER))
                },
              }),
            ]}
          >
            <Input disabled addonBefore={prefixSelector} placeholder="Phone Number" />
          </Form.Item>
        </Form.Item>
        <Form.Item label="First Name">
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: Constants.ERRORMESSAGE_ENTERFIRSTNAME,
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Last Name">
          <Form.Item 
            name="lastName"
            rules={[
              {
                required: true,
                message: Constants.ERRORMESSAGE_ENTERLASTNAME,
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Email">
          <Form.Item 
            name="email"
            rules={[
              {
                type: "email",
                message: Constants.ERRORMESSAGE_INVALIDEMAIL,
              },
              {
                required: true,
                message: Constants.ERRORMESSAGE_ENTEREMAIL,
              },
            ]}
          >
            <Input placeholder="Email" type="email" />
          </Form.Item>
        </Form.Item>
        {
          router.routeInfo.pathname === "/profile" &&
          <Form.Item 
            className="policy-ack-checkbox"
            name="policyAck"
            rules={[
              () => ({
                validator(_, value: boolean) {
                  if(value) {
                    return Promise.resolve()
                  } else {
                    return Promise.reject(Constants.ERRORMESSAGE_POLICYACK)
                  }
                },
              }),
            ]}
            valuePropName='checked'
          >
            <Checkbox value={true}>I acknowledge that I have read and agree to the <Button className="internal-link" type="link" onClick={() => Browser.open({ url: "https://tifyn.com.au/partner-terms-and-conditions.html" })}>Terms</Button> and <Button className="internal-link" type="link" onClick={() => Browser.open({ url: "https://tifyn.com.au/privacy-policy.html" })}>Privacy Policy</Button> of Tifyn.</Checkbox>
          </Form.Item>
        }
        <div className={`info-form-buttons ${router.routeInfo.pathname === "/profile" ? 'no-delete' : ''}`}>
          {
            router.routeInfo.pathname !== "/profile" &&
            <IonButton color="danger" onClick={() => setDeleteModalVisible(true)}>
              Delete
            </IonButton>
          }
          <div className="edit-group">
            <IonButton 
              onClick={() => {
                if(authenticate.status === "incomplete") {
                  dispatch(userReducer.signOutUser("partner"))
                } else if(router.canGoBack()) {
                  router.goBack()
                } else {
                  router.push("/main/account")
                }
              }}
              color="light"
            >
              Cancel
            </IonButton>
            <IonButton type="submit" color="primary">
              Confirm
            </IonButton>
          </div>
        </div>
        {renderSaveConfirmation()}
        {renderDeleteConfirmation()}
      </Form>
    )
  }

  const webUiConfig = uiConfig;
  if(auth.currentUser?.phoneNumber) {
    if(webUiConfig.signInOptions?.[0]) {
      (webUiConfig.signInOptions[0] as any).defaultNationalNumber = auth.currentUser.phoneNumber
    }
  }

  return (
    <IonPage className="account-info">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
          <IonBackButton defaultHref={authenticate.status !== "incomplete" ? "/main/account" : undefined}/>
          </IonButtons>
          <div className="page-header">
            {(user.firstName && user.lastName) ? (
              <>
                <IonIcon icon={personOutline} />
                <span className="page-header-text">Account Info</span>
              </>
            ) : (
              <>
                <IonIcon icon={personOutline} />
                <span className="page-header-text">Complete Profile</span>
              </>
            )}
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {
          error &&
          <Alert showIcon type="error" message={error} closable onClose={() => setError(undefined)} />
        }
        <div className="info-container">{renderAccountInfoForm()}</div>
        <IonLoading isOpen={loading} spinner="crescent" />
      </IonContent>
      {
        Capacitor.getPlatform() === "web" ?
        <SpringModal
          className="login-modal"
          isOpen={loginModalVisible}
          onCancel={() => setLoginModalVisible(false)}
        >
          <StyledFirebaseAuth
            className={`${auth.currentUser?.phoneNumber ? 'lock-phone-input' : ''}`}
            uiConfig={{
              ...webUiConfig,
              callbacks: {
                signInSuccessWithAuthResult: (authResult: any, redirectUrl?: string) => {
                  setLoginModalVisible(false)
                  onFinishEdit()
                  return false;
                }
              }
            }}
            firebaseAuth={app.auth()}
          />
        </SpringModal>
        :
        <SpringModal
          isOpen={loginModalVisible}
          onCancel={() => setLoginModalVisible(false)}
        >
          <LoginForm
            logoAlt="Tifyn Partner Logo"
            logoSrc={`${process.env.PUBLIC_URL}/assets/images/tifyn-partner-icon.png`}
            successCallback={() => {
              setLoginModalVisible(false)
              onFinishEdit();
            }}
            lockPhone={auth.currentUser?.phoneNumber}
            isVisible={loginModalVisible}
          />
        </SpringModal>
      }
    </IonPage>
  )
}
