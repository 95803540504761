import { useEffect, useMemo, useState } from "react";
import { DateFormat } from "@tiffin/core";
import { metricsReducer } from "@tiffin/app-common";
import { useAppDispatch, useAppSelector } from "../hooks";
import { LoadingSpinner, toast } from "@tiffin/components";

//3rd party
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import moment from "moment";
import { IonButton, IonSegment, IonSegmentButton, useIonToast } from "@ionic/react";
import RedoOutlined from "@ant-design/icons/RedoOutlined"

import './RevenueChart.scss'

interface IRevenueChartProps {
  className?: string,
  disableViewToggle?: boolean
}

export function RevenueChart(props: IRevenueChartProps) {
    //Redux
    const daily = useAppSelector((state) => state.metrics.daily)
    const status = useAppSelector((state) => state.metrics.dailyStatus)
    const error = useAppSelector((state) => state.metrics.error)
    const dispatch = useAppDispatch()

    const [view, setView] = useState<"week" | "month">("week")

    const [present] = useIonToast();

    useEffect(() => {
      if(status === "unknown") {
        dispatch(metricsReducer.getLast30DaysMetrics())
      }
    }, [status, dispatch])

    useEffect(() => {
      if(error) toast(present, error)
    }, [error, present])
  
    function refreshData() {
      dispatch(metricsReducer.getLast30DaysMetrics())
    }

    const data = useMemo(() => {
      let data = new Map<string, {
        date: string,
        revenue: number
      }>()

      //Fill empty data
      let numberOfDays = view === "week" ? 7 : 30

      for(let i=0; i<numberOfDays; i++) {
        const date = moment().subtract(i, "days").format(DateFormat)
        data.set(date, {
          date: date,
          revenue: 0
        })
      }

      if(daily) {
        for(let value of daily) {
          if(data.has(value.date)) {
            data.set(value.date, {
              date: value.date,
              revenue: (value.total - value.refundTotal - value.cancellationFees) / 100
            })
          }
        }
      }
      return data
    }, [view, daily]);

    return (
      <div className={`revenue-chart ${props.className ? props.className : ''}`}>
        <div className="chart-title">
          <span>{`Revenue (Last ${view === "week" ? 7 : 30} Days)`}</span>
          <div className="right-title-buttons">
            {
              !props.disableViewToggle &&
              <IonSegment className="sort-switch" mode="ios" value={view} onIonChange={(e) => setView(e.detail.value === "week" ? "week" : "month")} >
                <IonSegmentButton value="week">
                  7 DAYS
                </IonSegmentButton>
                <IonSegmentButton value="month">
                  30 DAYS
                </IonSegmentButton>
              </IonSegment>
            }
            <IonButton color="light" className="chart-refresh-btn" size="small" onClick={refreshData}>          
              <RedoOutlined />
            </IonButton>
          </div>
        </div>
        {
          <div className="chart-container">
            <ResponsiveContainer debounce={300} width="100%" height="100%" >
              <BarChart barSize={20} data={[...data.values()].reverse()}>
                <YAxis tickFormatter={(value) => {return value > 1000 ? `$${(value/1000).toFixed(1)}k` : `$${value}`}} axisLine={false} width={40} />
                <XAxis axisLine={false} dataKey="date" tickFormatter={(value) => moment(value, DateFormat).format("D MMM")}/>
                <CartesianGrid vertical={false} />
                <Bar dataKey="revenue" fill="#8884d8"/>
              </BarChart>
            </ResponsiveContainer>
          </div>
        }
        {
          status === "idle" && data.size === 0 &&
          <div className="no-data">
            No revenue in the last 7 days
          </div>
        }
        <div className={`chart-loading ${(status === "unknown" || status === "updating") ? 'fadeIn' : 'fadeOut'}`}>
          <LoadingSpinner />
        </div>
      </div>
    )
}