import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { IModifier, IModifierOption, ModifierSelectType } from "@tiffin/core";
import { SpringModal } from "@tiffin/components";
import { partnerReducer } from "@tiffin/app-common";
import { useAppDispatch, useAppSelector } from "../../hooks";

import { message, Button, Divider, Form, Input, InputNumber, Select, Switch, Alert, InputRef } from "antd";
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import DeleteOutlined from "@ant-design/icons/DeleteOutlined"
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from "lodash";

export const ModifierForm = styled(BaseModifierForm)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .error {
    color: var(--ion-color-danger);
    padding: 5px;
    font-weight: 900;
  }

  .subheading {
    color: var(--ion-color-dark);
    font-weight: 900;
  } 

  .field {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .row {
    display: flex;
    gap: 40px;
    align-items: center;
  }

  .modifier-type {
    width: 100px;
  }

  .new-modifier-input {
    display: grid;
    grid-template-columns: 4fr 2fr auto;
    gap: 20px;
    align-items: center;

    .ant-form-item {
      margin-bottom: 0;
    }

    .add-btn {
      justify-content: flex-end;
      display: flex;
    }
  }

  .price {
    display: flex;
    align-items: center;
    gap: 5px;

    .ant-input-number {
      background: var(--ion-color-light);
      border-radius: 10px;
    }

    .ant-input-number-affix-wrapper{
      width: 100%;
      border: none;
      background: var(--ion-color-light);
      border-radius: 10px;
      padding: 6px 20px;
    }
  }

  .ant-divider-horizontal {
    padding: 0;
    margin: 12px 0;
  }

  .modifier-options {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .modifier-option {
      display: grid;
      grid-template-columns: 4fr 2fr auto;
      align-items: center;
      gap: 20px;
  
      .price {
        display: flex;
        gap: 4px;
      }
  
      .delete-btn {
        justify-content: flex-end;
        display: flex;
      }
    }
  }

  .no-options {
    color: var(--ion-color-medium);
    display: flex;
    justify-content: center;
  }

  .action-btns {
    display: flex;
    justify-content: ${(props) => props.onDelete ? "space-between" : "flex-end"};
  }
`;

interface IModifierForm {
  className?: string,
  isNew?: boolean,
  modifier?: IModifier,
  onChange: (modifier: IModifier) => void,
  onDelete?: () => void
}

function BaseModifierForm({className, isNew, modifier, onChange, onDelete}: IModifierForm) {

  const modifiers = useAppSelector((state) => state.partner.modifiers)

  const [name, setName] = useState<string>(modifier?.name ?? '')
  const [showNameError, setShowNameError] = useState<boolean>(false)
  const [saveError, setSaveError] = useState(false)
  const [type, setType] = useState<ModifierSelectType>(modifier?.type ?? ModifierSelectType.Single)
  const [required, setRequired] = useState(modifier?.required ?? false)
  const [options, setOptions] = useState<IModifierOption[]>(modifier?.options ?? [])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [newOptionLabel, setNewOptionLabel] = useState("")

  const [form] = Form.useForm();
  const newLabelRef = useRef<InputRef>(null);

  const dispatch = useAppDispatch()

  useEffect(() => {
    setName(modifier?.name ?? '')
    setType(modifier?.type ?? ModifierSelectType.Single)
    setRequired(modifier?.required ?? false)
    setOptions(modifier?.options ?? [])
  }, [modifier])

  const addNewModifierOption = (val: IModifierOption) => {
    if(options.find((option) => option.label === val.label)) {
      message.error("Option with same label already exists")
    } else {
      setOptions([...options, {label: val.label, price: val.price ? Number(val.price) : 0}])
      setNewOptionLabel("")
      form.resetFields()
      newLabelRef.current?.focus()
    }
  }

  const removeNewModifierOption = (val: IModifierOption) => {
    setOptions(options.filter((option) => option.label !== val.label))
  }

  const saveModifier = () => {
    if(!showNameError && name && options.length > 0) {
      const modifierId = isNew ? uuidv4() : modifier!.id;
      const newModifier: IModifier = {
        id: modifierId,
        name,
        type,
        options,
        required
      }
      if(isNew) {
        dispatch(partnerReducer.addModifier({modifier: newModifier}))
      } else {
        dispatch(partnerReducer.updateModifier({modifier: newModifier}))
      }
      onChange(newModifier)

      //Reset to default
      setName('')
      setType(ModifierSelectType.Single)
      setRequired(false)
      setOptions([])
      setNewOptionLabel("")
    } else {
      setSaveError(true)
    }
  }

  const deleteModifier = () => {
    setShowDeleteModal(false)
    if(modifier) {
      dispatch(partnerReducer.removeModifier({id: modifier.id}))
      if(onDelete) onDelete()
    }
  }

  const updateOption = (index: number, label: string, price: number) => {
    const updatedOptions = cloneDeep(options);
    updatedOptions[index].label = label
    updatedOptions[index].price = price
    setOptions(updatedOptions)
  }

  const updateName = (value: string) => {
    let conflicingName = false;
    for(const modifier of modifiers) {
      if(modifier.name === value) {
        conflicingName = true;
        break;
      }
    }

    if(!showNameError && conflicingName) {
      setShowNameError(true)
    } else if(showNameError && !conflicingName) {
      setShowNameError(false)
    }
    setName(value)
  }

  return (
    <div className={className}>
      <div>
        <Input value={name} onChange={(e) => {updateName(e.target.value)}} placeholder="Modifier name" />
        <div className="error" style={{display: saveError && name === '' ? 'inherit' : 'none'}}>Please enter modifier name</div>
        <div className="error" style={{display: showNameError ? 'inherit' : 'none'}}>A modifier already exists with this name</div>
      </div>
      <div className="row">
        <div className="field">
          <div className="subheading">Type</div>
          <div>
            <Select
              className="modifier-type"
              value={type}
              onChange={(val) => setType(val)}
              options={[
                {
                  value: ModifierSelectType.Single,
                  label: 'Single'
                },
                {
                  value: ModifierSelectType.Multiple,
                  label: 'Multiple'
                }
              ]}
            />
          </div>
        </div>
        <div className="field">
          <div className="subheading">Required</div>
          <div>
            <Switch
              checked={required}
              onChange={(val) => setRequired(val)}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked
            />
          </div>
        </div>
      </div>
      <div className="modifier-options">
        <div className="subheading">Options</div>
        {
          options.map((val, index) => (
          <div className="modifier-option">
            <Input value={val.label} placeholder="Label" onChange={(e) => updateOption(index, e.target.value, val.price)}/>
            <div className="price">
              <InputNumber defaultValue={val.price} min={0} prefix="$" placeholder="Price" formatter={(val, info) => info.userTyping ? val?.toString() ?? '' : Number(val).toFixed(2) } onChange={(price) => updateOption(index, val.label, price ?? 0)}/>
            </div>
            <div className="delete-btn">
              <Button danger onClick={() => removeNewModifierOption(val)}><DeleteOutlined /></Button>
            </div>
          </div> 
          ))
        }
      </div>
      <Form
        form={form}
        onFinish={addNewModifierOption}
      >
        <div className="new-modifier-input">
          <Form.Item
            name="label"
          >
            <Input placeholder="Label" onChange={(e) => setNewOptionLabel(e.target.value)} ref={newLabelRef}/>
          </Form.Item>
          <Form.Item
            name="price"
          >
            <div className="price">
              <InputNumber defaultValue={0} min={0} prefix="$" placeholder="Price" formatter={(val, info) => info.userTyping ? val?.toString() ?? '' : Number(val).toFixed(2) }/>
            </div>
          </Form.Item>
          <Form.Item className="add-btn">
            <Button type="primary" htmlType="submit" disabled={newOptionLabel.length === 0}>
              <PlusOutlined />
            </Button>
          </Form.Item>
        </div>
      </Form>
      <div className="error" style={{display: saveError && options.length === 0 ? 'inherit' : 'none'}}>Please add at least 1 option</div>
      <div>
        <Divider />
        <div className="action-btns">
          {
            onDelete &&
            <Button danger type="primary" onClick={() => setShowDeleteModal(true)} icon={<DeleteOutlined />}>Delete Modifier</Button>
          }
          <Button type="primary" onClick={saveModifier} disabled={showNameError}>{isNew ? 'Add' : 'Update'}</Button>
        </div>
      </div>
      <SpringModal 
          title="Delete Modifier"
          isOpen={showDeleteModal}
          onOk={() => deleteModifier()}
          okText="Yes"
          onCancel={() => setShowDeleteModal(false)}
        >
          Are you sure you want to delete this modiifer?
          <Alert showIcon message="Deleting this modifier will not remove the modifier from existing menu items" type="error" />
      </SpringModal>
    </div>
  )
}