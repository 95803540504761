import styled from "styled-components";

import { useRef, useState } from "react";

import { DateFormat, IOrderExt } from "@tiffin/core";
import { OrderCardList } from "../components/OrderCardList";

//3rd party
import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

//Redux
import { useAppSelector } from "../hooks"
import { DailyMetrics } from "../components/dashboard/DailyMetrics";
import { DailyHeader } from "../components/dashboard/DailyHeader";
import { Unsubscribe } from "firebase/firestore";
import { partnerOrdersHelper } from "@tiffin/app-common";

interface IDashboardHistoricalDailyView {
  className?: string,
  match?: any
}

export const DashboardHistoricalDailyView = styled(BaseDashboardHistoricalDailyView)`
  ion-content {
    --background: var(--ion-color-light);

    .header {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
    }

    .daily-overview {
      min-height: calc(100% - 68px);
      background-color: var(--ion-color-light);
      padding: 0 20px 20px;
      padding-bottom: calc(var(--ion-safe-area-bottom,0) + var(--page-padding));
      width: 100%;
      display: grid;
      overflow: auto;

      @media (orientation: portrait) {
        padding-bottom: calc(var(--footer-height) + 20px);
      }  
    }

    .order-status-option {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }
`

export function BaseDashboardHistoricalDailyView({className, ...props}: IDashboardHistoricalDailyView) {
  const [orders, setOrders] = useState<IOrderExt[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  //Redux
  const partner = useAppSelector((state) => state.partner)

  const date = decodeURIComponent(props.match.params.date)
  const unsubOrder = useRef<Unsubscribe | null>(null)

  const fullView = useMediaQuery({ query: '(min-width:961px)' })

  useIonViewWillEnter(() => {
    unsubOrder.current = partnerOrdersHelper.subsctibeToDateRange(moment(date, DateFormat), moment(date, DateFormat).add(1, 'day'), (orders: IOrderExt[]) => {
      if(loading) setLoading(false)
      setOrders(orders)
    })
  }, [date])

  const day = moment(date, DateFormat).day();

  let pickupTimings: any, deliveryTimings: any;
  if(partner.adjustedDates?.[date]) {
    pickupTimings = partner.adjustedDates[date].pickupTimings;
    deliveryTimings = partner.adjustedDates[date].deliveryTimings;

  } else {
    pickupTimings = partner.pickup?.pickupDays?.[day];
    deliveryTimings = partner.delivery?.deliveryDays?.[day]
  }

  return (
    <IonPage className={className}>
      <IonContent>
        <div className="page-container">
          <div className="header">
            <DailyHeader date={date} />
          </div>
          <div className={`daily-overview`}>
            <div className="dashboard-main-content">
              {
                !fullView &&
                <>
                  <DailyMetrics
                    date={date}
                    orders={orders}
                    pickupTimings={pickupTimings}
                    deliveryTimings={deliveryTimings}
                  />
                  <OrderCardList date={date} orders={orders} grouped={true} />
                </>
              }
              {
                fullView &&
                <>
                  <DailyMetrics
                    date={date}
                    orders={orders}
                    pickupTimings={pickupTimings}
                    deliveryTimings={deliveryTimings}
                  />
                  <OrderCardList date={date} orders={orders} grouped={true} historic={true} />
                </>
              }
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}