var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import moment from "moment";
export function getRouteInfo(source, destination) {
    return new Promise(function (resolve, reject) {
        var docRef = doc(db, "routes", "lat".concat(source.lat, "lng").concat(source.lng, "lat").concat(destination.lat, "lng").concat(destination.lng));
        getDoc(docRef).then(function (docSnap) {
            if (docSnap.exists()) {
                resolve(docSnap.data());
            }
            else {
                //Call routes api
                var body = {
                    origin: {
                        location: {
                            latLng: {
                                latitude: source.lat,
                                longitude: source.lng,
                            },
                        },
                    },
                    destination: {
                        location: {
                            latLng: {
                                latitude: destination.lat,
                                longitude: destination.lng,
                            },
                        },
                    },
                    travelMode: "DRIVE",
                    routingPreference: "TRAFFIC_UNAWARE",
                    computeAlternativeRoutes: false,
                    routeModifiers: {
                        avoidTolls: false,
                        avoidHighways: false,
                        avoidFerries: false,
                    },
                    units: "METRIC",
                };
                fetch("https://routes.googleapis.com/directions/v2:computeRoutes", {
                    method: "post",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json",
                        "X-Goog-Api-Key": "AIzaSyCRV-Qhgyc0CiJh4TXdvKIaF6oH4IEk6T4",
                        "X-Goog-FieldMask": "routes.duration,routes.distanceMeters,routes.polyline.encodedPolyline",
                    },
                }).then(function (response) {
                    if (response.ok) {
                        response.json().then(function (json) {
                            if (json.routes.length > 0 && json.routes[0].duration === "0s") {
                                json.routes[0].distanceMeters = 0;
                            }
                            setDoc(docRef, __assign(__assign({}, json), { timestamp: moment().toISOString() }));
                            resolve(json);
                        });
                    }
                    else {
                        reject(new Error("An unexpected error occurred"));
                    }
                });
            }
        });
    });
}
