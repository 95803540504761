import { IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonRefresher, IonRefresherContent } from "@ionic/react";
import { useEffect, useRef } from "react";
import { LoadingSpinner } from "./LoadingSpinner";

import './InfiniteList.scss'

interface IInfiniteListProps {
  loading: boolean,
  hasMore: boolean,
  fetchMore: () => void,
  refresh?: () => void,
  className?: string,
  position?: "top" | "bottom",
  hideLoadingOverlay?: boolean
}

export const InfiniteList: React.FC<IInfiniteListProps> = ({children, loading, ...props}) => {
  const eventRef = useRef(null);
  
  useEffect(() => {
    if(eventRef.current && !loading) {
      if(eventRef.current.details) {
        eventRef.current.detail.complete()
      } else if(eventRef.current.target) {
        eventRef.current.target.complete()
      }
      eventRef.current = null
    }
  }, [eventRef.current, loading])

  function onRefresh(event: any) {
    eventRef.current = event
    props.refresh()
  }

  function onInfinite(event: any) {
    if(props.hasMore) {
      eventRef.current = event
      props.fetchMore()
    } else {
      event.detail.complete()
    }
  }

  function renderInfiniteScroll() {
    return (
      <IonInfiniteScroll 
        disabled={!props.hasMore}
        onIonInfinite={onInfinite}
        threshold="50px"
        position={props.position ? props.position : "bottom"}
      >
        <IonInfiniteScrollContent
          loadingSpinner="crescent"
        >
        </IonInfiniteScrollContent>
      </IonInfiniteScroll>
    )
  }

  return (
    <div className={`infinite-list ${props.className ?? ''}`}>
      {
        props.refresh &&
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent
            refreshingSpinner="crescent"
          >      
          </IonRefresherContent>
        </IonRefresher>
      }
      {
        !props.hideLoadingOverlay &&
        <div className={`loading-overlay ${(loading) ? 'fadeIn' : 'fadeOut2'}`}>
          <LoadingSpinner />
        </div>
      }
      <IonList>
        {children}
      </IonList>
      {renderInfiniteScroll()}
    </div>
  )
  
}