import styled from "styled-components";
import { useEffect, useState } from "react"
import { partnerReducer, eventHelper, cloudFunction } from "@tiffin/app-common"

//Redux
import { useAppDispatch, useAppSelector } from "../hooks"

//3rd party
import { Button, Divider, Select, Table } from "antd"
import moment from "moment";

const { Option } = Select

export const Events = styled(BaseEvents)`
  width: 100%;
  background: var(--ion-background-color);
  padding: 20px;

  .register-event {
    display: flex;
    gap: 10px;
    flex-direction: column;

    .register-label {
      font-size: medium;
      font-weight: 700;
    }

    .register-control {
      display: flex;
      align-items: center;
      gap: 10px;

      .register-event-select {
        width: 300px;
      }
    }
  }

  .registered-title {
    font-size: medium;
    font-weight: 700;
  }

  .event-name {
    display: flex;
    align-items: center;
    gap: 10px;
  
    img {
      height: 1.4em;
      width: auto;
    }
  
    .text {
      flex-grow: 1;
    }
  }

  .no-events {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
`

interface IEvents {
  className?: string
}

export function BaseEvents({className}: IEvents) {

  const [selectedEvent, setSelectedEvent] = useState<string>()
  const [registering, setRegistering] = useState(false)
  const [unregisteringEvent, setUnregisteringEvent] = useState<string>()
  const registeredEvents = useAppSelector((state) => state.partner.registeredEvents)
  const [registeredEventsDetails, setRegisteredEventDetails] = useState<eventHelper.IEvent[]>()
  const [availableEvents, setAvailableEvents] = useState<eventHelper.IEvent[]>()

  const dispatch = useAppDispatch()

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, event: eventHelper.IEvent) => <div className="event-name"><img alt="logo" src={event.logoImageUrl}/><div className="text">{event.name}</div></div>
    },
    {
      title: 'Registrations Open',
      dataIndex: 'registrationsOpen',
      key: 'registrationsOpen',
      render: (value: boolean) => value ? "Yes" : "No"
    },
    {
      title: 'Start Date',
      dataIndex: 'dates',
      key: 'startDate',
      render: (value: {start: string, end: string}) => moment(value.start).format('LL')
    },
    {
      title: 'End Date',
      dataIndex: 'dates',
      key: 'endDate',
      render: (value: {start: string, end: string}) => moment(value.end).format('LL')
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: eventHelper.IEvent) => <Button danger onClick={() => unregisterEvent(record.id)} loading={unregisteringEvent === record.id}>Unregister</Button>
    },
  ];

  //Populate events
  useEffect(() => {
    eventHelper.getEvents().then((e) => {
      setAvailableEvents(e)
    })
  }, [])

  useEffect(() => {
    if(!registeredEvents) return

    const promises: Promise<void>[] = []
    const eventDetails: eventHelper.IEvent[] = []
    for(const eventId of registeredEvents) {
      const promise = eventHelper.getEventDetails(eventId).then((event) => {
        if(event) {
          eventDetails.push(event)
        }
      })
      promises.push(promise)
    }

    //Wait for all the events to return before setting the registered events
    Promise.all(promises)
    .then(() => {
      setRegisteredEventDetails(eventDetails)
    })
  }, [registeredEvents])

  const registerEvent = async () => {
    setRegistering(true)
    await cloudFunction("manageEventRegistration", {
      action: "register",
      eventId: selectedEvent
    })
    dispatch(partnerReducer.registerEvent(selectedEvent))
    setRegistering(false)
    setSelectedEvent(undefined)
  }

  const unregisterEvent = async (eventId: string) => {
    setUnregisteringEvent(eventId)
    await cloudFunction("manageEventRegistration", {
      action: "unregister",
      eventId: eventId
    })
    dispatch(partnerReducer.unregisterEvent(eventId))
    setUnregisteringEvent(undefined)
  }

  const renderNoEvents = () => {
    return (
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: "10px 0 ", color: "var(--ion-color-medium)"}}>
        <span>No events available for registration</span>
      </div>
    )
  }

  const nonRegisteredEvents = availableEvents?.filter((e) => !registeredEvents?.includes(e.id) && e.registrationsOpen)

  return (
    <div className={className}>
      <div className="register-event">
        <div className="register-label">
          Register for an event
        </div>
        <div className="register-control">
          <Select className="register-event-select" placeholder="Select an event" notFoundContent={renderNoEvents()} value={selectedEvent} onChange={(e) => setSelectedEvent(e)}>
            {
              nonRegisteredEvents?.map((event) => <Option key={event.id} value={event.id}><div className="event-select-option"><img alt="logo" src={event.logoImageUrl}/><div className="text">{event.name}</div></div></Option>)
            }
          </Select>
          <Button type="primary" disabled={!selectedEvent} onClick={registerEvent} loading={registering}>Register</Button>
        </div>
      </div>
      <Divider />
      <div className="registered-title">
        Registered Events
      </div>
      {
        registeredEventsDetails ?
        <Table locale={{ emptyText: 'No registered events' }} dataSource={registeredEventsDetails} columns={columns} />
        :
        <div>No registered events</div>
      }
    </div>
  )
}
