import { IOrderExt, OrderStatus, DateFormat } from "@tiffin/core"

import { IonChip, IonIcon, useIonRouter } from "@ionic/react";
import { Card } from "antd";

import moment from "moment";
import { OrdersPieChart } from "./OrdersPieChart";
import { calculateRevenue } from "../common";
import { chevronForward } from "ionicons/icons";

interface IProps {
  date: string,
  orders: IOrderExt[],
  paused: boolean
}

export function DaySummaryCard(props: IProps) {
  
  const router = useIonRouter()

  function constructData() {
    let unconfirmedCount = 0
    let confirmedCount = 0
    let readyCount = 0
    let completeCount = 0

    for(let order of props.orders) {
      if(order.status === OrderStatus.Unconfirmed) {
        unconfirmedCount++
      } else if(order.status === OrderStatus.Confirmed) {
        confirmedCount++
      } else if(order.status === OrderStatus.Ready) {
        readyCount++
      } else if(order.status === OrderStatus.Completed) {
        completeCount++
      }
    }

    return [
      { key: OrderStatus.Unconfirmed, name: 'Requires Action', value: unconfirmedCount },
      { key: OrderStatus.Confirmed, name: 'Confirmed', value: confirmedCount },
      { key: OrderStatus.Ready, name: 'Ready', value: readyCount },
      { key: OrderStatus.Completed, name: 'Complete', value: completeCount },
    ]
  }

  function dateTitle() {
    let date = moment(props.date, DateFormat).startOf('day')
    if(date.isSame(moment().startOf('day'))) {
      return "Today"
    } else if(date.isSame((moment().startOf('day')).add(1,'day'))) {
      return "Tomorrow"
    } else {
      return date.format("dddd, D MMMM YYYY")
    }
  }

  const data = constructData()

  return (
    <Card 
      className="dashboard-card"
      onClick={() => router.push(`/main/dashboard/${encodeURIComponent(props.date)}`) }
      title={
        <>
          <div>
            <span>{dateTitle()}</span>
            {
              props.paused &&
              <IonChip color="warning">
                Paused
              </IonChip>
            }
          </div>
          <IonIcon icon={chevronForward} />
        </>
      }
    >
      <OrdersPieChart 
        data={data} 
        total={calculateRevenue(props.orders)}
      />
    </Card>
  )
}