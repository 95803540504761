import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, indexedDBLocalPersistence, initializeAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { Capacitor } from '@capacitor/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
export var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
export var app = firebase.initializeApp(firebaseConfig);
export var db = getFirestore(app);
// firestoreEmulator(db, "localhost", 8080) //Comment this line if you want to use production
function whichAuth() {
    var auth;
    if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(getApp(), {
            persistence: indexedDBLocalPersistence
        });
    }
    else {
        auth = getAuth();
    }
    return auth;
}
export var auth = whichAuth();
// authEmulator(auth, "http://localhost:9099") //Comment this line if you want to use production
export var storage = getStorage();
// storageEmulator(storage, "localhost", 9199) //Comment this line if you want to use production
export var functions = getFunctions(app);
// functionEmulator(functions, "localhost", 5001) //Comment this line if you want to use production
export function cloudFunction(name, request) {
    var callable = httpsCallable(functions, name);
    return callable(request);
}
