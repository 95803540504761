import styled from "styled-components";

interface ITifynFooter {
  className?: string,
  logoAlt: string,
  logoSrc: string
  
}

export const TifynFooter = styled(BaseTifynFooter)`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  justify-content: flex-end;
  background: #fff;
  font-size: 0.8em;
  font-weight: 600;

  .logo {
    height: 1.6em;
  }
`

function BaseTifynFooter(props: ITifynFooter) {
  return (
    <div className={props.className}>
      <span>Powered by</span>
      <img className="logo" alt={props.logoAlt} src={props.logoSrc} />
    </div>
  )
}