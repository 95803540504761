
import { auth, conversationsHelper } from '@tiffin/app-common';
import { DocumentData, QueryDocumentSnapshot, Unsubscribe } from 'firebase/firestore';

import { useEffect, useRef, useState } from 'react';
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLoading, IonPage, IonToolbar, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import { IMessage } from '@tiffin/core';
import { chatboxOutline } from 'ionicons/icons';

import { Divider } from 'antd';
import Form from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingSpinner } from './LoadingSpinner';
import isEmpty from "lodash.isempty"
import moment from 'moment';

import './ChatRoom.scss'

export function ChatRoom(props: any) {
  const unsubscribe = useRef<Unsubscribe>()
  const dummy = useRef<HTMLSpanElement>(null);

  const [hasMore, setHasMore] = useState(true)
  const [formValue, setFormValue] = useState('')
  const [loading, setLoading] = useState(true)
  const [messages, setMessages] = useState<IMessage[]>([])
  const prevDocSnap = useRef<QueryDocumentSnapshot<DocumentData>>()

  useIonViewDidEnter(() => {
    unsubscribe.current = conversationsHelper.subscribeToMessages(props.match.params.id, (snapshot) => {
      if(loading) setLoading(false);
      setMessages((messages) => {
        let records: IMessage[] = [...messages]
        snapshot.docChanges().forEach((change) => {
          if(change.type === "added") {
            let message = { id: change.doc.id, ...change.doc.data() } as IMessage
            if(!message.createdAt) {
              message.createdAt = {
                nanoseconds: 0,
                seconds: Math.round(moment().valueOf() / 1000)
              }
            }

            if(messages.length === 0) {
              records.push(message)
            } else {
              records.unshift(message)
            }
          }
        })
        return records
      })

      if(prevDocSnap.current === undefined) {
        prevDocSnap.current = snapshot.docs[snapshot.docs.length - 1]
      }

      if(snapshot.docs.length === 0) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }
    })
  }, [messages])

  useIonViewWillLeave(() => {
    setMessages([])
    prevDocSnap.current = undefined
    if(unsubscribe.current) unsubscribe.current()
    if(props.location.state?.userType === "partner") {
      conversationsHelper.resetPartnerUnreadMsgCount(props.match.params.id)
    } else if(props.location.state?.userType === "customer") {
      conversationsHelper.resetCustomerUnreadMsgCount(props.match.params.id)
    }
  })

  useEffect(() => {
    if(messages.length > 0) {
      dummy.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages])

  function handleInfiniteUpdate() {
    if(prevDocSnap.current) {
      conversationsHelper.getPrevMessages(props.match.params.id, prevDocSnap.current).then((snapshot) => {        
        //If empty list, do not replace previous doc snap
        if(snapshot.docs.length > 0) {
          prevDocSnap.current = snapshot.docs[snapshot.docs.length - 1]
        }

        let records: IMessage[] = [...messages]
        snapshot.forEach((doc) => {
          records.push({ id: doc.id, ...doc.data() } as IMessage)
        })
        setMessages(records)
        if(snapshot.docs.length === 0) {
          setHasMore(false)
        }
      })
    }
  }

  const sendMessage = async (e: any) => {
    const text = formValue
    if(isEmpty(text.trim())) {
      return;
    }
    setFormValue('');
    await conversationsHelper.sendMessage(props.match.params.id, {
      text: text,
      uid: auth.currentUser!.uid
    })
  }

  const onEnterHandler = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      sendMessage(e)
      e.preventDefault()
    }
  }

  return (
    <IonPage className="chat-room">
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/main/messages" />
        </IonButtons>
        <div className="page-header">
          <IonIcon icon={chatboxOutline}/>
          <span className="page-header-text">{props.location.state?.name}</span>
        </div>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <div className="main-content">
        <div className="messages">
          <div
            id="scrollableDiv"
            style={{
              height: "100%",
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
              padding: '20px'
            }}
          >
            {/*Put the scroll bar always on the bottom*/}
            <InfiniteScroll
              dataLength={messages.length}
              next={handleInfiniteUpdate}
              style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
              inverse={true} //
              hasMore={hasMore}
              loader={<LoadingSpinner />}
              scrollableTarget="scrollableDiv"
            >
              <span ref={dummy}></span>
              {messages && messages.map((msg, index) => {
                if((index + 1) < messages.length) {
                  const prevMsgDate = moment(messages[index + 1].createdAt.seconds * 1000).startOf('day')
                  const currMsgDate = moment(msg.createdAt.seconds * 1000).startOf('day')
                  if(!currMsgDate.isSame(prevMsgDate)) {
                    return (
                      <div key={msg.id}>
                        <Divider key={currMsgDate.format()} className='date-divider'>{currMsgDate.format('ddd, D MMM')}</Divider>
                        <ChatMessage key={msg.id} {...msg} />
                      </div>
                    )
                  }
                } else {
                  const currMsgDate = moment(msg.createdAt.seconds * 1000)
                  return (
                    <div key={msg.id}>
                      <Divider key={currMsgDate.format()} className='date-divider'>{currMsgDate.format('ddd, D MMM')}</Divider>
                      <ChatMessage key={msg.id} {...msg} />
                    </div>
                  )
                }

                return (
                  <div key={msg.id}>
                    <ChatMessage key={msg.id} {...msg} />
                  </div>
                )
              })}
            </InfiniteScroll>
          </div>
        </div>
        <Form className="message-form" onFinish={sendMessage}>
          <TextArea
            className="message-input"
            value={formValue}
            autoSize={{ minRows: 1, maxRows: 1 }}
            onChange={(e) => setFormValue(e.target.value)}
            placeholder="Message"
            onPressEnter={onEnterHandler}
            maxLength={255}
          />
          <IonButton onMouseDown={e => e.preventDefault()} type="submit" disabled={isEmpty(formValue.trim())}>Send</IonButton>
        </Form>
      </div>
      <IonLoading isOpen={loading} spinner="crescent" />
    </IonContent>
  </IonPage>
  )
}

interface IChatMessageProps {
  text: string,
  uid: string,
  createdAt: {
    nanoseconds: number,
    seconds: number
  }
}

function ChatMessage({ text, uid, createdAt }: IChatMessageProps) {

  let timestamp;
  if(createdAt?.seconds) {
    timestamp = moment(createdAt.seconds * 1000)
  }

  const messageClass = uid === auth.currentUser!.uid ? 'sent' : 'received';

  return (
    <div className={`message ${messageClass}`}>
      <div className={'message-content'}>
        <p>{text}</p>
        {
          timestamp &&
          <span className='timestamp'>{timestamp.format('h:mm a')}</span>
        }
      </div>
    </div>
  )
}
