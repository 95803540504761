import { useEffect, useRef, useState } from "react";
import { SpringModal } from "./SpringModal";
import { convertToTime } from "@tiffin/core";

import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { Picker, PickerView } from "antd-mobile";
import { PickerColumn, PickerColumnItem, PickerValue } from "antd-mobile/es/components/picker";

import './TimePicker.scss'

const MINUTE_VALUES: PickerColumnItem[] = [
  {
    label: '00',
    value: '0'
  },
  {
    label: '15',
    value: '15'
  },
  {
    label: '30',
    value: '30'
  },
  {
    label: '45',
    value: '45'
  }
];

export interface ITimePicker {
  title: string
  subTitle?: string
  onChange: (time: number) => void
  isOpen: boolean
  onClose: () => void
  defaultValue: number
  min: number
  max: number
}

export function TimePicker({defaultValue, min, max, ...props}: ITimePicker) {

  const [selectedHour, setSelectedHour] = useState<number>()
  const [selectedMinute, setSelectedMinute] = useState<number>()
  const [selectedPeriod, setSelectedPeriod] = useState<"AM" | "PM">()
  const [availableMinutes, setAvailableMinutes] = useState<PickerColumnItem[]>();
  const [availableHours, setAvailableHours] = useState<PickerColumnItem[]>();
  const [availablePeriods, setAvailablePeriods] = useState<PickerColumnItem[]>();

  //Sets the default selected hour
  const setDefaultRef = useRef(false);
  useEffect(() => {
    if(!setDefaultRef.current) {
      const defaultDate = convertToTime(defaultValue);
      setSelectedHour(defaultDate.hour)
      setSelectedMinute(defaultDate.minute)
      setSelectedPeriod(defaultDate.hour < 12 ? 'AM' : 'PM')
      setDefaultRef.current = true;
    }
  }, [defaultValue])

  //Calculate available periods
  useEffect(() => {
    //Round to next 15 minute
    let roundedMin = min;
    const minRemainder = 15 - min % 15;
    if(minRemainder < 15) {
      roundedMin += minRemainder
    }
      
    //Round to last 15 minute
    let roundedMax = max;
    const maxRemainder = min % 15;
    if(maxRemainder > 0) {
      roundedMax -= maxRemainder
    }

    let roundedMinHour = Math.floor(roundedMin / 60);
    let roundedMaxHour = Math.floor(roundedMax / 60);

    const periodColumns: PickerColumnItem[] = []
    if(roundedMinHour < 12) {
      periodColumns.push({
        label: 'AM',
        value: 'AM'
      })
    }

    if(roundedMaxHour >= 12) {
      if(selectedHour === 24) {
        periodColumns.push({
          label: '(Midnight)',
          value: 'PM'
        })
      } else {
        periodColumns.push({
          label: 'PM',
          value: 'PM'
        })
      }
    }

    setAvailablePeriods(periodColumns)
  }, [min, max, selectedHour])

  //Calculate available hours based on selected date, min and max and period
  useEffect(() => {
    //Round to next 15 minute
    let roundedMin = min;
    const minRemainder = 15 - min % 15;
    if(minRemainder < 15) {
      roundedMin += minRemainder
    }
      
    //Round to last 15 minute
    let roundedMax = max;
    const maxRemainder = min % 15;
    if(maxRemainder > 0) {
      roundedMax -= maxRemainder
    }

    const hourColumns: PickerColumnItem[] = [];
    let roundedMinHour = Math.floor(roundedMin / 60);
    let roundedMaxHour = Math.floor(roundedMax / 60);

    if(selectedPeriod === "AM" && roundedMaxHour > 12) {
      roundedMaxHour = 11
    }

    if(selectedPeriod === "PM" && roundedMinHour < 12) {
      roundedMinHour = 12
    }

    for(let i=roundedMinHour; i <= roundedMaxHour; i++) {
      let hour = i % 12
      hourColumns.push({
        label: hour === 0 ? '12' : hour < 10 ? `0${hour}` : hour.toString(),
        value: i.toString()
      })
    }

    setAvailableHours(hourColumns)
  }, [min, max, selectedPeriod])

  //Calculate available minutes based on selected hour
  useEffect(() => {
    if(selectedHour !== undefined && max !== undefined && min !== undefined) {
      const minHour = Math.floor(min / 60);
      const maxHour = Math.floor(max / 60);

      if(selectedHour <= minHour) {
        const minMinute = min % 60;
        setAvailableMinutes(MINUTE_VALUES.filter((val) => parseInt(val.value as string) >= minMinute))
      } else if(selectedHour >= maxHour) {
        const maxMinute = max % 60;
        setAvailableMinutes(MINUTE_VALUES.filter((val) => parseInt(val.value as string) <= maxMinute))
      } else {
        setAvailableMinutes(MINUTE_VALUES)
      }
    }
  }, [selectedHour, max, min])

  const handleOnSelect = (value: PickerValue[]) => {
    if(value[0] !== null && value[0] !== selectedHour?.toString()) {
      setSelectedHour(parseInt(value[0] as string))
    }
    if(value[1] !== null && value[1] !== selectedMinute?.toString()) {
      setSelectedMinute(parseInt(value[1] as string))
    }
    if(value[2] !== null && value[2] !== selectedPeriod) {
      setSelectedPeriod(value[2] as ("AM" | "PM"))
    }
  }

  const handleOnConfirm = () => {
    if(selectedHour !== undefined && selectedMinute !== undefined) {
      props.onChange(selectedHour * 60 + selectedMinute);
      props.onClose();
    } else {
      props.onClose();
    }
  }
  
  const hour = Math.floor(defaultValue / 60).toString();
  const minute = (defaultValue % 60).toString();
  let period: "AM" | "PM";
  if(Math.floor(defaultValue / 60) < 12) {
    period = "AM"
  } else {
    period = "PM"
  }
  let defaultPickerValue: PickerValue[] = [hour, minute, period]

  const renderPickerTitle = () => {
    return (
      <div className="header">
        <span className="title">{props.title}</span>
        <span className="subtitle">{props.subTitle && props.subTitle}</span>
      </div>
    )
  }

  const body = (columns: PickerColumn[]) => {
    if(isMobile && !isTablet && !isDesktop) {
      return (
        <Picker
          className="time-picker"
          visible={props.isOpen}
          title={renderPickerTitle()}
          columns={columns}
          defaultValue={defaultPickerValue}
          onSelect={handleOnSelect}
          onConfirm={handleOnConfirm}
          onCancel={props.onClose}
          confirmText="Confirm"
          cancelText="Cancel"
        />
      )
    } else {
      return (
        <SpringModal
          isOpen={props.isOpen}
          onCancel={props.onClose}
          onOk={handleOnConfirm}
          okText="Confirm"
          title={props.title}
        >
          {
            props.subTitle &&
            <div>{props.subTitle}</div>
          }
          {
            <PickerView
              className="time-picker"
              columns={columns}
              defaultValue={defaultPickerValue}
              onChange={handleOnSelect}
            />
          }
          
        </SpringModal>
      )
    }
  }

  let columns: PickerColumn[] | undefined;
  if(availableHours && availableMinutes && availablePeriods) {
    columns = [
      availableHours,
      availableMinutes,
      availablePeriods
    ]
  }
  
  return (
    <>
    {
      columns &&
      body(columns)
    }
    </>
  )
}