import { useEffect, useMemo, useRef, useState } from "react"
import { convertToTime } from "@tiffin/core"

import { IonIcon } from "@ionic/react"
import { arrowForwardOutline } from "ionicons/icons"
import { TimePicker } from "./TimePicker"
import { isMobile } from "react-device-detect";
import { TimePicker as AntTimePicker } from 'antd';
import dayjs from 'dayjs';

import './TimeRangePicker.scss'

interface ITimeRangePickerProps {
  initialValue?: {start: number, end: number},
  onChange?: (start: number, end: number) => void,
  className?: string
}

export function TimeRangePicker(props: ITimeRangePickerProps) {

  const [showStartPicker, setShowStartPicker] = useState(false);
  const [startTime, setStartTime] = useState<number>(props.initialValue.start)

  const [showEndPicker, setShowEndPicker] = useState(false);
  const [endTime, setEndTime] = useState<number>(props.initialValue.end)

  const initialLoad = useRef(true);

  useEffect(() => {
    if(!initialLoad.current && props.onChange && startTime !== undefined && endTime !== undefined) {
      props.onChange(startTime, endTime)
    } else {
      initialLoad.current = false;
    }
  }, [startTime, endTime])

  const startTimeFormatted = convertToTime(startTime).formatted12
  const endTimeFormatted = convertToTime(endTime).formatted12

  const defaultValues: any = useMemo(() => {
    if(startTime !== undefined && endTime !== undefined) {
      return [dayjs().startOf('day').add(startTime, 'minute'), dayjs().startOf('day').add(endTime, 'minute')]
    }
  }, [startTime, endTime])

  const handleAntTimePickerChange = (values) => {
    setStartTime(values[0].hour() * 60 + values[0].minute())
    const endTime = values[1].hour() * 60 + values[1].minute()
    setEndTime(endTime === 0 ? 1440 : values[1].hour() * 60 + values[1].minute())
  }

  return (
    <>
      {
        isMobile ?
        <>
          <div className="mobile-time-range-picker">
            <div
              className="ant-input"
              onClick={() => setShowStartPicker(true)}
            >
              {startTime >= 0 ? startTimeFormatted : "Start time"}
            </div>
            <div className="range-arrow">
              <IonIcon icon={arrowForwardOutline}/>
            </div>
            <div
              className="ant-input"
              onClick={() => setShowEndPicker(true)}
            >
              {endTime >= 0 ? endTimeFormatted : "End time"}
            </div>
          </div>
          <TimePicker 
            isOpen={showStartPicker}
            onClose={() => setShowStartPicker(false)}
            title="Select open time"
            defaultValue={props.initialValue.start ?? 480}
            min={0}
            max={endTime ?? 1440}
            onChange={(time) => setStartTime(time)}
          />
          <TimePicker 
            isOpen={showEndPicker}
            onClose={() => setShowEndPicker(false)}
            title="Select close time"
            defaultValue={props.initialValue.end ?? 1020}
            min={startTime ?? 0}
            max={1440}
            onChange={(time) => setEndTime(time)}
          />
        </>
        :
        <div className="time-range-picker">
          <AntTimePicker.RangePicker
            use12Hours
            format="h:mm A"
            minuteStep={15}
            defaultValue={defaultValues}
            onChange={handleAntTimePickerChange}
          />
        </div>
      }
    </>
  )
}