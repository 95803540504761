var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OrderType, DateFormat, getCutOffDate, APP_ID } from "@tiffin/core";
import { cleanupCart, getStoreCart, updateCart, updateCartDeliveryAddress, updateCartOrderType, updateOrderTime } from "../firebase-helpers/cart";
import { getTiffin } from "../firebase-helpers/browse";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import isEqual from "lodash.isequal";
import isEmpty from "lodash.isempty";
// Define the initial state using that type
var initialState = {
    status: "unknown",
    carts: {
        "APP": {
            orderType: OrderType.Pickup,
            times: {}
        }
    }
};
export var EmptyCart = {
    orderType: OrderType.Pickup,
    times: {}
};
var deleteItem = function (cart, date, index) {
    cart.items[date].splice(index, 1);
    if (Object.keys(cart.items[date]).length === 0) {
        delete cart.items[date];
        if (cart.times) {
            delete cart.times[date];
        }
    }
};
var addItem = function (cart, item) {
    if (!cart.items)
        cart.items = {};
    //Add date group if missing
    if (!cart.items[item.date]) {
        cart.items[item.date] = [];
    }
    var matchingItem = cart.items[item.date].find(function (cartItem) { return cartItem.details.id === item.details.id && isEqual(cartItem.modifiers, item.modifiers); });
    if (matchingItem) {
        matchingItem.count += item.count;
    }
    else {
        cart.items[item.date].push({
            details: item.details,
            count: item.count,
            modifiers: item.modifiers
        });
    }
};
var processCart = function (partnerId, partner, isApp, authenticateStatus, tableNumber) { return __awaiter(void 0, void 0, void 0, function () {
    var shouldUpdate, cart, _a, _b, _c, _i, key, date, cutOffTime;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                shouldUpdate = false;
                return [4 /*yield*/, getStoreCart(partnerId, isApp, authenticateStatus, tableNumber)];
            case 1:
                cart = _d.sent();
                if (!(cart === null)) return [3 /*break*/, 2];
                return [2 /*return*/, null];
            case 2:
                if (!cart.items) return [3 /*break*/, 10];
                _a = cart.items;
                _b = [];
                for (_c in _a)
                    _b.push(_c);
                _i = 0;
                _d.label = 3;
            case 3:
                if (!(_i < _b.length)) return [3 /*break*/, 7];
                _c = _b[_i];
                if (!(_c in _a)) return [3 /*break*/, 6];
                key = _c;
                date = moment(key, DateFormat);
                if (!!partner) return [3 /*break*/, 5];
                return [4 /*yield*/, getTiffin(partnerId !== null && partnerId !== void 0 ? partnerId : cart.partnerId)];
            case 4:
                partner = _d.sent();
                _d.label = 5;
            case 5:
                cutOffTime = getCutOffDate(date, partner, cart.orderType);
                if (!cutOffTime) {
                    if (cart.items[key])
                        delete cart.items[key];
                    shouldUpdate = true;
                    return [3 /*break*/, 6];
                }
                //Passed the cut off time
                if (moment().isAfter(cutOffTime)) {
                    //Remove date group from cart
                    if (cart.items[key])
                        delete cart.items[key];
                    shouldUpdate = true;
                }
                _d.label = 6;
            case 6:
                _i++;
                return [3 /*break*/, 3];
            case 7:
                if (!shouldUpdate) return [3 /*break*/, 10];
                if (!(Object.keys(cart.items).length === 0)) return [3 /*break*/, 8];
                cart.times = {};
                //clear cart - don't wait as this is a cloud function call
                cleanupCart(isApp ? APP_ID : partnerId, cart.orderType === OrderType.DineIn);
                return [3 /*break*/, 10];
            case 8: return [4 /*yield*/, updateCart(cart, isApp ? APP_ID : partnerId)];
            case 9:
                _d.sent();
                _d.label = 10;
            case 10: return [2 /*return*/, cart];
        }
    });
}); };
export var fetchCart = createAsyncThunk("cartMulti/fetchCart", function (payload, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, cart;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    return [4 /*yield*/, processCart(state.store.details.id, state.store.details, false, state.authenticate.status, payload.tableNumber)];
                case 1:
                    cart = _b.sent();
                    if (cart === null && !!payload.tableNumber) {
                        cart = {
                            orderType: OrderType.DineIn,
                            times: {},
                            items: {},
                            tableNumber: payload.tableNumber
                        };
                    }
                    else if ((cart === null && state.store.details.offerPickup) || (cart && cart.orderType === OrderType.Delivery && !state.store.details.offerDelivery)) {
                        cart = {
                            orderType: OrderType.Pickup,
                            times: {},
                            items: {}
                        };
                    }
                    else if ((cart === null && !state.store.details.offerPickup) || (cart && cart.orderType === OrderType.Pickup && !state.store.details.offerPickup)) {
                        cart = {
                            orderType: OrderType.Delivery,
                            times: {},
                            items: {}
                        };
                    }
                    return [2 /*return*/, {
                            partnerId: state.store.details.id,
                            cart: cart
                        }];
            }
        });
    });
});
export var fetchAppCart = createAsyncThunk("cartMulti/fetchAppCart", function (payload, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, cart;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    return [4 /*yield*/, processCart(undefined, undefined, true, state.authenticate.status)];
                case 1:
                    cart = _b.sent();
                    return [2 /*return*/, {
                            cart: cart
                        }];
            }
        });
    });
});
export var updateDeliveryAddress = createAsyncThunk("cartMulti/updateDeliveryAddress", function (payload, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    //Remove undefined subpremise property since firebase does not handle undefined properties
                    if (payload.address.subpremise === undefined) {
                        delete payload.address.subpremise;
                    }
                    if (!(state.authenticate.status !== "guest")) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateCartDeliveryAddress(payload.address, payload.id)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2: return [2 /*return*/, {
                        partnerId: payload.id,
                        address: payload.address
                    }];
            }
        });
    });
});
export var updateOrderType = createAsyncThunk("cartMulti/updateOrderType", function (payload, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    if (!(state.authenticate.status !== "guest")) return [3 /*break*/, 4];
                    if (!payload.clearCart) return [3 /*break*/, 2];
                    return [4 /*yield*/, cleanupCart(payload.id)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2: return [4 /*yield*/, updateCartOrderType(payload)];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4: return [2 /*return*/, payload];
            }
        });
    });
});
export var updateTime = createAsyncThunk("cartMulti/updateTime", function (payload, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    return [4 /*yield*/, updateOrderTime(cloneDeep(state.cartMulti.carts[payload.id].times), payload.date, payload.time, payload.id)];
                case 1:
                    _b.sent();
                    return [2 /*return*/, payload];
            }
        });
    });
});
export var addItemToCart = createAsyncThunk("cartMulti/addItemToCart", function (payload, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var cart, item, partner;
        var _b;
        return __generator(this, function (_c) {
            cart = cloneDeep(getState().cartMulti.carts[payload.isApp ? APP_ID : payload.partnerId]);
            if (payload.isApp && (!cart.partnerId || !isEqual(cart.partnerId, payload.partnerId))) {
                cart.partnerId = payload.partnerId;
                cart.partnerName = payload.partnerName;
                cart.items = {};
                cart.times = {};
            }
            if (!cart.items)
                cart.items = {};
            item = payload.item;
            addItem(cart, item);
            if (!payload.updatedTime && !((_b = cart.times) === null || _b === void 0 ? void 0 : _b[item.date])) {
                partner = void 0;
                if (payload.isApp) {
                    partner = getState().search.tiffinInView;
                }
                else {
                    partner = getState().store.details;
                }
                //Can only set tot ASAP if pre orders are disabled and the date is for today
                if (!partner.preparationEnabled && moment(item.date, DateFormat).isSame(moment(), 'day')) {
                    if (!cart.times) {
                        cart.times = {};
                    }
                    cart.times[item.date] = "now";
                }
            }
            //Update the time for this date group
            if (payload.updatedTime) {
                if (!cart.times) {
                    cart.times = {};
                }
                cart.times[item.date] = payload.updatedTime;
            }
            updateCart(cart, payload.isApp ? APP_ID : payload.partnerId, cart.orderType === OrderType.DineIn);
            return [2 /*return*/, {
                    cart: cart,
                    id: payload.isApp ? APP_ID : payload.partnerId
                }];
        });
    });
});
export var updateItemCountInCart = createAsyncThunk("cartMulti/incrementItemInCart", function (payload, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var cart;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    cart = cloneDeep(getState().cartMulti.carts[payload.isApp ? APP_ID : payload.partnerId]);
                    cart.items[payload.itemDate][payload.index].count = payload.itemCount;
                    return [4 /*yield*/, updateCart(cart, payload.isApp ? APP_ID : payload.partnerId, cart.orderType === OrderType.DineIn)];
                case 1:
                    _b.sent();
                    return [2 /*return*/, {
                            cart: cart,
                            id: payload.isApp ? APP_ID : payload.partnerId
                        }];
            }
        });
    });
});
export var updateItemInCart = createAsyncThunk("cartMulti/updateItemInCart", function (payload, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var cart, item;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    cart = cloneDeep(getState().cartMulti.carts[payload.isApp ? APP_ID : payload.partnerId]);
                    if (cart.partnerId !== payload.partnerId) {
                        throw ("Store id mismatch");
                    }
                    item = payload.item;
                    if (item.date !== item.prevDate) {
                        //Remove item from date group
                        deleteItem(cart, item.prevDate, payload.index);
                        //Add item to new date group
                        addItem(cart, {
                            id: item.id,
                            details: item.details,
                            date: item.date,
                            count: item.count,
                            modifiers: item.modifiers
                        });
                    }
                    else {
                        cart.items[item.date][item.id].count = item.count;
                    }
                    return [4 /*yield*/, updateCart(cart, payload.isApp ? APP_ID : payload.partnerId, cart.orderType === OrderType.DineIn)];
                case 1:
                    _b.sent();
                    return [2 /*return*/, {
                            cart: cart,
                            id: payload.partnerId
                        }];
            }
        });
    });
});
export var removeItemFromCart = createAsyncThunk("cartMulti/removeItemFromCart", function (payload, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var cart;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    cart = cloneDeep(getState().cartMulti.carts[payload.isApp ? APP_ID : payload.partnerId]);
                    deleteItem(cart, payload.date, payload.index);
                    if (!(Object.keys(cart.items).length === 0)) return [3 /*break*/, 2];
                    //clear cart
                    return [4 /*yield*/, cleanupCart(payload.isApp ? APP_ID : payload.partnerId, cart.orderType === OrderType.DineIn)];
                case 1:
                    //clear cart
                    _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, updateCart(cart, payload.isApp ? APP_ID : payload.partnerId, cart.orderType === OrderType.DineIn)];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4: return [2 /*return*/, {
                        cart: cart,
                        id: payload.isApp ? APP_ID : payload.partnerId
                    }];
            }
        });
    });
});
export var clearCart = createAsyncThunk("cartMulti/clearCart", function (payload, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var cart;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    cart = getState().cartMulti.carts[payload.isApp ? APP_ID : payload.partnerId];
                    return [4 /*yield*/, cleanupCart(payload.isApp ? APP_ID : payload.partnerId, cart.orderType === OrderType.DineIn)];
                case 1:
                    _b.sent();
                    return [2 /*return*/, {
                            id: payload.partnerId,
                            isApp: payload.isApp
                        }];
            }
        });
    });
});
export var cartSlice = createSlice({
    name: "cartMulti",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(fetchCart.pending, function (state, action) {
            if (!action.meta.arg.refetch) {
                state.status = "loading";
            }
        });
        builder.addCase(fetchCart.fulfilled, function (state, action) {
            state.status = "idle";
            if (!state.carts[action.payload.partnerId]) {
                state.carts[action.payload.partnerId] = EmptyCart;
                state.carts[action.payload.partnerId].partnerId = action.payload.partnerId;
            }
            if (action.payload.cart !== null) {
                if (action.payload.cart.deliveryAddress)
                    state.carts[action.payload.partnerId].deliveryAddress = action.payload.cart.deliveryAddress;
                if (action.payload.cart.orderType !== undefined)
                    state.carts[action.payload.partnerId].orderType = action.payload.cart.orderType;
                if (action.payload.cart.items)
                    state.carts[action.payload.partnerId].items = action.payload.cart.items;
                if (action.payload.cart.times)
                    state.carts[action.payload.partnerId].times = action.payload.cart.times;
                if (action.payload.cart.orderId)
                    state.carts[action.payload.partnerId].orderId = action.payload.cart.orderId;
                if (action.payload.cart.tableNumber)
                    state.carts[action.payload.partnerId].tableNumber = action.payload.cart.tableNumber;
                state.carts[action.payload.partnerId].partnerId = action.payload.partnerId;
            }
            else {
                delete state.carts[action.payload.partnerId].items;
                delete state.carts[action.payload.partnerId].times;
                delete state.carts[action.payload.partnerId].orderId;
            }
        });
        builder.addCase(fetchCart.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(fetchAppCart.pending, function (state, action) {
            if (!action.meta.arg) {
                state.status = "loading";
            }
        });
        builder.addCase(fetchAppCart.fulfilled, function (state, action) {
            state.status = "idle";
            if (!state.carts[APP_ID]) {
                state.carts[APP_ID] = EmptyCart;
            }
            if (action.payload.cart !== null) {
                if (action.payload.cart.deliveryAddress)
                    state.carts[APP_ID].deliveryAddress = action.payload.cart.deliveryAddress;
                if (action.payload.cart.orderType)
                    state.carts[APP_ID].orderType = action.payload.cart.orderType;
                if (action.payload.cart.items)
                    state.carts[APP_ID].items = action.payload.cart.items;
                if (action.payload.cart.times)
                    state.carts[APP_ID].times = action.payload.cart.times;
                if (action.payload.cart.partnerId)
                    state.carts[APP_ID].partnerId = action.payload.cart.partnerId;
                if (action.payload.cart.partnerName)
                    state.carts[APP_ID].partnerName = action.payload.cart.partnerName;
            }
            else {
                delete state.carts[APP_ID].items;
                delete state.carts[APP_ID].times;
                delete state.carts[APP_ID].partnerId;
                delete state.carts[APP_ID].partnerName;
            }
        });
        builder.addCase(fetchAppCart.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(updateDeliveryAddress.fulfilled, function (state, action) {
            state.carts[action.payload.partnerId].deliveryAddress = action.payload.address;
        });
        builder.addCase(updateDeliveryAddress.rejected, function (state, action) {
            state.error = action.error.message;
        });
        builder.addCase(updateOrderType.pending, function (state, action) {
            //Only need to set to loading if clearing the cart
            if (action.meta.arg.clearCart) {
                state.status = "loading";
            }
        });
        builder.addCase(updateOrderType.fulfilled, function (state, action) {
            state.status = "idle";
            state.carts[action.payload.id].orderType = action.payload.orderType;
            if (action.payload.deliveryAddress) {
                state.carts[action.payload.id].deliveryAddress = action.payload.deliveryAddress;
            }
            else {
                delete state.carts[action.payload.id].deliveryAddress;
            }
            if (action.payload.clearCart) {
                delete state.carts[action.payload.id].items;
            }
        });
        builder.addCase(updateOrderType.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(updateTime.pending, function (state) {
            state.status = "loading";
        });
        builder.addCase(updateTime.fulfilled, function (state, action) {
            state.status = "idle";
            if (!state.carts[action.payload.id].times) {
                state.carts[action.payload.id].times = {};
            }
            state.carts[action.payload.id].times[action.payload.date] = action.payload.time;
        });
        builder.addCase(updateTime.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(addItemToCart.fulfilled, function (state, action) {
            state.carts[action.payload.id].partnerId = action.payload.cart.partnerId;
            state.carts[action.payload.id].partnerName = action.payload.cart.partnerName;
            state.carts[action.payload.id].items = action.payload.cart.items;
            state.carts[action.payload.id].times = action.payload.cart.times;
        });
        builder.addCase(addItemToCart.rejected, function (state, action) {
            state.error = action.error.message;
        });
        builder.addCase(updateItemCountInCart.fulfilled, function (state, action) {
            state.carts[action.payload.id].items = action.payload.cart.items;
        });
        builder.addCase(updateItemCountInCart.rejected, function (state, action) {
            state.error = action.error.message;
        });
        builder.addCase(updateItemInCart.fulfilled, function (state, action) {
            state.carts[action.payload.id].items = action.payload.cart.items;
        });
        builder.addCase(updateItemInCart.rejected, function (state, action) {
            state.error = action.error.message;
        });
        builder.addCase(removeItemFromCart.pending, function (state, action) {
            //Only set to loading if removing last item from cart, since this will
            //call a cloud function and take longer, so hence the loading screen
            if (Object.keys(state.carts[action.meta.arg.isApp ? APP_ID : action.meta.arg.partnerId].items).length === 1 &&
                Object.keys(state.carts[action.meta.arg.isApp ? APP_ID : action.meta.arg.partnerId].items[action.meta.arg.date]).length === 1 &&
                action.meta.arg.index === 0) {
                state.status = "loading";
            }
        });
        builder.addCase(removeItemFromCart.fulfilled, function (state, action) {
            state.status = "idle";
            if (!isEmpty(action.payload.cart.items)) {
                state.carts[action.payload.id].items = action.payload.cart.items;
            }
            else {
                if (action.payload.id === APP_ID) {
                    delete state.carts[action.payload.id].items;
                    delete state.carts[action.payload.id].partnerName;
                    delete state.carts[action.payload.id].partnerId;
                }
                else {
                    delete state.carts[action.payload.id].items;
                }
            }
            if (action.payload.cart.times) {
                state.carts[action.payload.id].times = action.payload.cart.times;
            }
            else {
                delete state.carts[action.payload.id].times;
            }
        });
        builder.addCase(removeItemFromCart.rejected, function (state, action) {
            state.error = action.error.message;
        });
        builder.addCase(clearCart.pending, function (state) {
            state.status = "loading";
        });
        builder.addCase(clearCart.fulfilled, function (state, action) {
            state.status = "idle";
            if (action.payload.isApp) {
                delete state.carts[APP_ID].items;
                delete state.carts[APP_ID].partnerId;
                delete state.carts[APP_ID].partnerName;
            }
            else {
                delete state.carts[action.payload.id].items;
                delete state.carts[action.payload.id].partnerId;
                delete state.carts[action.payload.id].partnerName;
            }
        });
        builder.addCase(clearCart.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
    }
});
export default cartSlice.reducer;
