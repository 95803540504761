var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { cloudFunction } from "../firebase";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Define the initial state
var initialState = {
    status: "unknown",
    transactions: [],
    hasMoreTransactions: true
};
export var getTransactions = createAsyncThunk("income/getTransactions", function (req) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, cloudFunction("partnerTransactions", { limit: req.limit })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, {
                        transactions: response.data.transactions.data,
                        hasMore: response.data.transactions.has_more
                    }];
        }
    });
}); });
export var getNextTransactions = createAsyncThunk("income/getNextTransactions", function (req, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, startingAfterTransactionId, lastTransactionWithTransfer, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    //No need to fetch if there aren't any more transactions
                    if (!state.income.hasMoreTransactions) {
                        return [2 /*return*/];
                    }
                    startingAfterTransactionId = state.income.transactions && state.income.transactions.length > 0 ? state.income.transactions[state.income.transactions.length - 1].id : undefined;
                    lastTransactionWithTransfer = state.income.transactions && state.income.transactions.length > 0 ? state.income.transactions.findLast(function (val) { return !!val.transferId; }) : undefined;
                    return [4 /*yield*/, cloudFunction("partnerTransactions", {
                            startingAfter: {
                                transactionId: startingAfterTransactionId,
                                transferId: lastTransactionWithTransfer.transferId
                            },
                            limit: req.limit
                        })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, {
                            transactions: response.data.transactions.data,
                            hasMore: response.data.transactions.has_more
                        }];
            }
        });
    });
});
export var getLatestTransactions = createAsyncThunk("income/getLatestTransactions", function (req, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, request, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    request = {
                        limit: req.limit
                    };
                    if (state.income.transactions && state.income.transactions.length > 0) {
                        request.endingBefore = state.income.transactions[0].id;
                    }
                    return [4 /*yield*/, cloudFunction("partnerTransactions", request)];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, {
                            transactions: response.data.transactions.data,
                            hasMore: response.data.transactions.has_more
                        }];
            }
        });
    });
});
export var incomeSlice = createSlice({
    name: "income",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(getTransactions.pending, function (state) {
            state.status = "updating";
        });
        builder.addCase(getTransactions.fulfilled, function (state, action) {
            state.status = "idle";
            state.transactions = action.payload.transactions;
            state.hasMoreTransactions = action.payload.hasMore;
        });
        builder.addCase(getTransactions.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        }),
            builder.addCase(getNextTransactions.pending, function (state) {
                state.status = "updating";
            });
        builder.addCase(getNextTransactions.fulfilled, function (state, action) {
            state.status = "idle";
            if (action.payload) {
                state.hasMoreTransactions = action.payload.hasMore;
                if (action.payload.transactions.length > 0) {
                    state.transactions = __spreadArray(__spreadArray([], state.transactions, true), action.payload.transactions, true);
                }
            }
        });
        builder.addCase(getNextTransactions.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(getLatestTransactions.pending, function (state) {
            state.status = "updating";
        });
        builder.addCase(getLatestTransactions.fulfilled, function (state, action) {
            state.status = "idle";
            state.hasMoreTransactions = action.payload.hasMore;
            if (action.payload.transactions.length > 0) {
                state.transactions = __spreadArray(__spreadArray([], action.payload.transactions, true), state.transactions, true);
            }
        });
        builder.addCase(getLatestTransactions.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
    }
});
export default incomeSlice.reducer;
