import { Constants } from '@tiffin/app-common'
import { PieChart, Pie, Cell, Tooltip, Label, Legend } from 'recharts';

import './OrdersPieChart.scss'

interface IProps {
  data: {
    name: string,
    value: number
  }[],
  total?: number
}

const COLORS = [
  Constants.UNCONFIRMED_COLOR, 
  Constants.CONFIRMED_COLOR, 
  Constants.READY_COLOR, 
  Constants.COMPLETE_COLOR
];

export function OrdersPieChart(props: IProps) {

  const renderLegend = (props: any) => {
    const { payload } = props;
  
    return (
      <ul>
        {
          payload.map((entry: any, index: number) => 
            <div key={`item-${index}`} className="legend-item">
              <div className="legend-indicator" style={{borderColor: entry.color, backgroundColor: entry.payload.value ? entry.color : 'none', color: entry.payload.value ? '$fff' : entry.color}}>{entry.payload.value}</div>
              {entry.value}
            </div>
          )
        }
      </ul>
    );
  }

  return (
    <PieChart className="orders-pie-chart" width={320} height={150}>
      <Pie 
        data={props.data} 
        dataKey="value"
        cx="70" 
        cy="50%" 
        innerRadius={60}
        outerRadius={70}
        startAngle={450}
        endAngle={90}
        fill="#8884d8"
      >
      {props.data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} stroke={COLORS[index % COLORS.length]} />
      ))}
      {
        props.total !== undefined &&
        <Label className="center-value" value={`$${(props.total / 100).toFixed(2)}`} position="center" />
      }
    </Pie>
    <Tooltip />
    <Legend content={renderLegend} layout="vertical" verticalAlign="middle" align="right" />
  </PieChart>
  )
}