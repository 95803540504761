
import styled from "styled-components";
import { useEffect, useMemo, useRef, useState } from "react"

import { useAppDispatch } from "../../hooks"
import { ordersHelper, cartMultiReducer, cloudFunction, Utils, Constants, CartItems, CartStatus, auth } from "@tiffin/app-common"
import { APP_ID, calculatePrice, calculatePromoDiscount, DateFormat, IAddressExtended, IDeliveryInformation, IPartner, IPromo, OrderType } from "@tiffin/core"
import { TiffinTimePicker } from "./TiffinTimePicker"
import { toast } from "../Toast"
import DeliveryDetailsModal from "../DeliveryDetailsModal"
import { Loading } from "../Loading"
import { SpringModal } from "../SpringModal"
import { CheckoutItems } from "./CheckoutItems"

//3rd party
import { createOutline } from "ionicons/icons";
import moment, { Moment } from "moment"
import GoogleMapReact from "google-map-react"
import EnvironmentOutlined from "@ant-design/icons/EnvironmentOutlined"
import EnvironmentFilled from "@ant-design/icons/EnvironmentFilled"
import { IonButton, IonIcon, IonSegment, IonSegmentButton, useIonToast, useIonViewWillLeave } from "@ionic/react"
import { timeOutline, alertCircleOutline, close, informationCircle } from "ionicons/icons"
import { Divider, Input, Tag } from "antd"
import isEmpty from "lodash.isempty"
import { GoogleMapRoute } from "./GoogleMapRoute";

export const CheckoutSummary = styled(BaseCheckoutSummary)`
  z-index: 1;
  
  display: flex;
  flex-direction: column;
  gap: 20px;

  .section {
    background: #fff;
    border-radius: 8px;
    box-shadow: rgba(128, 128, 128, 0.08) -2px 2px 2px, rgba(128, 128, 128, 0.04) 0px 2px 2px;
    padding:   20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .section-title {
      font-weight: 600;
      font-size: medium;
      color: var(--color-dark);
    }

    .ant-divider {
      margin: 6px 0;
    }
  }

  .tiffin-header {
    border-bottom: 1px solid #F0F0F0;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }

  .address-info {
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 35px;

    svg {
      font-size: 14px;
    }
  }

  .special-instructions-note {
    display: flex;
    align-items: center;
    padding: 10px 0;

    .hide {
      width: 0px;
      overflow: hidden;
    }

    .note-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      &.show {
        width: 100%;
      }
    }

    .note-code-btn,
    .note-apply-btn {
      height: 40px;
      font-size: var(--font-size-regular);
      margin: 0;
    }

    .applied-note {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;

      .note-heading {
        font-weight: 600;
        padding-left: 5px;
      }
      
      .note-text {
        padding: 5px 0;
        background: var(--ion-color-light);
        border-radius: 6px;
        display: grid;
        grid-template-columns: 14px 1fr auto;
        align-items: center;
        gap: 10px;
        flex-grow: 1;

        ion-icon {
          font-size: 14px;
        }

        ion-button {
          height: 30px;
          font-weight: 300;
          margin: 0;

          --background: var(--ion-color-light);
          --color: var(--ion-color-light-contrast);
          --background-hover: var(--ion-color-danger);
          --color-hover: var(--ion-color-danger-contrast);
        }
      }

      .edit-note-icon {
        font-size: 24px;
      }
    }
  }

  .checkout-date-options {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (min-width:576px) {
      align-items: center;
    }

    @media (min-width:961px) {
      flex-direction: row;
    }

    .time-options {
      min-width: 250px;
    }
  }

  .checkout-date-picker {
    display: grid;
    grid-template-columns: 14px 1fr auto;
    gap: 10px;
    align-items: center;
    width: 100%;

    ion-icon {
      font-size: 14px;
    }

    .error-icon {
      color: var(--ion-color-danger);
      font-size: var(--font-size-large);
    }

    .checkout-time {
      .error-text {
        color: var(--ion-color-danger);
        font-weight: 600;
      }
    }
  }

  .checkout-map {
    height: 200px;
  }

  .address-card-marker  {
    font-size: 24px;
    margin-left: -12px;
    margin-top: -24px;
  }

  .checkout-items {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .ant-collapse {
      font-size: 13px;
    }
    
    .ant-divider-horizontal {
      margin: 0;
    }

    .promo-code {
      display: flex;
      align-items: center;
      margin: 20px 0;

      .hide {
        width: 0px;
        overflow: hidden;
      }
      
      .promo-input {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .applied-promo {
        display: flex;
        align-items: center;

        .promo-error {
          color: var(--ion-color-danger);
        }

        .promo-code-tag {
          height: 40px;
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 0 12px;
        }
      }

      .promo-code-btn,
      .promo-apply-btn {
        height: 40px;
        font-size: 1em;
        margin: 0;
      }

      .ant-input {
        height: 40px;
        width: 200px;
      }
    }
  }

  .checkout-subheading {
    font-size: var(--font-size-medium);
    font-weight: 600;
    padding: 0 16px;
  }

  .checkout-items-footer {
    display: grid;
    gap: 5px;
    padding: 0 16px;
  }

  .checkout-paymentFee,
  .checkout-serviceFee,
  .checkout-promoAmount,
  .checkout-subtotal,
  .checkout-deliveryFee {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .checkout-paymentFee,
  .checkout-deliveryFee,
  .checkout-serviceFee {
    .label {
      display: flex;
      align-items: center;
      gap: 5px;

      ion-icon {
        color: var(--ion-color-medium-shade);
        font-size: 1.2em;
      }
    }
  }

  .checkout-total {
    font-size: 1.2em;
    font-weight: 600;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 0 16px;
  }
`

interface IBaseCheckoutSummary {
  className?: string,
  orderId?: string,
  date: string,
  orderTime?: string,
  orderType: OrderType,
  cart: cartMultiReducer.IStoreCart,
  cartStatus: CartStatus,
  deliveryAddress?: IAddressExtended,
  items: CartItems[],
  tiffin: IPartner,
  pageRef?: any,
  applyPromoCallback: (promoCode: string) => Promise<any>,
  removePromoCallback: () => Promise<any>,
  appliedPromo?: IPromo,
  specialInstructions?: string,
  isPartnerWebsite?: boolean,
  deliveryFee?: number,
  deliveryInformation?: IDeliveryInformation,
  deliveryAddressUpdateCallback: (amount: number | null, deliveryFee: number, deliveryInformation: IDeliveryInformation) => void,
  paymentFee?: number
}

function BaseCheckoutSummary(props: IBaseCheckoutSummary) {

  const [loading, setLoading] = useState(false);
  const [showTimePickerModal, setShowTimePickerModal] = useState(false);

  //Internal states
  const [showDeliveryFeeInfoModal, setShowDeliveryFeeInfoModal] = useState<boolean>(false)
  const [showServiceFeeInfoModal, setShowServiceFeeInfoModal] = useState<boolean>(false)
  const [showPaymentFeeInfoModal, setShowPaymentFeeInfoModal] = useState<boolean>(false)
  const [showAddressModal, setShowAddressModal] = useState<boolean>(false)
  const [inputPromoCode, setInputPromoCode] = useState<boolean>(false)
  const [promoCode, setPromoCode] = useState<string>(props.appliedPromo?.code ?? "")
  const [promoError, setPromoError] = useState<string>()
  const [orderTimeType, setOrderTimeType] = useState<"asap" | "schedule">(props.orderTime === "now" ? "asap" : "schedule")
  const promoInputRef = useRef<any>(null)

  const [inputNote, setInputNote] = useState<boolean>(false)
  const [specialInstructions, setSpecialInstructions] = useState<string>(props.specialInstructions ?? "")
  const noteInputRef = useRef<any>(null)
  const changingTimeRef = useRef<boolean>(false)

  const [present] = useIonToast();

  //Redux
  const dispatch = useAppDispatch()

  useEffect(() => {
    if(props.specialInstructions) {
      setSpecialInstructions(props.specialInstructions)
    }
  }, [props.specialInstructions])

  useEffect(() => {
    if(props.appliedPromo?.code) {
      setPromoCode(props.appliedPromo.code)
    }
  }, [props.appliedPromo])

  useIonViewWillLeave(() => {
    setShowTimePickerModal(false)
  }, [])

  const googleMap = useMemo(() => {
    if(props.deliveryInformation) {
      return (<GoogleMapRoute encodedPolyline={props.deliveryInformation.encodedPolyline} />)
    }
  }, [props.deliveryInformation?.encodedPolyline])

  const handleAddressChange = (address: IAddressExtended) => {
    if (props.orderId) {
      setLoading(true)
      setShowAddressModal(false)
      //Call cloud function to update delivery address
      cloudFunction("updateDeliveryAddress", { deliveryAddress: address, orderId: props.orderId }).then((result: any) => {
        if (result.data.status !== "success") {
          toast(present, "An unexpected error occurred", "danger")
        }
        setLoading(false)
        props.deliveryAddressUpdateCallback(result.data.amount, result.data.deliveryFee, result.data.deliveryInformation)
      }, (error) => {
        if (error.details?.message) {
          toast(present, error.detail?.message, "danger")
        }
        setLoading(false)
      });
    }
  }

  useEffect(() => {
    //Reset back to ASAP if scheduled time was not set
    if(showTimePickerModal === false && props.orderTime === "now" && !changingTimeRef.current) {
      setOrderTimeType("asap")
    }
  }, [showTimePickerModal, props.orderTime])

  const handleTimeTypeChange = (e: any) => {
    if(e.target.value === "asap") {
      setOrderTimeType("asap")
      handleTimeOnChange("now")
    } else {
      setShowTimePickerModal(true);
      setOrderTimeType("schedule")
    }
  }

  const handleTimeOnChange = (time: Moment | "now") => {
    changingTimeRef.current = true;
    setShowTimePickerModal(false);
    if (props.orderTime === time) {
      //No need to update if order time hasn't changed
      changingTimeRef.current = false;
      return;
    }
    if (props.orderId) {
      setLoading(true)
      //Call cloud function to update order time
      cloudFunction("updateOrderTime", { date: props.date, time: time === "now" ? time : time.format(), timezone: moment.tz.guess(), orderId: props.orderId }).then((result: any) => {
        changingTimeRef.current = false;
        if (result.data.status !== "success") {
          toast(present, "An unexpected error occurred", "danger")
        } else {
          dispatch(cartMultiReducer.updateTime({ id: props.isPartnerWebsite ? props.tiffin.id : APP_ID, date: props.date, time: time === "now" ? time : time.format() }))
        }
        setLoading(false)
      }, (error) => {
        changingTimeRef.current = false;
        if (error.details?.message) {
          toast(present, error.detail?.message, "danger")
        } else if (error.message) {
          toast(present, error.message, "danger")
        }
        setLoading(false)
      });
    } else {
      dispatch(cartMultiReducer.updateTime({ id: props.isPartnerWebsite ? props.tiffin.id : APP_ID, date: props.date, time: time === "now" ? time : time.format() }))
      changingTimeRef.current = false;
    }
  }

  const applyPromo = async () => {
    setInputPromoCode(false)

    if(!isEmpty(promoCode)) {
      props.applyPromoCallback(promoCode).then(() => {}, (err) => {
        setPromoError(err.details?.error)
      })
    }
  }

  const removePromo = async () => {
    if(props.appliedPromo) {
      props.removePromoCallback()
    }
    
    setPromoError(undefined)
    setPromoCode("")
  }

  const handleInputPromo = () => {
    setInputPromoCode(true)
    if(promoInputRef.current) {
      promoInputRef.current.focus({
        preventScroll: true,
      })
    }
  }

  const handleInputNote = () => {
    setInputNote(true)
    if(noteInputRef.current) {
      noteInputRef.current.focus({
        preventScroll: true,
      })
    }
  }

  const addNote = async () => {
    setInputNote(false)

    if(!isEmpty(specialInstructions) && props.orderId) {
      ordersHelper.addSpecialInstructionsNoteToOrder(props.orderId, specialInstructions)
    }
  }

  const removeNote = async () => {
    setSpecialInstructions("")
    if(props.orderId) {
      ordersHelper.removeSpecialInstructionsFromOrder(props.orderId)
    }
  }

  function calculateSummaryInfo() {
    let info: { subtotal: number; deliveryFee?: number; total: number, serviceFee: number, paymentFee: number } = {
      subtotal: 0,
      total: 0,
      serviceFee: 0,
      paymentFee: 0
    }

    for (let item of props.items) {
      info.subtotal += calculatePrice(item.details.price, item.count, item.modifiers)
    }

    info.total = info.subtotal

    if (props.orderType === OrderType.Delivery && props.deliveryFee !== undefined) {
      info.deliveryFee = (props.deliveryFee / 100) ?? 0
      info.total += (props.deliveryFee / 100)
    }

    if (props.orderType !== OrderType.DineIn) {
      info.serviceFee = Math.round(info.total * 100 * Constants.SERVICE_FEE) / 100
    }

    if (props.appliedPromo) {
      info.total -= (calculatePromoDiscount(info.subtotal * 100, props.appliedPromo) / 100)
    }

    //Calculate the service fee
    info.total += info.serviceFee

    if (props.paymentFee !== undefined) {
      info.paymentFee = props.paymentFee / 100
      info.total += info.paymentFee
    }

    return info
  }

  function getTimeText() {
    if (!props.orderTime) {
      return (
        <>
          <div className="error-text">
            Select a {props.orderType === OrderType.Pickup ? "pickup" : "delivery"} time for {moment(props.date, DateFormat).format('dddd, Do MMMM')}
          </div>
        </>)
    } else {
      if (props.orderTime === "now") {
        return props.orderType === OrderType.Pickup ? `Pickup in ${props.tiffin.preparationInMins} minutes` : `Delivery in ${props.tiffin.preparationInMins + (props.deliveryInformation ? Math.round(props.deliveryInformation.durationSeconds / 60) : 0)} minutes`
      } else {
        const orderTime = moment(props.orderTime);
        return `${props.orderType === OrderType.Pickup ? "Pickup" : "Delivery"} ${Utils.getRelativeDateString(orderTime)} at ${orderTime.format("h:mm a")}`
      }
    }
  }

  let summaryInfo = calculateSummaryInfo()

  const Marker = (props: { lat: number; lng: number }) => (
    <div className="address-card-marker">
      <EnvironmentFilled />
    </div>
  )

  return (
    <div className={props.className}>
      <div className="section">
        <div className="section-title">Order Details</div>
        <Divider />
        {
          props.orderType !== OrderType.DineIn &&
          <>
            <div className="checkout-date-options">
              {
                moment(props.date, DateFormat).isSame(moment(), 'day') && props.tiffin.preparationEnabled === false &&
                <div className="time-options">
                  <IonSegment mode="ios" value={orderTimeType} onIonChange={(e) => {handleTimeTypeChange(e)}}>
                    <IonSegmentButton value="asap">
                      <div>Standard</div>
                    </IonSegmentButton>
                    <IonSegmentButton value="schedule">
                      <div>Schedule</div>
                    </IonSegmentButton>
                  </IonSegment>
                </div>
              }
              <div className="checkout-date-picker">
                {props.orderTime ? <IonIcon icon={timeOutline} /> : <IonIcon className="error-icon" icon={alertCircleOutline} />}
                <span className="checkout-time">{getTimeText()}</span>
                {
                  props.orderTime !== "now" &&
                  <IonButton color="light" size="small" onClick={ () => setShowTimePickerModal(true) }>Change Time</IonButton>
                }
              </div>
            </div>
            <div className="address-info">
              {
                props.orderType === OrderType.Pickup && 
                <>
                  <EnvironmentOutlined />
                  <div>Pick up from <b>{props.tiffin.address}</b></div>
                </>
              }
              {
                props.orderType === OrderType.Delivery && 
                <>
                  <EnvironmentOutlined />
                  <div>Delivery to <b>{Utils.formatAddress(props.deliveryAddress)}</b></div>
                </>
              }
              {
                (props.orderType === OrderType.Delivery && props.deliveryAddress) && 
                <IonButton color="light" size="small" onClick={() => setShowAddressModal(true)}>Change Address</IonButton>
              }
            </div>
          </>
        }
        {
          props.orderType === OrderType.DineIn && <div>Table {props.cart.tableNumber} • Dine in order</div>
        }
        <div className="special-instructions-note">
          <IonButton className={`note-code-btn ${!inputNote && !specialInstructions ? "show" : "hide"}`} color="light" onClick={() => handleInputNote()} disabled={!props.orderTime}>Add special instructions</IonButton>
          {
            !inputNote && specialInstructions &&
            <div className="applied-note">
              <span className="note-heading">Special instructions:</span>
              <div onClick={() => handleInputNote()} className="note-text">
                <IonIcon className="edit-note-icon" icon={createOutline} />
                <span>{specialInstructions}</span>
                <IonButton size="small" onClick={() => removeNote()}><IonIcon icon={close} /></IonButton>
              </div> 
            </div>
          }
          <div className={`note-input ${inputNote ? "show" : "hide"}`}>
            <Input ref={noteInputRef} placeholder="Add special instructions" value={specialInstructions} onChange={(e) => setSpecialInstructions(e.target.value)} onBlur={() => addNote()} />
            <IonButton className="note-apply-btn" onClick={() => addNote()}>Add</IonButton>
          </div>
        </div>
        <TiffinTimePicker
          isOpen={showTimePickerModal}
          onClose={() => {
            setShowTimePickerModal(false)
          }}
          onChange={(time) => handleTimeOnChange(time)}
          orderDate={props.date}
          tiffin={props.tiffin}
          orderType={props.orderType}
          title={`Select a ${props.orderType === OrderType.Pickup ? 'pickup' : 'delivery'} time`}
          defaultValue={props.orderTime !== "now" ? props.orderTime : undefined}
        />
        {props.orderType === OrderType.Pickup && (
          <div className="checkout-map">
            <GoogleMapReact
              key={props.orderId}
              center={{
                lat: props.tiffin.hideExactAddress ? props.tiffin.maskedGeoLoc.lat : props.tiffin._geoloc.lat,
                lng: props.tiffin.hideExactAddress ? props.tiffin.maskedGeoLoc.lng : props.tiffin._geoloc.lng,
              }}
              defaultZoom={14}
              options={{
                zoomControl: false,
                fullscreenControl: false,
                gestureHandling: "none",
                clickableIcons: false,
              }}
            >
              <Marker lat={props.tiffin.hideExactAddress ? props.tiffin.maskedGeoLoc.lat : props.tiffin._geoloc.lat} lng={props.tiffin.hideExactAddress ? props.tiffin.maskedGeoLoc.lng : props.tiffin._geoloc.lng} />
            </GoogleMapReact>
          </div>
        )}
        {
          props.orderType === OrderType.Delivery &&
          <div className="checkout-map">
            {googleMap}
          </div>
        }
      </div>
      <div className="section">
        <div className="section-title">Items Summary</div>
        <Divider />
        <div className="checkout-items">
          <CheckoutItems date={props.date} items={props.items} />
          <div className="checkout-subheading">Summary</div>
          <div className="checkout-items-footer">
            <div className="checkout-subtotal">
              <span>Subtotal</span>
              <span>${summaryInfo.subtotal.toFixed(2)}</span>
            </div>
            {props.orderType === OrderType.Delivery && (
              <div className="checkout-deliveryFee">
                <span className="label">Delivery Fee <IonIcon onClick={() => setShowDeliveryFeeInfoModal(true)} icon={informationCircle} /></span>
                {
                  summaryInfo.deliveryFee !== undefined ?
                  <span>{summaryInfo.deliveryFee === 0 ? 'Free' : `$${summaryInfo.deliveryFee.toFixed(2)}`}</span>
                  :
                  <span>-</span>
                }
                
              </div>
            )}
            {
              (props.orderType === OrderType.Pickup || props.orderType === OrderType.Delivery) &&
              <div className="checkout-serviceFee">
                <span className="label">Service Fee <IonIcon onClick={() => setShowServiceFeeInfoModal(true)} icon={informationCircle} /></span>
                <span>${summaryInfo.serviceFee.toFixed(2)}</span>
              </div>
            }
            {
              (props.orderType === OrderType.DineIn && props.paymentFee !== undefined && props.paymentFee > 0) &&
              <div className="checkout-paymentFee">
                <span className="label">Payment Fee <IonIcon onClick={() => setShowPaymentFeeInfoModal(true)} icon={informationCircle} /></span>
                <span>${summaryInfo.paymentFee.toFixed(2)}</span>
              </div>
            }
            {
              props.appliedPromo &&
              <div className="checkout-promoAmount">
                  <span>Promotion Code</span>
                  <span>- ${(calculatePromoDiscount(summaryInfo.subtotal * 100, props.appliedPromo) / 100).toFixed(2)}</span>
              </div>
            }
          </div>
          <Divider />
          {
            (auth.currentUser && props.orderType !== OrderType.DineIn) &&
            <>
              <div className="promo-code">
                <IonButton className={`promo-code-btn ${!inputPromoCode && !promoCode ? "show" : "hide"}`} color="light" onClick={() => handleInputPromo()} disabled={!props.orderTime}>Add promotion code</IonButton>
                <div className="applied-promo">
                  {
                    !inputPromoCode && promoCode &&
                    <>
                      <Tag className="promo-code-tag" color={promoError ? 'red' : 'default'} closable onClose={removePromo}>{promoCode}</Tag>
                    </>
                  }
                  {
                    promoError &&
                    <span className="promo-error">{promoError}</span>
                  }
                  {
                    props.appliedPromo &&
                    <span>{props.appliedPromo.description}</span>
                  }
                </div>
                <div className={`promo-input ${inputPromoCode ? "show" : "hide"}`}>
                  <Input ref={promoInputRef} id="promo-code" placeholder="Add promotion code" value={promoCode} onChange={(e) => setPromoCode(e.target.value)} onBlur={() => applyPromo()} />
                  <IonButton className="promo-apply-btn" onClick={() => applyPromo()}>Apply</IonButton>
                </div>
            </div>
            <Divider />
          </>
          }
          <div className="checkout-total">
            <span>Total</span>
            <span>${summaryInfo.total.toFixed(2)}</span>
          </div>
        </div>
      </div>
      <DeliveryDetailsModal
        isOpen={showAddressModal}
        onCancel={() => setShowAddressModal(false)}
        presentingElement={props.pageRef?.current}
        hideDatePicker={true}
        disableClearCart={true}
        onAddressChange={handleAddressChange}
        partner={props.tiffin}
        cart={props.cart}
        cartStatus={props.cartStatus}
        isPartnerWebsite={props.isPartnerWebsite ?? false}
      />
      <Loading isOpen={loading} showBox={true} spinnerSize="x-large" />
      <SpringModal
        isOpen={showServiceFeeInfoModal}
        onClose={() => setShowServiceFeeInfoModal(false)}
        title="Service Fee"
        onOk={() => setShowServiceFeeInfoModal(false)}
      >
        This 5% service fee helps us operate Tifyn
      </SpringModal>
      <SpringModal
        isOpen={showDeliveryFeeInfoModal}
        onClose={() => setShowDeliveryFeeInfoModal(false)}
        title="Delivery Fee"
        onOk={() => setShowDeliveryFeeInfoModal(false)}
      >
        <p>This delivery fee has been set by <b>{props.tiffin.name}</b> and helps cover delivery costs related to your order.</p>
      </SpringModal>
      <SpringModal
        isOpen={showPaymentFeeInfoModal}
        onClose={() => setShowPaymentFeeInfoModal(false)}
        title="Payment Fee"
        onOk={() => setShowPaymentFeeInfoModal(false)}
      >
        This fee covers credit and debit card surcharges
      </SpringModal>
    </div>
  )
}