import { AdvanceDurationType, convertToTime, DateFormat, IPartner, OrderType } from "@tiffin/core";
import { SpringModal } from "../SpringModal";
import { Picker, PickerView } from "antd-mobile";
import { PickerColumn, PickerColumnItem, PickerValue } from "antd-mobile/es/components/picker";
import moment from "moment";
import { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { isDesktop, isMobile, isTablet } from "react-device-detect";

import './TiffinTimePicker.scss'

const MINUTE_VALUES: PickerColumnItem[] = [
  {
    label: '00',
    value: '0'
  },
  {
    label: '15',
    value: '15'
  },
  {
    label: '30',
    value: '30'
  },
  {
    label: '45',
    value: '45'
  }
];

export interface ITimePickerModal {
  title: string
  subTitle?: string
  onChange: (date: Moment) => void
  isOpen: boolean
  onClose: () => void
  tiffin: IPartner
  orderType: OrderType
  orderDate: string
  defaultValue?: string
}

const DEFAULT_PREP_TIME = 60;

export function TiffinTimePicker({orderType, orderDate, tiffin, defaultValue, isOpen, ...props}: ITimePickerModal) {

  const [selectedHour, setSelectedHour] = useState<number>()
  const [selectedMinute, setSelectedMinute] = useState<number>()
  const [availableMinutes, setAvailableMinutes] = useState<PickerColumnItem[]>();
  const [availableHours, setAvailableHours] = useState<PickerColumnItem[]>();
  const [min, setMin] = useState<Moment>()
  const [max, setMax] = useState<Moment>()

  //Sets the default selected hour
  const setDefaultRef = useRef(false);
  useEffect(() => {
    if(!setDefaultRef.current) {
      const defaultDate = moment(defaultValue);
      const defaultHourValue = defaultDate.hour();
      setSelectedHour(defaultHourValue)
      setDefaultRef.current = true;
    }
  }, [defaultValue])

  //Calculate available hours based on selected date
  useEffect(() => {
    if(isOpen && tiffin && orderType !== undefined && orderDate) {
      const date = moment(orderDate, DateFormat);
      // Get adjusted date if set
      const adjustedDate = orderType === OrderType.Pickup ?
      tiffin.adjustedDates?.[date.format(DateFormat)]?.pickupTimings :
      tiffin.adjustedDates?.[date.format(DateFormat)]?.deliveryTimings

      const day = date.day();

      //Tiffin timings for the selected day
      const timings = orderType === OrderType.Pickup ? tiffin.pickupDays! : tiffin.deliveryDays!;
      
      let min = adjustedDate ? moment(convertToTime(adjustedDate.start).formatted, 'HH:mm') : moment(convertToTime(timings[day].start).formatted, 'HH:mm');

      if(!tiffin.preparationEnabled) {
        //Minimum must take take into account prep time
        min.add( tiffin.preparationInMins ?  tiffin.preparationInMins : DEFAULT_PREP_TIME, 'minutes')
      }

      //Ensure the minimum takes into account hourly prepartion configuration
      if(moment().isSame(date, 'day')) {
        if(tiffin.preparationEnabled && tiffin.preparationDurationType === AdvanceDurationType.Hours) {
          const nextPossibleOrder = moment().add(tiffin.preparationDuration, 'hours')
          if(nextPossibleOrder > min) {
            min = nextPossibleOrder;
          }
        } else if(!tiffin.preparationEnabled) {
          //Scheduled orders for non prep enabled stores starts after their prep time, if none is set then use 60 minutes
          const nextPossibleOrder = moment().add(tiffin.preparationInMins ?  tiffin.preparationInMins : DEFAULT_PREP_TIME, 'minutes')
          if(nextPossibleOrder > min) {
            min = nextPossibleOrder;
          }
        }
      }

      //Round to next 15 minute
      const minRemainder = 15 - (min.minute() % 15);
      if(minRemainder < 15) {
        min.add(minRemainder, 'minute')
      }
  
      const max = adjustedDate ? moment(convertToTime(adjustedDate.end).formatted, 'HH:mm') : moment(convertToTime(timings[day].end).formatted, 'HH:mm');
      
      let maxHour = max.hour()
      if(maxHour === 0 && !min.isSame(max, 'day')) {
        maxHour = 24;
      }
      
      //Round to last 15 minute
      const maxRemainder = (min.minute() % 15);
      if(maxRemainder > 0) {
        max.subtract(maxRemainder, 'minute')
      }
  
      const hourColumns: PickerColumnItem[] = [];
      for(let i=min.hour(); i <= maxHour; i++) {
        hourColumns.push({
          label: i < 10 ? `0${i}` : i.toString(),
          value: i.toString()
        })
      }
  
      setMax(max)
      setMin(min)
      setAvailableHours(hourColumns)
    }
  }, [isOpen, tiffin, orderType, orderDate])

  //Calculate available minutes based on selected hour
  useEffect(() => {
    if(selectedHour !== undefined && max && min) {
      let maxHour = max.hour()
      if(maxHour === 0 && !min.isSame(max, 'day')) {
        maxHour = 24;
      }

      if(selectedHour <= min.hour()) {
        setAvailableMinutes(MINUTE_VALUES.filter((val) => parseInt(val.value as string) >= min.minute()))
      } else if(selectedHour >= maxHour) {
        setAvailableMinutes(MINUTE_VALUES.filter((val) => parseInt(val.value as string) <= max.minute()))
      } else {
        setAvailableMinutes(MINUTE_VALUES)
      }
    }
  }, [selectedHour, max, min])

  //Set the initial selected hour
  useEffect(() => {
    if(!selectedHour && availableHours) {
      setSelectedHour(parseInt(availableHours[0].value as string));
    }
  }, [availableHours, selectedHour])

  //Set the initial selected minute
  useEffect(() => {
    if(!selectedMinute && availableMinutes) {
      setSelectedMinute(parseInt(availableMinutes[0].value as string));
    }
  }, [availableMinutes, selectedMinute])

  const handleOnSelect = (value: PickerValue[]) => {
    if(value[0] !== null && value[0] !== selectedHour?.toString()) {
      setSelectedHour(parseInt(value[0] as string))
    }
    if(value[1] !== null && value[1] !== selectedMinute?.toString()) {
      setSelectedMinute(parseInt(value[1] as string))
    }
  }

  const handleOnConfirm = () => {
    if(selectedHour !== undefined && selectedMinute !== undefined) {
      const date = moment(orderDate, DateFormat).set({
        hours: selectedHour,
        minutes: selectedMinute
      })
      props.onChange(date);
    } else {
      props.onClose();
    }
  }

  let defaultPickerValue: PickerValue[] | undefined;
  if(defaultValue) {
    const defaultDate = moment(defaultValue);
    const hour = defaultDate.hour().toString();
    const minute = defaultDate.minute().toString();
    defaultPickerValue = [hour, minute]
  }

  const renderPickerTitle = () => {
    return (
      <div className="header">
        <span className="title">{props.title}</span>
        <span className="subtitle">{props.subTitle && props.subTitle}</span>
      </div>
    )
  }

  const body = (columns: PickerColumn[]) => {
    if(isMobile && !isTablet && !isDesktop) {
      return (
        (min && max && min < max) ?
        <Picker
          className="tiffin-time-picker"
          visible={isOpen}
          title={renderPickerTitle()}
          columns={columns}
          defaultValue={defaultPickerValue}
          onSelect={handleOnSelect}
          onConfirm={handleOnConfirm}
          onCancel={props.onClose}
          confirmText="Confirm"
          cancelText="Cancel"
        />
        :
        <SpringModal
          isOpen={isOpen}
          onCancel={props.onClose}
          title={props.title}
          cancelText="Close"
        >
          <div>
            Scheduled orders unavailable
          </div>
        </SpringModal>
      )
    } else {
      return (
        <SpringModal
          isOpen={isOpen}
          onCancel={props.onClose}
          onOk={handleOnConfirm}
          okText="Confirm"
          title={props.title}
        >
          {
            props.subTitle &&
            <div>{props.subTitle}</div>
          }
          {
            (min && max && min < max) ?
            <PickerView
              className="tiffin-date-time-picker"
              columns={columns}
              defaultValue={defaultPickerValue}
              onChange={handleOnSelect}
            />
            :
            <div>
              Scheduled orders unavailable
            </div>
          }
          
        </SpringModal>
      )
    }
  }

  let columns: PickerColumn[] | undefined;
  if(availableHours && availableMinutes) {
    columns = [
      availableHours,
      availableMinutes
    ]
  }
  
  return (
    <>
    {
      columns &&
      body(columns)
    }
    </>
  )
}