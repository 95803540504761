var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchPastDays, fetchItemsMetrics, fetchMetrics } from "../firebase-helpers/metrics";
// Define the initial state
var initialState = {
    dailyStatus: "unknown",
    itemsStatus: "unknown",
    weeklyStatus: "unknown",
    daily: [],
    items: [],
    base: {
        dates: []
    },
    baseStatus: "unknown"
};
export var getWeeklyMetrics = createAsyncThunk("metrics/weekly", function () { return __awaiter(void 0, void 0, void 0, function () {
    var currentMetrics, currentResponse, pastMetrics, pastResponse;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                currentMetrics = {
                    orderCount: 0,
                    total: 0,
                    subTotal: 0,
                    refundTotal: 0,
                    cancellationFees: 0,
                    deliveryFees: 0
                };
                return [4 /*yield*/, fetchPastDays(7, 0)];
            case 1:
                currentResponse = _a.sent();
                currentResponse.forEach(function (metrics) {
                    currentMetrics.orderCount += metrics.orderCount;
                    currentMetrics.total += metrics.total;
                    currentMetrics.subTotal += metrics.subTotal;
                    currentMetrics.cancellationFees += metrics.cancellationFees;
                    currentMetrics.deliveryFees += metrics.deliveryFees;
                    currentMetrics.refundTotal += metrics.refundTotal;
                });
                pastMetrics = {
                    orderCount: 0,
                    total: 0,
                    subTotal: 0,
                    refundTotal: 0,
                    cancellationFees: 0,
                    deliveryFees: 0
                };
                return [4 /*yield*/, fetchPastDays(7, 7)];
            case 2:
                pastResponse = _a.sent();
                pastResponse.forEach(function (metrics) {
                    pastMetrics.orderCount += metrics.orderCount;
                    pastMetrics.total += metrics.total;
                    pastMetrics.subTotal += metrics.subTotal;
                    pastMetrics.cancellationFees += metrics.cancellationFees;
                    pastMetrics.deliveryFees += metrics.deliveryFees;
                    pastMetrics.refundTotal += metrics.refundTotal;
                });
                return [2 /*return*/, {
                        current: currentMetrics,
                        last: pastMetrics
                    }];
        }
    });
}); });
export var getLast30DaysMetrics = createAsyncThunk("metrics/lastMonth", function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchPastDays(30)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); });
export var getItemsMetrics = createAsyncThunk("metrics/topItems", function (req) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchItemsMetrics(req.top, req.type)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); });
export var getMetrics = createAsyncThunk("metrics/base", function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchMetrics()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); });
export var metricsSlice = createSlice({
    name: "metrics",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(getWeeklyMetrics.pending, function (state) {
            state.weeklyStatus = "updating";
        });
        builder.addCase(getWeeklyMetrics.fulfilled, function (state, action) {
            state.weeklyStatus = "idle";
            state.currentWeek = action.payload.current;
            state.lastWeek = action.payload.last;
        });
        builder.addCase(getWeeklyMetrics.rejected, function (state, action) {
            state.weeklyStatus = "failed";
            state.error = action.error.message;
        });
        builder.addCase(getLast30DaysMetrics.pending, function (state) {
            state.dailyStatus = "updating";
        });
        builder.addCase(getLast30DaysMetrics.fulfilled, function (state, action) {
            state.dailyStatus = "idle";
            state.daily = action.payload;
        });
        builder.addCase(getLast30DaysMetrics.rejected, function (state, action) {
            state.dailyStatus = "failed";
            state.error = action.error.message;
        });
        builder.addCase(getItemsMetrics.pending, function (state) {
            state.itemsStatus = "updating";
        });
        builder.addCase(getItemsMetrics.fulfilled, function (state, action) {
            state.itemsStatus = "idle";
            state.items = action.payload;
        });
        builder.addCase(getItemsMetrics.rejected, function (state, action) {
            state.itemsStatus = "failed";
            state.error = action.error.message;
        });
        builder.addCase(getMetrics.pending, function (state) {
            state.baseStatus = "updating";
        });
        builder.addCase(getMetrics.fulfilled, function (state, action) {
            state.baseStatus = "idle";
            if (action.payload) {
                state.base = action.payload;
            }
        });
        builder.addCase(getMetrics.rejected, function (state, action) {
            state.baseStatus = "failed";
            state.error = action.error.message;
        });
    }
});
export default metricsSlice.reducer;
