import { useEffect, useRef, useState } from "react"

import { AddressCard } from "./AddressCard"
import { AddressInput } from "./AddressInput"
import { userReducer, cartMultiReducer, searchReducer, RootState, CartStatus, Utils } from "@tiffin/app-common"
import { IAddressExtended, ILocality, IPartner } from "@tiffin/core"
import { OrderDatePickerModal } from "./OrderDatePickerModal"

//Redux
import { useAppDispatch } from "../hooks"
import { connect, ConnectedProps } from "react-redux"

//3rd party components
import { Capacitor } from "@capacitor/core"
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react"
import { Button, message, Modal } from "antd"
import { timeOutline } from "ionicons/icons"
import cloneDeep from "lodash.clonedeep"
import moment from "moment"

import "./DeliveryDetailsModal.scss"

//Steps for connected redux component
const mapState = (state: RootState) => ({
  addresses: state.user.addresses,
  search: state.search
})
const connector = connect(mapState, null)
type PropsFromRedux = ConnectedProps<typeof connector>

interface IDeliveryDetailsModalProps extends PropsFromRedux {
  isOpen: boolean,
  presentingElement?: HTMLElement,
  onCancel: () => void,
  hideDatePicker?: boolean,
  onAddressChange?: (address: IAddressExtended) => void,
  disableClearCart?: boolean,
  cart?: cartMultiReducer.IStoreCart,
  cartStatus: CartStatus,
  partner?: IPartner,
  isPartnerWebsite: boolean
}

export function DeliveryDetailsModal({cart, cartStatus, partner, isPartnerWebsite, addresses, search, ...props}: IDeliveryDetailsModalProps) {

  const addressListRef = useRef<HTMLIonModalElement | null>(null);

  //Redux states
  const dispatch = useAppDispatch()
  
  //Internal states
  const [warningAddress, setWarningAddress] = useState<IAddressExtended>()
  const [showTimeModal, setShowTimeModal] = useState<boolean>(false)
  const [selectedAddressId, setSelectedAddressId] = useState(cart.deliveryAddress?.placeId)
  const [expandedAddressId, setExpandedAddressId] = useState<string>(cart.deliveryAddress?.placeId)

  useEffect(() => {
    setSelectedAddressId(cart.deliveryAddress?.placeId);
    setExpandedAddressId(cart.deliveryAddress?.placeId)
  }, [cart.deliveryAddress])

  async function addAddress(value: string, coords: { lat: number; lng: number }, location?: google.maps.GeocoderResult) {
    if (location) {
      let address: any = {}
      for (let component of location?.address_components) {
        if (component.types.includes("subpremise")) {
          address.subpremise = component.long_name
        } else if (component.types.includes("street_number")) {
          address.streetNumber = component.long_name
        } else if (component.types.includes("route")) {
          address.street = component.long_name
        } else if (component.types.includes("locality")) {
          address.locality = component.long_name
        } else if (component.types.includes("administrative_area_level_1")) {
          address.state = component.short_name
        } else if (component.types.includes("country")) {
          address.country = component.long_name
        } else if (component.types.includes("postal_code")) {
          address.postalCode = component.long_name
        }
      }
      address.placeId = location.place_id
      address.coords = coords

      let deliveryCheck = await Utils.checkDeliveryAvailable(partner._geoloc, address.coords, partner.deliveryConfiguration)

      if (!partner || deliveryCheck) {
        setSelectedAddressId(address.placeId)
      } else {
        setWarningAddress(address)
      }
      dispatch(userReducer.updateAddress(address))
    }
  }

  function deleteAddress(deleteAddress: IAddressExtended) {
    let update = cloneDeep(addresses)

    let index = 0
    for (let address of update) {
      if (address.placeId === deleteAddress.placeId) {
        update.splice(index, 1)
      }
      index++
    }

    if (update.length === 0) {
      message.error("Cannot delete the address. You must have at least one delivery address")
      return false
    }

    if (deleteAddress.default) {
      //Change default to first address in the list
      update[0].default = true
    }

    dispatch(userReducer.updateAddresses(update))
  }

  function updateDefaultCallback(defaultAddress: IAddressExtended) {
    if (addresses) {
      let update = cloneDeep(addresses)
      for (let address of update) {
        if (address.placeId === defaultAddress.placeId) {
          address.default = true
        } else if (address.default) {
          delete address.default
        }
      }
      dispatch(userReducer.updateAddresses(update))
      return true
    } else {
      return false
    }
  }

  function updateDeliveryDate(selectedDate: string, clear?: boolean) {
    let locality: ILocality | undefined;
    if(cart.deliveryAddress) {
      locality = {
        locality: cart.deliveryAddress.locality,
        state: cart.deliveryAddress.state,
        country: cart.deliveryAddress.country,
      }
    }
    
    dispatch(searchReducer.fetchDeliveryTiffins({
      locality: locality, 
      page: 0, 
      date: clear ? "any" : selectedDate,
    }))
  }

  function renderModalContent() {
    return (
      <>
        {
          !props.hideDatePicker &&
          <div className="search-date-picker">
            <IonIcon icon={timeOutline} />
            <span>{search.date === "any" ? "Select a delivery time" : search.date === "now" ? "Today • ASAP" : moment(search.date).format("LLL")}</span>
            <IonButton color="light" size="small" onClick={() => setShowTimeModal(true)} mode="ios">Change</IonButton>
          </div>
        }
        <div className="add-address-btn">
          <AddressInput
            placeholder="Add a new address"
            setAddress={addAddress}
            types={["address"]}
          />
        </div>
        <div className="addresses-list">
          {addresses && 
            addresses.map((address, i) => {
              return (
                <AddressCard 
                  key={address.placeId}
                  expanded={expandedAddressId === address.placeId}
                  expandCallback={(id) => setExpandedAddressId(id)}
                  selected={selectedAddressId === address.placeId} 
                  address={address} 
                  deleteCallback={deleteAddress} 
                  updateDefaultCallback={updateDefaultCallback} 
                  onChange={props.onAddressChange} 
                  disableClearCart={props.disableClearCart || isPartnerWebsite}
                  cart={{
                    deliveryAddress: cart.deliveryAddress,
                    status: cartStatus,
                    orderType: cart.orderType
                  }}
                  partner={partner}
                  isPartnerWebsite={isPartnerWebsite}
                />
              )
            })}
        </div>
      </>
    )
  }

  function renderAddressWarning() {
    let maxDistanceMeters = 0
    if(partner?.deliveryConfiguration) {
      maxDistanceMeters = partner.deliveryConfiguration.baseDistanceMeters
      if(partner.deliveryConfiguration?.tiers) {
        for(const tier of partner.deliveryConfiguration?.tiers) {
          maxDistanceMeters += tier.distanceMeters
        }
      }
    }
    
    return (
      <Modal
        title="Cannot change delivery address"
        open={!!warningAddress}
        onCancel={(e) => {
          setWarningAddress(undefined)
          e?.stopPropagation()
        }}
        footer={[
          <Button key="back" onClick={() => setWarningAddress(undefined)}>
            Cancel
          </Button>
        ]}
      >
        <div className="address-warning-modal">
          <span>
            <b>{partner?.name}</b> does not provide delivery services to <b>{warningAddress ? Utils.formatAddress(warningAddress) : ''}</b>.
          </span>
          <span>Delivery is available up to <b>{maxDistanceMeters/1000} kilometres away</b> from store</span>
        </div>
      </Modal>
    )
  }

  return (
    <>
      {
        isPartnerWebsite ?
        <Modal
          className="delivery-details-modal partner-website"
          open={props.isOpen}
          onCancel={() => {
            props.onCancel()
          }}
          okButtonProps={{ hidden: true }}
          title="Delivery Details"
          closable={true}
          bodyStyle={{overflow: "auto", padding: "20px 2px"}}
        >
          {renderModalContent()}
        </Modal>
        :
        <IonModal
          ref={addressListRef}
          className="delivery-details-modal"
          isOpen={props.isOpen}
          canDismiss={true}
          presentingElement={props.presentingElement}
          onWillDismiss={() => {
            props.onCancel()
          }}
          mode={Capacitor.getPlatform() === 'android' ? 'md' : 'ios'}
        >
          <IonHeader translucent>
            <IonToolbar>
              <IonTitle>Delivery Details</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={props.onCancel}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {renderModalContent()}
          </IonContent>
        </IonModal>
        }

      <OrderDatePickerModal
        title="Delivery time"
        date={search.date}
        isOpen={showTimeModal}
        onClose={() => setShowTimeModal(false)}
        presentingElement={addressListRef.current}
        onUpdate={updateDeliveryDate}
      />
      {renderAddressWarning()}
    </>
  )
}

export default connector(DeliveryDetailsModal);