import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export const GoogleMapRoute = styled(BaseGoogleRouteMap)`
  height: 100%;
  border-radius: 20px;
`

const svg = `
<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><circle cx="256" cy="256" r="144"/></svg>
`;

const endSvg = `
<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path d="M256 464c-114.69 0-208-93.31-208-208S141.31 48 256 48s208 93.31 208 208-93.31 208-208 208z"/></svg>`;

const blob = new Blob([svg], {type: 'image/svg+xml'});
const url = URL.createObjectURL(blob);

const endBlob = new Blob([endSvg], {type: 'image/svg+xml'});
const endUrl = URL.createObjectURL(endBlob);

interface IBaseGoogleRouteMap {
  className?: string,
  encodedPolyline: string
}

export function BaseGoogleRouteMap({className, encodedPolyline}: IBaseGoogleRouteMap) {
  const ref = useRef<HTMLDivElement>(null);

  const [polylineCoordinates, setPolylineCoordinates] = useState<google.maps.LatLng[]>([])

  const mapRef = useRef<google.maps.Map>();

  useEffect(() => {
    setPolylineCoordinates(window.google.maps.geometry.encoding.decodePath(encodedPolyline))
  }, [encodedPolyline])

  useEffect(() => {
    if(ref.current && polylineCoordinates.length > 0) {
      var bounds = new google.maps.LatLngBounds();

      for(const coordinate of polylineCoordinates) {
        bounds.extend(coordinate);
      }

      const map = new window.google.maps.Map(ref.current, {
        mapId: 'df985dda5294a561',
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        fullscreenControl: false,
        keyboardShortcuts: false,
        scrollwheel: false,
        disableDoubleClickZoom: true
      });

      mapRef.current = map

      const flightPath = new google.maps.Polyline({
        path: polylineCoordinates,
        geodesic: true,
        strokeColor: "#000",
        strokeOpacity: 1.0,
        strokeWeight: 3.2
      });
    
      flightPath.setMap(map)
      map.fitBounds(bounds, {top: 5, bottom: 5, left: 5, right: 5});

      new google.maps.Marker({
        position: polylineCoordinates[0],
        icon: {
          scaledSize: new google.maps.Size(16, 16),
          anchor: new google.maps.Point(8,8),
          url: url
        },
        map: map,
      });

      new google.maps.Marker({
        position: polylineCoordinates[polylineCoordinates.length - 1],
        icon: {
          scaledSize: new google.maps.Size(16, 16),
          anchor: new google.maps.Point(8,8),
          url: endUrl,
        },
        map: map,
      });
    }
  }, [polylineCoordinates]);

  const polylineUpdateTimer = useRef<NodeJS.Timeout>();
  const coordinateIndex = useRef<number>(1);
  const flightPathRef = useRef<google.maps.Polyline>();
  useEffect(() => {
    if(polylineCoordinates.length > 0) {

      const coordinates = [...polylineCoordinates].reverse()
      const flightPath = new google.maps.Polyline({
        path: coordinates.slice(0, coordinateIndex.current),
        geodesic: true,
        strokeColor: "#A0A0A0",
        strokeOpacity: 1.0,
        strokeWeight: 4
      });

      flightPath.setMap(mapRef.current)
      flightPathRef.current = flightPath;

      clearInterval(polylineUpdateTimer.current)

      polylineUpdateTimer.current = setInterval(() => {
        if(coordinateIndex.current >= polylineCoordinates.length + ((polylineCoordinates.length)/3)) {
          flightPathRef.current.setPath(coordinates.slice(0, coordinateIndex.current))
          coordinateIndex.current = 1;
        } else {
          if(coordinateIndex.current <= polylineCoordinates.length) {
            flightPathRef.current.setPath(coordinates.slice(0, coordinateIndex.current))
          }
          coordinateIndex.current += 1;
        }
      }, 3000/polylineCoordinates.length);
    }
  }, [polylineCoordinates])
  
  return (
    <div className={className} ref={ref} id="map" />
  );
}