import styled from "styled-components";
import { useEffect, useState } from "react";
import { useAppSelector, useIsPortrait } from "../hooks"
import { Capacitor } from "@capacitor/core";
import { IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar } from "@ionic/react";
import { downloadOutline } from "ionicons/icons";
import { Alert, Button, Checkbox, QRCode } from "antd";
import { saveAs } from 'file-saver';
import JSZip from "jszip"

export const Resources = styled(BaseResources)`
  .resource-container {
    height: 100%;
    background-color: var(--ion-color-light);
    display: flex;
    justify-content: center;

    .resource-details {
      padding: 20px;
      width: 100%;
      height: 100%;
      background: var(--ion-background-color);
      display: flex;
      flex-direction: column;
      gap: 40px;

      .resource-group {
        .heading {
          font-weight: 600;
          font-size: medium;
        }

        .description {
          font-size: small;
        }

        .add-logo-checkbox {
          font-size: small;
        }

        .download-btn {
          display: flex;
          gap: 10px;
          align-items: center;
          flex-wrap: wrap;
        }

        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  /* tablet, landscape iPad, lo-res laptops ands desktops */
  @media (min-width:961px)  {
    .resource-details {
      padding: 40px;
    }
    
    .resource-details.landscape {
      padding: 40px;
      width: 70%;
    }

    .resource-details.portrait {
      width: 80%;
    }
  }
`

interface IResourcesProps {
  className?: string
}

export function BaseResources({className}: IResourcesProps) {

  const [logoDataUrl, setLogoDataUrl] = useState<string>()
  const [showLogoInDineInCode, setShowLogoInDIneInCode] = useState<boolean>(false)
  const [showLogoInOnlineCode, setShowLogoInOnlineCode] = useState<boolean>(false)

  const partnerOfferDineIn = useAppSelector((state) => state.partner.pickup?.offerDineIn)
  const partnerLogoImageUrl = useAppSelector((state) => state.partner.logoImageUrl)
  const partnerUrlName = useAppSelector((state) => state.partner.urlName)
  const partnerDineIn = useAppSelector((state) => state.partner.dineIn)
  const isPortrait = useIsPortrait()

  useEffect(() => {
    if(partnerLogoImageUrl) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        const reader = new FileReader();
        reader.onload = function(e: any) {
          setLogoDataUrl(e.target.result);
        }
        reader.readAsDataURL(blob);
      };
      xhr.open('GET', partnerLogoImageUrl);
      xhr.send();
    }
  }, [partnerLogoImageUrl])

  const downloadDineInQRCodes = () => {
    if(!partnerDineIn?.numberOfTables) {
      return
    }

    const zip = new JSZip();
    for(let i = 1; i < partnerDineIn?.numberOfTables + 1; i++) {
      const canvas = document.getElementById(`table-${i}`)?.querySelector<HTMLCanvasElement>('canvas');
      if(canvas) {
        const url = canvas.toDataURL();
        var idx = url.indexOf('base64,') + 'base64,'.length; // or = 28 if you're sure about the prefix
        var content = url.substring(idx);
        zip.file(`Table${i}-QRCode.png`, content, {base64: true});
      }
    }

    zip.generateAsync({
      type: "blob"
    }).then(function(content) {
      saveAs(content, "DineInQRCodes.zip");
    });
  }
  
  const downloadOnlineQRCode = () => {
    const canvas = document.getElementById('online-qr-code')?.querySelector<HTMLCanvasElement>('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = 'Online-QRCode.png';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  return (
    <IonPage className={className}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main/settings" />
          </IonButtons>
          <div className="page-header">
            <IonIcon icon={downloadOutline} />
            <span className="page-header-text">Resources</span>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="resource-container">
          <div className={`resource-details ${isPortrait ? 'portrait' : 'landscape'}`}>
            <div className="resource-group">
              <div key="online-qr-code" id="online-qr-code" style={{display: 'none'}}>
                <QRCode 
                  value={`http://${process.env.REACT_APP_PARTNER_WEBSITE_URL_BASE}/${partnerUrlName}`}
                  icon={logoDataUrl && showLogoInOnlineCode ? logoDataUrl : undefined}
                  bgColor={"#fff"}
                  size={256}
                  iconSize={64}
                  errorLevel="H"
                />
              </div>
              <span className="heading">Online Resources</span>
              <span className="description">QR code that directs to your website for customers to place online orders</span>
              {
                partnerLogoImageUrl &&
                <Checkbox className="add-logo-checkbox" value={showLogoInOnlineCode} onChange={(val) => setShowLogoInOnlineCode(val.target.checked)}>Add store logo to QR code</Checkbox>
              }
              <div className="download-btn">
                  <Button onClick={() => downloadOnlineQRCode()} disabled={Capacitor.getPlatform() !== 'web'}>Download QR Code</Button>
                  {
                    Capacitor.getPlatform() !== 'web' &&
                    <Alert type="warning" showIcon message={<>QR codes can only be downloaded from the Tifyn partner <a href="https://partner.tifyn.com.au/">website</a></>} />
                  }
                </div>
            </div>
            {
              partnerOfferDineIn &&
              <div className="resource-group">
                {
                  (partnerDineIn?.numberOfTables && partnerDineIn?.numberOfTables > 0) &&
                  Array.from({length: partnerDineIn.numberOfTables}, (_, i) => {
                    return (
                      <div key={`table-${i+1}`} id={`table-${i+1}`} style={{display: 'none'}}>
                        <QRCode 
                          value={`http://${process.env.REACT_APP_PARTNER_WEBSITE_URL_BASE}/${partnerUrlName}?table=${i+1}`}
                          icon={logoDataUrl && showLogoInDineInCode ? logoDataUrl : undefined}
                          bgColor={"#fff"}
                          size={256}
                          iconSize={64}
                          errorLevel="H"
                        />
                      </div>
                    )
                  })
                }
                <span className="heading">Dine In Resources</span>
                <span className="description">QR codes for each table in your store that allow customers to place orders</span>
                {
                  partnerLogoImageUrl &&
                  <Checkbox className="add-logo-checkbox" value={showLogoInDineInCode} onChange={(val) => setShowLogoInDIneInCode(val.target.checked)}>Add store logo to QR codes</Checkbox>
                }
                <div className="download-btn">
                  <Button onClick={() => downloadDineInQRCodes()} disabled={Capacitor.getPlatform() !== 'web'}>Download QR Codes</Button>
                  {
                    Capacitor.getPlatform() !== 'web' &&
                    <Alert type="warning" showIcon message={<>QR codes can only be downloaded from the Tifyn partner <a href="https://partner.tifyn.com.au/">website</a></>} />
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}