import { OrderTypePill, Pill } from "@tiffin/components";
import { IOrderExt, OrderType, calculateProcessingFee } from "@tiffin/core";
import { RefundStatusPill } from "./RefundStatusPill";

//3rd party
import { IonSpinner } from "@ionic/react";
import { useMediaQuery } from "react-responsive";

import './OrderCardHeader.scss'

interface IOrderCardHeader {
  order: IOrderExt,
  busy?: boolean
}

export function OrderCardHeader({order, busy}: IOrderCardHeader) {
  const fullView = useMediaQuery({ query: '(min-width:768px)' })

  const orderTypeText = () => {
    if(order.orderType === OrderType.Pickup) {
      return 'Pickup'
    } else if(order.orderType === OrderType.Delivery) {
      return 'Delivery'
    } else if(order.orderType === OrderType.DineIn) {
      return 'Dine In'
    }
  }

  return (
    <div className="partner-order-header">
      <div className="left-header">
        <div className="header-customer-name">
          {
            busy &&
            <IonSpinner className="header-loading" name="crescent"/>
          }
          <span>{order.tableNumber ? `Table ${order.tableNumber} (${order.customerFirstName} ${order.customerLastName})` : `${order.customerFirstName} ${order.customerLastName}`}</span>
        </div>
        <div className="header-pills">
          <Pill text={orderTypeText()}/>
          <OrderTypePill orderStatus={order.status} />
          {
            order.refundTotal > 0 &&
            <RefundStatusPill fullRefund={order.refundTotal >= (order.total - (order.promoAmount ?? 0) - (order.serviceFee ?? 0) - (order.paymentFee ?? 0))} />
          }
        </div>
      </div>
      {
        (fullView) &&
        <div className="right-header">
          <Pill text={`$${((order.total - order.refundTotal - order.serviceFee - (order.paymentFeeAbsorbed ? 0 : order.paymentFee) - calculateProcessingFee(order).processingFee) / 100).toFixed(2)}`}/>
        </div>
      }
    </div>
  )
}