import styled from "styled-components";

interface IBasePulseBlob {
  className?: string,
  colorrgb: string
}

export const PulseBlob = styled(BasePulseBlob)`
  --blob-color-rgb: ${(props) => props.colorrgb};

  background: rgb(var(--blob-color-rgb));
  border-radius: 50%;
  margin: 0.2em;
  height: 0.6em;
  width: 0.6em;

  box-shadow: 0 0 0 0 rgba(var(--blob-color-rgb), 1);
  transform: scale(1);
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(var(--blob-color-rgb), 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 0.4em rgba(var(--blob-color-rgb), 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(var(--blob-color-rgb), 0);
    }
  }
`

export function BasePulseBlob(props: IBasePulseBlob) {
  return (<div className={props.className}></div>)
}