import React, { useEffect, useRef, useState } from "react"

import { IConversationsMetrics, IOrderExt, PushNotifType } from "@tiffin/core"

import { Route } from "react-router-dom"
import { IonApp, IonButton, IonRouterOutlet, setupIonicReact, useIonToast } from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"
import PrivateRoute from "./components/PrivateRoute"
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Mode } from "@ionic/core"
import { TourProvider } from "@reactour/tour"
import newOrderSound from "./assets/audio/new-order.mp3"
import useSound from 'use-sound';

/* firebase */
import { auth, authenticateReducer, conversationsHelper, conversationsMetricsReducer, globalPartnerConfigReducer, metricsReducer, ordersReducer, partnerOrdersHelper, userHelper, userReducer } from "@tiffin/app-common"
import { Unsubscribe } from "firebase/firestore"
import { signInWithCustomToken, User } from "firebase/auth"

/* push notifications */
import { Capacitor } from "@capacitor/core"
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from "@capacitor/push-notifications";

/* pages */
import { Login } from "./pages/Login"
import { LoginWeb } from "./pages/LoginWeb"
import { Home } from "./pages/Home"
import { PartnerOnboarding } from "./pages/PartnerOnboarding"
import { DetailsForm } from "./pages/PartnerDetailsForm"
import { PartnerEdit } from "./pages/PartnerEdit"
import { AdvanceForm } from "./pages/PartnerAdvanceForm"
import { PaymentForm } from "./pages/PartnerPaymentForm"
import { DeliveryForm } from "./pages/PartnerDeliveryForm"
import { PickupForm } from "./pages/PartnerPickupForm"
import { AccountInfo } from "./pages/AccountInfo"
import { OrderDetails } from "./pages/OrderDetails"
import { ChatRoom, toast } from "@tiffin/components"
import { AvailableDates } from "./pages/AvailableDates"
import { Status } from "./pages/Status"
import { CustomerReviews } from "./pages/CustomerReviews"
import { Plan } from "./pages/Plan"
import { Unacknowledged } from "./pages/Unacknowledged"
import { WebsiteOptionsForm } from "./pages/PartnerWebsiteOptions"
import { DineInForm } from "./pages/PartnerDineInForm"
import { Resources } from "./pages/Resources"
import { Events } from "./pages/Events"

/* Components */
import { AppUrlListener } from "./components/AppUrlListener"

/* Redux */
import { useAppDispatch, useAppSelector } from "./hooks"
import { partnerReducer } from "@tiffin/app-common";

/* Styling */
import "./App.scss"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"

/* Theme variables */
import "./theme/light.scss"
import "./theme/light.less"

// Temporarily disable dark theme - will add properly in later support
// import "./theme/dark.scss"
// import "./theme/dark.less"

const App: React.FC = () => {

  const [present] = useIonToast();
  
  let mode: Mode = 'ios';
  if(Capacitor.getPlatform() === 'android') {
    mode = 'md';
    StatusBar.setStyle({ style: Style.Light });
    StatusBar.setBackgroundColor({ color: "#FFFFFF" });
  } else if(Capacitor.getPlatform() === 'ios') {
    mode = 'ios';
    StatusBar.setStyle({ style: Style.Light });
  }

  setupIonicReact({
    mode: mode,
    swipeBackEnabled: false
  });

  //Redux states
  const userInfo = useAppSelector((state) => state.user)
  const partner = useAppSelector((state) => state.partner)
  const orders = useAppSelector((state) => state.orders)
  const [lastOrders, setLastOrders] = useState<IOrderExt[]>()
  const dispatch = useAppDispatch()

  //Internal states
  const globalConfig = useAppSelector((state) => state.globalPartnerConfig)

  const unsubOrders = useRef<Unsubscribe | null>(null)
  const unsubUnreadConverations = useRef<Unsubscribe | null>(null)
  const initialOrdersLoadReturned = useRef<boolean | null>(false)
  const [play] = useSound(newOrderSound);

  useEffect(() => {
    dispatch(globalPartnerConfigReducer.getPartnerConfig())
  }, [dispatch])

  useEffect(() => {
    const unsubcribe = auth.onAuthStateChanged(async (user: User | null) => {
      if (user) {
        dispatch(userReducer.fetchUser())
        dispatch(partnerReducer.getPartner())

        const idTokenResult = await auth.currentUser?.getIdTokenResult()
        if(idTokenResult.claims.admin === true) {
          //Sign in as user
          const token = await userHelper.getUserToken();
          if(token) {
            toast(present, "Impersonating user", undefined, "top", 3000);
            signInWithCustomToken(auth, token)
          }
        } else {
          const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
          if(!isPushNotificationsAvailable) return
      
          PushNotifications.checkPermissions().then((res) => {
            if (res.receive !== 'granted') {
              PushNotifications.requestPermissions().then((res) => {
                if (res.receive === 'denied') {
                  console.error('Push Notification permission denied')
                }
                else {
                  register(
                    present, 
                    (val: string) => dispatch(userReducer.updateDeviceRegistrationToken(val)),
                    (val: string) => window.location.href = val
                  );
                }
              });
            }
            else {
              register(
                present, 
                (val: string) => dispatch(userReducer.updateDeviceRegistrationToken(val)),
                (val: string) => window.location.href = val
              );
            }
          });
        }
      } else {
        SplashScreen.hide();
        dispatch(authenticateReducer.setUnauthenticated())
      }
    })

    return () => {
      unsubcribe()
    }
  }, [dispatch, present])

  useEffect(() => {
    //Cannot proceed without global config
    if(!globalConfig) return

    if(userInfo.status === "succeeded") {
      const policyAckVersion = globalConfig.tcPartnerVersion;
      if(userInfo.firstName && userInfo.lastName && userInfo.phone && userInfo.phonePrefix && userInfo.email && userInfo.partnerPolicyAckVer && userInfo.partnerPolicyAckVer === policyAckVersion) {
        SplashScreen.hide();
        dispatch(authenticateReducer.setAuthenticated())
      } else if(userInfo.firstName && userInfo.lastName && userInfo.phone && userInfo.phonePrefix && userInfo.email && userInfo.partnerPolicyAckVer && userInfo.partnerPolicyAckVer !== policyAckVersion) {
        SplashScreen.hide();
        dispatch(authenticateReducer.setUnacknowledged())
      } else if(!userInfo.signedOut) {
        SplashScreen.hide();
        dispatch(authenticateReducer.setIncomplete())
      }
    } else if(userInfo.status === "idle" && !userInfo.signedOut) {
      SplashScreen.hide();
      dispatch(authenticateReducer.setIncomplete())
    }
  }, [userInfo, globalConfig, dispatch])

  useEffect(() => {
    //Clear error as user is not registered
    if (partner.status === "unregistered") {
      dispatch(partnerReducer.resetError())
    }
  }, [dispatch, partner.status])

  useEffect(() => {
    if(partner.partnerStatus === "active" || partner.partnerStatus === "inactive") {
      //Unsubscribe before re-subscribing
      if(unsubOrders.current !== null) {
        unsubOrders.current()
      }

      //Subscribe to orders
      unsubOrders.current = partnerOrdersHelper.subscribeToOrders((orders: IOrderExt[]) => {
        initialOrdersLoadReturned.current = true
        dispatch(ordersReducer.updateActiveOrders(orders))
      })

      //Unsubscribe before re-subscribing
      if(unsubUnreadConverations.current !== null) {
        unsubUnreadConverations.current()
      }

      //Subscribe to conversations metrics
      unsubUnreadConverations.current = conversationsHelper.subscribeToConversationMetrics((metrics: IConversationsMetrics) => dispatch(conversationsMetricsReducer.updateMetrics(metrics)))

      //Get the historic order dates from metrics
      dispatch(metricsReducer.getMetrics())
    }

    return () => {
      if(unsubOrders.current !== null) {
        unsubOrders.current()
      }

      if(unsubUnreadConverations.current !== null) {
        unsubUnreadConverations.current()
      }
    }
  }, [partner.partnerStatus, dispatch])

  useEffect(() => {
    if(initialOrdersLoadReturned.current === false) return;

    if(!lastOrders && initialOrdersLoadReturned.current) {
      setLastOrders(orders.active)
      initialOrdersLoadReturned.current = null
      return
    }

    const isNewOrders = !!orders.active.find((val) => !!lastOrders?.find((order) => order.id === val.id) === false)
    if(isNewOrders) {
      play()
    }
    setLastOrders(orders.active)
  }, [orders.active, lastOrders, play])

  const register = (present: any, onRegister: (token: string) => void, navigate: (path: string) => void) => {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        userHelper.updatePartnerDeviceRegistrationToken(token.value)
        onRegister(token.value)
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.error(error);
        toast(present, 'Error on registration: ' + JSON.stringify(error), "danger");
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        //Handle notification that appear while app is open
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (action: ActionPerformed) => {
        if(action.actionId === "tap") {
          if(action.notification.data.type === PushNotifType.Message) {
            navigate('main/messages')
          }
        }
      }
    );
  }

  return (
    <IonApp>
      <TourProvider
        steps={[]}
        disableDotsNavigation={true}
        showPrevNextButtons={true}
        disableKeyboardNavigation={true}
        showCloseButton={false}
        disableInteraction={true}
        onClickMask={(clickProps) => {
          clickProps.setCurrentStep(clickProps.currentStep + 1)
        }}
        prevButton={() => <></>}
        nextButton={(props) => {
          if(props.currentStep === props.stepsLength - 1) {
            return <IonButton size="small" onClick={() => props.setIsOpen(false)}>Close</IonButton>
          }
          return <IonButton size="small" onClick={() => props.setCurrentStep(props.currentStep + 1)}>Next</IonButton>
        }}
        position={"bottom"}
        scrollSmooth={true}
      >
        <IonReactRouter>
          <AppUrlListener />
          <IonRouterOutlet >
            <Route exact path="/" render={() => {
              if(Capacitor.getPlatform() === 'web') {
                return <LoginWeb />
              } else {
                return <Login />
              }
            }} />
            <PrivateRoute path="/unacknowledged" render={() => <Unacknowledged />} />
            <PrivateRoute path="/profile" render={() => <AccountInfo />} />
            <PrivateRoute path="/main" component={Home} />
            <PrivateRoute path="/order/:orderId" component={OrderDetails} />
            <PrivateRoute path="/partner-onboarding/:step?" component={PartnerOnboarding} />
            <PrivateRoute path="/resources" component={Resources} />
            <PrivateRoute exact path="/edit/details">
              <PartnerEdit heading="Edit Details">
                <DetailsForm independent={true} />
              </PartnerEdit>
            </PrivateRoute>
            <PrivateRoute exact path="/edit/websiteoptions">
              <PartnerEdit heading="Edit Website Options">
                <WebsiteOptionsForm />
              </PartnerEdit>
            </PrivateRoute>
            <PrivateRoute exact path="/edit/advance">
              <PartnerEdit heading="Edit Preparation Options">
                <AdvanceForm independent={true} />
              </PartnerEdit>
            </PrivateRoute>
            <PrivateRoute exact path="/edit/available-dates">
              <AvailableDates />
            </PrivateRoute>
            <PrivateRoute exact path="/edit/dinein">
              <PartnerEdit heading="Edit Dine In Options">
                <DineInForm independent={true} />
              </PartnerEdit>
            </PrivateRoute>
            <PrivateRoute exact path="/edit/store">
              <PartnerEdit heading="Edit Store Options">
                <PickupForm independent={true} />
              </PartnerEdit>
            </PrivateRoute>
            <PrivateRoute exact path="/edit/delivery">
              <PartnerEdit heading="Edit Delivery Options">
                <DeliveryForm independent={true} />
              </PartnerEdit>
            </PrivateRoute>
            <PrivateRoute exact path="/edit/payment">
              <PartnerEdit heading="Edit Stripe Details">
                <PaymentForm independent={true} />
              </PartnerEdit>
            </PrivateRoute>
            <PrivateRoute exact path="/edit/events">
              <PartnerEdit heading="Event Registration">
                <Events />
              </PartnerEdit>
            </PrivateRoute>
            <PrivateRoute exact path="/account/status" component={Status} />
            <PrivateRoute path="/account/info" render={() => <AccountInfo />} />
            <PrivateRoute exact path="/messages/:id" component={ChatRoom} />
            <PrivateRoute exact path="/plan" component={Plan} />
            <PrivateRoute exact path="/reviews" component={CustomerReviews} />
          </IonRouterOutlet >
        </IonReactRouter>
      </TourProvider>
    </IonApp>
  )
}

export default App
