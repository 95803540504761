import { IOrderExt, OrderStatus, PartnerStatus } from "@tiffin/core"
import { useAppSelector } from "../hooks";

import { Badge } from "antd";
import { IonIcon, useIonRouter } from '@ionic/react'
import { receiptOutline, personOutline, settingsOutline, peopleOutline, cashOutline, restaurantOutline, checkmarkDoneOutline, chatboxOutline, homeOutline } from 'ionicons/icons';

import './Footer.scss'

export function Footer(props: {status?: PartnerStatus}) {

  const orders = useAppSelector((state) => state.orders)
  const conversationsMetrics = useAppSelector((state) => state.conversationsMetrics)
  const router = useIonRouter()

  function renderOption(icon: string, label: string, path: string, badgeCount?: number) {
    return (
      <div className={`footer-option ${router.routeInfo.pathname.startsWith(path) ? "selected" : ""}`} onClick={() => {router.push(path)}} >
        <Badge count={badgeCount}>
          <IonIcon className={`${router.routeInfo.pathname === path ? "selected" : ""}`} style={{fontSize: "1.6em"}} icon={icon}/>
        </Badge>
      </div>
    )
  }

  return (
      <div className="main-footer">
        {
          (props.status === "unregistered" || props.status === "onboarding" || !props.status) &&
          <>
            {renderOption(peopleOutline, "Setup", "/main/setup")}
            {renderOption(personOutline, "Account", "/main/account")}
          </>
        }
        {
          (props.status === "active" || props.status === "inactive") &&
          <>
            {renderOption(homeOutline, "Home", "/main/dashboard")}
            {renderOption(receiptOutline, "Orders", "/main/orders", orders.active.filter((order: IOrderExt) => order.status === OrderStatus.Unconfirmed).length)}
            {renderOption(restaurantOutline, "Menu", "/main/menu")}
            {renderOption(cashOutline, "Income", "/main/income")}
            {renderOption(chatboxOutline, "Messages", "/main/messages", conversationsMetrics.metrics.tiffinUnread)}
            {renderOption(settingsOutline, "Settings", "/main/settings")}
          </>
        }
        {
          (props.status === "pending" || props.status === "pending-delete") &&
          <>
            {renderOption(checkmarkDoneOutline, "Status", "/main/status")}
            {renderOption(personOutline, "Account", "/main/account")}
          </>
        }
        {
          (props.status === "approved") &&
          <>
            {renderOption(checkmarkDoneOutline, "Status", "/main/status")}
            {renderOption(restaurantOutline, "Menu", "/main/menu")}
            {renderOption(settingsOutline, "Settings", "/main/settings")}
          </>
        }
      </div>
  )
}