"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NEW_CUSTOMER_DISCOUNT = exports.SubscriptionPlan = void 0;
var SubscriptionPlan;
(function (SubscriptionPlan) {
    SubscriptionPlan[SubscriptionPlan["BasicHome"] = 0] = "BasicHome";
    SubscriptionPlan[SubscriptionPlan["StarterMonthly"] = 1] = "StarterMonthly";
    SubscriptionPlan[SubscriptionPlan["StarterAnnual"] = 2] = "StarterAnnual";
    SubscriptionPlan[SubscriptionPlan["OnlineOrderingMonthly"] = 3] = "OnlineOrderingMonthly";
    SubscriptionPlan[SubscriptionPlan["OnlineOrderingAnnual"] = 4] = "OnlineOrderingAnnual";
    SubscriptionPlan[SubscriptionPlan["Event"] = 5] = "Event";
})(SubscriptionPlan = exports.SubscriptionPlan || (exports.SubscriptionPlan = {}));
exports.NEW_CUSTOMER_DISCOUNT = 0.5;
