import { IonDatetime } from "@ionic/react";
import moment, { Moment } from "moment";
import { Modal } from "antd";

import './DatePickerModal.scss'

interface IDatePickerModal {
  className?: string,
  title: string,
  date: Moment,
  isOpen: boolean,
  min?: string,
  max?: string,
  onClose: () => void,
  onUpdate: (date: Moment) => void,
  isDateEnabled: (date: Moment) => boolean
}

export function DatePickerModal({className, title, date, isOpen, min, max, onClose, onUpdate, isDateEnabled}: IDatePickerModal) {
  return (
    <Modal
      className={`${className} date-picker-modal`}
      open={isOpen}
      closable={true}
      onCancel={onClose}
      title={title}
      footer={[]}
      destroyOnClose={true}
    >
        <IonDatetime
          mode="md"
          presentation="date"
          size="cover"
          value={date.format()}
          onIonChange={(e) => {
            onUpdate(moment(e.detail.value));
            onClose()
          }}
          isDateEnabled={(date) => isDateEnabled(moment(date))}
          min={min}
          max={max}
        />
    </Modal>
  )
}