import { Utils } from "@tiffin/app-common";
import { OrderStatus } from "@tiffin/core";
import { Pill } from "./Pill";

interface IOrderTypePill {
  orderStatus: OrderStatus,
  className?: string
}

export function OrderTypePill({orderStatus, ...rest}: IOrderTypePill) {
  return (
    <Pill
      className={rest.className}
      text={Utils.OrderStatusText(orderStatus)}
      backgroundColor={Utils.OrderStatusColour(orderStatus)}
    />
  )
}