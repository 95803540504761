import { useEffect, useRef, useState } from "react"
import { AdvanceDurationType, IPartnerAdvance, MAX_ADVANCE_DAYS, MAX_PREPARATION_DURATION_MINS } from "@tiffin/core"
import { SpringModal, toast } from "@tiffin/components"

//Redux
import { useAppDispatch, useAppSelector } from "../hooks"
import { partnerReducer } from "@tiffin/app-common"

//3rd party
import { Button, Form, FormInstance, InputNumber, Radio, RadioChangeEvent, Slider } from "antd"
import { useIonToast } from "@ionic/react"
import { isEmpty } from "lodash"
import { Prompt } from "react-router"

interface IAdvanceFormProps {
  active?: boolean,
  independent?: boolean,
  onDone?: (action: "save" | "prev" | "next") => void
}

export function AdvanceForm({active, onDone, ...props}: IAdvanceFormProps) {

  const [showPrepInfoModal, setShowPrepInfoModal] = useState(false)

  //Redux
  const status = useAppSelector((state) => state.partner.status)
  const error = useAppSelector((state) => state.partner.error)
  const partnerAdvance = useAppSelector((state) => state.partner.advance)

  const dispatch = useAppDispatch()

  //Internal states
  const [prepTimeEnabled, setPrepTimeEnabled] = useState<boolean>(true)
  const [prepTimeType, setPrepTimeType] = useState<AdvanceDurationType>(AdvanceDurationType.Days)
  const [valueChanged, setValuesChanged] = useState(false)
  const [preparationInMins, setPreparationInMins] = useState<number>()

  const navigateFormRef = useRef<"prev" | "next" | null>(null)
  const formRef = useRef<FormInstance>(null)
  const [present] = useIonToast();

  useEffect(() => {
    //Populate Form
    if (!isEmpty(partnerAdvance) && partnerAdvance) {
      formRef.current?.setFieldsValue({
        preparationEnabled: partnerAdvance.preparationEnabled,
        advanceOrderDays: partnerAdvance.advanceOrderDays
      })

      setPrepTimeEnabled(partnerAdvance.preparationEnabled)
      if (partnerAdvance.preparationDurationType !== undefined) {
        setPrepTimeType(partnerAdvance.preparationDurationType)
        formRef.current?.setFieldsValue({
          preparationDuration: partnerAdvance.preparationDuration,
          preparationDurationType: partnerAdvance.preparationDurationType,
        })
      }

      if(partnerAdvance.preparationInMins !== undefined) {
        formRef.current?.setFieldsValue({
          preparationInMins: partnerAdvance.preparationInMins,
        })
        setPreparationInMins(partnerAdvance.preparationInMins)
      }
    }
  }, [formRef, partnerAdvance])

  useEffect(() => {
    if(active) {
      if (status === "saved" && navigateFormRef.current === "next") {
        dispatch(partnerReducer.resetStatus())
        if(onDone) onDone("next")
        navigateFormRef.current = null
      } else if (status === "saved" && navigateFormRef.current === "prev") {
        dispatch(partnerReducer.resetStatus())
        if(onDone) onDone("prev")
        navigateFormRef.current = null
      } else if (status === "saved") {
        dispatch(partnerReducer.resetStatus())
        toast(present, "Saved changes", "success")
        if(onDone) onDone("save")
      }
    }
  }, [status, dispatch, onDone, active, present])

  useEffect(() => {
    if(props.independent) {
      if (status === "saved") {
        dispatch(partnerReducer.resetStatus())
        toast(present, "Saved changes", "success", "bottom")
        setValuesChanged(false)
      }
    }
  }, [props.independent, status, dispatch, present])

  useEffect(() => {
    if (error) {
      toast(present, error, "danger")
    }
  }, [error, present])

  function handlePrev() {
    handleSave()
    //When the save is successful, we will navigate to the prev form
    navigateFormRef.current = "prev"
  }

  function handleNext(e: any) {
    //Remove focus to prevent user clicking 'enter' button to progress forward
    if(e?.target) {
      e.target.blur()
    }
    
    handleSave()
    //When the save is successful, we will navigate to the next form
    navigateFormRef.current = "next"
  }

  function handleSave() {
    formRef.current?.validateFields().then(
      (fields: any) => {
        let details: IPartnerAdvance = {
          preparationEnabled: fields.preparationEnabled,
          advanceOrderDays: fields.advanceOrderDays
        }

        if (fields.preparationEnabled) {
          details.preparationDuration = fields.preparationDuration
          details.preparationDurationType = fields.preparationDurationType
        } else {
          details.preparationInMins = parseInt(fields.preparationInMins)
        }

        //Updates details in the redux store
        dispatch(partnerReducer.updateAdvance(details))
      },
      () => {
        navigateFormRef.current = null
      }
    )
  }

  function handleOrderAdvanceSwitch(e: RadioChangeEvent) {
    setPrepTimeEnabled(e.target.value)
  }

  function handleAdvanceDurationType(e: RadioChangeEvent) {
    if (e.target.value === AdvanceDurationType.Hours) {
      setPrepTimeType(AdvanceDurationType.Hours)
    } else if (e.target.value === AdvanceDurationType.Days) {
      setPrepTimeType(AdvanceDurationType.Days)
    }
  }

  return (
    <>
      <Form
        ref={formRef}
        className="partner-form"
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
        initialValues={{
          preparationEnabled: false,
          preparationDurationType: AdvanceDurationType.Days,
          advanceOrderDays: 7
        }}
        scrollToFirstError
        onValuesChange={() => props.independent && setValuesChanged(true)}
      >
        <div className="outer-form-container">
          <div className="form-container">

            <Form.Item
              name="preparationEnabled"
              label="Do you require all orders to be pre-ordered?"
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                },
              ]}
            >
              <Radio.Group onChange={handleOrderAdvanceSwitch} buttonStyle="solid">
                <Radio.Button value={false}>No</Radio.Button>
                <Radio.Button value={true}>Yes</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {
              !prepTimeEnabled && (
                <Form.Item
                  name="preparationInMins"
                  required={true}
                  label={'How long do you require to prepare an order?'}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value <= 0) {
                          return Promise.reject(new Error("Number of minutes must be greater than 0"))
                        } else if(value > MAX_PREPARATION_DURATION_MINS) {
                          return Promise.reject(new Error(`Number of minutes cannot be greater than ${MAX_PREPARATION_DURATION_MINS}`))
                        } else if(!value) {
                          return Promise.reject(new Error(`Please enter a value`))
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <div className="prep-time-mins">
                    <InputNumber defaultValue={preparationInMins} min={1} max={MAX_PREPARATION_DURATION_MINS} type="number" />
                    <span>Minutes</span>
                  </div>
                </Form.Item>
              )
            }
            {!!prepTimeEnabled && (
              <Form.Item
                className="prep-time"
                label={'How long do you require for preparation?'}
              >
                <Form.Item
                  name="preparationDuration"
                  rules={[
                    {
                      required: prepTimeEnabled,
                      message: `Please enter the number of ${prepTimeType === AdvanceDurationType.Days ? "days" : "hours"}`,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (prepTimeType === AdvanceDurationType.Days && (value < 1 || value > 7)) {
                          return Promise.reject(new Error("Number of days must be between 1-7"))
                        } else if (prepTimeType === AdvanceDurationType.Hours && (value < 0.25 || value > 24)) {
                          return Promise.reject(new Error("Number of hours must be between 0.25-24"))
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <InputNumber min={0.25} max={prepTimeType === AdvanceDurationType.Days ? 7 : 24} type="number" disabled={!prepTimeEnabled} />
                </Form.Item>
                <Form.Item
                  name="preparationDurationType"
                  rules={[
                    {
                      required: prepTimeEnabled,
                      message: `Please select the initial duration ${prepTimeType === AdvanceDurationType.Days ? "days" : "hours"}`,
                    },
                  ]}
                >
                  <Radio.Group onChange={handleAdvanceDurationType} buttonStyle="solid">
                    <Radio.Button value={AdvanceDurationType.Hours}>Hours</Radio.Button>
                    <Radio.Button value={AdvanceDurationType.Days}>Days</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <div className="value-info">Please enter a value between {prepTimeType === AdvanceDurationType.Days ? '1-7' : '0.25-24'}</div>
              </Form.Item>
            )}
            <Form.Item
              className="advance-order-days"
              label={"Maximum number of days in advance orders can be placed?"}
            >
              <Form.Item
                name="advanceOrderDays"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value <= 0) {
                        return Promise.reject(new Error("Number of days must be greater than 0"))
                      } else if(value > MAX_ADVANCE_DAYS) {
                        return Promise.reject(new Error("Number of days cannot be greater than 100"))
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <InputNumber min={1} type="number"/>
              </Form.Item>
              <span>Days</span>
              <div className="value-info">Default is 7 days</div>
            </Form.Item>
          </div>
        </div>
        <Form.Item className="form-buttons" wrapperCol={{ span: 24 }}>
          {
            !props.independent &&
            <>
              <Button size="large" className="left-btn" onClick={handlePrev}>
                Back
              </Button>
              <Button size="large" type="primary" className="right-btn" htmlType="submit" onClick={handleNext}>
                Next
              </Button>
            </>
          }
          <Button type={props.independent ? "primary" : "default"} size="large" className="right-btn" htmlType="submit" onClick={handleSave}>
            Save
          </Button>
        </Form.Item>
        {
          valueChanged &&
          <Prompt
            message="Any unsaved changes will be discarded?"
          />
        }
      </Form>
      <SpringModal
        className="prep-time-info"
        title="Preparation Time"
        isOpen={showPrepInfoModal}
        cancelText="Close"
        onCancel={() => setShowPrepInfoModal(false)}
        disableAutoHeight={true}
      >
        <span>
          Setting preparation times provide multiple benefits:
        </span>
        <span>
          ● It allows you to provide the customer an estimated wait time from when an order is placed and when it will be ready.
        </span>
        <span>
          ● It controls when your last order for the day can be made, providing you with enough time to complete the order.
        </span>
        <span className='example-heading'>
          Example 1
        </span>
        <span>
          If you set a preparation time of 3 hours and your opening hours are 9am to 6pm, the latest an order can be placed is 3pm.
        </span>
        <Slider
          className='example-prep-hours'
          defaultValue={75}
          tooltipVisible={false}
          marks={{
            0: {
              style: {
                color: 'var(--ion-color-medium)'
              },
              label: '9am'
            },
            100: {
              style: {
                color: 'var(--ion-color-medium)'
              },
              label: '6pm'
            },
            75: <div className="prep-cutt-off"><span>3pm</span><div className="label">No more orders after this point</div></div>
          }}
          handleStyle={{
            background: 'var(--ion-color-warning)',
            border: 'var(--ion-color-warning)'
          }}
          trackStyle={{
            background: 'var(--ion-color-success)'
          }}
          tooltipPlacement="top" 
        />
        <span className='example-heading'>
          Example 2
        </span>
        <span>
          If you set a preparation time of 1 day and your opening hours are 9am to 6pm, the latest an order can be placed is 6pm the day before.
        </span>
        <div className='example-prep-days'>
          <div className='day-marker'>
            <div className='day-indicator'></div>
            <div className='day-label'>
              Monday 6 PM
            </div>
          </div>
          <div className='day-arrow'>
            <div className="arrow">
              <div className="point"></div>
              <div className="line"></div>
            </div>
            <div>An order for Tuesday must be placed by Monday 6 PM</div>
          </div>
          <div className='day-marker'>
            <div className='day-indicator'></div>
            <div className='day-label'>
              Tuesday
            </div>
          </div>
        </div>
      </SpringModal>
    </>
  )
}
