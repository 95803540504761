import { useEffect, useState } from "react";
import { partnerReducer } from "@tiffin/app-common";
import { useAppDispatch, useAppSelector } from "../hooks";
import { LoadingSpinner, SpringModal, toast } from "@tiffin/components";
import { MenuEditSelector } from "../components/menu-editor/MenuEditSelector";
import { MenuCategoryReorder } from "../components/menu-editor/MenuCategoryReorder";
import { MenuArchivedItems } from "../components/menu-editor/MenuArchivedItems";
import { MenuEditModifiers } from "../components/menu-editor/MenuEditModifiers";

//3rd party
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { IonButton, IonChip, IonContent, IonIcon, IonPage, useIonToast } from "@ionic/react";
import { Tabs } from "antd";
import { alertCircle, saveOutline } from "ionicons/icons";
import { Prompt } from "react-router";

import './MenuEditor.scss'

const { TabPane } = Tabs;

export function MenuEditor() {

  //Redux
  const unsavedChanges = useAppSelector((state) => state.partner.unsavedMenuChanges)
  const isRestaurant = useAppSelector((state) => state.partner.details?.isRestaurant)
  const status = useAppSelector((state) => state.partner.menuStatus)
  const error = useAppSelector((state) => state.partner.error)

  const [confirmDiscardModal, setConfirmDiscardModal] = useState(false)

  const dispatch = useAppDispatch()
  const [present] = useIonToast();

  useEffect(() => {
    if(status === 'saved') {
      toast(present, 'Saved changes', 'success')
    }
  }, [status, present])

  useEffect(() => {
    if(error) {
      toast(present, error, 'danger')
    }
    dispatch(partnerReducer.resetError())
  }, [error, dispatch, present])

  function saveMenu() {
    dispatch(partnerReducer.saveMenu())
  }

  function discardChanges() {
    dispatch(partnerReducer.getMenu())
    setConfirmDiscardModal(false)
  }

  function renderTabExtraButtons() {
    return (
      <div className="extra-tab-buttons">
        {
          unsavedChanges &&
          <IonChip className="unsaved-changes" color="warning">
            <IonIcon icon={alertCircle} />
            You have unsaved changes
          </IonChip>
        }
        <IonButton size="small" color="light" disabled={!unsavedChanges || status === 'saving'} onClick={() => setConfirmDiscardModal(true)}>
          Discard
        </IonButton>
        <IonButton size="small" color="primary" disabled={!unsavedChanges} onClick={saveMenu}>
          <LoadingSpinner className={`loading-btn ${status === 'saving' ? 'fadeIn2' : 'fadeOut2'}`} />
          <IonIcon icon={saveOutline} /> <span className="label">{status === 'saving' ? 'Saving' : 'Save'}</span>
        </IonButton>
      </div>
    )
  }

  return (
    <IonPage className="menu">
      <IonContent>
        <div className="menu-tabs">
          <Tabs defaultActiveKey="1" tabBarExtraContent={(isTablet || isDesktop) && renderTabExtraButtons()}>
            <TabPane tab="Menu" key="1">
              <MenuEditSelector view="category"/>
            </TabPane>
            {
              !isRestaurant &&
              <TabPane tab="Days" key="2">
                <MenuEditSelector view="day"/>
              </TabPane>
            }
            <TabPane tab="Categories" key="3">
              <MenuCategoryReorder />
            </TabPane>
            <TabPane tab="Modifiers" key="5">
              <MenuEditModifiers />
            </TabPane>
            <TabPane tab="Archived Items" key="4">
              <MenuArchivedItems />
            </TabPane>
          </Tabs>
          {isMobile && !isTablet && 
            <div className="menu-buttons">
              {renderTabExtraButtons()}
            </div>  
          }
        </div>
        <Prompt
          when={unsavedChanges}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <SpringModal 
          title="Discard changes"
          isOpen={confirmDiscardModal}
          onOk={() => discardChanges()}
          okText="Yes"
          onCancel={() => setConfirmDiscardModal(false)}
        >
          Are you sure you want to discard your changes?
      </SpringModal>
      </IonContent>
    </IonPage>
  )
}