import { useEffect, useRef, useState } from "react";

import './OTPInput.scss'

interface IOTPInput {
  onChange: (val: string) => void,
  disabled?: boolean,
  focused?: boolean
}

export function OTPInput({ onChange, disabled, focused }: IOTPInput) {

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if(ref.current && focused) {
      const elem = ref.current as HTMLElement;
      if(elem.children.length > 0) {
        (elem.children[0] as HTMLInputElement)?.focus()
      }
    }
  }, [focused])

  useEffect(() => {
    onChange(otp.join(""))
  }, [otp, onChange])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if(e.key === "Backspace") {
      const target = e.target as any;

      setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);

      if(target.previousSibling) {
        target.previousSibling.focus();
      }
      e.preventDefault()
    }
  }

  const handleChange = (element: any, index: number) => {
    //Strip out any non numeric characters
    const value = element.value.replace(/\D/,'')

    //Consider autofilled value if length of 6
    if(value.length === 6) {
      setOtp(value.split(''))
      //Focus on last child
      element.parentElement.lastChild.focus();
    } else {
      setOtp([...otp.map((d, idx) => (idx === index ? value[value.length - 1] : d))]);
      //Focus next input
      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  return (
    <div className="otp-input" ref={ref}>
      {otp.map((data, index) => {
        return (
          <input
            autoFocus={index === 0}
            className="otp-field"
            disabled={disabled}
            type="number"
            pattern="[0-9]*"
            name="otp"
            key={index}
            value={data}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={e => handleChange(e.target, index)}
            onClick={e => (e.target as any).focus()}
            onFocus={e => e.target.select()}
            onKeyDown={e => handleKeyDown(e, index)}
            autoComplete="one-time-code"
          />
        );
      })}
    </div>
  )
}