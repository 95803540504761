
import { useEffect } from "react";
import { partnerReducer } from "@tiffin/app-common"
import { InfiniteList } from "@tiffin/components";
import { CustomerReview } from "./CustomerReview";

//Redux
import { useAppDispatch, useAppSelector } from "../hooks";

//3rd party
import { IonItem, IonLabel } from "@ionic/react";

import './CustomerReviewsList.scss'

export function CustomerReviewsList() {

  //Redux
  const customerReviews = useAppSelector((state) => state.partner.customerReviews)
  const status = useAppSelector((state) => state.partner.reviewsStatus)
  const hasMore = useAppSelector((state) => state.partner.hasMoreCustomerReviews)

  const dispatch = useAppDispatch()
 
  useEffect(() => {
    if(status === "unknown") {
      dispatch(partnerReducer.getReviews())
    }
  }, [dispatch, status])

  function renderReviewCards() {
    let cards: JSX.Element[] = []
    for(let review of customerReviews) {
      cards.push(
        <IonItem key={review.id}>
          <CustomerReview {...review} />
        </IonItem>
      )
    }
    if(status !== "loading" && customerReviews.length === 0) {
      cards.push(
        <IonItem className="no-data" key="no-data">
          <IonLabel className="no-reviews">No customer reviews</IonLabel>
        </IonItem>
      )
    }
    return cards
  }

  return (
    <div className="reviews-card-list">
      <InfiniteList
        loading={status === "loading"}
        hasMore={hasMore}
        fetchMore={() => dispatch(partnerReducer.getNextReviews())}
      >
        {renderReviewCards()}
      </InfiniteList>
    </div>
  )
}