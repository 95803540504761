import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../hooks";

import { cloudFunction, incomeReducer } from "@tiffin/app-common"
import { IncomeTable } from "../components/income/IncomeTable";
import { PayoutTable } from "../components/income/PayoutTable";
import { PayoutCardList } from "../components/income/PayoutCardList";
import { PageBreadcrumb } from "../components/PageBreadcrumb";

//3rd party
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonPage, IonPopover, IonTitle, IonToolbar, useIonPopover, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import { caretDownOutline, cashOutline, ellipsisVertical } from "ionicons/icons";
import RedoOutlined from "@ant-design/icons/RedoOutlined"
import { Button, Card, Skeleton, Statistic } from "antd";
import { useResizeDetector } from "react-resize-detector";
import {
  isMobile,
  isTablet,
  isDesktop
} from "react-device-detect";
import moment, { Moment } from "moment";

const METRIC_CARD_HEIGHT_DESKTOP = 102 //Metric cards height
const METRIC_CARD_HEIGHT_MOBILE = 262 //Metric cards height
const BOTTOM_PADDING = 10

const REFRESH_DELAY = 10
const INCOME_ORDER_LIMIT = 25

export const Income = styled(BaseIncome)`
  ion-content {
    --background: var(--ion-color-light);
  }

  .last-updated {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: small;
  }

  .main-content {
    padding-top: var(--page-padding);
    padding-left: var(--page-padding);
    padding-right: var(--page-padding);

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
    gap: 10px;
  
    .metric-cards {
      display: grid;
      gap: 10px;
  
      .balance-stat {
        .ant-statistic {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .ant-statistic-title {
            font-weight: 600;
            color: var(--ion-color-dark-tint);
            font-family: var(--ion-font-family);
          }

          .ant-statistic-content {
            font-size: 1.2em;
            color: var(--ion-color-medium);

            .ant-statistic-content-prefix {
              margin-inline-end: 0px;
            }
          }
        }
      }
  
      .payout-stat {
        .ant-card-body {
          height: 100%;
          display: grid;
          grid-template-columns: 0 1fr auto;
  
          .ant-card-loading-content {
            width: 100%;
          }
  
          .ant-statistic-title {
            font-weight: 600;
            color: var(--ion-color-dark-tint);
            display: flex;
            align-items: center;
            gap: 10px;
          }
  
          .payout-value {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.2em;
            color: var(--ion-color-medium);

            .ant-statistic-title {
              font-size: 14px;
            }

            .ant-skeleton-content {
              display: flex;
              justify-content: center;

              .ant-statistic-content-prefix {
                margin-inline-end: 0px;
              }
            }
            
            .negative-amount {
              color: var(--ion-color-danger)
            }
          }
  
          .payout-dates {
            display: flex;
            flex-direction: column;
            gap: 5px;
  
            .payout-date {
              display: flex;
              text-align: right;
              flex-direction: column;
  
              .ant-statistic-title {
                margin-bottom: 0px;
              }
            }
          }
  
          .payout-more-icon {
            color: var(--ion-color-medium);
            font-size: 1.5em;
          }
        }
      }
    }
  }

  ion-button.refresh-btn {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:961px)  {
  .main-content {
    
    main {
      overflow: hidden;
    }

    .metric-cards {
      grid-template-columns: repeat(3, 1fr);

      .balance-stat {
        .ant-card-body {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
  
          .ant-statistic {
            display: block;
    
            .ant-statistic-content {
              display: flex;
              justify-content: center;
            }
          }
        }
      }

      .payout-stat {
        .ant-card-body {
          display: flex;
          justify-content: space-between;
          grid-template-columns: 0 1fr 1fr auto;

          .payout-value {
            flex-direction: column;
            justify-content: center;
            align-items: unset;
          }
        }
      }
    }
  }
`
interface IIncomeProps {
  className?: string
}

export function BaseIncome({className}: IIncomeProps) {

  const [loadingBalance, setLoadingBalance] = useState(true)
  const [availableBalance, setAvailableBalance] = useState<number>()
  const [pendingBalance, setPendingBalance] = useState<number>()

  const [loadingLastPayout, setLoadingLastPayout] = useState(true)
  const [lastPayout, setLastPayout] = useState<number>()
  const [showPayoutMenu, setShowPayoutMenu] = useState(false)
  const [showPayoutModal, setShowPayoutModal] = useState(false)

  const [disableRefresh, setDisableRefresh] = useState(true)
  const [lastUpdated, setLastUpdated] = useState<Moment>(moment())
  const [lastUpdatedMsg, setLastUpdatedMsg] = useState<string>()
  const lastUpdatedTimer = useRef<number>()

  useIonViewDidEnter(() => {
    //Clear existing interval
    setLastUpdatedMsg(lastUpdated.fromNow()) 
    if(moment().diff(lastUpdated, 'second') >= REFRESH_DELAY) {
      setDisableRefresh(false)
    }

    if(!lastUpdatedTimer.current) {
      lastUpdatedTimer.current = window.setInterval(() => {
        setLastUpdatedMsg(lastUpdated.fromNow())
        if(moment().diff(lastUpdated, 'second') >= REFRESH_DELAY) {
          setDisableRefresh(false)
        }
      }, 10000)
    }
  }, [lastUpdated])

  useEffect(() => {
    //Clear existing interval
    clearInterval(lastUpdatedTimer.current)
    setLastUpdatedMsg(lastUpdated.fromNow()) 
    if(moment().diff(lastUpdated, 'second') >= REFRESH_DELAY) {
      setDisableRefresh(false)
    }

    //Create new interval
    lastUpdatedTimer.current = window.setInterval(() => {
      setLastUpdatedMsg(lastUpdated.fromNow())
      if(moment().diff(lastUpdated, 'second') >= REFRESH_DELAY) {
        setDisableRefresh(false)
      }
    }, 10000)
  }, [lastUpdated])

  useIonViewDidLeave(() => {
    clearInterval(lastUpdatedTimer.current)
    lastUpdatedTimer.current = undefined
  }, [])

  const PopoverList: React.FC<{
    onHide: () => void
  }> = () => (
    <IonList>
      <IonItem 
      onClick={() => {
        dismissPopover()
        setShowPayoutModal(true)
      }}
      button
    >
        View payouts</IonItem>
    </IonList>
  );
  
  const [presentPopover, dismissPopover] = useIonPopover(PopoverList, { onHide: () => dismissPopover() });

  //Redux
  const dispatch = useAppDispatch()

  const { height, ref } = useResizeDetector();

  useEffect(() => {
    updateMetricCards()
  }, [])

  function updateMetricCards() {
    cloudFunction("partnerBalance").then(
      (result: any) => {
        setAvailableBalance(result.data.available[0].amount)
        setPendingBalance(result.data.pending[0].amount)
        setLoadingBalance(false)
      },
      (error) => {
        console.error(error)
      }
    )

    cloudFunction("partnerPayouts", {limit: 1}).then(
      (result: any) => {
        if(result.data.payouts.data.length > 0) {
          setLastPayout(result.data.payouts.data[0].amount / 100)
        }
        setLoadingLastPayout(false)
      },
      (error) => {
        console.error(error)
      }
    )
  }

  function refreshPage() {
    dispatch(incomeReducer.getTransactions({limit: INCOME_ORDER_LIMIT}))
    setLoadingBalance(true)
    setLoadingLastPayout(true)
    updateMetricCards()
    setLastUpdated(moment())
    setDisableRefresh(true)
  }

  return (
    <IonPage className={className}>
      <IonContent>
        <div className="page-container">  
          <div className="page-breadcrumb-header">
            <PageBreadcrumb
              items={[
                {
                  title: <><IonIcon icon={cashOutline} /> Income</>,
                }
              ]}
              extra={
                <div className="last-updated">
                  <span>
                    Last updated {lastUpdatedMsg}
                  </span>
                  <Button
                    size="small"
                    className="refresh-btn"
                    disabled={disableRefresh}
                    onClick={refreshPage}
                    icon={<RedoOutlined />}
                  />
                </div>
              }
            />
          </div>
          <div className="main-content" ref={ref}>
            <div className="metric-cards">
              <Card className="balance-stat">
                <Statistic
                  loading={loadingBalance}
                  title="Pending Balance"
                  value={pendingBalance && (pendingBalance / 100)}
                  precision={2}
                  prefix="$"
                />
              </Card>
              <Card className="balance-stat">
                <Statistic
                  loading={loadingBalance}
                  title="Available Balance"
                  value={availableBalance && (availableBalance / 100)}
                  precision={2}
                  prefix="$"
                />
              </Card>
              <Card className="payout-stat">
                <div className="payout-value">
                  <span className="ant-statistic-title">
                    <span>Last Payout</span>
                    {
                      isMobile && !isTablet && !isDesktop &&
                      <IonButton size="small" color="light" onClick={(e) =>
                          presentPopover({
                            event: e.nativeEvent,
                          })
                        } 
                      >
                        <IonIcon icon={caretDownOutline} />
                      </IonButton>
                    }
                  </span>
                  {
                    loadingLastPayout &&
                    <Skeleton title={{width: 100}} paragraph={{ rows: 0 }}/>
                  }
                  {!loadingLastPayout && lastPayout === undefined && 'N/A'}
                  {!loadingLastPayout && lastPayout !== undefined && (lastPayout >= 0 ? `$${lastPayout.toFixed(2)}` : <span className="negative-amount">{`$${lastPayout.toFixed(2)}`}</span>)}
                </div>
                {
                  (isTablet || isDesktop) &&
                  <IonIcon className="payout-more-icon" icon={ellipsisVertical}
                    onClick={(e) =>
                      presentPopover({
                        event: e.nativeEvent,
                      })
                    } 
                  />
                }
                <IonPopover isOpen={showPayoutMenu} onDidDismiss={() => setShowPayoutMenu(false)}>
                  <p>This is popover content</p>
                </IonPopover>
                <IonModal isOpen={showPayoutModal} className='my-custom-class'>
                  <IonHeader translucent>
                    <IonToolbar>
                      <IonTitle>Payouts</IonTitle>
                      <IonButtons slot="end">
                        <IonButton onClick={() => setShowPayoutModal(false)}>Close</IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent>
                    {
                      isMobile && !isTablet && !isDesktop &&
                      <PayoutCardList />
                    }
                    {
                      (isTablet || isDesktop) &&
                      <PayoutTable />
                    }
                  </IonContent>
                </IonModal>
              </Card>
            </div>
            <IncomeTable height={height && height - BOTTOM_PADDING - (isMobile && !isTablet && !isDesktop ? METRIC_CARD_HEIGHT_MOBILE : METRIC_CARD_HEIGHT_DESKTOP)}/>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}