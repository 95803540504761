import { Redirect, Route } from "react-router-dom"

/* Redux */
import { useAppSelector } from "../hooks"
import { Loading } from "../pages/Loading";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const authenticate = useAppSelector((state) => state.authenticate)

  function renderRoute() {
    if(authenticate.status === "unauthenticated") {
      window.location.href = "/";
    } else if(authenticate.status === "unknown") {
      return <Route {...rest}><Loading /></Route>
    } else if(authenticate.status === "unacknowledged" && rest.path !== "/unacknowledged") {
      return <Redirect to="/unacknowledged" />
    } else if(authenticate.status === "incomplete" && rest.path !== "/profile") {
      return <Redirect to="/profile" />
    } else if(authenticate.status === "authenticated" && rest.path === "/profile") {
      return <Redirect to="/main" />
    }
    return <Route {...rest} render={rest.render ? rest.render : (props: any) => <Component {...props} {...props.location.state} />} />
  }

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    renderRoute()
  )
}

export default PrivateRoute
