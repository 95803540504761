import { useState, useEffect, useCallback, useRef } from "react"
import { APP_ID, IAddressExtended, IPartner, OrderType } from "@tiffin/core"
import { cartMultiReducer, CartStatus, searchReducer, userReducer, Utils } from "@tiffin/app-common"
import { SpringModal } from "./SpringModal"
import { Pill } from "./Pill"
import { LoadingSpinner } from "./LoadingSpinner"

//Redux
import { useAppDispatch } from "../hooks"

//3rd party components
import EnvironmentFilled from "@ant-design/icons/EnvironmentFilled"
import DeleteFilled from "@ant-design/icons/DeleteFilled"
import EnvironmentOutlined from "@ant-design/icons/EnvironmentOutlined"
import {Button, Input, message, Modal} from "antd"

import "./AddressCard.scss"

interface IAddressCardProps {
  address: IAddressExtended
  expanded: boolean
  expandCallback: (id: string) => void
  deleteCallback: (address: IAddressExtended) => void
  updateDefaultCallback: (address: IAddressExtended) => boolean
  selected?: boolean
  onChange?: (address: IAddressExtended) => void
  disableClearCart?: boolean,
  cart: {
    deliveryAddress: IAddressExtended,
    status: CartStatus,
    orderType: OrderType
  },
  partner?: IPartner,
  isPartnerWebsite?: boolean
}

export function AddressCard({
  selected,
  address,
  expanded,
  expandCallback,
  deleteCallback,
  updateDefaultCallback,
  onChange, 
  disableClearCart, 
  cart,
  partner,
  isPartnerWebsite
}: IAddressCardProps) {
  //Redux
  const dispatch = useAppDispatch()

  //Internal states
  const [showAddressWarning, setShowAddressWarning] = useState<boolean>(false)
  const [clearCartModalVisible, setClearCartModalVisible] = useState<boolean>(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
  const [defaultLoading, setDefaultLoading] = useState<boolean>(false)
  const [subpremise, setSubpremise] = useState<string | undefined>(address.subpremise)

  const initialLoad = useRef(true)
  const clearingCart = useRef(false)

  const changeAddress = useCallback(async () => {
    //Check if cart contains a tiffin that does not delivery to new address
    if (!partner || await Utils.checkDeliveryAvailable(partner._geoloc, address.coords, partner.deliveryConfiguration)) {
      if(cart.orderType === OrderType.Delivery) {
        dispatch(cartMultiReducer.updateDeliveryAddress({address: {...address, subpremise}, id: isPartnerWebsite ? partner.id : APP_ID}))
      } else {
        if(!isPartnerWebsite) {
          dispatch(searchReducer.orderTypeChange(true))
        }

        dispatch(
          cartMultiReducer.updateOrderType({
            orderType: OrderType.Delivery,
            deliveryAddress: {...address, subpremise},
            id: isPartnerWebsite ? partner.id : APP_ID
          })
        )
      }
      setClearCartModalVisible(false)
      if(onChange) onChange(address)
    } else {
      //Tiffin does not deliver to your selected delivery address
      if(disableClearCart) {
        setShowAddressWarning(true)
      } else {
        setClearCartModalVisible(true)
      }
    }
  }, [isPartnerWebsite, partner, subpremise, address, cart.orderType, dispatch, onChange, disableClearCart])

  useEffect(() => {
    if (defaultLoading && address.default) {
      setDefaultLoading(false)
    }
  }, [address.default, defaultLoading])

  //On load check if this is the selected address and whether it should update the cart
  useEffect(() => {
    if(initialLoad.current && address) {
      if(selected && cart.deliveryAddress?.placeId !== address.placeId) {
        changeAddress()
      }
      initialLoad.current = false
    }
  }, [selected, address, changeAddress, cart.deliveryAddress])

  //The handler for when a user clicks the address card to change cart address
  function handleSelect() {
    expandCallback(address.placeId)
  }

  function deleteAddress(e?: React.MouseEvent<HTMLElement>) {
    deleteCallback(address)
    setDeleteModalVisible(false)
    if(e) e.stopPropagation()
  }

  function updateDefaultAddress() {
    if (updateDefaultCallback(address)) {
      setDefaultLoading(true)
    } else {
      message.error("Could not set address to default")
    }
  }

  function saveAndUseAddress(e: React.MouseEvent<HTMLElement>) {
    let newAddress = {
      ...address
    }
    
    //Save if there is any change to subpremise
    if(address.subpremise !== subpremise) {
      newAddress.subpremise = subpremise
      dispatch(userReducer.updateAddress(newAddress))
    }

    changeAddress()
    if(e) e.stopPropagation()
  }

  function clearCartAndUpdate(e?: React.MouseEvent<HTMLElement>) {
    //Clear the cart in the redux store and change the delivery address
    clearingCart.current = true
    if(cart.orderType === OrderType.Delivery) {
      dispatch(cartMultiReducer.updateDeliveryAddress({address, id: isPartnerWebsite ? partner.id : APP_ID}))
    } else {
      if(!isPartnerWebsite) {
        dispatch(searchReducer.orderTypeChange(true))
      }

      dispatch(
        cartMultiReducer.updateOrderType({
          orderType: OrderType.Delivery,
          deliveryAddress: address,
          id: isPartnerWebsite ? partner.id : APP_ID
        })
      )
    }
    dispatch(cartMultiReducer.clearCart({partnerId: partner.id, isApp: !isPartnerWebsite}))
    setClearCartModalVisible(false)
    if(onChange) onChange(address)
    if(e) e.stopPropagation()
  }

  function cancelChangeAddress(e?: React.MouseEvent<HTMLElement>) {
    setClearCartModalVisible(false)
    if(e) e.stopPropagation()
  }

  function handleDelete(e: React.MouseEvent<HTMLElement>) {
    setDeleteModalVisible(true)
    if(e) e.stopPropagation()
  }

  function renderClearCartConfirmation() {
    return (
      <SpringModal 
        title="Start new order?" 
        isOpen={clearCartModalVisible} 
        onOk={clearCartAndUpdate}
        okLoading={cart.status === "loading"}
        onCancel={cancelChangeAddress} 
        okText="Continue"
      >
        <span>
          Your cart contains items from <b>{partner?.name}</b> whom do not provide delivery services to <b>{Utils.formatAddress(address)}</b>.
        </span>
        <span>
          The cart will be cleared if you continue to change your delivery address?
        </span>
      </SpringModal>
    )
  }

  function renderAddressWarning() {
    let maxDistanceMeters = 0
    if(partner?.deliveryConfiguration) {
      maxDistanceMeters = partner.deliveryConfiguration.baseDistanceMeters
      if(partner.deliveryConfiguration?.tiers) {
        for(const tier of partner.deliveryConfiguration?.tiers) {
          maxDistanceMeters += tier.distanceMeters
        }
      }
    }

    return (
      <Modal
        title="Cannot change delivery address"
        open={showAddressWarning}
        onCancel={(e) => {
          setShowAddressWarning(false)
          e?.stopPropagation()
        }}
        footer={[
          <Button key="back" onClick={() => setShowAddressWarning(false)}>
            Cancel
          </Button>
        ]}
      >
        <div className="address-warning-modal">
          <span>
            <b>{partner?.name}</b> does not provide delivery services to <b>{Utils.formatAddress(address)}</b>.
          </span>
          <span>Delivery is available up to <b>{maxDistanceMeters/1000} kilometres</b> away from store</span>
        </div>
      </Modal>
    )
  }

  function renderDeleteConfirmation() {
    return (
      <Modal
        open={deleteModalVisible}
        okText="Yes"
        onOk={deleteAddress}
        onCancel={(e) => {
          setDeleteModalVisible(false)
          e?.stopPropagation()
        }}
        title="Delete address"
      >
        <div>
          Are you sure you want to delete your address <span style={{ fontWeight: 600 }}>{Utils.formatAddress(address)}</span>?
        </div>
      </Modal>
    )
  }

  const Marker = (props: { lat: number; lng: number }) => (
    <div className="address-card-marker">
      <EnvironmentFilled />
    </div>
  )

  let streetInfo = ""
  if (address.subpremise) {
    streetInfo += `${address.subpremise}/`
  }
  if (address.streetNumber) {
    streetInfo += `${address.streetNumber} `
  }
  streetInfo += `${address.street}`
  let regionInfo = `${address.locality} ${address.state}`

  return (
    <div key={address.placeId} className={`address-card ${expanded ? "selected" : ""}`} onClick={handleSelect}>
      <div className="address-card-icon">
        <EnvironmentOutlined />
      </div>
      <div className="address-street-info">
        <div>
          <div className="address">{streetInfo}</div>
          <div className="address-region-info">{regionInfo}</div>
        </div>
        <div>
          {address.default && <Pill text="Default" backgroundColor="var(--ion-color-light)" color="var(--ion-text-color)" />}
        </div>
      </div>
      <div className="address-delete-icon" onClick={handleDelete}>
        <DeleteFilled />
      </div>
      {
        expanded &&
        <div className={`address-edit ${expanded ? "fadeIn2" : "fadeOut2"}`}>
          <div className="address-apt-number">
            <Input value={subpremise} placeholder="Apt/Suite" onChange={(e: any) => setSubpremise(e.target.value)}>
            </Input>
          </div>
          <div className="address-card-btns">
            {!address.default && (
                <Button onClick={updateDefaultAddress}>
                  {defaultLoading && <LoadingSpinner className={`loading-btn ${defaultLoading ? 'fadeIn2' : 'fadeOut2'}`}/>}
                  Set to default
                </Button>
            )}
            <Button type="primary" className="address-card-save-btn" onClick={saveAndUseAddress} disabled={selected && subpremise === address.subpremise}>
              {address.subpremise === subpremise ? 'Use' : 'Save & Use'}
            </Button>
          </div>
        </div>
      }
      {renderDeleteConfirmation()}
      {renderClearCartConfirmation()}
      {renderAddressWarning()}
    </div>
  )
}