import PropTypes from 'prop-types';
import Table, { TableProps } from 'antd/lib/table';
import { useCallback, useRef } from 'react';
import { useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';

const scrollConfig = {
  y: 500
};

interface IInfiniteTableProps extends TableProps<any> {
  dataSource?: any[],
  scroll: any,
  onFetch: () => void,
  isLoading: boolean
}

export function InfiniteTable({
  dataSource = [],
  scroll = scrollConfig,
  onFetch = () => null,
  isLoading,
  ...props
}: IInfiniteTableProps) {

  const tableRef = useRef<HTMLDivElement>(null);
  const eventListenerRef = useRef(false);

  const fetchHandler = useCallback((event: any) => {
    const target = (event.target as HTMLDivElement)
    let maxScroll = target.scrollHeight - target.clientHeight - 20; //Buffer of 20px
    let currentScroll = target.scrollTop
    if (currentScroll >= maxScroll) {
      onFetch()
    }
  }, [onFetch])

  useIonViewDidEnter(() => {
    if(tableRef.current && !eventListenerRef.current) {
      var tableContent = tableRef.current.querySelector('.ant-table-body')
      if(tableContent) {
        eventListenerRef.current = true;
        tableContent.addEventListener('scroll', fetchHandler)
      }
    }
  }, [fetchHandler])

  useIonViewWillLeave(() => {
    var tableContent = tableRef.current.querySelector('.ant-table-body')
    if(tableContent) {
      tableContent.removeEventListener('scroll', fetchHandler)
    }
    eventListenerRef.current = false;
  }, [fetchHandler])

  return (
    <Table ref={tableRef} dataSource={dataSource} scroll={scroll} pagination={false} {...props} />
  );
};

InfiniteTable.propTypes = {
  lastId: PropTypes.any,
  dataSource: PropTypes.any,
  debug: PropTypes.bool,
  infinity: PropTypes.bool,
  loadingIndicator: PropTypes.bool,
  onFetch: PropTypes.func,
  scroll: PropTypes.any,
};