import styled from "styled-components";
import { LoadingSpinner } from "./LoadingSpinner";

export const Loading = styled(BaseLoading)`
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.4);
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  .loading-message {
    font-size: var(--font-size-small);
  }

  .logo {
    height: 40px;
    margin: 20px;
    font-size: var(--font-size-xlarge);
  }

  .loading-box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
    padding: 26px 34px;
    border-radius: 10px;
    background: ${(props) => props.showBox ? "#fff" : "unset"};
    color: ${(props) => props.showBox ? "var(--color-dark)" : "var(--color-light)"};
  }
`

interface ILoading {
  className?: string,
  isOpen: boolean,
  message?: string,
  showIcon?: boolean,
  iconOptions?: {
    alt: string,
    src: string
  },
  spinnerSize?: string,
  showBox?: boolean
}

function BaseLoading({className, isOpen, message, showIcon, iconOptions, spinnerSize}: ILoading) {
  return (
    <div className={`${className} ${isOpen ? "fadeIn2" : "fadeOut2"}`}>
      <div className="loading-box">
        {showIcon && <img className="logo" alt={iconOptions.alt} src={iconOptions.src} />}
        <LoadingSpinner fontSize={spinnerSize ?? "var(--font-size-large)"}/>
        {message && <span className="loading-message">{message}</span>}
      </div>
    </div>
  )
}