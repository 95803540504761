import { useState } from "react";
import { Footer } from "../components/Footer";
import { MenuBar } from "../components/MenuBar";

import PrivateRoute from "../components/PrivateRoute";
import { SettingsMenu } from "./SettingsMenu";
import { Dashboard } from "./Dashboard";
import { AccountMenu } from "./AccountMenu";
import { Setup } from "./Setup";
import { Orders } from "./Orders";
import { Income } from "./Income";
import { HistoricOrders } from "./HistoricOrders";
import { MenuEditor } from "./MenuEditor";
import { OnboardingStatus } from "./OnboardingStatus";
import { Messages } from "./Messages";
import { DashboardDailyView } from "./DashboardDailyView";
import { DashboardHistoricalDailyView } from "./DashboardHistoricalDailyView";

//Redux
import { useAppSelector } from "../hooks"

//3rd party
import { IonFooter, IonLoading, IonPage, IonRouterOutlet, IonToolbar, useIonRouter } from '@ionic/react';
import SwiperCore, { Pagination } from 'swiper';
import { Redirect } from "react-router";
import { useMediaQuery } from 'react-responsive'
import { isMobile } from "react-device-detect";

import './Home.scss';

export function Home() {
  SwiperCore.use([Pagination]);

  const router = useIonRouter()

  //Redux states
  const user = useAppSelector((state) => state.user)
  const partner = useAppSelector((state) => state.partner)

  //Internal states
  const [menuBarCollapsed, setMenuBarCollapsed] = useState(isMobile)

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const isLoading = partner.status === "unknown" || user.status === "unknown"
  return (
    <div className={`home ${menuBarCollapsed && 'menu-bar-collapsed'}`}>
      {
        isLoading &&
        <IonLoading isOpen={true} spinner="crescent"/>
      }
      {
        !isLoading &&
        <>
          {
            (router.routeInfo.pathname === "/main" && (partner.status === "unregistered" || partner.partnerStatus === "onboarding")) &&
            <Redirect to="/main/setup" />
          }
          {
            (router.routeInfo.pathname === "/main" && (partner.partnerStatus === "pending" || partner.partnerStatus === "approved" || partner.partnerStatus === "pending-delete")) &&
            <Redirect to="/main/status" />
          }
          {
            (router.routeInfo.pathname === "/main" && (partner.partnerStatus === "active" || partner.partnerStatus === "inactive")) &&
            <Redirect to="/main/dashboard" />
          }
          <IonPage>
            <IonRouterOutlet >
              <PrivateRoute exact path="/main/setup" component={Setup} />
              <PrivateRoute exact path="/main/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/main/dashboard/:date" component={DashboardDailyView} />
              <PrivateRoute exact path="/main/dashboard-historical/:date" component={DashboardHistoricalDailyView} />
              <PrivateRoute exact path="/main/orders" component={Orders} />
              <PrivateRoute exact path="/main/orders/historic" component={HistoricOrders} />
              <PrivateRoute exact path="/main/menu" component={MenuEditor} />
              <PrivateRoute exact path="/main/income" component={Income} />
              <PrivateRoute exact path="/main/settings" component={SettingsMenu} />
              <PrivateRoute exact path="/main/account" component={AccountMenu} />
              <PrivateRoute exact path="/main/status" component={OnboardingStatus} />
              <PrivateRoute exact path="/main/messages" component={Messages} />
            </IonRouterOutlet >
            {
              !isPortrait && router.routeInfo.pathname.startsWith("/main") &&
              <MenuBar defaultCollapse={menuBarCollapsed} onCollapseChange={(collapsed) => setMenuBarCollapsed(collapsed)} status={partner.partnerStatus} />
            }
            {
              isPortrait &&
              <IonFooter>
                <IonToolbar>
                  <Footer status={partner.partnerStatus} />
                </IonToolbar>
              </IonFooter>
            }
          </IonPage>
        </>
      }
    </div>
  )
}