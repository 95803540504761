import { useEffect, useRef } from "react";
import { incomeReducer } from "@tiffin/app-common"
import { InfiniteTable, LoadingSpinner, Pill } from "@tiffin/components";

//Redux
import { useAppDispatch, useAppSelector } from "../../hooks";

//3rd party
import { Button, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { arrowDownOutline, helpCircle } from "ionicons/icons";
import { IonIcon, useIonRouter } from "@ionic/react";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { useMediaQuery } from "react-responsive";

import './IncomeTable.scss'

const TABLE_HEADER = 40
const TABLE_FOOTER = 40
const INCOME_ORDER_LIMIT = 25

export interface IPaymentTransaction {
  id: string,
  amount: number,
  description: string | null,
  available_on: number,
  created: number,
  fee: number,
  fee_details: {
    amount: number,
    description: string
  },
  net: number,
  source: string,
  status: string,
  type: string,
  metadata: any
}

const transactionDescription = (record: IPaymentTransaction) => {
  let cellValue = record.description

  if(record.metadata?.type === "order-payment") {
    cellValue = 'Order completion payment'
  } else if(record.metadata?.type === "promo-reimbursement") {
    cellValue = 'Tifyn promo code reimbursement'
  } else if(record.type === 'transfer') {
    cellValue = 'Order cancellation or refund fee'
  } else if(record.type === 'payment') {
    cellValue = 'Order completion payment'
  } else if(record.description) {
    if(record.description.startsWith('Application fee refund')) {
      cellValue = 'Tifyn fee refund'
    } else if(record.description === "REFUND FOR PAYMENT") {
      cellValue = 'Refunded order'
    } else if(record.description === "STRIPE PAYOUT") {
      cellValue = 'Tifyn payout'
    }
  }

  return (
    <Tooltip title={cellValue}>
      {cellValue}
    </Tooltip>
  )
}

let columns : ColumnsType<IPaymentTransaction> = [
  {
    dataIndex: 'net',
    key: 'indicator',
    width: 30,

    render: (value) => {
      if(value < 0) {
        return <IonIcon className="outgoing-balance" icon={arrowDownOutline} />
      } else if(value > 0) {
        return <IonIcon className="incoming-balance" icon={arrowDownOutline} />
      }
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 80,
    key: 'status',
    render: (value) => {
      let color = '';
      if(value === 'available') {
        color = 'var(--ion-color-success)'
      } else if(value === 'pending') {
        color = 'var(--ion-color-primary)'
      }
      return <Pill text={value} backgroundColor={color} />
    }
  },
  {
    title: <Tooltip title='The date that the funds will be made available'><div className="date-col-header"><span>Date</span><IonIcon icon={helpCircle}/></div></Tooltip>,
    dataIndex: 'available_on',
    width: isMobile && !isTablet && !isDesktop ? 80 : 145,
    key: 'available_on',
    render: (value, record) => {
      if(record.status === "pending") {
        return <Tooltip title={moment(value * 1000).format('LL')}>{moment(value * 1000).format(isMobile && !isTablet && !isDesktop ? 'DD/MM/YY' : 'DD MMMM YYYY')}</Tooltip>
      } else {
        return <Tooltip title={moment(value * 1000).format('LLL')}>{moment(value * 1000).format(isMobile && !isTablet && !isDesktop ? 'DD/MM/YY' : 'DD MMMM YYYY')}</Tooltip>
      }
    }
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: true,
    render: (value, record) => transactionDescription(record)
  },
  {
    title: 'Amount',
    dataIndex: 'net',
    key: 'net',
    width: 120,
    render: (value) => `$${(value / 100).toFixed(2)}`
  }
]

if(isDesktop || isTablet) {
  columns.splice(2, 0, {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    filters: [
      { text: 'Payment', value: 'payment' },
      { text: 'Refund', value: 'payment_refund'},
      { text: 'Fee', value: 'transfer'},
      { text: 'Payout', value: 'payout'}
    ],
    onFilter: (value, record) => record.type === value,
    render: (value) => {
      if(value === 'transfer') {
        return 'Fee'
      } else if(value === 'payment_refund') {
        return 'Refund'
      }
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    width: 80
  },)
}

interface IIncomeTableProps {
  height?: number
}

export function IncomeTable(props: IIncomeTableProps) {
  
  const income = useAppSelector((state) => state.income)
  const updateInProgress = useRef(false)
  const router = useIonRouter();

  const expandableColumns = useMediaQuery({ query: '(min-width:768px)' })

  //Redux
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(incomeReducer.getTransactions({limit: INCOME_ORDER_LIMIT}))
  }, [dispatch])

  useEffect(() => {
    if(updateInProgress.current && income.status === "idle") {
      updateInProgress.current = false
    }
  }, [income.status])

  function getNextTransactions() {
    //Fetch next if there are more transactions and there is not an update already in progress
    if(income.hasMoreTransactions && !updateInProgress.current) {
      updateInProgress.current = true
      dispatch(incomeReducer.getNextTransactions({limit: INCOME_ORDER_LIMIT}))
    }
  }

  return (
    <div>
      <InfiniteTable
        onFetch={() => {getNextTransactions()}}
        className="income-table"
        columns={columns}
        dataSource={income.transactions}
        loading={{
          indicator: <LoadingSpinner />,
          spinning: income.status === "updating"
        }}
        isLoading={income.status === "updating"}
        rowKey="id"
        size="small"
        scroll={{ y: props.height && props.height - TABLE_HEADER - TABLE_FOOTER}}
        footer={() => { return `Displaying 1 - ${income.transactions?.length}`}}
        pagination={false}
        expandable={expandableColumns ? {
          expandedRowRender: (record) => (
            <div className="expanded-row">
              <div className="info">
                <span className="heading">Subtotal</span><span className="value">${(record.metadata.subTotal / 100).toFixed(2)}</span>
              </div>
              {
                (record.metadata.deliveryFee !== undefined && record.metadata.deliveryFee > 0) &&
                <div className="info">
                  <span className="heading">Delivery Fee</span><span className="value">${(record.metadata.deliveryFee / 100).toFixed(2)}</span>
                </div>
              }
              {
                (record.metadata.refundTotal !== undefined && record.metadata.refundTotal > 0) &&
                <div className="info">
                  <span className="heading">Refunded</span><span className="value">-${(record.metadata.refundTotal / 100).toFixed(2)}</span>
                </div>
              }
              {
                (record.metadata.processingFee !== undefined && record.metadata.processingFee > 0) &&
                <div className="info">
                  <span className="heading">Processing Fee</span><span className="value">-${(record.metadata.processingFee / 100).toFixed(2)}</span>
                </div>
              }
              {
                (record.metadata.paymentFee !== undefined && record.metadata.paymentFee > 0) &&
                <div className="info">
                  <span className="heading">Payment Fee</span><span className="value">-${(record.metadata.paymentFee / 100).toFixed(2)}</span>                  
                </div>
              }
              {
                record.metadata.orderId &&
                <div className="view-order">
                  <Button type="link" size="small" onClick={() => {router.push(`/order/${record.metadata.orderId}`)}}>View order details</Button>
                </div>
              }
            </div>
          ),
          rowExpandable: (record) => (!!record.metadata && record.metadata?.type === "order-payment") 
        } : undefined}
      />
    </div>    
  )
}