var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
//firebase
import { collection, getDoc, doc, getDocs, query, where, limit, startAfter, orderBy, startAt, endAt } from "firebase/firestore";
import { db } from "../firebase";
//Algolia
import algoliasearch from 'algoliasearch/lite';
import { DateFormat, AdjustDateType } from "@tiffin/core";
import { Utils } from "../utils";
import moment from "moment";
import isEmpty from "lodash.isempty";
import { distanceBetween, geohashQueryBounds } from "geofire-common";
/* Fetch limits */
var STORE_DELIVERY_LIMIT = 20;
var STORE_PICKUP_LIMIT = 20;
/* Algolia constants */
var ALGOLIA_APPID = process.env.REACT_APP_ALGOLIA_APPID;
var ALGOLIA_SEARCH_API_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
var ATTRIBUTES = [
    'id',
    'name',
    'description',
    'address',
    '_geoloc',
    'phonePrefix',
    'phone',
    'email',
    'cuisines',
    'tags',
    'preparationEnabled',
    'preparationDuration',
    'preparationDurationType',
    'advanceOrderDays',
    'offerPickup',
    'pickupDays',
    'offerDelivery',
    'deliveryDays',
    'deliveryFee',
    'minDeliveryOrder',
    'deliveryAreas',
    'accountId',
    'geohash',
    'rating',
    'numberOfRatings',
    'reviews',
    'adjustedDates',
    'imageUrl',
    'status'
];
/* Browse delivery related functions */
export function getDeliveryTiffins(locality, page, doc, searchText, date) {
    if (date !== "any" || !isEmpty(searchText)) {
        return getDeliveryTiffinsAlgolia(locality, page, searchText, date);
    }
    else {
        if (page > 0) {
            return getNextDeliveryTiffinsFirebase(locality, doc);
        }
        else {
            return getDeliveryTiffinsFirebase(locality);
        }
    }
}
export function getDeliveryTiffinsFirebase(locality) {
    return new Promise(function (resolve) {
        var partnersRef = collection(db, "partners");
        var partnerQuery = query(partnersRef, where("isRestaurant", "==", false), where("offerDelivery", "==", true), where("status", "==", "active"), where("deliveryAreas", "array-contains", locality), limit(STORE_DELIVERY_LIMIT));
        getDocs(partnerQuery).then(function (querySnapshot) {
            var records = [];
            querySnapshot.forEach(function (doc) {
                records.push(__assign({ id: doc.id }, doc.data()));
            });
            resolve(records);
        });
    });
}
export function getNextDeliveryTiffinsFirebase(locality, doc) {
    return new Promise(function (resolve) {
        var partnersRef = collection(db, "partners");
        var partnerQuery = query(partnersRef, where("isRestaurant", "==", false), where("offerDelivery", "==", true), where("status", "==", "active"), where("deliveryAreas", "array-contains", locality), startAfter(doc), limit(STORE_DELIVERY_LIMIT));
        getDocs(partnerQuery).then(function (querySnapshot) {
            var records = [];
            querySnapshot.forEach(function (doc) {
                records.push(__assign({ id: doc.id }, doc.data()));
            });
            resolve(records);
        });
    });
}
export function getDeliveryTiffinsAlgolia(locality, page, searchText, date) {
    var client = algoliasearch(ALGOLIA_APPID, ALGOLIA_SEARCH_API_KEY);
    var index = client.initIndex('partners');
    return new Promise(function (resolve, reject) {
        var query = "isRestaurant:false AND offerDelivery:true AND status:active AND deliveryAreas.country:'".concat(locality.country, "' AND deliveryAreas.locality:'").concat(locality.locality, "' AND deliveryAreas.state:'").concat(locality.state, "'");
        var deliveryDate;
        var deliveryTime;
        if (date !== "any") {
            deliveryDate = date === "now" ? moment() : moment(date);
            deliveryTime = deliveryDate.hours() * 60 + deliveryDate.minutes();
            var advanceDays = deliveryDate.startOf('day').diff(moment().startOf('day'), 'days', true);
            var formattedDate = deliveryDate.format(DateFormat);
            var day = deliveryDate.day();
            query += " AND advanceOrderDays >= ".concat(advanceDays);
            query += " AND (deliveryDays.".concat(day, ".start <= ").concat(deliveryTime, " OR adjustedDates.").concat(formattedDate, ".deliveryTimings.start <= ").concat(deliveryTime, ")");
            query += " AND (deliveryDays.".concat(day, ".end >= ").concat(deliveryTime, " OR adjustedDates.").concat(formattedDate, ".deliveryTimings.end >= ").concat(deliveryTime, ")");
        }
        index.search(searchText, {
            page: page,
            hitsPerPage: STORE_DELIVERY_LIMIT,
            attributesToRetrieve: ATTRIBUTES,
            filters: query
        }).then(function (_a) {
            var hits = _a.hits;
            var records = [];
            hits.forEach(function (hit) {
                var _a;
                if (deliveryDate !== undefined && deliveryTime !== undefined) {
                    var formattedDate = deliveryDate.format(DateFormat);
                    //Ensure that the tiffin is within its adjusted dates times
                    if ((_a = hit.adjustedDates) === null || _a === void 0 ? void 0 : _a[formattedDate]) {
                        if (hit.adjustedDates[formattedDate].type === AdjustDateType.Adjusted && (deliveryTime >= hit.adjustedDates[formattedDate].deliveryTimings.start && deliveryTime <= hit.adjustedDates[formattedDate].deliveryTimings.end)) {
                            records.push(hit);
                        }
                        else if (hit.adjustedDates[formattedDate].type === AdjustDateType.Unchanged) {
                            records.push(hit);
                        }
                    }
                    else {
                        records.push(hit);
                    }
                }
                else {
                    records.push(hit);
                }
            });
            resolve(records);
        });
    });
}
/* Browse pickup related functions */
export function getPickupTiffinsInRadiusFirebase(pos, radius, accurate) {
    return new Promise(function (resolve, reject) {
        var bounds = geohashQueryBounds([pos.lat, pos.lng], radius);
        var promises = [];
        for (var _i = 0, bounds_1 = bounds; _i < bounds_1.length; _i++) {
            var b = bounds_1[_i];
            var partnersRef = collection(db, "partners");
            var partnerQuery = query(partnersRef, where("isRestaurant", "==", false), where("offerPickup", "==", true), where("status", "==", "active"), orderBy("geohash"), startAt(b[0]), endAt(b[1]));
            promises.push(getDocs(partnerQuery));
        }
        // Collect all the query results together into a single list
        Promise.all(promises)
            .then(function (snapshots) {
            var matchingDocs = [];
            for (var _i = 0, snapshots_1 = snapshots; _i < snapshots_1.length; _i++) {
                var snap = snapshots_1[_i];
                for (var _a = 0, _b = snap.docs; _a < _b.length; _a++) {
                    var doc_1 = _b[_a];
                    if (accurate) {
                        var coords = doc_1.get("_geoloc");
                        // We have to filter out a few false positives due to GeoHash
                        // accuracy, but most will match
                        var distanceInKm = distanceBetween([coords.lat, coords.lng], [pos.lat, pos.lng]);
                        var distanceInM = distanceInKm * 1000;
                        if (distanceInM <= radius) {
                            matchingDocs.push(doc_1);
                        }
                    }
                    else {
                        matchingDocs.push(doc_1);
                    }
                }
            }
            return matchingDocs;
        })
            .then(function (matchingDocs) {
            // Process the matching documents
            if (matchingDocs) {
                var records_1 = [];
                matchingDocs.forEach(function (doc) {
                    records_1.push(__assign({ id: doc.id }, doc.data()));
                });
                //Sort records by distance from center
                records_1.sort(function (a, b) { return Utils.haversineDistanceInKm(pos, a._geoloc) - Utils.haversineDistanceInKm(pos, b._geoloc); });
                resolve(records_1);
            }
            else {
                resolve([]);
            }
        });
    });
}
export function getPickupTiffinsInRadiusAlgolia(pos, radius, searchText, date) {
    var client = algoliasearch(ALGOLIA_APPID, ALGOLIA_SEARCH_API_KEY);
    var index = client.initIndex('partners');
    return new Promise(function (resolve, reject) {
        var query = 'isRestaurant:false AND offerPickup:true AND status:active';
        var pickupDate;
        var pickupTime;
        if (date !== "any") {
            pickupDate = date === "now" ? moment() : moment(date);
            pickupTime = pickupDate.hours() * 60 + pickupDate.minutes();
            var advanceDays = pickupDate.startOf('day').diff(moment().startOf('day'), 'days', true);
            var formattedDate = pickupDate.format(DateFormat);
            var day = pickupDate.day();
            query += " AND advanceOrderDays >= ".concat(advanceDays);
            query += " AND (pickupDays.".concat(day, ".start <= ").concat(pickupTime, " OR adjustedDates.").concat(formattedDate, ".pickupTimings.start <= ").concat(pickupTime, ")");
            query += " AND (pickupDays.".concat(day, ".end >= ").concat(pickupTime, " OR adjustedDates.").concat(formattedDate, ".pickupTimings.end >= ").concat(pickupTime, ")");
        }
        index.search(searchText, {
            page: 0,
            hitsPerPage: STORE_PICKUP_LIMIT,
            attributesToRetrieve: ATTRIBUTES,
            aroundLatLng: "".concat(pos.lat, ", ").concat(pos.lng),
            aroundRadius: Math.round(radius),
            filters: query
        }).then(function (_a) {
            var hits = _a.hits;
            var records = [];
            hits.forEach(function (hit) {
                var _a;
                if (pickupDate !== undefined && pickupTime !== undefined) {
                    var formattedDate = pickupDate.format(DateFormat);
                    //Ensure that the tiffin is within its adjusted dates times
                    if ((_a = hit.adjustedDates) === null || _a === void 0 ? void 0 : _a[formattedDate]) {
                        if (hit.adjustedDates[formattedDate].type === AdjustDateType.Adjusted && (pickupTime >= hit.adjustedDates[formattedDate].pickupTimings.start && pickupTime <= hit.adjustedDates[formattedDate].pickupTimings.end)) {
                            records.push(hit);
                        }
                        else if (hit.adjustedDates[formattedDate].type === AdjustDateType.Unchanged) {
                            records.push(hit);
                        }
                    }
                    else {
                        records.push(hit);
                    }
                }
                else {
                    records.push(hit);
                }
            });
            resolve(records);
        });
    });
}
export function getPickupTiffins(pos, mapBounds, searchText, date) {
    var latRadius = Utils.haversineDistanceInKm({ lat: pos.lat, lng: mapBounds.nw.lng }, pos) * 1000;
    var lngRadius = Utils.haversineDistanceInKm({ lat: mapBounds.nw.lat, lng: pos.lng }, pos) * 1000;
    var radius;
    //Pick the larger radius so as to ensure all tiffins listed are plotted on the map
    if (latRadius > lngRadius) {
        radius = latRadius;
    }
    else {
        radius = lngRadius;
    }
    return new Promise(function (resolve, reject) {
        if (!radius) {
            reject("Invalid radius");
            return;
        }
        if (date !== "any" || !isEmpty(searchText)) {
            getPickupTiffinsInRadiusAlgolia(pos, radius, searchText, date)
                .then(function (records) {
                resolve(filterOutOfBounds(records, mapBounds));
            });
        }
        else {
            getPickupTiffinsInRadiusFirebase(pos, radius, true)
                .then(function (records) {
                resolve(filterOutOfBounds(records, mapBounds));
            });
        }
    });
}
function filterOutOfBounds(partners, mapBounds) {
    return partners.filter(function (partner) { return (partner._geoloc.lng > mapBounds.nw.lng &&
        partner._geoloc.lng < mapBounds.ne.lng &&
        partner._geoloc.lat < mapBounds.nw.lat &&
        partner._geoloc.lat > mapBounds.sw.lat); });
}
export function getFavouriteTiffins(favourite_ids) {
    return new Promise(function (resolve, reject) {
        var partnersRef = collection(db, "partners");
        var partnerQuery = query(partnersRef, where("id", "in", favourite_ids), where("status", "==", "active"));
        getDocs(partnerQuery).then(function (querySnapshot) {
            var records = [];
            querySnapshot.forEach(function (doc) {
                records.push(__assign({ id: doc.id }, doc.data()));
            });
            resolve(records);
        });
    });
}
export function getTiffinByName(name) {
    return new Promise(function (resolve, reject) {
        getTiffinDocByName(name).then(function (docSnap) {
            if (docSnap.exists()) {
                resolve(docSnap.data());
            }
            else {
                reject("Store does not exist");
            }
        }, function (error) {
            reject(error);
        });
    });
}
export function getTiffin(id) {
    return new Promise(function (resolve, reject) {
        getTiffinDoc(id).then(function (docSnap) {
            if (docSnap.exists()) {
                resolve(docSnap.data());
            }
            else {
                reject("Store does not exist");
            }
        }, function (error) {
            reject(error);
        });
    });
}
export function getTiffinMenu(id) {
    return new Promise(function (resolve, reject) {
        var docRef = doc(db, "tiffins", id);
        getDoc(docRef).then(function (docSnap) {
            if (docSnap.exists()) {
                var menuDetails = docSnap.data();
                if (!menuDetails.menu) {
                    //This is the old menu structure, so modify and return new structure
                    resolve({
                        menu: menuDetails,
                        modifiers: []
                    });
                }
                else {
                    //This is the new menu structure so return as is
                    resolve(menuDetails);
                }
            }
            else {
                reject("Store does not exist");
            }
        }, function (error) {
            reject(error);
        });
    });
}
export function getTiffinDoc(id) {
    return new Promise(function (resolve, reject) {
        var docRef = doc(db, "partners", id);
        getDoc(docRef).then(function (docSnap) {
            resolve(docSnap);
        }, function (error) {
            reject(error);
        });
    });
}
export function getTiffinDocByName(name) {
    return new Promise(function (resolve, reject) {
        var partnersRef = collection(db, "partners");
        var partnerQuery = query(partnersRef, where("urlName", "==", name), where("subscriptionPlan", ">", 0), where("status", "==", "active"), limit(1));
        getDocs(partnerQuery).then(function (docSnap) {
            if (docSnap.size === 1) {
                resolve(docSnap.docs[0]);
            }
            else {
                reject("Store does not exist");
            }
        }, function (error) {
            reject(error);
        });
    });
}
