import { useEffect, useRef, useState } from "react"
import { Utils, partnerReducer } from "@tiffin/app-common"
import { AdvanceDurationType, convertToTime, Day, IPartnerPickup } from "@tiffin/core"
import { toast } from "@tiffin/components"
import { TimeRangePicker } from "@tiffin/components";
import CheckOutlined from "@ant-design/icons/CheckOutlined"
import CloseOutlined from "@ant-design/icons/CloseOutlined"

//Redux
import { useAppDispatch, useAppSelector } from "../hooks"

//3rd party
import { useIonRouter, useIonToast } from "@ionic/react"
import { Button, Checkbox, Form, FormInstance, Switch } from "antd"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import moment from "moment"
import cloneDeep from "lodash.clonedeep"
import { Prompt } from "react-router";

interface IPickupFormProps {
  active?: boolean,
  independent?: boolean,
  onDone?: (action: "save" | "prev" | "next") => void,
  navigate?: (index: number) => void
}

export function PickupForm({active, onDone, ...props}: IPickupFormProps) {
  //Redux
  const status = useAppSelector((state) => state.partner.status)
  const error = useAppSelector((state) => state.partner.error)
  const partnerPickup = useAppSelector((state) => state.partner.pickup)
  const partnerAdvance = useAppSelector((state) => state.partner.advance)
  const partnerIsRestaurant = useAppSelector((state) => state.partner.details?.isRestaurant)

  const dispatch = useAppDispatch()
  const router = useIonRouter()

  //Internal states
  const [dineInAvailable, setDineInAvailable] = useState<boolean>(false)
  const [pickupAvailable, setPickupAvailable] = useState<boolean>(false)
  const [pickupDays, setPickupDays] = useState<Day[]>()
  const [pickupTimes, setPickupTimes] = useState<{ start: number; end: number }[]>([])
  const [valueChanged, setValuesChanged] = useState(false)

  const navigateFormRef = useRef<"prev" | "next" | null>(null)
  const formRef = useRef<FormInstance>(null)

  const [present] = useIonToast();

  useEffect(() => {
    //Populate form
    if (partnerPickup?.pickupDays && (partnerPickup.offerPickup !== undefined || partnerPickup.offerDineIn !== undefined)) {
      let initPickupDays: number[] = []
      let initPickupTimes: { start: number; end: number }[] = []
      let pickupTimesFields: any = {}

      for (let day in partnerPickup.pickupDays) {
        initPickupDays.push(Number(day))

        pickupTimesFields[`pickupTime-${day}`] = {...partnerPickup.pickupDays[day]}
        initPickupTimes[Number(day)] = {...partnerPickup.pickupDays[day]}
      }

      formRef.current?.setFieldsValue({
        offerPickup: partnerPickup.offerPickup,
        offerDineIn: partnerPickup.offerDineIn,
        pickupDays: initPickupDays,
        ...pickupTimesFields,
      })

      setPickupDays(initPickupDays)
      setPickupTimes(initPickupTimes)
      setPickupAvailable(partnerPickup.offerPickup ?? false)
      setDineInAvailable(partnerPickup.offerDineIn ?? false)
    }
  }, [partnerPickup, formRef])

  useEffect(() => {
    if(active) {
      if (status === "saved" && navigateFormRef.current === "next") {
        dispatch(partnerReducer.resetStatus())
        if(onDone) onDone("next")
        navigateFormRef.current = null
      } else if (status === "saved" && navigateFormRef.current === "prev") {
        dispatch(partnerReducer.resetStatus())
        if(onDone) onDone("prev")
        navigateFormRef.current = null
      } else if (status === "saved") {
        dispatch(partnerReducer.resetStatus())
        toast(present, "Saved changes", "success")
        if(onDone) onDone("save")
      }
    }
  }, [status, dispatch, onDone, active, present])

  useEffect(() => {
    if(props.independent) {
      if (status === "saved") {
        dispatch(partnerReducer.resetStatus())
        toast(present, "Saved changes", "success", "bottom")
        setValuesChanged(false)
      }
    }
  }, [props.independent, status, dispatch, present])

  useEffect(() => {
    if (error) {
      toast(present, error, "danger")
    }
  }, [error, present])

  function handlePrev() {
    handleSave()
    //When the save is successful, we will navigate to the prev form
    navigateFormRef.current = "prev"
  }

  function handleNext(e: any) {
    //Remove focus to prevent user clicking 'enter' button to progress forward
    if(e?.target) {
      e.target.blur()
    }
    
    handleSave()
    //When the save is successful, we will navigate to the next form
    navigateFormRef.current = "next"
  }

  function handleSave() {
    formRef.current?.validateFields().then(
      (fields: any) => {
        let details: IPartnerPickup = {}
        details.offerPickup = fields.offerPickup
        details.pickupDays = {}

        if(partnerIsRestaurant) {
          details.offerDineIn = fields.offerDineIn
        }

        if (fields.pickupDays) {
          for (let day of fields.pickupDays) {
            details.pickupDays[day] = {
              ...fields[`pickupTime-${day}`]
            }
          }
        }

        //Updates details in the redux store
        dispatch(partnerReducer.updatePickup(details))
      },
      () => {
        navigateFormRef.current = null
      }
    )
  }

  function handleDineInSwitch(checked: boolean) {
    setDineInAvailable(checked)
  }

  function handlePickupSwitch(checked: boolean) {
    setPickupAvailable(checked)
  }

  function handlePickUpDays(checkedValue: Array<CheckboxValueType>) {
    setPickupDays(checkedValue as Day[])
  }

  function handlePickupTimes(day: Day, start: number, end: number) {
    let pickupTimesFields: any = {}
    pickupTimesFields[`pickupTime-${day}`] = {start: start, end: end}

    formRef.current?.setFieldsValue({
      ...pickupTimesFields
    })

    let newPickupTimes = cloneDeep(pickupTimes)
    newPickupTimes[Number(day)] = {start: start, end: end}
    setPickupTimes(newPickupTimes)
  }

  function dayCutOffTimeMessage(day: Day) {
    if (partnerAdvance?.preparationDurationType === AdvanceDurationType.Hours) {
      if (pickupTimes[day]) {
        const time = convertToTime(pickupTimes[day].end)
        let cutOffTime = moment(time.formatted, 'HH:mm').subtract(partnerAdvance?.preparationDuration, "hours")
        return `Cut off time for order placement is ${cutOffTime.format("h:mm A")}`
      }
    } else {
      if (pickupTimes[day]) {
        const time = convertToTime(pickupTimes[day].end)
        let cutOffTime = moment(time.formatted, 'HH:mm').subtract(partnerAdvance?.preparationInMins, "minutes")
        return `Cut off time for order placement is ${cutOffTime.format("h:mm A")}`
      }
    }
  }

  return (
    <Form
      ref={formRef}
      className="partner-form"
      initialValues={{
        offerPickup: false,
        offerDineIn: false
      }}
      scrollToFirstError
      onValuesChange={() => props.independent && setValuesChanged(true)}
    >
      <div className="outer-form-container">
        <div className="form-container">
          {
            partnerIsRestaurant &&
            <>
              <Form.Item
                name="offerDineIn"
                label="Enable dine in orders?"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} onChange={handleDineInSwitch} checked={dineInAvailable} />
              </Form.Item>
              {
                props.independent &&
                <div className="field-description" style={{paddingTop: 0}}>
                  Please ensure you have fully configured dine in <Button type="link" onClick={() => router.push("/edit/dinein")}>here</Button>
                </div>
              }
            </>
          }
          <Form.Item
            name="offerPickup"
            label="Enable pickup orders?"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            rules={[
              {
                required: true,
                message: "Please select an option",
              },
            ]}
          >
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} onChange={handlePickupSwitch} checked={pickupAvailable} />
          </Form.Item>
          {(pickupAvailable || dineInAvailable) && (
            <Form.Item 
              label="Which days are you open?"
              labelCol={{ span: 24 }}
            >
              {partnerAdvance?.preparationEnabled &&
                typeof partnerAdvance?.preparationDurationType !== "undefined" &&
                typeof partnerAdvance?.preparationDuration !== "undefined" && (
                  <div className="field-description" style={{ paddingBottom: "10px" }}>
                    {Utils.getCutoffDescription(partnerAdvance, props.navigate)}
                  </div>
                )}
              <Form.Item
                name="pickupDays"
                rules={[
                  {
                    required: pickupAvailable || dineInAvailable,
                    message: "Please select the pickup days",
                  },
                ]}
              >
                <Checkbox.Group
                  className="day-checkbox"
                  options={[
                    { label: "Mon", value: Day.Monday },
                    { label: "Tue", value: Day.Tuesday },
                    { label: "Wed", value: Day.Wednesday },
                    { label: "Thu", value: Day.Thursday },
                    { label: "Fri", value: Day.Friday },
                    { label: "Sat", value: Day.Saturday },
                    { label: "Sun", value: Day.Sunday },
                  ]}
                  onChange={handlePickUpDays}
                />
              </Form.Item>
              {[Day.Monday, Day.Tuesday, Day.Wednesday, Day.Thursday, Day.Friday, Day.Saturday, Day.Sunday].map((day, index) => {
                return (
                  <div key={day}>
                    <Form.Item
                      className="day-timing"
                      label={Utils.getDayString(day)}
                      name={`pickupTime-${day}`}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                      rules={[
                        {
                          required: pickupDays?.includes(day),
                          message: `Please select pickup times for ${Utils.getDayString(day)}`,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if(!value) {
                              return Promise.resolve()
                            }
                            if(value.start === undefined) {
                              return Promise.reject(new Error("Open time cannot be empty"))
                            }
                            if(value.end === undefined) {
                              return Promise.reject(new Error("Close time cannot be empty"))
                            }
                            if(value.start >= value.end) {
                              return Promise.reject(new Error("Open time must be less than close time"))
                            }
                            return Promise.resolve()
                          },
                        })
                      ]}
                      hidden={!pickupDays?.includes(day)}
                    >
                      <TimeRangePicker initialValue={{start: pickupTimes[day]?.start, end: pickupTimes[day]?.end}} onChange={(start: number, end: number) => {
                          handlePickupTimes(day, start, end)
                      }}/>
                    </Form.Item>
                    {
                      pickupDays?.includes(day) &&
                      <div className="field-description">{dayCutOffTimeMessage(day)}</div>                    
                    }
                  </div>
                )
              })}
            </Form.Item>
          )}
        </div>
      </div>
      <Form.Item className="form-buttons" wrapperCol={{ span: 24 }}>
        {
          !props.independent &&
          <>
            <Button size="large" className="left-btn" onClick={handlePrev}>
              Back
            </Button>
            <Button size="large" type="primary" className="right-btn" htmlType="submit" onClick={handleNext}>
              Next
            </Button>
          </>
        }
        <Button type={props.independent ? "primary" : "default"} size="large" className="right-btn" htmlType="submit" onClick={handleSave}>
          Save
        </Button>
      </Form.Item>
      {
        valueChanged &&
        <Prompt
          message="Any unsaved changes will be discarded?"
        />
      }
    </Form>
  )
}
