var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Utils } from "../utils";
import { getDeliveryTiffins, getPickupTiffins, getFavouriteTiffins, getTiffin, getTiffinDoc } from "../firebase-helpers/browse";
import isEqual from "lodash.isequal";
import { clone } from "lodash";
// Define the initial state using that type
var initialState = {
    status: "idle",
    favouriteStatus: "idle",
    tiffins: [],
    favouriteTiffins: [],
    hasMore: true,
    mapSearchConfig: {
        center: {}
    },
    searchText: '',
    date: "any",
    tiffinInViewStatus: "idle",
    updateRequired: false,
    pickupAddress: "Map location"
};
export var getTiffinInView = createAsyncThunk("tiffins/inview", function (id, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, tiffins, tiffin, response;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    state = getState();
                    tiffins = state.search.tiffins;
                    tiffin = tiffins.find(function (tiffin) { return tiffin.id === id; });
                    if (((_b = state.search.tiffinInView) === null || _b === void 0 ? void 0 : _b.id) === id) {
                        return [2 /*return*/];
                    }
                    if (!(tiffin === undefined)) return [3 /*break*/, 2];
                    return [4 /*yield*/, getTiffin(id)];
                case 1:
                    response = _c.sent();
                    return [2 /*return*/, response];
                case 2: return [2 /*return*/, tiffin];
            }
        });
    });
});
export var fetchDeliveryTiffins = createAsyncThunk("tiffins/fetchDeliveryTiffins", function (params, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, deliveryDate, response, lastTiffin;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    state = getState();
                    deliveryDate = (_b = params.date) !== null && _b !== void 0 ? _b : state.search.date;
                    if (!state.search.updateRequired &&
                        state.search.lastDeliverySearchParams &&
                        isEqual(params.locality, state.search.lastDeliverySearchParams.locality) &&
                        params.page === state.search.lastDeliverySearchParams.page &&
                        params.date === state.search.lastDeliverySearchParams.date) {
                        //Nothing changed so no need to send request
                        return [2 /*return*/];
                    }
                    if (!params.locality) return [3 /*break*/, 4];
                    lastTiffin = void 0;
                    if (!(deliveryDate === "any" && params.page > 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, getTiffinDoc(state.search.tiffins[state.search.tiffins.length - 1].id)];
                case 1:
                    lastTiffin = _d.sent();
                    _d.label = 2;
                case 2: return [4 /*yield*/, getDeliveryTiffins(params.locality, params.page, lastTiffin, (_c = params.searchText) !== null && _c !== void 0 ? _c : state.search.searchText, deliveryDate)];
                case 3:
                    response = _d.sent();
                    _d.label = 4;
                case 4: return [2 /*return*/, {
                        tiffins: response !== null && response !== void 0 ? response : [],
                        append: params.page > 0 ? true : false,
                        date: deliveryDate,
                        searchText: params.searchText === undefined ? state.search.searchText : params.searchText,
                        lastDeliverySearchParams: {
                            locality: params.locality,
                            page: params.page,
                            date: params.date
                        }
                    }];
            }
        });
    });
});
export var fetchPickupTiffins = createAsyncThunk("tiffins/fetchPickupTiffins", function (undefined, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    if (!state.search.updateRequired &&
                        state.search.lastPickupSearchParams &&
                        isEqual(state.search.mapSearchConfig, state.search.lastPickupSearchParams.mapConfig) &&
                        state.search.searchText === state.search.lastPickupSearchParams.searchText &&
                        state.search.date === state.search.lastPickupSearchParams.date) {
                        //Nothing changed so no need to send request
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getPickupTiffins({
                            lat: state.search.mapSearchConfig.center.lat,
                            lng: state.search.mapSearchConfig.center.lng
                        }, state.search.mapSearchConfig.bounds, state.search.searchText, state.search.date)];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response];
            }
        });
    });
});
export var updateFavouriteTiffins = createAsyncThunk("tiffins/favourites", function (favourites) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (favourites.length === 0) {
                    return [2 /*return*/, []];
                }
                return [4 /*yield*/, getFavouriteTiffins(favourites)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); });
export var searchSlice = createSlice({
    name: "tiffins",
    initialState: initialState,
    reducers: {
        orderTypeChange: function (state, action) {
            state.updateRequired = action.payload;
        },
        searchTextChange: function (state, action) {
            state.searchText = action.payload;
            state.updateRequired = true;
        },
        dateChange: function (state, action) {
            state.date = action.payload;
            state.updateRequired = true;
        },
        mapCenterChange: function (state, action) {
            //Calcuate new bounds
            var diffLat = state.mapSearchConfig.center.lat - action.payload.lat;
            var diffLng = state.mapSearchConfig.center.lng - action.payload.lng;
            var bounds;
            if (state.mapSearchConfig.bounds) {
                bounds = {
                    ne: {
                        lat: Utils.calcAdjustedLatitude(state.mapSearchConfig.bounds.ne.lat, diffLat),
                        lng: Utils.calcAdjustedLongitude(state.mapSearchConfig.bounds.ne.lng, diffLng)
                    },
                    se: {
                        lat: Utils.calcAdjustedLatitude(state.mapSearchConfig.bounds.se.lat, diffLat),
                        lng: Utils.calcAdjustedLongitude(state.mapSearchConfig.bounds.se.lng, diffLng)
                    },
                    sw: {
                        lat: Utils.calcAdjustedLatitude(state.mapSearchConfig.bounds.sw.lat, diffLat),
                        lng: Utils.calcAdjustedLongitude(state.mapSearchConfig.bounds.sw.lng, diffLng)
                    },
                    nw: {
                        lat: Utils.calcAdjustedLatitude(state.mapSearchConfig.bounds.nw.lat, diffLat),
                        lng: Utils.calcAdjustedLongitude(state.mapSearchConfig.bounds.nw.lng, diffLng)
                    }
                };
            }
            state.mapSearchConfig = {
                center: {
                    lat: action.payload.lat,
                    lng: action.payload.lng
                },
                radius: state.mapSearchConfig.radius,
                bounds: bounds !== null && bounds !== void 0 ? bounds : state.mapSearchConfig.bounds
            };
        },
        mapConfigChange: function (state, action) {
            state.mapSearchConfig = action.payload;
        },
        pickupAddressChange: function (state, action) {
            state.pickupAddress = action.payload;
        },
        nearbyAddressChange: function (state, action) {
            state.nearbyAddress = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder.addCase(getTiffinInView.pending, function (state) {
            state.tiffinInViewStatus = "loading";
        });
        builder.addCase(getTiffinInView.fulfilled, function (state, action) {
            state.tiffinInViewStatus = "succeeded";
            if (action.payload) {
                state.tiffinInView = action.payload;
            }
        });
        builder.addCase(getTiffinInView.rejected, function (state, action) {
            state.tiffinInViewStatus = "failed";
            state.error = action.error.message;
        });
        builder.addCase(fetchPickupTiffins.pending, function (state, action) {
            state.activeRequestId = action.meta.requestId;
            state.status = "updating";
        });
        builder.addCase(fetchPickupTiffins.fulfilled, function (state, action) {
            state.status = "succeeded";
            if (action.payload && state.activeRequestId === action.meta.requestId) {
                state.tiffins = action.payload;
                state.lastPickupSearchParams = {
                    mapConfig: clone(state.mapSearchConfig),
                    searchText: state.searchText,
                    date: state.date
                };
            }
        });
        builder.addCase(fetchPickupTiffins.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(fetchDeliveryTiffins.pending, function (state) {
            state.status = "updating";
        });
        builder.addCase(fetchDeliveryTiffins.fulfilled, function (state, action) {
            state.status = "succeeded";
            if (action.payload) {
                if (action.payload.append) {
                    state.tiffins = __spreadArray(__spreadArray([], state.tiffins, true), action.payload.tiffins, true);
                }
                else {
                    state.tiffins = __spreadArray([], action.payload.tiffins, true);
                }
                if (action.payload.tiffins.length === 0) {
                    state.hasMore = false;
                }
                else {
                    state.hasMore = true;
                }
                state.date = action.payload.date;
                state.searchText = action.payload.searchText;
                state.lastDeliverySearchParams = action.payload.lastDeliverySearchParams;
                state.updateRequired = false;
            }
        });
        builder.addCase(fetchDeliveryTiffins.rejected, function (state, action) {
            state.status = "failed";
            state.error = action.error.message;
        });
        builder.addCase(updateFavouriteTiffins.pending, function (state, action) {
            state.favouriteStatus = "updating";
        });
        builder.addCase(updateFavouriteTiffins.fulfilled, function (state, action) {
            state.favouriteStatus = "succeeded";
            state.favouriteTiffins = __spreadArray([], action.payload, true);
        });
        builder.addCase(updateFavouriteTiffins.rejected, function (state, action) {
            state.favouriteStatus = "failed";
            state.favouriteTiffins = [];
            state.error = action.error.message;
        });
    },
});
export var orderTypeChange = (_a = searchSlice.actions, _a.orderTypeChange), searchTextChange = _a.searchTextChange, dateChange = _a.dateChange, mapCenterChange = _a.mapCenterChange, pickupAddressChange = _a.pickupAddressChange, nearbyAddressChange = _a.nearbyAddressChange, mapConfigChange = _a.mapConfigChange;
export default searchSlice.reducer;
