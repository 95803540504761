import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";

import { ARCHVIED_CATEGORY, IMenuItem } from "@tiffin/core";
import { MenuItemEditor } from "./MenuItemEditor";

import { Input, Menu } from "antd";
import { IonButton, IonIcon, IonModal, useIonViewWillLeave } from "@ionic/react";
import { arrowBackOutline, chevronForwardOutline } from "ionicons/icons";
import { isMobile, isTablet } from "react-device-detect";

import './MenuArchivedItems.scss'

export function MenuArchivedItems() {

  //Redux
  const menu = useAppSelector((state) => state.partner.menu)
  const archivedItems = useAppSelector((state) => state.partner.archived)
  const unsavedChanges = useAppSelector((state) => state.partner.unsavedMenuChanges)

  //Internal states
  const [selectedItemId, setSelectedItemId] = useState<string>()
  const [selectedItem, setSelectedItem] = useState<IMenuItem>()
  const [editingItem, setEditingItem] = useState(false)
  const [searchInput, setSearchInput] = useState<string>()
  const [filteredArchivedItems, setFilteredArchivedItems] = useState<IMenuItem[]>(archivedItems)

  const showModalView = isMobile && !isTablet

  useIonViewWillLeave(() => {
    setSelectedItemId(undefined)
    setEditingItem(false)
  }, [])

  useEffect(() => {
    if(!searchInput) {
      setFilteredArchivedItems(archivedItems)
    } else {
      setFilteredArchivedItems(archivedItems.filter((val: IMenuItem) => val.name.toLowerCase().includes(searchInput)));
    }
  }, [archivedItems, searchInput])

  //Sets the selected item and category based on the menu select id
  useEffect(() => {
    if(editingItem) {
      if(selectedItemId) {
        for(let item of filteredArchivedItems) {
          if(item.id === selectedItemId) {
            setSelectedItem(item)
            return;
          }
        }

        //Didn't find matching id, unselected and hide edit modal
        setSelectedItemId(undefined)
        setEditingItem(false)
      }
    } else {
      setSelectedItemId(undefined)
      setEditingItem(false)
    }
  }, [editingItem, selectedItemId, filteredArchivedItems])

  useEffect(() => {
    //Unselects the item, required otherwise the item edit form will persist old values
    if(!unsavedChanges) {
      setSelectedItemId(undefined)
      setSelectedItem(undefined)
    }
  }, [unsavedChanges])

  useEffect(() => {
    //Changed the selected item when an item is unarchived when not in modal view
    //This is to unselect the item in the menu - in modal view this is done when the modal is dismissed
    if(!showModalView && !filteredArchivedItems.find((val) => val.id === selectedItemId)) {
      setSelectedItemId(undefined)
      setSelectedItem(undefined)
    }
  }, [filteredArchivedItems, selectedItemId, showModalView])

  function handleEditModalDismiss() {
    setEditingItem(false)
    setSelectedItemId(undefined)
  }

  function getMenuItems() {
    let childrenItems: any[] = []
    for(let item of filteredArchivedItems) {
      childrenItems.push({
        label: (
          <div className="menu-item">
            <span>{item.name}</span>
            <IonIcon icon={chevronForwardOutline} />
          </div>
        ),
        key: item.id
      })
    }

    return [{
      type: 'group',
      children: childrenItems,
      key: 'archive'
    }]
  }

  function sortedCategoryList() {
    const categories = [];
    for(const category in menu) {
      categories.push({name: category, order: menu[category].order})
    }
    categories.sort((a, b) => a.order - b.order)

    return categories.map((val) => val.name)
  }

  function renderItemDetails() {
    if(selectedItem) {
      return (
        <>
          <IonModal className={"menu-item-modal"} isOpen={editingItem && showModalView} onDidDismiss={() => {if(showModalView) handleEditModalDismiss()}}>
            <div className="page-header">
              <IonButton onClick={() => setEditingItem(false)}><IonIcon icon={arrowBackOutline}/></IonButton>
              <span className="page-header-text">Menu Item</span>
            </div>
            <MenuItemEditor key={selectedItem.id} item={selectedItem} category={ARCHVIED_CATEGORY} menuCategories={sortedCategoryList()}/>
          </IonModal>
          {
            !showModalView &&
            <MenuItemEditor key={selectedItem.id} item={selectedItem} category={ARCHVIED_CATEGORY} menuCategories={sortedCategoryList()}/>
          }
        </>
      )
    }
  }

  return (
    <>
      <div className={`menu-content ${showModalView ? 'compact-view' : 'full-view'}`}>
        {
          archivedItems.length === 0 &&
          <div className="no-archived-items">No archived items</div>
        }
        {
          !showModalView &&
          <Input className="search-menu-input" placeholder="Search Menu" value={searchInput} onChange={(e) => setSearchInput(e.target.value.toLowerCase())} allowClear />
        }
        <Menu
          key="items"
          className="menu-categories"
          style={{ width: showModalView ? 'auto' : 300 }}
          mode="inline"
          selectedKeys={selectedItemId ? [selectedItemId] : []}
          onSelect={(info) => {
            setSelectedItemId(info.key)
            setEditingItem(true)
          }}
          items={getMenuItems()}
        />
        {
          renderItemDetails()
        }
      </div>
    </>
  )
}