import { useAppSelector } from "../hooks"

//Components
import { IonContent, IonPage } from "@ionic/react"
import { Redirect } from "react-router-dom"
import { LoginForm } from "@tiffin/components";

declare global {
  interface Window { recaptchaVerifier: any; }
}

export function Login() {
  const authenticate = useAppSelector((state) => state.authenticate)

  return (
    <>
    {
      authenticate.status === "authenticated" &&
      <Redirect to="/main" />
    }
    {
      authenticate.status === "incomplete" &&
      <Redirect to="/profile" />
    }
    {
      authenticate.status === "unacknowledged" &&
      <Redirect to="/unacknowledged" />
    }
    {
      authenticate.status === "unauthenticated" &&
      <IonPage>
        <IonContent className="ion-padding">
          <LoginForm 
            logoAlt="Tifyn Partner Logo"
            logoSrc={`${process.env.PUBLIC_URL}/assets/images/tifyn-partner-icon.png`}
          />
        </IonContent>
      </IonPage>
    }
    </>
  )
}
