import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
export function getGlobalConfig() {
    return new Promise(function (resolve, reject) {
        var docRef = doc(db, "global", "config");
        getDoc(docRef).then(function (docSnap) {
            if (docSnap.exists()) {
                resolve(docSnap.data());
            }
            else {
                reject("Global config not found. Please contact Tifyn customer support");
            }
        });
    });
}
export function getGlobalPartnerConfig() {
    return new Promise(function (resolve, reject) {
        var docRef = doc(db, "global", "partnerConfig");
        getDoc(docRef).then(function (docSnap) {
            if (docSnap.exists()) {
                resolve(docSnap.data());
            }
            else {
                reject("Global partner config not found. Please contact Tifyn customer support");
            }
        });
    });
}
