import styled from "styled-components";
import { ISelectedModifier } from '@tiffin/core'

export const OrderItem = styled(BaseOrderItem)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 10px;
  padding: 5px 0px;
  color: var(--ion-color-dark);

  .item-count {
    background: var(--app-base-color);
    color: #FFF;
    padding: 4px 10px;
    border-radius: 5px;
    margin: 5px auto auto;
    grid-row: 1/3;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .item-name {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: var(--ion-color-dark-tint);
  }
  
  .item-desc {
    grid-column: 2;
    white-space: pre-wrap;
  }
  
  .item-price {
    grid-row: 1/3;
    grid-column: 3;
    display: flex;
    align-items: center;
  }

  .item-modifiers {
    grid-column: 2/4;

    .item-modifier {
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      gap: 5px;

      .modifier-title {
        font-size: var(--font-size-regular);
        font-weight: 600;
      }

      .modifier-option {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }
    }
  }
`

interface IOrderItemProps {
  className?: string,
  count: number,
  name: string,
  description?: string,
  price: number,
  modifiers?: ISelectedModifier[]
}

export function BaseOrderItem(props: IOrderItemProps) {

  return (
    <div className={props.className}>
      <div className="item-count">{props.count}</div>
      <div className="item-name">{props.name}</div>
      <div className="item-desc">{props.description}</div>
      <div className="item-price">${(props.price * props.count).toFixed(2)}</div>
      <div className="item-modifiers">
        {
          props.modifiers?.map((modifier) => {
            if(modifier.selectedOptions.length === 0) return;

            return (
              <div key={modifier.id} className="item-modifier">
                <div className="modifier-title">{modifier.name}</div>
                {
                  modifier.selectedOptions.map((selectedOption) => {
                    const optionDetails = modifier.options.find((val) => val.label === selectedOption)
                    if(optionDetails) {
                      return (
                        <div key={selectedOption} className="modifier-option">
                          <div>{optionDetails.label}</div>
                          <div>{optionDetails.price ? `+$${(optionDetails.price * props.count).toFixed(2)}` : ''}</div>
                        </div>
                      )
                    }
                  })
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}