import styled from "styled-components";
import { useRef, useState } from "react"
import { IConversation } from "@tiffin/core"
import { conversationsHelper } from "@tiffin/app-common"
import { DocumentData, QuerySnapshot, Unsubscribe } from "firebase/firestore"
import { ReactComponent as BeginChatSVG } from '../assets/images/begin-chat.svg';
import { PageBreadcrumb } from "../components/PageBreadcrumb";

//3rd party
import { IonContent, IonIcon, IonPage, useIonViewWillEnter } from "@ionic/react"
import { chatboxOutline } from "ionicons/icons"
import { Link } from "react-router-dom"
import { Badge } from "antd"


export const Messages = styled(BaseMessages)`

  ion-content {
    --background: var(--ion-color-light);
  }

  .main-content {
    &.empty-conversations {
      height: 100%;
      color: var(--ion-color-medium);
      text-align: center;
  
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 40px;
  
      svg {
        width: 100px;
        height: fit-content;
      }
    }
  }

  .conversation {
    color: var(--ion-color-dark);
    background-color: #fff;
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    font-size: var(--large-font-size);
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 20px;
    align-items: center;

    img.conversation-avatar {
      object-fit: cover;
    }

    .conversation-avatar {
      font-size: 1.2em;
      height: 2em;
      width: 2em;
      border-radius: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--ion-color-tertiary);
      color: var(--ion-color-tertiary-contrast);
    }

    .body {
      display: grid;
      grid-template-rows: 1fr 1em;

      .info {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: flex-end;
        gap: 10px;

        .last-message {
          font-size: var(--xs-font-size);
          color: var(--ion-color-medium);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
    
        .timestamp {
          font-size: 0.6em;
          display: flex;
          align-items: flex-end;
          height: 100%;
        }
      }
    }
  }
`

interface IMessagesProps {
  className?: string
}

export function BaseMessages({className}: IMessagesProps) {

  const [conversations, setConversations] = useState<IConversation[]>([])
  const unsubRef = useRef<Unsubscribe>()
  
  useIonViewWillEnter(() => {
    conversationsHelper.getPartnersConversations().then((data) => {
      setConversations(data)

      //Start the new conversation listener
      if(data.length > 0) {
        unsubRef.current = conversationsHelper.getNewPartnersConversations(handleNewMessages, data[0].lastMessage)
      } else {
        unsubRef.current = conversationsHelper.getNewPartnersConversations(handleNewMessages)
      }
    })
  })

  function handleNewMessages(snapshot: QuerySnapshot<DocumentData>) {
    if(snapshot.size === 0) {
      return
    }

    //Remove the listener
    if(unsubRef.current) unsubRef.current()

    setConversations((conversations) => {
      let updatedConversations: IConversation[] = [...conversations]
      snapshot.forEach((doc) => {
        //Check if conversations already exists, if so remove it because it will be added again at the top
        const index = updatedConversations.findIndex((conversation) => conversation.id === doc.id);
        if(index > -1) {
          updatedConversations.splice(index, 1)
        }

        updatedConversations.unshift({ id: doc.id, ...doc.data() } as IConversation)
      })
      //Update conversations list
      return updatedConversations;
    })

    //Create a new listener from the latest message date
    unsubRef.current = conversationsHelper.getNewPartnersConversations(handleNewMessages, (snapshot.docs[0].data() as IConversation).lastMessage)
  }

  function resetUnreadCount(id: string) {
    conversationsHelper.resetPartnerUnreadMsgCount(id)
    const updatedConversations = [...conversations]
    for(const conversation of updatedConversations) {
      if(conversation.id === id) {
        conversation.tiffinUnreadMessages = 0
      }
    }
    setConversations(updatedConversations)
  }

  return (
    <IonPage className={className}>
      <IonContent>
        <div className="page-container">
          <div className="page-breadcrumb-header">
            <PageBreadcrumb
              items={[
                {
                  title: <><IonIcon icon={chatboxOutline} /> Messages</>,
                }
              ]}
            />
          </div>
          <div className={`main-content ${conversations.length === 0 ? 'empty-conversations' : ''}`}>
            {
              conversations.map((conversation) => {
                return (
                  <Link key={conversation.id} to={{ pathname: `/messages/${conversation.id}`, state: { name: conversation.customerName, userType: "partner" } }} onClick={() => resetUnreadCount(conversation.id)}> 
                    <div className="conversation">
                      <Badge count={conversation.tiffinUnreadMessages}>
                        <div className="conversation-avatar">
                          {conversation.customerName[0]}
                        </div>
                      </Badge>
                      <div className="body">
                        <div className="title">
                          {conversation.customerName}
                        </div>
                        <div className="info">
                          <div className="last-message">
                            {conversation.lastMessageText}
                          </div>
                          <div className="timestamp">
                            {conversation.lastMessage?.toDate().toDateString()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })
            }
            {
              conversations.length === 0 &&
              <>
                <BeginChatSVG />
                <span>You currently have no messages</span>
              </>
            }
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}