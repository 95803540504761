import { useState } from "react"
import styled from "styled-components";
import { ISelectedModifier } from "@tiffin/core";
import { SpringModal } from "../SpringModal";
import { cartMultiReducer } from "@tiffin/app-common"
import MinusOutlined from "@ant-design/icons/MinusOutlined"
import PlusOutlined from "@ant-design/icons/PlusOutlined"

//Redux
import { useAppDispatch } from "../../hooks"

//3rd party components
import DeleteOutlined from "@ant-design/icons/DeleteOutlined"
import { Button, Divider } from "antd";

export const CartItemCard = styled(BaseCartItemCard)`
	display: flex;
  flex-direction: column;
	background-color: #FFF;
	min-height: 4em;

  .item-details {
    display: grid;
    gap: 5px;
    grid-template-columns: auto 1fr;
  }
  
  .item-count-container {
    display: flex;
    
    .item-count-btns {
      padding: 0 5px;

      display: flex;
      align-items: center;
      gap: 5px;

      background: var(--color-light);
      border-radius: 8px;
    }
  }

  .item-count {
    padding: 5px 10px;
    border-radius: 5px;
    margin: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 25px;
  }

  .item-count-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .item-title {
    text-align: left;
    font-size: 16px;
    font-weight: 450;
    padding-bottom: 10px;
  
    display: flex;
    align-items: center;
  }
  
  .item-price {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .item-modifiers {
    grid-column: 2/3;

    .item-modifier {
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      gap: 5px;

      .modifier-title {
        font-size: var(--font-size-medium);
        font-weight: 600;
      }

      .modifier-option {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }
    }
  }
  
  .item-delete:active {
    background-color: var(--red-2);
  }

  .ant-divider {
    margin: 12px 0;
  }
`

interface ICartItemCardProps {
  className?: string
  partnerId: string
  index: number
  id: string
  name: string
  price: number
  count: number
  date: string
  modifiers: ISelectedModifier[]
  onClickCallback?: (id: string, date: string) => void,
  isPartnerWebsite: boolean
}

function BaseCartItemCard({className, ...props}: ICartItemCardProps) {
  const dispatch = useAppDispatch()

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)

  function cardClickHandler() {
    if (props.onClickCallback) {
      props.onClickCallback(props.id, props.date)
    }
  }

  function updateCount(action: "increase" | "decrease") {
    dispatch(cartMultiReducer.updateItemCountInCart({
      partnerId: props.partnerId,
      isApp: !props.isPartnerWebsite,
      index: props.index,
      itemDate: props.date,
      itemCount: action === "increase" ? props.count + 1 : props.count - 1
    }))
  }

  function deleteItem() {
    dispatch(
      cartMultiReducer.removeItemFromCart({
        date: props.date,
        index: props.index,
        partnerId: props.partnerId,
        isApp: !props.isPartnerWebsite
      })
    )
    setDeleteModalVisible(false)
  }

  function renderDeleteConfirmation() {
    return (
      <SpringModal 
        isOpen={deleteModalVisible} 
        onOk={deleteItem} 
        onCancel={() => setDeleteModalVisible(false)} 
        title={`Delete item`}
        okText="Yes"
      >
        <div>
          Are you sure you want to delete <span style={{ fontWeight: 600 }}>{props.name}</span> from your cart?
        </div>
      </SpringModal>
    )
  }

  return (
    <div className={className}>
      <div>
        <div className="item-details" onClick={cardClickHandler}>
          <div className="item-title">{props.name}</div>
          <div className="item-price">${(props.price * props.count).toFixed(2)}</div>
        </div>
        <div className="item-modifiers">
          {
            props.modifiers.map((modifier) => {
              if(modifier.selectedOptions.length === 0) return;

              return (
                <div key={modifier.id} className="item-modifier">
                  <div className="modifier-title">{modifier.name}</div>
                  {
                    modifier.selectedOptions.map((selectedOption) => {
                      const optionDetails = modifier.options.find((val) => val.label === selectedOption)
                      if(optionDetails) {
                        return (
                          <div key={selectedOption} className="modifier-option">
                            <div>{optionDetails.label}</div>
                            <div>{optionDetails.price ? `+$${(props.count * optionDetails.price).toFixed(2)}` : ''}</div>
                          </div>
                        )
                      }
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="item-count-container">
        <div className="item-count-btns">
          {
            props.count === 1 ?
            <Button className="item-count-btn" size="small" type="text"  icon={<DeleteOutlined style={{ fontSize: '10px'}} />} onClick={() => setDeleteModalVisible(true)} />
            :
            <Button className="item-count-btn" size="small" type="text"  icon={<MinusOutlined style={{ fontSize: '10px'}} />} onClick={() => updateCount("decrease")}/>
          }
          <div className="item-count">
            {props.count}
          </div>
          <Button className="item-count-btn" size="small" type="text"  icon={<PlusOutlined style={{ fontSize: '10px'}} />} onClick={() => updateCount("increase")}/>
        </div>
      </div>
      <Divider />
      {renderDeleteConfirmation()}
    </div>
  )
}
