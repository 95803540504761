import { convertToTime, cutoffDate, DateFormat, OrderType } from "@tiffin/core";
import { Utils } from "./utils";
export function getOrderDays(orderType, pickupDays, deliveryDays) {
    var orderDays = {};
    if ((orderType === OrderType.Pickup || orderType === OrderType.DineIn) && pickupDays) {
        orderDays = pickupDays;
    }
    else if (orderType === OrderType.Delivery && deliveryDays) {
        orderDays = deliveryDays;
    }
    return orderDays;
}
export function getTimingInfo(selectedOrderDay, orderType, partner) {
    var _a, _b, _c, _d;
    var info = {};
    if (selectedOrderDay) {
        var orderDays = getOrderDays(orderType, partner.pickupDays, partner.deliveryDays);
        // Get adjusted date
        var adjustedDate = orderType === OrderType.Pickup || orderType === OrderType.DineIn ?
            (_b = (_a = partner.adjustedDates) === null || _a === void 0 ? void 0 : _a[selectedOrderDay.format(DateFormat)]) === null || _b === void 0 ? void 0 : _b.pickupTimings :
            (_d = (_c = partner.adjustedDates) === null || _c === void 0 ? void 0 : _c[selectedOrderDay.format(DateFormat)]) === null || _d === void 0 ? void 0 : _d.deliveryTimings;
        if (orderDays && orderDays[selectedOrderDay.day()]) {
            info.cutOffTime = cutoffDate(selectedOrderDay, orderDays[selectedOrderDay.day()], partner.preparationEnabled, partner.preparationDurationType, partner.preparationDuration, adjustedDate, partner.preparationInMins);
            var startTime = adjustedDate ? convertToTime(adjustedDate.start) : convertToTime(orderDays[selectedOrderDay.day()].start);
            var endTime = adjustedDate ? convertToTime(adjustedDate.end) : convertToTime(orderDays[selectedOrderDay.day()].end);
            info.timings = "".concat(startTime.formatted12, " - ").concat(endTime.formatted12);
            var nextOrderDayString = Utils.getRelativeDayString(selectedOrderDay);
            info.orderDay = nextOrderDayString;
            info.timingsTitle = "".concat(nextOrderDayString, " ").concat(orderType === OrderType.Pickup ? "Pickup" : "Delivery", " Times");
        }
    }
    return info;
}
