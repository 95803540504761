import { useEffect, useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import type { RootState, AppDispatch } from "@tiffin/app-common"
import useEventListener from "@use-it/event-listener";
import { useWindowSize, useWindowHeight as useWH } from "@react-hook/window-size";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export enum KeyboardState {
  SHOW = 0,
  WILL_SHOW = 1,
  WILL_HIDE = 2,
  HIDDEN = 3
}

export function useKeyboardVisibility() {
  const [keyboardState, setKeyboardState] = useState(KeyboardState.HIDDEN);

  const handleDidShow = (info: any) => {
    setKeyboardState(KeyboardState.SHOW)
  }

  const handleWillShow = (info: any) => {
    setKeyboardState(KeyboardState.WILL_SHOW)
  }

  const handleWillHide = (info: any) => {
    setKeyboardState(KeyboardState.WILL_HIDE)
  }

  const handleDidHide = (info: any) => {
    setKeyboardState(KeyboardState.HIDDEN)
  }

  useEventListener('keyboardDidShow', handleDidShow)
  useEventListener('keyboardWillShow', handleWillShow)
  useEventListener('keyboardWillHide', handleWillHide)
  useEventListener('keyboardDidHide', handleDidHide)

  return keyboardState
}

export function useKeyboardHeight() {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  const handleWillShow = (info: any) => {
    setKeyboardHeight(info.keyboardHeight)
  }

  const handleWillHide = () => {
    setKeyboardHeight(0)
  }

  useEventListener('keyboardWillShow', handleWillShow)
  useEventListener('keyboardDidHide', handleWillHide)

  return keyboardHeight
}

export function useIsPortrait() {
  const [windowWidth, windowHeight] = useWindowSize()
  const [isPortrait, setIsPortrait] = useState(windowHeight > windowWidth)
  
  const keyboardHeight = useRef(0)
  const prevWindowHeight = useRef(windowHeight);

  useEventListener('keyboardWillShow', (info: any) => {
    keyboardHeight.current = info.keyboardHeight
  })

  useEffect(() => {
    if(prevWindowHeight.current !== windowHeight) {
      if((windowHeight + keyboardHeight.current) !== prevWindowHeight.current) {
        setIsPortrait(windowHeight > windowWidth)
      }
      prevWindowHeight.current = windowHeight
    }
  }, [windowHeight, windowWidth])
  
  return isPortrait 
}

export function useWindowHeight() {
  const windowHeight = useWH()
  const keyboardHeight = useRef(0)
  const prevWindowHeight = useRef(windowHeight);
  const [height, setHeight] = useState(windowHeight)

  useEventListener('keyboardWillShow', (info: any) => {
    keyboardHeight.current = info.keyboardHeight
  })

  useEffect(() => {
    if(prevWindowHeight.current !== windowHeight) {
      if((windowHeight + keyboardHeight.current) !== prevWindowHeight.current) {
        setHeight(windowHeight)
      }
      prevWindowHeight.current = windowHeight
    }
  }, [windowHeight])

  return height
}