import { useEffect, useRef, useState } from "react"
import { AddressInput, toast } from "@tiffin/components"
import { Constants, Utils, eventHelper, partnerReducer, partnerHelper } from "@tiffin/app-common"
import { ContactPhoneMode, IPartnerDetails, IPartnerWebsiteDetails } from "@tiffin/core"
import ExampleCoverPhoto from "../assets/images/example-cover-photo.png"

//Redux
import { useAppDispatch, useAppSelector } from "../hooks"

//3rd party
import UploadOutlined from "@ant-design/icons/UploadOutlined"
import { callOutline, logoWhatsapp } from "ionicons/icons"
import { Alert, Button, Form, FormInstance, Input, Radio, Select, Upload } from "antd"
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface"
import { RcFile } from "antd/lib/upload"
import { IonIcon, IonToggle, useIonToast } from "@ionic/react"
import { Prompt } from "react-router"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import { useMediaQuery } from "react-responsive"
import TextArea from "antd/lib/input/TextArea"

const { Option } = Select

interface IDetailsFormProps {
  active?: boolean,
  independent?: boolean,
  onDone?: (action: "save" | "prev" | "next") => void
}

export function DetailsForm({active, onDone, ...props}: IDetailsFormProps) {
  //Redux
  const status = useAppSelector((state) => state.partner.status)
  const error = useAppSelector((state) => state.partner.error)
  const partnerDetails = useAppSelector((state) => state.partner.details)
  const coverImageUrl = useAppSelector((state) => state.partner.imageUrl)
  const logoImageUrl = useAppSelector((state) => state.partner.logoImageUrl)
  const pendingCoverImageUrl = useAppSelector((state) => state.partner.pendingImageUrl)
  const cuisinesMap = useAppSelector((state) => state.globalPartnerConfig.cusines)

  const dispatch = useAppDispatch()

  //Internal states
  const [isRestaurant, setIsRestaurant] = useState(true)
  const [isEventRegistration, setIsEventRegistration] = useState(false)
  const [events, setEvents] = useState<eventHelper.IEvent[]>()
  const [coverImage, setCoverImage] = useState<UploadFile<any> | null>(null)
  const [logoImage, setLogoImage] = useState<UploadFile<any> | null>(null)
  const [address, setAddress] = useState<string>("")
  const [maskedAddress, setMaskedAddress] = useState<string>("")
  const [maskedGeoLoc, setMaskedGeoLoc] = useState<{lat: number, lng: number}>()
  const [hideExactAddress, setHideExactAddress] = useState(true)
  const [valueChanged, setValuesChanged] = useState(false)
  const [saving, setSaving] = useState(false)

  const fullView = useMediaQuery({ query: '(min-width:576px)' })
  const globalConfig = useAppSelector((state) => state.globalPartnerConfig)

  const navigateFormRef = useRef<"next" | null>(null)
  const nameValidateRef = useRef<any>(null)
  const formRef = useRef<FormInstance>(null)
  const isPopulatedRef = useRef(false)
  const [present] = useIonToast();

  const hasCoverImage = !!coverImage;

  //Populate form with user information if provided through props
  useEffect(() => {
    if (partnerDetails && !isPopulatedRef.current) {
      formRef.current?.setFieldsValue({ ...partnerDetails, isEventRegistration: !!partnerDetails.eventId })

      if(partnerDetails.eventId !== undefined) {
        setIsEventRegistration(true)
      }

      if(partnerDetails.isRestaurant !== undefined) {
        setIsRestaurant(partnerDetails.isRestaurant)
      }

      if(partnerDetails.address) {
        setAddress(partnerDetails.address)
      }
      if(partnerDetails.maskedAddress) {
        setMaskedAddress(partnerDetails.maskedAddress)
      }
      if(partnerDetails.hideExactAddress) {
        setHideExactAddress(partnerDetails.hideExactAddress)
      }
      if(partnerDetails.maskedGeoLoc) {
        setMaskedGeoLoc(partnerDetails.maskedGeoLoc)
      }

      if (!hasCoverImage) {
        if(pendingCoverImageUrl) {
          setCoverImage({
            uid: "-1",
            name: "",
            status: "done",
            url: pendingCoverImageUrl,
            thumbUrl: pendingCoverImageUrl,
          })
        } else if(coverImageUrl) {
          setCoverImage({
            uid: "-1",
            name: "",
            status: "done",
            url: coverImageUrl,
            thumbUrl: coverImageUrl,
          })
        }
      }

      if(logoImageUrl) {
        setLogoImage({
          uid: "-1",
          name: "",
          status: "done",
          url: logoImageUrl,
          thumbUrl: logoImageUrl,
        })
      }

      isPopulatedRef.current = true;
    }
  }, [formRef, partnerDetails, coverImageUrl, pendingCoverImageUrl, logoImageUrl, hasCoverImage])

  useEffect(() => {
    if(active) {
      if (status === "saved" && navigateFormRef.current) {
        setSaving(false)
        dispatch(partnerReducer.resetStatus())
        if(onDone) onDone("next")
        navigateFormRef.current = null
      } else if (status === "saved") {
        setSaving(false)
        dispatch(partnerReducer.resetStatus())
        toast(present, "Saved changes", "success")
        if(onDone) onDone("save")
      }
    }
  }, [active, status, dispatch, onDone, present])

  useEffect(() => {
    if(props.independent) {
      if (status === "saved") {
        setSaving(false)
        dispatch(partnerReducer.resetStatus())
        toast(present, "Saved changes", "success", "bottom")
        setValuesChanged(false)
      }
    }
  }, [props.independent, status, dispatch, present])

  useEffect(() => {
    if (error) {
      toast(present, error, "danger")
    }
  }, [error, present])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //Populate events
  useEffect(() => {
    if(isEventRegistration && !events) {
      eventHelper.getEvents().then((e) => {
        setEvents(e)
      })
    }
  }, [isEventRegistration])

  function handleNext(e: any) {
    //Remove focus to prevent user clicking 'enter' button to progress forward
    if(e?.target) {
      e.target.blur()
    }

    handleSave()
    //When the save is successful, we will navigate to the next form
    navigateFormRef.current = "next"
  }

  function handleSave(){
    setTimeout(() => {
      formRef.current?.validateFields().then(
        (fields: any) => {
          setSaving(true)

          let details: IPartnerDetails & IPartnerWebsiteDetails = {
            isRestaurant: isRestaurant,
            name: fields.name,
            nameLowercase: Utils.convertToLowerName(fields.name),
            address: address,
            maskedAddress: maskedAddress,
            maskedGeoLoc: maskedGeoLoc ?? fields._geoloc, //Default to exact if masked does not exist - this is not expected
            _geoloc: fields._geoloc,
            cuisines: fields.cuisines,
            hideExactAddress: !isRestaurant ? hideExactAddress : false,
          }

          if(isEventRegistration) {
            details.eventId = fields.eventId ?? partnerDetails?.eventId
          }

          if(!props.independent) {
            details.urlName = Utils.convertToLowerName(fields.name)
          }

          if(fields.customerContactPhone !== undefined) {
            details.customerContactPhone = fields.customerContactPhone
          }

          if(fields.contactPhoneMode !== undefined) {
            details.contactPhoneMode = fields.contactPhoneMode
          }

          if(fields.customerContactEmail !== undefined) {
            details.customerContactEmail = fields.customerContactEmail
          }

          if (fields.description !== undefined) {
            details.description = fields.description
          }

          if (fields.longDescription !== undefined) {
            details.longDescription = fields.longDescription
          }

          if (fields.tags) {
            details.tags = fields.tags
          }
          
          //Updates details in the redux store
          dispatch(partnerReducer.updateDetails(details))

          if (coverImage && coverImage.originFileObj) {
            dispatch(partnerReducer.updateCoverImage(coverImage))
          } else if ((coverImageUrl || pendingCoverImageUrl) && !coverImage) {
            dispatch(partnerReducer.updateCoverImage(null))
          }

          if (logoImage && logoImage.originFileObj) {
            dispatch(partnerReducer.updateLogoImage(logoImage))
          } else if (!logoImage) {
            dispatch(partnerReducer.updateLogoImage(null))
          }
        },
        (err) => {
          if(err && err.errorFields?.[0]) {
            toast(present, err.errorFields[0].errors[0], "danger")
          }
          navigateFormRef.current = null
        }
    )})
  }

  function changeCoverImage(info: UploadChangeParam) {
    if (info.fileList.length === 0) {
      //call redux to delete image
      setCoverImage(null)
    } else {
      setCoverImage(info.fileList[0])
    }
  }

  function changeLogoImage(info: UploadChangeParam) {
    if (info.fileList.length === 0) {
      //call redux to delete image
      setLogoImage(null)
    } else {
      setLogoImage(info.fileList[0])
    }
  }

  function beforeUpload(file: RcFile) {
    let res = Utils.checkCoverImage(file)
    if(res.error) {
      toast(present, res.error, "danger")
    }
    return res.valid
  }

  function customRequest(options: any) {
    let result = Utils.checkCoverImage(options.file)
    if (result.valid) {
      options.onSuccess("ok")
    } else {
      toast(present, result.error, "danger")
      options.onError({
        status: "error",
      })
    }
    return true
  }

  async function handleAddressChange(value: string, coords: { lat: number; lng: number }, geoLocation?: google.maps.GeocoderResult) {

    if(geoLocation) {
      const geoAddress = Utils.parseAddress(geoLocation)
      const geoMaskedAddress = Utils.formatAddress(geoAddress, true)
      setMaskedAddress(geoMaskedAddress)

      const geoLocations = await geocodeByAddress(geoMaskedAddress)
      const latLng = await getLatLng(geoLocations[0])
      setMaskedGeoLoc({ lat: latLng.lat, lng: latLng.lng })
    }

    setAddress(value)
    formRef.current?.setFieldsValue({
      address: value, //needed for address validation
      _geoloc: { lat: coords.lat, lng: coords.lng },
    })
  }

  const isUniqueTiffinName = async (_: any, name: string): Promise<boolean> => {
    const response = await partnerHelper.storeNameIsUnique(name)
    if(response) {
      return Promise.resolve(true)
    }
    return Promise.reject(Constants.ERRORMESSAGE_TIFFINNAMEEXISTS)
  }

  let cuisines: string[] = []
  if(cuisinesMap) {
    cuisines = Object.keys(cuisinesMap).sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    })
  }

  return (
    <Form
      ref={formRef}
      className="partner-form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{
        phonePrefix: "61",
      }}
      scrollToFirstError
      onValuesChange={() => props.independent && setValuesChanged(true)}
    >
      <div className="outer-form-container">
        <div className="form-container">
          {
            (!props.independent && globalConfig.enableEventRegistration) &&
            <Form.Item
              initialValue={isEventRegistration}
              name="isEventRegistration"
              label="Are you registering for an event?"
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                },
              ]}
            >
              <Radio.Group value={isRestaurant} buttonStyle="solid" onChange={(e) => setIsEventRegistration(e.target.value)}>
                <Radio.Button value={true}>Yes</Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
              </Radio.Group>
            </Form.Item>
          }
          {
            (!props.independent && isEventRegistration && globalConfig.enableEventRegistration) &&
            <Form.Item
              name="eventId"
              label="Event"
              rules={[{ required: true, message: 'Please select an event' }]}
            >
              <Select placeholder="Please select an event">
                {
                  events?.map((event) => <Option key={event.id} value={event.id}><div className="event-select-option"><img alt="logo" src={event.logoImageUrl}/><div className="text">{event.name}</div></div></Option>)
                }
              </Select>
            </Form.Item>
          }
          <Form.Item
            initialValue={isRestaurant}
            name="isRestaurant"
            label="Are you a restaurant/cafe?"
            rules={[
              {
                required: true,
                message: "Please select an option",
              },
            ]}
          >
            <Radio.Group value={isRestaurant} buttonStyle="solid" onChange={(e) => setIsRestaurant(e.target.value)}>
              <Radio.Button value={true}>Yes</Radio.Button>
              <Radio.Button value={false}>No</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="name"
            label="Store name"
            rules={[
              {
                required: true,
                message: "Please enter the name of your store",
              },
              () => ({
                async validator(_, value: string) {
                  // Empty value error message taken care of by first rule
                  if(!value) return Promise.reject()

                  return new Promise((resolve, reject) => {
                    if(nameValidateRef.current) {
                      clearTimeout(nameValidateRef.current)
                    }
                    
                    nameValidateRef.current = setTimeout(() => {
                      isUniqueTiffinName(_, value).then((value) => {
                        resolve(value)
                      }, (err) => {
                        reject(err)
                      })
                    }, 500)
                  })

                },
              }),
            ]}
            hasFeedback
          >
            <Input placeholder="Please enter your store's name" />
          </Form.Item>
          <Form.Item name="description" label="Store description">
            <Input placeholder="Please enter a short description of your store" />
          </Form.Item>
          <Form.Item name="longDescription" label="About us">
            <TextArea placeholder="Let customers know your story, this will appear in the information section for your store website" />
          </Form.Item>
          {
            isEventRegistration &&
            <Alert className="location-note" message="If you are solely registering for an event, we recommend entering the events address" type="info" showIcon />
          }
          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: "Please enter an address",
              },
            ]}
          >
            <AddressInput placeholder="Please enter address" defaultAddress={address} setAddress={handleAddressChange} types={["address"]} />
          </Form.Item>
          {
            (!isRestaurant && !isEventRegistration) &&
            <div className={fullView ? "ant-col-16 ant-col-offset-8" : ""}>
              <Alert className="location-note" message="Please note, this must be your exact address including street number since it will be provided to customers as the pickup address." type="info" showIcon />
              <div className="hide-location">
                <span className="hide-location-label">Hide exact address</span>
                <IonToggle checked={hideExactAddress} onIonChange={(e) => setHideExactAddress(e.detail.checked)} />
                <span className="hide-location-info">By default this option is enabled to hide your unit and/or street number for privacy. <br/> Your full address will only be provided to customers that have submitted a pickup order.</span>
              </div>
            </div>
          }
          <Form.Item name="_geoloc" hidden={true}>
            <></>
          </Form.Item>
          <Form.Item 
            name="customerContactPhone" 
            label="Customer contact phone number"
            rules={[
              {
                required: isEventRegistration ? false : true,
                message: "A customer contact phone number is required",
              },
            ]}
          >
            <Input type="number" inputMode="numeric" placeholder="Phone number" />
          </Form.Item>
          <Form.Item
              initialValue={ContactPhoneMode.Phone}
              name="contactPhoneMode"
              label="Preferred mode of contact?"
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                },
              ]}
            >
              <Radio.Group className="contact-phone-mode" buttonStyle="solid">
                <Radio.Button value={ContactPhoneMode.Phone}><div className="phone-mode-option"><IonIcon icon={callOutline}/> Phone</div></Radio.Button>
                <Radio.Button value={ContactPhoneMode.WhatsApp}><div className="phone-mode-option"><IonIcon icon={logoWhatsapp}/> WhatsApp</div></Radio.Button>
              </Radio.Group>
            </Form.Item>
          <Form.Item 
            name="customerContactEmail" 
            label="Customer contact email address"
          >
            <Input placeholder="Email address" />
          </Form.Item>
          <Form.Item label="Cover photo">
            {
              pendingCoverImageUrl &&
              <Alert message="Your updated cover photo is pending approval" type="warning" showIcon />
            }
            {
              !isRestaurant &&
              <div className="cover-photo-guidlines">
                <div>
                  <span><b>The cover photo must:</b></span>
                  <ul>
                    <li>be less than or equal to 2MB</li>
                    <li>be clear and in focus</li>
                    <li>contain no text</li>
                    <li>be roughly 16:9 ratio</li>
                  </ul>
                </div>
                <div className="example-photo">
                  <span><b>Example cover photo:</b></span>
                  <img src={ExampleCoverPhoto} alt="example cover" />
                </div>
              </div>
            }
            <Upload
              className="cover-image-upload"
              accept={"image/*"}
              listType="picture-card"
              maxCount={1}
              fileList={coverImage ? [coverImage] : []}
              onChange={changeCoverImage}
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              onRemove={() => setCoverImage(null)}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Logo photo">
            <Upload
              className="cover-image-upload"
              accept={"image/*"}
              listType="picture-card"
              maxCount={1}
              fileList={logoImage ? [logoImage] : []}
              onChange={changeLogoImage}
              onRemove={() => setLogoImage(null)}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="cuisines"
            label="Cuisines"
            rules={[{ required: true, message: 'Please select at least one cuisine', type: 'array' }]}
          >
            <Select mode="multiple" placeholder="Please select cuisines">
              {
                cuisines.map((cuisine) => <Option key={cuisine} value={cuisine}>{cuisine}</Option>)
              }
            </Select>
          </Form.Item>
        </div>
      </div>
      <Form.Item key={1} className="form-buttons" wrapperCol={{ span: 24 }}>
        {
          !props.independent &&
          <Button size="large" type="primary" loading={navigateFormRef.current === "next" && saving} className="right-btn" htmlType="submit" onClick={handleNext}>
            Next
          </Button>
        }
        <Button type={props.independent ? "primary" : "default"} size="large" loading={navigateFormRef.current === null && saving} className="right-btn" htmlType="submit" onClick={handleSave}>
          Save
        </Button>
      </Form.Item>
      {
        valueChanged &&
        <Prompt
          message="Any unsaved changes will be discarded?"
        />
      }
    </Form>
  )
}
