var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, where, limit, endBefore, startAfter } from "firebase/firestore";
import { DateFormat, OrderStatus } from "@tiffin/core";
import { auth, db } from "../firebase";
import moment from "moment";
var MAX_HISTORICAL_ORDER_REQUEST_LIMIT = 100;
export function subsctibeToDateRange(startDate, endDate, callback) {
    var ordersQuery = query(collection(db, "orders"), where("tiffinId", "==", auth.currentUser.uid), where("date", ">=", startDate.format(DateFormat)), where("date", "<", endDate.format(DateFormat)), orderBy("date", "asc"));
    var unsubscribe = onSnapshot(ordersQuery, function (querySnapshot) {
        var records = [];
        querySnapshot.forEach(function (doc) {
            records.push(__assign({ id: doc.id, key: doc.id }, doc.data()));
        });
        callback(records.filter(function (order) { return order.status !== OrderStatus.Unpaid; }));
    });
    return unsubscribe;
}
export function subscribeToOrders(callback) {
    var ordersQuery = query(collection(db, "orders"), where("tiffinId", "==", auth.currentUser.uid), where("date", ">=", moment().startOf('day').format(DateFormat)), orderBy("date", "asc"));
    var unsubscribe = onSnapshot(ordersQuery, function (querySnapshot) {
        var records = [];
        querySnapshot.forEach(function (doc) {
            records.push(__assign({ id: doc.id, key: doc.id }, doc.data()));
        });
        callback(records.filter(function (order) { return order.status !== OrderStatus.Unpaid; }));
    });
    return unsubscribe;
}
// Historical orders functions
export function fetchPrevHistoricOrders(before) {
    return new Promise(function (resolve) {
        var ordersRef = collection(db, "orders");
        var ordersQuery = query(ordersRef, where("tiffinId", "==", auth.currentUser.uid), where("date", "<", moment().startOf('day').format(DateFormat)), orderBy("date", "desc"), endBefore(before));
        getDocs(ordersQuery).then(function (querySnapshot) {
            var records = [];
            querySnapshot.forEach(function (doc) {
                records.push(__assign({ id: doc.id, key: doc.id }, doc.data()));
            });
            var orders = records.filter(function (order) { return order.status !== OrderStatus.Unpaid; });
            resolve({
                start: querySnapshot.docs[0],
                orders: orders
            });
        }, function (error) { return console.error(error); });
    });
}
export function fetchNextHistoricOrders(orderLimit, after) {
    return new Promise(function (resolve) {
        var ordersRef = collection(db, "orders");
        var ordersQuery = query(ordersRef, where("tiffinId", "==", auth.currentUser.uid), where("date", "<", moment().startOf('day').format(DateFormat)), orderBy("date", "desc"), startAfter(after), limit(orderLimit));
        getDocs(ordersQuery).then(function (querySnapshot) {
            var records = [];
            querySnapshot.forEach(function (doc) {
                records.push(__assign({ id: doc.id, key: doc.id }, doc.data()));
            });
            var orders = records.filter(function (order) { return order.status !== OrderStatus.Unpaid; });
            resolve({
                end: querySnapshot.docs[querySnapshot.docs.length - 1],
                orders: orders
            });
        }, function (error) { return console.error(error); });
    });
}
export function fetchHistoricOrders(orderLimit) {
    return new Promise(function (resolve) {
        var ordersRef = collection(db, "orders");
        //Max 200 items per request
        if (orderLimit > MAX_HISTORICAL_ORDER_REQUEST_LIMIT) {
            orderLimit = MAX_HISTORICAL_ORDER_REQUEST_LIMIT;
        }
        var ordersQuery = query(ordersRef, where("tiffinId", "==", auth.currentUser.uid), where("date", "<", moment().startOf('day').format(DateFormat)), orderBy("date", "desc"), limit(orderLimit));
        getDocs(ordersQuery).then(function (querySnapshot) {
            var records = [];
            querySnapshot.forEach(function (doc) {
                records.push(__assign({ id: doc.id, key: doc.id }, doc.data()));
            });
            var orders = records.filter(function (order) { return order.status !== OrderStatus.Unpaid; });
            resolve({
                start: querySnapshot.docs[0],
                end: querySnapshot.docs[querySnapshot.docs.length - 1],
                orders: orders
            });
        }, function (error) { return console.error(error); });
    });
}
// Single order functions
export function subscribeToOrder(id, callback) {
    var unsubscribe = onSnapshot(doc(db, "orders", id), function (doc) {
        callback(__assign({ id: id, key: id }, doc.data()));
    });
    return unsubscribe;
}
export function fetchOrder(id) {
    return new Promise(function (resolve, reject) {
        var ordersRef = doc(db, "orders", id);
        getDoc(ordersRef).then(function (docSnap) {
            if (docSnap.exists()) {
                resolve(__assign({ id: id, key: id }, docSnap.data()));
            }
            else {
                reject("Tifyn does not exist");
            }
        }, function (error) {
            reject(error);
        });
    });
}
