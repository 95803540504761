import { useEffect, useMemo, useRef, useState } from "react"

import { SpringModal, toast } from "@tiffin/components"
import { MenuEditSelector } from "../components/menu-editor/MenuEditSelector";
import { MenuCategoryReorder } from "../components/menu-editor/MenuCategoryReorder";
import { MenuEditModifiers } from "../components/menu-editor/MenuEditModifiers";

//Redux
import { useAppDispatch, useAppSelector } from "../hooks"
import { partnerReducer } from "@tiffin/app-common"

//3rd Party
import { useIonToast } from "@ionic/react";
import { Button, Form, Tabs} from "antd"
import { isMobile, isTablet } from "react-device-detect";

interface IMenuFormProps {
  active?: boolean,
  onDone?: (action: "save" | "prev" | "next") => void,
}

const { TabPane } = Tabs;

export function MenuForm({active, onDone, ...props}: IMenuFormProps) {

  //Internal
  const [openCategoryTutorial, setOpenCategoryTutorial] = useState(false)
  const [openMenuTutorial, setOpenMenuTutorial] = useState(false)
  const [discardNewItemPrompt, setDiscardNewItemPrompt] = useState(false)
  const activeKey = useRef<string>("1")
  
  const addingItemRef = useRef<boolean>()
  const discardItemAction = useRef<"prev" | "next">()
  const discardItemRef = useRef<() => void>()

  //Redux
  const menu = useAppSelector((state) => state.partner.menu)
  const unsavedChanges = useAppSelector((state) => state.partner.unsavedMenuChanges)
  const status = useAppSelector((state) => state.partner.menuStatus)
  const error = useAppSelector((state) => state.partner.error)

  const dispatch = useAppDispatch()

  //Internal states
  const navigateFormRef = useRef<"prev" | "next" | null>(null)

  const showModalView = isMobile && !isTablet

  const [present] = useIonToast();

  // Disabling tutorials for now
  // useEffect(() => {
  //   if(active) {
  //     setTimeout(() => {
  //       setOpenMenuTutorial(localStorage.getItem("tifyn-menu-tutorial") ? false : true)
  //       setOpenCategoryTutorial(localStorage.getItem("tifyn-category-tutorial") ? false : true)
  //     }, 500)
  //   }
  // }, [active])

  useEffect(() => {
    if(active) {
      if (status === "saved" && navigateFormRef.current === "next") {
        dispatch(partnerReducer.resetMenuStatus())
        if(onDone) onDone("next")
        navigateFormRef.current = null
      } else if (status === "saved" && navigateFormRef.current === "prev") {
        dispatch(partnerReducer.resetMenuStatus())
        if(onDone) onDone("prev")
        navigateFormRef.current = null
      } else if (status === "saved") {
        dispatch(partnerReducer.resetMenuStatus())
        toast(present, "Saved changes", "success")
        if(onDone) onDone("save")
      }
    }
  }, [active, status, dispatch, onDone, present])

  useEffect(() => {
    if(error) {
      toast(present, error, 'danger')
    }
    dispatch(partnerReducer.resetError())
  }, [error, dispatch, present])

  function isMenuValid() {
    for(let category in menu) {
      if(menu[category].items.length > 0) {
        return true;
      }
    }
    return false;
  }

  function handlePrev(e?: any, ignoreNewIncomplete?: boolean) {
    if(addingItemRef.current && !ignoreNewIncomplete) {
      discardItemAction.current = "prev"
      setDiscardNewItemPrompt(true)
      return
    }

    discardItemAction.current = undefined

    if(unsavedChanges) {
      handleSave()
      //When the save is successful, we will navigate to the prev form
      navigateFormRef.current = "prev"
    } else {
      if(onDone) onDone("prev")
    }
  }

  function handleNext(e?: any, ignoreNewIncomplete?: boolean) {
    if(addingItemRef.current && !ignoreNewIncomplete) {
      discardItemAction.current = "next"
      setDiscardNewItemPrompt(true)
      return
    }

    //Remove focus to prevent user clicking 'enter' button to progress forward
    if(e?.target) {
      e.target.blur()
    }

    if(!isMenuValid()) {
      toast(present, "You must have at least 1 menu item", "danger")
      return;
    }

    discardItemAction.current = undefined

    if(unsavedChanges) {
      handleSave()
      //When the save is successful, we will navigate to the next form
      navigateFormRef.current = "next"
    } else {
      if(onDone) onDone("next")
    }
  }

  function handleSave(e?: any, ignoreNewIncomplete?: boolean) {
    if(addingItemRef.current && !ignoreNewIncomplete) {
      setDiscardNewItemPrompt(true)
      return
    }

    dispatch(partnerReducer.saveMenu())
  }

  const handleCloseMenuTutorial = useMemo(() => {
    return () => {
      setOpenMenuTutorial(false)
      localStorage.setItem("tifyn-menu-tutorial", "opened")
    }
  }, [])

  const handleCloseCategoryTutorial = useMemo(() => {
    return () => {
      setOpenCategoryTutorial(false)
      localStorage.setItem("tifyn-category-tutorial", "opened")
    }
  }, [])

  return (
    <div className={`menu-form-container ${showModalView ? 'menu-modal-view' : ''}`}>
      <Form 
        name="menu" 
        className="partner-form menu-form"
        scrollToFirstError
      >
        <Tabs defaultActiveKey="1" onChange={(key) => activeKey.current = key}>
          <TabPane tab="Menu" key="1">
            <MenuEditSelector 
              view="category"
              openTutorial={openMenuTutorial}
              closeTutorial={handleCloseMenuTutorial}
              addingNewItem={(val) => addingItemRef.current = val}
              discardItemRef={discardItemRef}
              hideArchive={true}
            />
          </TabPane>
          <TabPane tab="Categories" key="2">
            <MenuCategoryReorder openTutorial={openCategoryTutorial} closeTutorial={handleCloseCategoryTutorial} onboardingForm={true} />
          </TabPane>
          <TabPane tab="Modifiers" key="5">
            {
              active && <MenuEditModifiers onboardingForm={true} />
            }
          </TabPane>
        </Tabs>
        <Form.Item className="form-buttons">
          <Button size="large" className="left-btn" onClick={handlePrev} disabled={status === "saving"}>
            Back
          </Button>
          <Button size="large" type="primary" className="right-btn" htmlType="submit" onClick={handleNext} disabled={status === "saving"}>
            Next
          </Button>
          <Button size="large" id="save" className="right-btn" htmlType="submit" onClick={handleSave} loading={status === "saving"}>
            Save
          </Button>
        </Form.Item>
      </Form>
      <SpringModal
        isOpen={discardNewItemPrompt}
        title="New item incomplete"
        onOk={() => {
          if(discardItemAction.current === "next") {
            handleNext(undefined, true)
          } else if(discardItemAction.current === "prev") {
            handlePrev(undefined, true)
          } else {
            handleSave(undefined, true)
          }
          if(discardItemRef.current) discardItemRef.current()
          setDiscardNewItemPrompt(false)
        }}
        onCancel={() => {
          setDiscardNewItemPrompt(false)
          discardItemAction.current = undefined
        }}
        okText="Discard and save"
      >
        You have not completed adding the new item. If you continue, your new item will be discarded.
      </SpringModal>
    </div>
  )
}
