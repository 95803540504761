import styled from "styled-components";

import { useMemo, useState } from "react";

import { DateFormat, IOrderExt, OrderStatus } from "@tiffin/core";
import { OrderCardList } from "../components/OrderCardList";

//3rd party
import { ReactComponent as NoData } from '../assets/no-data.svg';
import { IonContent, IonIcon, IonPage, IonSegment, IonSegmentButton } from "@ionic/react";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

//Redux
import { useAppSelector } from "../hooks"
import { DailyMetrics } from "../components/dashboard/DailyMetrics";
import { DailyHeader } from "../components/dashboard/DailyHeader";
import { OrderList } from "../components/OrderList";
import { checkmarkCircle, flame } from "ionicons/icons";

interface IDashboardDailyView {
  className?: string,
  match?: any
}

export const DashboardDailyView = styled(BaseDashboardDailyView)`
  ion-content {
    --background: var(--ion-color-light);

    .header {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
    }
    
    .daily-overview {
      min-height: calc(100% - 42px);
      background-color: var(--ion-color-light);
      padding: 0 20px 20px;
      padding-bottom: calc(var(--ion-safe-area-bottom,0) + var(--page-padding));
      width: 100%;
      display: grid;
      overflow: auto;

      @media (min-width:961px) {
        min-height: calc(100% - 52px);
      }

      @media (orientation: portrait) {
        padding-bottom: calc(var(--footer-height) + 20px);
      }  
    }

    .active-order-lists {
      display: grid;
      grid-template-columns: calc((100% - 20px)/3) calc((100% - 20px)/3) calc((100% - 20px)/3);
      gap: 10px;
    }

    .inactive-order-lists {
      display: grid;
      grid-template-columns: calc((100% - 10px)/2) calc((100% - 10px)/2);
      gap: 10px;
    }
    
    .active-icon {
      color: orange;
    }

    .inactive-icon {
      color: var(--ion-color-success);
    }

    .order-status-option {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }

    .empty-orders {
      display: flex;
      flex-direction: column;
      gap: 10px;
  
      .empty-message {
        flex-grow: 1;
        background: var(--ion-background-color);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 8px;
        padding: 10%;
  
        svg {
          width: 100px;
          height: fit-content;
        }
      }
    }
  }
`

export function BaseDashboardDailyView({className, ...props}: IDashboardDailyView) {

  //Internal states
  const [showActive, setShowActive] = useState(true)

  //Redux
  const orders = useAppSelector((state) => state.orders)
  
  const date = decodeURIComponent(props.match.params.date)
  const partner = useAppSelector((state) => state.partner)

  const fullView = useMediaQuery({ query: '(min-width:961px)' })

  const filteredOrders = useMemo(() => {
    let result: IOrderExt[] = []

    for(let order of orders.active) {
      if(order.date === date) {
        result.push({...order})
      }
    }

    return result
  }, [orders.active, date])

  const day = moment(date, DateFormat).day();

  let pickupTimings: any, deliveryTimings: any;
  if(partner.adjustedDates?.[date]) {
    pickupTimings = partner.adjustedDates[date].pickupTimings;
    deliveryTimings = partner.adjustedDates[date].deliveryTimings;

  } else {
    pickupTimings = partner.pickup?.pickupDays?.[day];
    deliveryTimings = partner.delivery?.deliveryDays?.[day]
  }

  const renderEmpty = () => {
    return (
      <>
        <DailyMetrics
          date={date}
          orders={filteredOrders}
          pickupTimings={pickupTimings}
          deliveryTimings={deliveryTimings}
          orderPreparationTime={!partner.advance?.preparationEnabled ? partner.advance?.preparationInMins : undefined}
        />
        <div className="empty-message">
          <NoData />
          <span>There are currently no orders</span>
        </div>
      </>
    )
  }
  
  const isEmpty = filteredOrders.length === 0;

  return (
    <IonPage className={className}>
      <IonContent>
        <div className="page-container">
          <div className="header">
            <DailyHeader date={date} />
            {
              fullView &&
              <div>
                <IonSegment mode="ios" value={showActive ? "active" : "inactive"} onIonChange={(e) => setShowActive(e.detail.value === "active")}>
                  <IonSegmentButton value="active">
                    <div className="order-status-option">
                      <IonIcon className="active-icon" icon={flame} />
                      Active
                    </div>
                  </IonSegmentButton>
                  <IonSegmentButton value="inactive">
                    <div className="order-status-option">
                      <IonIcon className="inactive-icon" icon={checkmarkCircle} />
                      Inactive
                    </div>
                  </IonSegmentButton>
                </IonSegment>
              </div>
            }
          </div>
          <div className={`daily-overview ${isEmpty ? 'empty-orders' : ''}`}>
            {
              !isEmpty ?
              <div className="dashboard-main-content">
                {
                  !fullView &&
                  <>
                    <DailyMetrics
                      date={date}
                      orders={filteredOrders}
                      pickupTimings={pickupTimings}
                      deliveryTimings={deliveryTimings}
                      orderPreparationTime={!partner.advance?.preparationEnabled ? partner.advance?.preparationInMins : undefined}
                    />
                    <OrderCardList date={date} orders={filteredOrders} grouped={true} />
                  </>
                }
                {
                  fullView &&
                  <>
                    <DailyMetrics
                      date={date}
                      orders={filteredOrders}
                      pickupTimings={pickupTimings}
                      deliveryTimings={deliveryTimings}
                      orderPreparationTime={!partner.advance?.preparationEnabled ? partner.advance?.preparationInMins : undefined}
                    />
                    <div className={showActive ? "active-order-lists" : "inactive-order-lists"}>
                      {
                        showActive ?
                        <>
                          <div>
                            <OrderList date={date} orders={filteredOrders.filter((val) => val.status === OrderStatus.Unconfirmed)} orderStatus={OrderStatus.Unconfirmed} />
                          </div>
                          <div>
                            <OrderList date={date} orders={filteredOrders.filter((val) => val.status === OrderStatus.Confirmed)} orderStatus={OrderStatus.Confirmed} />
                          </div>
                          <div>
                            <OrderList date={date} orders={filteredOrders.filter((val) => val.status === OrderStatus.Ready)} orderStatus={OrderStatus.Ready} />
                          </div>
                        </>
                        :
                        <>
                          <div>
                            <OrderList date={date} orders={filteredOrders.filter((val) => val.status === OrderStatus.Completed)} orderStatus={OrderStatus.Completed} />
                          </div>
                          <div>
                            <OrderList date={date} orders={filteredOrders.filter((val) => val.status === OrderStatus.Cancelled)} orderStatus={OrderStatus.Cancelled} />
                          </div>
                        </>
                      }
                    </div>
                  </>
                }
              </div>
              :
              renderEmpty()
            }
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}